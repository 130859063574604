import * as Yup from "yup";
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Button, Dialog, Stack, Typography } from '@mui/material';
import { LoadingButton } from "@mui/lab";
// redux
import { useDispatch, useSelector } from "../../../../../redux/store";
import { addPackage, modifyPackage } from "../../../../../redux/slices/package";
// hooks
import useLocales from "../../../../../hooks/useLocales";
// components
import Image from "../../../../../components/Image";
import { FormProvider, RHFCheckbox, RHFTextField } from '../../../../../components/hook-form';
import { DialogHeader } from "../../../../../components/dialog";
import { FormPackageDimension } from "../../../../../components/form";

// ----------------------------------------------------------------------

/**
 * @function NewEditPackageDialog
 * @description
 */

NewEditPackageDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    isEdit: PropTypes.bool,
    currentDetail: PropTypes.object,
};

export default function NewEditPackageDialog({ open, onClose, isEdit, currentDetail }) {
    const dispatch = useDispatch();

    const { translate } = useLocales();

    const { isLoading } = useSelector((state) => state.package);

    const packageSchema = Yup.object().shape({
        nickName: Yup.string().max(20, `${translate('input_schema_package_form_1_1')}`).required(`${translate('input_schema_package_form_1_2')}`),
        length: Yup.number().transform(value => value || 0).min(6, `${translate('input_schema_package_form_2_1')}`)
            .test('checkLengthValue', (value, item) => {
                const perimeter = (item.parent.length + item.parent.width + item.parent.height) * 2 - Math.max(item.parent.length, item.parent.width, item.parent.height);

                return perimeter > 165 ? item.createError({
                    message: `${translate('input_schema_package_form_2_2')}${perimeter}"`,
                }) : true;
            })
            .required(`${translate('input_schema_package_form_2_3')}`),
        width: Yup.number().transform(value => value || 0).min(3, `${translate('input_schema_package_form_3_1')}`)
            .test('checkWidthValue', (value, item) => {
                const perimeter = (item.parent.length + item.parent.width + item.parent.height) * 2 - Math.max(item.parent.length, item.parent.width, item.parent.height);

                return perimeter > 165 ? item.createError({
                    message: `${translate('input_schema_package_form_3_2')}${perimeter}"`,
                }) : true;
            })
            .required(`${translate('input_schema_package_form_3_3')}`),
        height: Yup.number().transform(value => value || 0).min(0.25, `${translate('input_schema_package_form_4_1')}`)
            .test('checkHeightValue', (value, item) => {
                const perimeter = (item.parent.length + item.parent.width + item.parent.height) * 2 - Math.max(item.parent.length, item.parent.width, item.parent.height);

                return perimeter > 165 ? item.createError({
                    message: `${translate('input_schema_package_form_4_2')}${perimeter}"`,
                }) : true;
            })
            .required(`${translate('input_schema_package_form_4_3')}`),
    });

    const getDefaultValues = (detail) => {
        const { nick_name: nickName, dimension, is_default: isDefault } = detail || {};
        const { length, width, height } = dimension || {};

        return {
            nickName: nickName || '',
            type: 'USER',
            length: length || '',
            width: width || '',
            height: height || '',
            isDefault: isDefault || false,
        };
    };

    const defaultValues = useMemo(() => getDefaultValues(currentDetail), [currentDetail]);

    const methods = useForm({
        resolver: yupResolver(packageSchema),
        // mode: 'onChange',
        defaultValues,
    });

    const {
        handleSubmit,
    } = methods;

    const onSubmit = async (values) => {
        try {
            const packageDetail = {
                nick_name: values.nickName || '',
                package_type: values.type || '',
                is_default: values.isDefault || false,
                dimension: {
                    length: values.length || '',
                    width: values.width || '',
                    height: values.height || '',
                },
            };

            if (isEdit) {
                const result = await dispatch(modifyPackage({ id: currentDetail.id, ...packageDetail }));

                if (result) onClose();
            } else {
                const result = dispatch(addPackage({ ...packageDetail }));

                if (result) onClose();
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Dialog fullWidth open={open} onClose={onClose}>
            <DialogHeader
                title={`${translate(`${isEdit ? 'dialog_add_package_2' : 'dialog_add_package_1'}`)}`}
                content={`${translate('dialog_add_package_1_1')}`}
                src="/assets/image/function/package.svg"
            />

            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} sx={{ height: 'calc( 100% - 120px )', overflow: "auto" }}>
                <Box sx={{ pt: 3.75, pb: 7.5, px: { xs: 1.875, sm: 3.75 } }}>
                    <Stack mb={3.75} spacing={3.75}>
                        <Stack spacing={2.5}>
                            <Typography variant="h4">{`${translate('dialog_add_package_1_8')}`}</Typography>

                            <RHFTextField name="nickName" label={`${translate('dialog_add_package_1_9')}`} size="small"/>
                        </Stack>

                        <Stack spacing={2.5}>
                            <Typography variant="h4">{`${translate('dialog_add_package_1_2')}`}</Typography>

                            <Stack direction="row" spacing={2} sx={{ px: 2, py: 1, border: '1px solid rgba(41, 41, 41, 0.2)', borderRadius: '4px' }}>
                                <Image src="/assets/image/packages/custom_package.svg" alt="custom_package" sx={{ flexShrink: 0, width: '80px', height: '80px', '& img': { objectFit: 'contain' } }} visibleByDefault disabledEffect/>

                                <Stack justifyContent="center">
                                    <Typography variant="body1" minHeight="21px">{`${translate('option_custom_package_name')}`}</Typography>
                                    <Typography variant="body1" minHeight="21px" color="text.secondary">{`${translate('option_custom_package_desc')}`}</Typography>
                                </Stack>
                            </Stack>
                        </Stack>

                        <Stack spacing={2.5}>
                            <Typography variant="h4">{`${translate('dialog_add_package_1_4')}`}</Typography>

                            <FormPackageDimension withX/>

                            <RHFCheckbox
                                name="isDefault"
                                label={<Typography variant="body1">{`${translate('dialog_add_package_1_10')}`}</Typography>}
                                color="success"
                                sx={{ width: 'fit-content', '& .MuiCheckbox-root': { padding: "0", marginRight: '8px' } }}
                            />
                        </Stack>
                    </Stack>
                </Box>

                <Stack
                    position="absolute" width={1} height="60px" bottom={0} padding="10px 30px" bgcolor="white"
                    direction="row" justifyContent="flex-end" spacing={3}
                    boxShadow="-40px 40px 80px 10px rgba(0, 0, 0, 0.24)" zIndex={1}
                >
                    <Button color="inherit" variant="outlined" onClick={onClose}>
                        {`${translate('dialog_add_package_1_11')}`}
                    </Button>

                    <LoadingButton variant="contained" loading={isLoading} type="submit">
                        {`${translate(isEdit ? 'dialog_add_package_2_1' : 'dialog_add_package_1_12')}`}
                    </LoadingButton>
                </Stack>
            </FormProvider>
        </Dialog>
    );
}
