// utils
import axios from '../utils/axios';

// ----------------------------------------------------------------------

const getPurchasedList = (filter) => axios.post('/shipments/puchased/list', {
    label_type: filter?.labelType === 'all' ? null : (filter?.labelType || null),
    // store_id: filter?.storeId === 'all' ? null : (filter?.storeId || null),
    // eslint-disable-next-line no-nested-ternary
    status: filter?.status === 'label_created' ? ['created', 'pre_transit'] :
        // eslint-disable-next-line no-nested-ternary
        filter?.status === 'in_transit' ? ['in_transit'] :
            // eslint-disable-next-line no-nested-ternary
            filter?.status === 'delivered' ? ['delivered', 'available_for_pickup'] :
                // eslint-disable-next-line no-nested-ternary
                filter?.status === 'unsuccessful' ? ['return_to_sender', 'failure', 'voided', 'error', 'failed', 'cancelled'] :
                    // eslint-disable-next-line no-nested-ternary
                    filter?.status === 'unknown' ? ['unknown'] :
                        // eslint-disable-next-line no-nested-ternary
                        filter?.status === 'all' ? null :
                            (filter?.status || null) ? [filter?.status] : null,
    carrier_account_id: filter?.carrierAccountId === 'all' ? null : (filter?.carrierAccountId || null),
    // eslint-disable-next-line no-nested-ternary
    keyword: filter?.keyword === 'all' ? null : (filter?.keyword ? filter.keyword.trim() : null),
    per_page: filter?.rowsPerPage || 5,
    current_page: filter?.page || 0,
    order_by: [
        {
            name: "created_at",
            sorting: filter?.sort || "desc",
        },
    ],
});

const getPurchased = (id) => axios.get('/shipments/puchased/info', {
    params: {
        shipments_id: id,
    },
});

// ----------------------------------

const getUnPurchasedList = (filter) => axios.post('/shipments/unpuchased/list', {
    carrier_account_id: filter?.carrierAccountId === 'all' ? null : (filter?.carrierAccountId || null),
    // eslint-disable-next-line no-nested-ternary
    keyword: filter?.keyword === 'all' ? null : (filter?.keyword ? filter.keyword.trim() : null),
    per_page: filter?.rowsPerPage || 5,
    current_page: filter?.page || 0,
    order_by: [
        {
            name: "created_at",
            sorting: filter?.sort || "desc",
        },
    ],
});

const getUnPurchased = (id) => axios.get('/shipments/unpuchased/info', {
    params: {
        shipments_rate_id: id,
    },
});

const getUnPurchasedNumber = () => axios.get('/shipments/unpuchased/count');

const addUnPurchased = (data) => axios.post('/shipments/unpuchased/add', { ...data });

const modifyUnPurchased = (data) => axios.post('/shipments/unpuchased/modify', { ...data });

const deleteUnPurchased = (shipmentsRateId) => axios.delete('/shipments/unpuchased/delete', {
    params: {
        shipments_rate_id: shipmentsRateId,
    },
});

// ----------------------------------

const getPickupList = (filter) => axios.post('/shipments/pickup/list', {
    status: filter?.status === 'all' ? null : (filter?.status || null),
    // eslint-disable-next-line no-nested-ternary
    confirmation_number: filter?.keyword === 'all' ? null : (filter?.keyword ? filter.keyword.trim() : null),
    per_page: filter?.rowsPerPage || 5,
    current_page: filter?.page || 0,
    order_by: [
        {
            name: "created_at",
            sorting: filter?.sort || "desc",
        },
    ],
});

const createPickup = (data) => axios.post('/shipments/pickup/add', { ...data });

const cancelPickup = (id) => axios.get('/shipments/pickup/cancel', {
    params: {
        pickup_id: id,
    },
});

const getPickupShipments = () => axios.get('/shipments/pickup/shipments', {
    params: {
        carrier: 'USPS',
    },
});

// ----------------------------------

const buyLabel = (data) => axios.post('/shipments/label/buy', { ...data });

const getRatesList = (shipment) => axios.post('/shipments/label/rate', { ...shipment });

const voidLabel = (id) => axios.get('/shipments/label/void', {
    params: {
        shipments_id: id,
    },
});

const labelPrinted = (shipmentsId) => axios.get('/shipments/label/print', {
    params: {
        shipments_id: shipmentsId,
    },
});

const labelPreview = (shipment) => axios.post('/shipments/label/preview', {
        shipments_id: shipment.shipmentsId,
        size: shipment.size,
        position: shipment.position,
        share_code: shipment.shareCode,
    }, { responseType: 'blob' },
);

const labelShareCode = (shipmentId) => axios.post('/shipments/label/share', {
    shipments_id: shipmentId,
});

const getQRCode = (shipmentId) => axios.post('/shipments/qrcode/preview', {
    shipments_id: shipmentId,
}, { responseType: 'blob' });

const getPrintType = () => axios.get('/shipments/print_type/get');

// ----------------------------------------------------------------------

const getScanFormList = (filter) => axios.post('/shipments/sacn_form/list', {
    // eslint-disable-next-line no-nested-ternary
    keyword: filter?.keyword === 'all' ? null : (filter?.keyword ? filter.keyword.trim() : null),
    per_page: filter?.rowsPerPage || 5,
    current_page: filter?.page || 0,
    order_by: [
        {
            name: "created_at",
            sorting: filter?.sort || "desc",
        },
    ],
});

const addScanForm = (data) => axios.post('shipments/sacn_form/add', { ...data });

const getScanFormShipment = (data) => axios.get(`/shipments/sacn_form/shipments`, {
    params: data,
});

const printScanForm = (id) => axios.get('/shipments/sacn_form/print', {
    responseType: 'blob',
    params: {
        id,
    },
});

// ----------------------------------------------------------------------

const labelDownload = (detail) => axios.post('/shipments/label/download', {
    ...detail,
});

const labelPrint = (detail) => axios.post('/shipments/label/batch_print', {
    ...detail,
}, { responseType: 'blob' });

// ----------------------------------------------------------------------

export {
    getPurchasedList,
    getPurchased,
    // ----------------------------------
    getUnPurchasedList,
    getUnPurchased,
    getUnPurchasedNumber,
    addUnPurchased,
    modifyUnPurchased,
    deleteUnPurchased,
    // ----------------------------------
    getPickupList,
    createPickup,
    cancelPickup,
    getPickupShipments,
    //----------------------------
    getScanFormList,
    addScanForm,
    getScanFormShipment,
    printScanForm,
    // ----------------------------------
    buyLabel,
    getRatesList,
    voidLabel,
    labelPrinted,
    labelPreview,
    labelShareCode,
    getQRCode,
    getPrintType,
    // ----------------------------------
    labelDownload,
    labelPrint,
};
