// ----------------------------------------------------------------------

export default function Typography(theme) {
    const MAIN_TEXT = theme.palette.grey[800];

    return {
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: MAIN_TEXT
                },
                paragraph: {
                    marginBottom: theme.spacing(2),
                },
                gutterBottom: {
                    marginBottom: theme.spacing(1),
                },
            },
        },
    };
}
