import axios from 'axios';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

export const controller = new AbortController();

const axiosInstance = axios.create({
    baseURL: HOST_API,
    headers: {
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
    },
});

axios.interceptors.request.use((config) => config, (error) => Promise.reject(error));
axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.data) {
            window.localStorage.setItem("error", JSON.stringify(error.response.data));
        } else {
            window.localStorage.setItem("error", "Something went wrong");
        }

        return Promise.reject((error.response && error.response.data) || 'Something went wrong')
    },
);

export default axiosInstance;
