import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import { batch } from "react-redux";
// api
import {
    register as registerAPI,
    login as loginAPI,
    logout as logoutAPI,
    forgotPassword as forgotPasswordAPI,
    resetPassword as resetPasswordAPI,
    activeUser as activeUserAPI,
    verifyToken as verifyTokenAPI,
} from "../api/auth";
import {
    getUser as getUserAPI,
    getCompany as getCompanyAPI,
    getCompanyPlan as getCompanyPlanAPI,
    modifyCompany as modifyCompanyAPI,
} from "../api/user";
// redux
import { persistor, store, dispatch as reduxDispatch } from "../redux/store";
import { getTour } from "../redux/slices/tour";
import { getAddressList } from "../redux/slices/address";
import { getAllPackageList } from "../redux/slices/package";
import { getBalance, getMethodList } from "../redux/slices/payment";
import { getOptionStoreList, getStoreList } from "../redux/slices/store";
import { getOrderList } from "../redux/slices/order";
import { getPermissionList } from "../redux/slices/permission";
// utils
import { isValidToken, setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
    company: null,
    permissions: [],
    needCompletePlanConfig: false,
    locked: false,
};

const handlers = {
    INITIALIZE: ( state, action ) => {
        const {isAuthenticated, user, company, permissions, needCompletePlanConfig, locked} = action.payload;

        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user: {
                ...state.user,
                ...user,
            },
            company: {
                ...state.company,
                ...company,
            },
            permissions,
            needCompletePlanConfig: needCompletePlanConfig === true || needCompletePlanConfig === 'true',
            locked,
        };
    },
    LOGIN: ( state, action ) => {
        const {user, company, permissions, needCompletePlanConfig, locked} = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user,
            company,
            permissions,
            needCompletePlanConfig: needCompletePlanConfig === true || needCompletePlanConfig === 'true',
            locked,
        };
    },
    LOGOUT: ( state ) => ({
        ...state,
        isAuthenticated: false,
        user: null,
        company: null,
        permissions: [],
        needCompletePlanConfig: false,
        locked: false,
    }),
    REGISTER: ( state, action ) => {
        const {user} = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user,
        };
    },
    GET_USER: ( state, action ) => {
        const {user, company} = action.payload;

        return {
            ...state,
            user: {
                ...state.user,
                ...user,
            },
            company: {
                ...state.company,
                ...company,
            },
        };
    },
    MODIFY_USER: ( state, action ) => {
        const {user, company} = action.payload;

        return {
            ...state,
            user: {
                ...state.user,
                ...user,
            },
            company: {
                ...state.company,
                ...company,
            },
        };
    },
    MODIFY_COMPANY: ( state, action ) => {
        const {company} = action.payload;

        return {
            ...state,
            company: {
                ...state.company,
                ...company,
            },
        };
    },
    NEED_COMPLETE_PLAN_UPDATE: ( state, action ) => {
        const {needCompletePlanConfig} = action.payload;

        return {
            ...state,
            needCompletePlanConfig: needCompletePlanConfig === true || needCompletePlanConfig === 'true',
        };
    },
};

const reducer = ( state, action ) => (handlers[action.type] ? handlers[action.type]( state, action ) : state);

const AuthContext = createContext( {
    ...initialState,
    method: 'jwt',
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    verifyToken: () => Promise.resolve(),
    register: () => Promise.resolve(),
    forgotPassword: () => Promise.resolve(),
    resetPassword: () => Promise.resolve(),
    activeUser: () => Promise.resolve(),
    getUser: () => Promise.resolve(),
    modifyUser: () => Promise.resolve(),
    modifyUserAvatar: () => Promise.resolve(),
    checkPermission: () => Promise.resolve(),
    completePlanConfigUpdate: () => Promise.resolve(),
} );

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
    children: PropTypes.node,
};

function AuthProvider( {children} ) {
    const [state, dispatch] = useReducer( reducer, initialState );

    useEffect( () => {
        const initialize = async () => {
            try {
                const accessToken = localStorage.getItem( 'accessToken' );

                if (accessToken && isValidToken( accessToken )) {
                    setSession( accessToken );

                    const user = JSON.parse( localStorage.getItem( 'user' ) );
                    const permissions = JSON.parse( localStorage.getItem( 'permissions' ) );
                    const needCompletePlanConfig = localStorage.getItem( 'needCompletePlanConfig' );
                    const isLocked = localStorage.getItem( 'isLocked' );

                    let tempUser;
                    let tempCompany;
                    let tempCompanyPlan;

                    const responses = await Promise.all( [
                        getUserAPI(),
                        getCompanyAPI(),
                        getCompanyPlanAPI(),
                    ] );

                    const {status: statusA, data: dataA} = responses[0];
                    const {status: statusB, data: dataB} = responses[1];
                    const {status: statusC, data: dataC} = responses[2];

                    if (statusA === 200) tempUser = dataA;
                    if (statusB === 200) tempCompany = dataB;
                    if (statusC === 200) tempCompanyPlan = dataC;

                    dispatch( {
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: true,
                            user: {
                                ...user,
                                ...tempUser,
                            },
                            company: {
                                ...tempCompany,
                                ...tempCompanyPlan,
                            },
                            permissions,
                            needCompletePlanConfig,
                            locked: isLocked === 'true',
                        },
                    } );
                } else {
                    dispatch( {
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                            company: null,
                            permissions: [],
                            needCompletePlanConfig: false,
                            locked: false,
                        },
                    } );
                }
            } catch (err) {
                console.error( err );

                dispatch( {
                    type: 'INITIALIZE',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                        company: null,
                        permissions: [],
                        needCompletePlanConfig: false,
                        locked: false,
                    },
                } );
            }
        };

        initialize().then( null );
    }, [] );

    const login = async ( email, password, isRemember, code, fingerprint ) => {
        try {
            const response = await loginAPI( email, password, isRemember, code, fingerprint );

            const {status, data} = response;

            if (status === 200) {
                let storeList = [];

                const {auth_token: accessToken, user, permissions, need_complete_plan_config: needCompletePlanConfig, company_plan: companyPlan, locked} = data;

                // sessionStorage.removeItem('email-recovery');
                localStorage.removeItem( 'email-recovery' );

                setSession( accessToken );

                localStorage.setItem( 'user', JSON.stringify( user ) );
                localStorage.setItem( 'permissions', JSON.stringify( permissions ) );
                localStorage.setItem( 'needCompletePlanConfig', JSON.stringify( needCompletePlanConfig ) );
                localStorage.setItem( 'isLocked', JSON.stringify( locked ) );

                await batch( async () => {
                    reduxDispatch( getTour() );
                    reduxDispatch( getAddressList() );
                    reduxDispatch( getAllPackageList() );
                    reduxDispatch( getMethodList() );
                    reduxDispatch( getOrderList( {} ) );
                    reduxDispatch( getPermissionList() );
                    reduxDispatch( getBalance() );
                    reduxDispatch( getOptionStoreList() );

                    const localStoreList = await reduxDispatch( getStoreList() );
                    storeList = [...localStoreList];
                } )

                dispatch( {
                    type: 'LOGIN',
                    payload: {
                        user,
                        permissions,
                        needCompletePlanConfig,
                        company: companyPlan,
                        locked,
                    },
                } );

                return {
                    storeList,
                    permissionList: permissions
                };
            }
        } catch (error) {
            await Promise.reject( error );
        }
    };

    const activeUser = async ( email, code ) => {
        try {
            const response = await activeUserAPI( {code, email} );

            const {status, data} = response;

            if (status === 200) {
                const {auth_token: accessToken, user, permissions, need_complete_plan_config: needCompletePlanConfig, company_plan: companyPlan, locked} = data;

                // sessionStorage.removeItem('email-recovery');
                localStorage.removeItem( 'email-recovery' );

                setSession( accessToken );

                localStorage.setItem( 'user', JSON.stringify( user ) );
                localStorage.setItem( 'permissions', JSON.stringify( permissions ) );
                localStorage.setItem( 'needCompletePlanConfig', JSON.stringify( needCompletePlanConfig ) );
                localStorage.setItem( 'isLocked', JSON.stringify( locked ) );

                await batch( async () => {
                    reduxDispatch( getTour() );
                    reduxDispatch( getPermissionList() );
                } )

                dispatch( {
                    type: 'LOGIN',
                    payload: {
                        user,
                        permissions,
                        needCompletePlanConfig,
                        company: companyPlan,
                        locked,
                    },
                } );

                return {
                    permissionList: permissions
                };
            }
        } catch (error) {
            await Promise.reject( error );
        }
    };

    const register = async ( detail ) => {
        try {
            const response = await registerAPI( detail );

            const {status} = response;

            return status === 200;
        } catch (error) {
            await Promise.reject( error );
        }
    };

    const forgotPassword = async ( detail ) => {
        try {
            const response = await forgotPasswordAPI( detail );

            const {status, data} = response;

            return status === 200 && data?.result === 'success';
        } catch (error) {
            await Promise.reject( error );
        }
    };

    const resetPassword = async ( key, password ) => {
        try {
            const response = await resetPasswordAPI( key, password );

            const {status, data} = response;

            return status === 200 && data?.result === 'success';

            // if (status === 200 && data?.result === 'success') {
            //     // const emailRecovery = sessionStorage.getItem('email-recovery');
            //     const emailRecovery = localStorage.getItem('email-recovery');
            //
            //     await login(emailRecovery, password, false)
            // }
        } catch (error) {
            await Promise.reject( error );
        }
    };

    const logout = async () => {
        const response = await logoutAPI();

        const {status, data} = response;

        if (status === 200 && data?.result === 'success') {
            dispatch( {type: 'LOGOUT'} );

            setSession( null );

            localStorage.removeItem( 'user' );
            localStorage.removeItem( 'permissions' );
            localStorage.removeItem( 'needCompletePlanConfig' );
            localStorage.removeItem( 'isLocked' );
            localStorage.clear();

            await persistor.purge();

            store.dispatch( {type: "USER_LOGOUT"} );
        }
    };

    const verifyToken = async () => {
        try {
            const response = await getUserAPI();

            const {status} = response;

            return status === 200;
        } catch (error) {
            console.error( error );

            return error;
        }
    };

    const getUser = async () => {
        try {
            let tempUser;
            let tempCompany;
            let tempCompanyPlan;

            const responses = await Promise.all( [
                getUserAPI(),
                getCompanyAPI(),
                getCompanyPlanAPI(),
            ] );

            const {status: statusA, data: dataA} = responses[0];
            const {status: statusB, data: dataB} = responses[1];
            const {status: statusC, data: dataC} = responses[2];

            if (statusA === 200) tempUser = dataA;
            if (statusB === 200) tempCompany = dataB;
            if (statusC === 200) tempCompanyPlan = dataC;

            dispatch( {
                type: 'GET_USER',
                payload: {
                    user: {
                        ...tempUser,
                    },
                    company: {
                        ...tempCompany,
                        ...tempCompanyPlan,
                    },
                },
            } );
        } catch (error) {
            console.error( error );
        }
    };

    const modifyUserAvatar = ( detail ) => {
        dispatch( {
            type: 'MODIFY_USER',
            payload: {
                user: {...detail},
            },
        } );
    };

    const modifyUser = async ( detail ) => {
        const response = await modifyCompanyAPI( {...detail} );

        const {status, data} = response;

        if (status === 200) {
            const {result} = data;

            if (result === 'success') {
                dispatch( {
                    type: 'MODIFY_USER',
                    payload: {
                        user: {
                            first_name: detail?.first_name ?? '',
                            last_name: detail?.last_name ?? '',
                        },
                        company: {
                            company: detail?.company_name ?? '',
                        },
                    },
                } );
            }
        }

        return response;
    };

    const checkPermission = ( key, permissions ) => {
        if (key === 'default') return true;

        // eslint-disable-next-line no-nested-ternary
        const tempPermissions = (permissions && permissions.length > 0) ? permissions : (state.permissions && state.permissions.length > 0) ? state.permissions : [];

        const result = tempPermissions.findIndex( _permission => _permission === key );

        return result > -1;
    };

    const completePlanConfigUpdate = () => {
        localStorage.setItem( 'needCompletePlanConfig', 'false' );

        dispatch( {
            type: 'NEED_COMPLETE_PLAN_UPDATE',
            payload: {
                needCompletePlanConfig: false,
            },
        } );
    };

    return (
        <AuthContext.Provider
            value={ {
                ...state,
                method: 'jwt',
                login,
                logout,
                verifyToken,
                register,
                forgotPassword,
                resetPassword,
                activeUser,
                getUser,
                modifyUser,
                modifyUserAvatar,
                checkPermission,
                completePlanConfigUpdate,
            } }
        >
            { children }
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthProvider };
