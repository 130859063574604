import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
    isNotificationDisplayed: false,
    shipmentTab: 'shipment',
    orderTab: 'orderAll',
    accountTab: 'accountGeneral',
    itemTab: 'item',
};

const slice = createSlice({
    name: 'other',
    initialState,
    reducers: {
        // UPDATE NOTIFICATION DISPLAY
        updateNotificationDisplayed(state) {
            state.isNotificationDisplayed = true;
        },

        // UPDATE SHIPMENT TAB
        updateShipmentTab(state, action) {
            state.shipmentTab = action.payload;
        },

        // UPDATE ORDER TAB
        updateOrderTab(state, action) {
            state.orderTab = action.payload;
        },

        // UPDATE ACCOUNT TAB
        updateAccountTab(state, action) {
            state.accountTab = action.payload;
        },

        // UPDATE ITEM TAB
        updateItemTab(state, action) {
            state.itemTab = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { updateNotificationDisplayed, updateShipmentTab, updateOrderTab, updateAccountTab, updateItemTab } = slice.actions;

// ----------------------------------------------------------------------
