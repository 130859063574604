// ----------------------------------------------------------------------

export default function Button(theme) {
    const MAIN_TEXT = theme.palette.grey[800];

    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
                sizeMedium: {
                    height: '40px',
                    fontWeight: 400,
                    fontSize: '14px',
                },
                sizeLarge: {
                    // height: 48,
                    fontWeight: 700,
                    fontSize: '18px',
                },
                // contained
                containedInherit: {
                    color: MAIN_TEXT,
                    boxShadow: theme.customShadows.z8,
                    '&:hover': {
                        backgroundColor: theme.palette.grey[400],
                    },
                },
                containedPrimary: {
                    boxShadow: theme.customShadows.primary,
                },
                containedSecondary: {
                    boxShadow: theme.customShadows.secondary,
                },
                containedInfo: {
                    boxShadow: theme.customShadows.info,
                },
                containedSuccess: {
                    boxShadow: theme.customShadows.success,
                },
                containedWarning: {
                    boxShadow: theme.customShadows.warning,
                },
                containedError: {
                    boxShadow: theme.customShadows.error,
                },
                // outlined
                outlinedInherit: {
                    border: `1px solid ${theme.palette.grey[500_32]}`,
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                    },
                },
                text: {
                    color: MAIN_TEXT,
                },
                textInherit: {
                    color: MAIN_TEXT,
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                    },
                },
                textPrimary: {
                    color: theme.palette.primary.main,
                },
                textSecondary: {
                    color: theme.palette.secondary.main,
                },
                textSuccess: {
                    color: theme.palette.success.main,
                },
                textError: {
                    color: theme.palette.error.main,
                },
                textInfo: {
                    color: theme.palette.info.main,
                },
                textWarning: {
                    color: theme.palette.warning.main,
                },
            },
        },
    };
}
