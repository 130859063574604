import * as Yup from "yup";
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { NavLink as RouterLink } from "react-router-dom";
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Button, Dialog, ListSubheader, MenuItem, Stack, Typography } from '@mui/material';
import { LoadingButton } from "@mui/lab";
// constants
import { UNIT_OPTIONS } from "../../../../../constants/options";
// routes
import { PATH_DASHBOARD } from "../../../../../routes/paths";
// hooks
import useLocales from "../../../../../hooks/useLocales";
import useAuth from "../../../../../hooks/useAuth";
// redux
import { batch, useDispatch, useSelector } from "../../../../../redux/store";
import { addShippingRule, modifyShippingRule, resetShippingRules } from "../../../../../redux/slices/shippingRule";
import { getAllPackageList } from "../../../../../redux/slices/package";
import { getOptionStoreList } from "../../../../../redux/slices/store";
// components
import Image from "../../../../../components/Image";
import { FormProvider, RHFSelect, RHFTextField } from '../../../../../components/hook-form';
import { DialogHeader } from "../../../../../components/dialog";
import { FormPackageDimension } from "../../../../../components/form";
import DefaultLabel from "../../../../../components/customized/DefaultLabel";
// sections
import { OrderNewItemDetail } from "../../../order/detail";
// utils
import { getWeightByUnit, getParcel } from "../../../../../utils/getRequestData";
import PermissionGuard from "../../../../../guards/PermissionGuard";

// ----------------------------------------------------------------------

/**
 * @function NewEditShippingRuleDialog
 * @description
 */

NewEditShippingRuleDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    isEdit: PropTypes.bool,
    currentDetail: PropTypes.object,
};

export default function NewEditShippingRuleDialog( {open, onClose, isEdit, currentDetail} ) {
    const dispatch = useDispatch();

    const {translate} = useLocales();

    const {permissions} = useAuth();

    const {isLoading} = useSelector( ( {shippingRule} ) => shippingRule );
    const {optionStoreList} = useSelector( ( {store} ) => store );
    const {allPackageList} = useSelector( ( state ) => state.package );

    const [storePermission, setStorePermission] = useState( false );

    const ShippingRuleSchema = Yup.object().shape( {
        storeId: Yup.string().nullable().required( "Store is required" ),
        orderItems: Yup.array().test( 'checkSelected', ( value, item ) => {
            const orderItems = value.map( product => {
                if (product) {
                    return {
                        id: product?.selectedItem?.id || '',
                        quantity: product?.qty || 0,
                    };
                }
                return null;
            } ).filter( product => Boolean( product ) && product?.id && product?.quantity );

            return orderItems.length === 0 ? item.createError( {
                message: `${ translate( 'input_schema_add_edit_order_form_9' ) }`,
            } ) : true;
        } ),
        length: Yup.number().transform( value => value || 0 ).when( 'packageType', {
            is: 1,
            then: Yup.number().transform( value => value || 0 ).min( 6, `${ translate( 'input_schema_calculator_domestic_form_4_1' ) }` ).required( `${ translate( 'input_schema_calculator_domestic_form_4_2' ) }` ),
        } ).test( "check perimeter", ( value, item ) => {
            const perimeter = (value + item.parent.width + item.parent.height) * 2 - Math.max( value, item.parent.width, item.parent.height );
            return (item.parent.packageType !== 1 || (item.parent.packageType === 1 && perimeter <= 165)) ? true :
                item.createError( {
                    message: `${ translate( 'input_schema_calculator_domestic_form_4_3' ) }${ perimeter }"`,
                } );
        } ),
        width: Yup.number().transform( value => value || 0 ).when( 'packageType', {
            is: 1,
            then: Yup.number().transform( value => value || 0 ).min( 3, `${ translate( 'input_schema_calculator_domestic_form_5_1' ) }` ).required( `${ translate( 'input_schema_calculator_domestic_form_5_2' ) }` ),
        } ).test( "check perimeter", ( value, item ) => {
            const perimeter = (item.parent.length + value + item.parent.height) * 2 - Math.max( item.parent.length, value, item.parent.height );
            return (item.parent.packageType !== 1 || (item.parent.packageType === 1 && perimeter <= 165)) ? true :
                item.createError( {
                    message: `${ translate( 'input_schema_calculator_domestic_form_5_3' ) }${ perimeter }"`,
                } );
        } ),
        height: Yup.number().transform( value => value || 0 ).when( 'packageType', {
            is: 1,
            then: Yup.number().transform( value => value || 0 ).min( 0.25, `${ translate( 'input_schema_calculator_domestic_form_6_1' ) }` ).required( `${ translate( 'input_schema_calculator_domestic_form_6_2' ) }` ),
        } ).test( "check perimeter", ( value, item ) => {
            const perimeter = (item.parent.length + item.parent.width + value) * 2 - Math.max( item.parent.length, item.parent.width, value );
            return (item.parent.packageType !== 1 || (item.parent.packageType === 1 && perimeter <= 165)) ? true :
                item.createError( {
                    message: `${ translate( 'input_schema_calculator_domestic_form_6_3' ) }${ perimeter }"`,
                } );
        } ),
        weight: Yup.number().transform( value => value || 0 ).when( 'weightUnit', {
            is: 'lb',
            then: Yup.number().transform( value => value || 0 ).when( 'packageType', ( packageType ) => {
                if (allPackageList.find( p => p.pid === packageType )?.code === 'LETTER')
                    return Yup.number().transform( value => value || 0 ).min( 0.01, `${ translate( 'input_schema_ship_form_8_1_1' ) }` ).max( 0.21, `${ translate( 'input_schema_ship_form_8_2_2_1' ) }` );
                return Yup.number().transform( value => value || 0 ).min( 0.01, `${ translate( 'input_schema_ship_form_8_1_1' ) }` ).required( `${ translate( 'input_schema_ship_form_8_1_3' ) }` );
            } ),
        } ).when( 'weightUnit', {
            is: 'oz',
            then: Yup.number().transform( value => value || 0 ).when( 'packageType', ( packageType ) => {
                if (allPackageList.find( p => p.pid === packageType )?.code === 'LETTER')
                    return Yup.number().transform( value => value || 0 ).min( 0.16, `${ translate( 'input_schema_ship_form_8_2_1' ) }` ).max( 3.43, `${ translate( 'input_schema_ship_form_8_2_2_1' ) }` );
                return Yup.number().transform( value => value || 0 ).min( 0.16, `${ translate( 'input_schema_ship_form_8_2_1' ) }` ).required( `${ translate( 'input_schema_ship_form_8_2_3' ) }` );
            } ),
        } ).when( 'weightUnit', {
            is: 'kg',
            then: Yup.number().transform( value => value || 0 ).when( 'packageType', ( packageType ) => {
                if (allPackageList.find( p => p.pid === packageType )?.code === 'LETTER')
                    return Yup.number().transform( value => value || 0 ).min( 0.01, `${ translate( 'input_schema_ship_form_8_3_1' ) }` ).max( 0.09, `${ translate( 'input_schema_ship_form_8_2_2_1' ) }` );
                return Yup.number().transform( value => value || 0 ).min( 0.01, `${ translate( 'input_schema_ship_form_8_3_1' ) }` ).required( `${ translate( 'input_schema_ship_form_8_3_3' ) }` );
            } ),
        } ).when( 'weightUnit', {
            is: 'g',
            then: Yup.number().transform( value => value || 0 ).when( 'packageType', ( packageType ) => {
                if (allPackageList.find( p => p.pid === packageType )?.code === 'LETTER')
                    return Yup.number().transform( value => value || 0 ).min( 4.54, `${ translate( 'input_schema_ship_form_8_4_1' ) }` ).max( 97.5, `${ translate( 'input_schema_ship_form_8_2_2_1' ) }` );
                return Yup.number().transform( value => value || 0 ).min( 4.54, `${ translate( 'input_schema_ship_form_8_4_1' ) }` ).required( `${ translate( 'input_schema_ship_form_8_4_3' ) }` );
            } ),
        } ),
    } );

    const getDefaultValues = ( detail ) => {
        const {predefined_package: predefinedPackage, store_id: storeId, items = [], length, width, height, weight, signature} = detail || {};
        const {id} = predefinedPackage || {};

        const tempItems = items.map( _item => ({
            searchKeyword: _item?.sku || _item?.title,
            searchResult: [],
            searchPage: 0,
            selectedItem: _item ?? null,
            qty: _item?.quantity ?? '',
        }) );

        return {
            packageType: allPackageList.find( p => p.id === id )?.pid || 1,
            length: length || '',
            width: width || '',
            height: height || '',
            storeId: storeId || '',
            weightUnit: 'lb',
            weight: weight || '',
            signature: signature || 'NO_SIGNATURE',
            orderItems: tempItems.length > 0 ? tempItems : [{
                searchKeyword: '',
                searchResult: [],
                searchPage: 0,
                selectedItem: null,
                qty: '',
            }],
        };
    };

    const defaultValues = useMemo( () => getDefaultValues( currentDetail ), [currentDetail] );

    const methods = useForm( {
        resolver: yupResolver( ShippingRuleSchema ),
        mode: 'onChange',
        defaultValues,
    } );

    const {
        setValue,
        watch,
        handleSubmit,
    } = methods;

    const watched = watch();

    const handleSelectOnChange = ( event ) => {
        setValue( 'storeId', event.target.value );

        setValue( 'orderItems', [{
            searchKeyword: '',
            searchResult: [],
            searchPage: 0,
            selectedItem: null,
            qty: '',
        }] );
    };

    const handleInputChange = ( type, value ) => {
        if (value !== '') {
            value = Math.round( value * 100 ) / 100;
            setValue( type, Math.round( value * 100 ) / 100 );
        }
    };

    const onSubmit = async ( values ) => {
        try {
            const shippingRuleItems = values.orderItems.map( product => {
                if (product) {
                    return {
                        id: product?.selectedItem?.id || '',
                        quantity: product?.qty || 0,
                    };
                }
                return null;
            } ).filter( product => Boolean( product ) && product?.id && product?.quantity );

            let selectedPackage;

            if (values.packageType !== 1) {
                selectedPackage = allPackageList.find( ( p ) => p.pid === values.packageType );
            }

            const weightValue = getWeightByUnit( values.weight, values.weightUnit );
            const parcel = getParcel( values, selectedPackage );

            const shippingRuleDetail = {
                store_id: Number( values.storeId ),
                items: shippingRuleItems,
                signature: values?.signature || 'NO_SIGNATURE',
                weight: weightValue,
                predefined_package_id: parcel.carrier === 'USER' ? null : selectedPackage?.id,
                length: parcel?.length || values?.length || 0,
                width: parcel?.width || values?.width || 0,
                height: parcel?.height || values?.height || 0,
            };

            let result;

            if (isEdit) {
                result = await dispatch( modifyShippingRule( {id: currentDetail.id, ...shippingRuleDetail} ) );
            } else {
                result = await dispatch( addShippingRule( {...shippingRuleDetail} ) );
            }

            if (result) onClose();
        } catch (error) {
            console.error( error );
        }
    };

    // ----------------------------------

    useEffect( () => batch( () => {
        dispatch( resetShippingRules() );
        dispatch( getAllPackageList() );
        dispatch( getOptionStoreList() );
    } ), [dispatch] );

    useEffect( () => {
        const current = (permissions || []).find( _p => _p === 'integration.store' );
        if (current === 'integration.store') {
            setStorePermission( true );
        }
    }, [permissions] );

    return (
        <Dialog fullWidth open={ open } onClose={ onClose }>
            <DialogHeader
                title={ isEdit ? `${ translate( 'dialog_add_shipping_rule_2' ) }` : `${ translate( 'dialog_add_shipping_rule_1' ) }` }
                content={ `${ translate( 'dialog_add_shipping_rule_1_1' ) }` }
                src="/assets/image/function/shipping_rule.svg"
            />

            <FormProvider methods={ methods } onSubmit={ handleSubmit( onSubmit ) } sx={ {height: 'calc( 100% - 120px )', overflow: "auto"} }>
                <Box sx={ {pt: 3.75, pb: 7.5, px: {xs: 1.875, sm: 3.75}} }>
                    <Stack mb={ 3.75 } spacing={ 3.75 }>
                        <Stack spacing={ 2.5 }>
                            <Typography variant="h4">{ `${ translate( 'dialog_add_shipping_rule_1_2' ) }` }</Typography>

                            <RHFSelect
                                name="storeId"
                                label={ `${ translate( 'dialog_add_shipping_rule_1_2_1' ) }` }
                                size="small"
                                onChange={ handleSelectOnChange }
                                SelectProps={ {
                                    native: false,
                                    MenuProps: {
                                        sx: {
                                            maxHeight: '236px',
                                        },
                                    },
                                } }
                            >
                                { optionStoreList?.map( ( option ) => (
                                    <MenuItem
                                        key={ `store-${ option.id }` }
                                        value={ option.id }
                                        sx={ {mx: 1, my: 0.5, borderRadius: 0.75, typography: 'body2'} }
                                    >
                                        { option.name }
                                    </MenuItem>
                                ) ) }

                                { optionStoreList?.length === 0 && !storePermission &&
                                    <MenuItem
                                        value={ null }
                                        sx={ {mx: 1, my: 0.5, borderRadius: 0.75, typography: 'body2'} }
                                    >
                                        { `${ translate( 'dialog_add_shipping_rule_1_2_2' ) }` }
                                    </MenuItem>
                                }

                                <PermissionGuard permission="integration.store">
                                    <Button
                                        component={ RouterLink } to={ PATH_DASHBOARD.settings.integration } variant="text" fullWidth
                                        sx={ {width: '-webkit-fill-available', height: '34px', margin: '4px 8px', padding: '6px 16px', justifyContent: 'flex-start', color: 'info.main', fontWeight: 600, lineHeight: '1.375rem'} }
                                    >
                                        { `${ translate( `dialog_item_3_1_1` ) }` }
                                    </Button>
                                </PermissionGuard>
                            </RHFSelect>
                        </Stack>

                        <OrderNewItemDetail/>

                        <Stack spacing={ 2.5 }>
                            <Typography variant="h4">{ `${ translate( 'dialog_add_shipping_rule_1_3' ) }` }</Typography>

                            <Stack spacing={ 2.5 }>
                                <RHFSelect
                                    name="packageType"
                                    inputProps={ {sx: {py: 1.25, pl: 1}} }
                                    SelectProps={ {
                                        native: false,
                                        MenuProps: {
                                            sx: {
                                                maxHeight: '320px', maxWidth: '320px',
                                            },
                                        },
                                    } }
                                    onChange={ ( event ) => setValue( "packageType", event.target.value ) }
                                >
                                    { allPackageList && allPackageList.map( ( _item, index ) => {
                                            if (_item.isSubheader) {
                                                return (
                                                    <ListSubheader key={ `subheader-${ index }` }>{ `${ translate( `option_subheader_${ _item?.name.replaceAll( ' ', '_' ) }` ) }` }</ListSubheader>
                                                );
                                            }

                                            return (
                                                <MenuItem key={ `item-${ index }` } value={ _item.pid }>
                                                    <Stack direction="row" alignItems="center" spacing={ 2 } width={ 1 }>
                                                        <Image
                                                            src={ `/assets/image/packages/${ _item.code.toLowerCase() === 'package' ? 'custom_package.svg' : `${ _item.code.toLowerCase() }.png` }` } alt=""
                                                            sx={ {flexShrink: 0, width: '80px', height: '80px', '& img': {objectFit: 'contain'}} }
                                                            onError={ ( event ) => {
                                                                event.target.src = '/assets/image/packages/custom_package.svg';
                                                            } }
                                                            visibleByDefault disabledEffect
                                                        />

                                                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={ 2 } width={ 1 }>
                                                            <Stack overflow="hidden">
                                                                <Typography variant="body1" overflow="hidden" sx={ {textOverflow: 'ellipsis'} } nowrap>
                                                                    { _item?.type === 'custom_package' ? `${ translate( `option_${ _item.type }_name` ) }` : _item?.nick_name ?? _item?.name }
                                                                </Typography>
                                                                <Typography variant="body1" color="text.secondary" overflow="hidden" sx={ {textOverflow: 'ellipsis'} } nowrap>
                                                                    { _item?.type === 'custom_package' ? `${ translate( `option_${ _item.type }_desc` ) }` : _item?.description }
                                                                </Typography>
                                                            </Stack>

                                                            { _item.is_default && (
                                                                <DefaultLabel sx={ {width: 'fit-content', ':first-of-type': {marginRight: 2}} }/>
                                                            ) }
                                                        </Stack>
                                                    </Stack>
                                                </MenuItem>
                                            );
                                        },
                                    ) }
                                </RHFSelect>

                                { watched.packageType === 1 && (
                                    <FormPackageDimension/>
                                ) }

                                <Stack display="grid" columnGap={ 2.5 } gridTemplateColumns={ {xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)'} }>
                                    <Stack display="flex" flexDirection="row">
                                        <RHFTextField
                                            name="weight"
                                            size="small"
                                            type="number"
                                            label={ `${ translate( 'quickrate_5_2' ) }` }
                                            onWheel={ ( e ) => e.target.blur() }
                                            onBlur={ ( e ) => handleInputChange( 'weight', e.target.value ) }
                                            sx={ {
                                                '& .MuiOutlinedInput-root': {
                                                    borderTopRightRadius: 0,
                                                    borderBottomRightRadius: 0,
                                                    borderRight: 'none',
                                                },
                                            } }
                                        />

                                        <RHFSelect
                                            name="weightUnit"
                                            size="small"
                                            SelectProps={ {native: false} }
                                            sx={ {
                                                width: 80,
                                                '& .MuiOutlinedInput-root': {
                                                    borderTopLeftRadius: 0,
                                                    borderBottomLeftRadius: 0,
                                                },
                                            } }
                                        >
                                            { UNIT_OPTIONS.map( ( option ) => (
                                                <MenuItem
                                                    key={ option.key }
                                                    value={ option.key }
                                                    sx={ {
                                                        mx: 1,
                                                        my: 0.5,
                                                        borderRadius: 0.75,
                                                        typography: 'body2',
                                                    } }
                                                >
                                                    { option.name }
                                                </MenuItem>
                                            ) ) }
                                        </RHFSelect>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>

                        <Stack spacing={ 2.5 }>
                            <Typography variant="h4">{ `${ translate( 'dialog_add_shipping_rule_1_4' ) }` }</Typography>

                            <Stack display="grid" rowGap={ 2.5 } columnGap={ 2.5 } gridTemplateColumns={ {xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)'} }>
                                <RHFSelect
                                    name="signature"
                                    size="small"
                                    label={ `${ translate( 'shipments_create_5_3_3' ) }` }
                                    SelectProps={ {native: false, sx: {textTransform: 'capitalize'}} }
                                >
                                    <MenuItem
                                        key="NO_SIGNATURE"
                                        value="NO_SIGNATURE"
                                        sx={ {
                                            mx: 1,
                                            my: 0.5,
                                            borderRadius: 0.75,
                                            typography: 'body2',
                                            textTransform: 'capitalize',
                                        } }
                                    >
                                        { `${ translate( 'shipments_create_5_3_3_1' ) }` }
                                    </MenuItem>
                                    <MenuItem
                                        key="SIGNATURE"
                                        value="SIGNATURE"
                                        sx={ {
                                            mx: 1,
                                            my: 0.5,
                                            borderRadius: 0.75,
                                            typography: 'body2',
                                            textTransform: 'capitalize',
                                        } }
                                    >
                                        { `${ translate( 'shipments_create_5_3_3_2' ) }` }
                                    </MenuItem>
                                </RHFSelect>
                            </Stack>
                        </Stack>
                    </Stack>
                </Box>

                <Stack
                    position="absolute" width={ 1 } height="60px" bottom={ 0 } padding="10px 30px" bgcolor="white"
                    direction="row" justifyContent="flex-end" spacing={ 3 }
                    boxShadow="-40px 40px 80px 10px rgba(0, 0, 0, 0.24)" zIndex={ 1 }
                >
                    <Button color="inherit" variant="outlined" onClick={ onClose }>
                        { `${ translate( 'dialog_add_shipping_rule_1_5' ) }` }
                    </Button>

                    <LoadingButton variant="contained" loading={ isLoading } type="submit">
                        { isEdit ? `${ translate( 'dialog_add_shipping_rule_2_1' ) }` : `${ translate( 'dialog_add_shipping_rule_1_6' ) }` }
                    </LoadingButton>
                </Stack>
            </FormProvider>
        </Dialog>
    );
}
