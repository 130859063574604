import { isEqual } from "lodash";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// form
import { Controller, useForm } from 'react-hook-form';
// @mui
import { styled } from "@mui/material/styles";
import { Button, Checkbox, Drawer, FormControlLabel, MenuItem, Stack, Typography } from '@mui/material';
// @types
import { NAVBAR } from '../../../../config';
// hooks
import useLocales from "../../../../hooks/useLocales";
import usePrevious from "../../../../hooks/usePrevious";
// redux
import { useDispatch, useSelector } from "../../../../redux/store";
import { getWarehouseList } from "../../../../redux/slices/warehouse";
// components
import Image from "../../../../components/Image";
import Iconify from '../../../../components/Iconify';
import Scrollbar from '../../../../components/Scrollbar';
import { FormProvider, RHFSelect, RHFTextField } from '../../../../components/hook-form';

// ----------------------------------------------------------------------

const SHIPPING_AREA_OPTIONS = [
    { value: 'all', label: 'option_default' },
    { value: 'domestic', label: 'option_domestic' },
    { value: 'international', label: 'option_international' },
];

const FormWrapperStyle = styled('div')(({ theme }) => ({
    height: 'inherit',
    '& form': {
        height: 'inherit',
    },
}));

// ----------------------------------------------------------------------

OrderFilterSidebar.propTypes = {
    onSearch: PropTypes.func,
    onReset: PropTypes.func,
};

export default function OrderFilterSidebar({ onSearch, onReset }) {
    const dispatch = useDispatch();

    const { translate } = useLocales();

    const { sidebarFilter } = useSelector(({ order }) => order);
    const { warehouseList } = useSelector(({ warehouse }) => warehouse);

    const prevValues = usePrevious({ ...sidebarFilter });

    const OrderFilterSchema = Yup.object().shape({
        itemQuantity: Yup.number().positive(`${translate('input_schema_order_1')}`)
            .integer(`${translate('input_schema_order_1')}`).typeError(`${translate('input_schema_order_1')}`).max(9999999999, `${translate('input_schema_order_1_1')}`),
    })

    const methods = useForm({
        resolver: yupResolver(OrderFilterSchema),
        mode: 'onChange',
        defaultValues: {
            ...sidebarFilter,
        },
    });

    const {
        reset,
        control,
        watch,
        handleSubmit,
    } = methods;

    const watched = watch();

    const [openFilter, setOpenFilter] = useState(false);

    const [warehouseOptions, setWarehouseOptions] = useState([]);

    const handleOpenFilter = () => {
        dispatch(getWarehouseList());

        setOpenFilter(true);
    };

    const handleCloseFilter = () => setOpenFilter(false);

    const handleOnReset = () => {
        onReset();

        reset({ ...sidebarFilter });
    };

    const onSubmit = async (values) => {
        try {
            console.log(values);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const tempOptions = [{ value: 'all', label: "option_default" }];

        warehouseList.forEach(warehouse => tempOptions.push({ value: warehouse.id, label: `${warehouse.first_name} ${warehouse.last_name}` }));

        setWarehouseOptions(tempOptions);
    }, [warehouseList]);

    useEffect(() => {
        if (isEqual(prevValues, sidebarFilter)) return;

        reset({ ...sidebarFilter });
    }, [prevValues, sidebarFilter, reset]);

    return (
        <>
            <Button
                variant="text" color="primary" sx={{ ml: { xs: '0 !important', md: '10px !important' }, '&:hover': { bgcolor: 'transparent' } }}
                startIcon={
                    <Image src="/assets/icon/orders/ic_filter.svg" alt="filter" sx={{ width: 14, height: 14, '& img': { objectFit: 'contain' } }} visibleByDefault/>
                }
                onClick={handleOpenFilter}
                disableRipple
            >
                {`${translate('orders_20')}`}
            </Button>

            <Drawer
                anchor="right" open={openFilter} onClose={handleCloseFilter}
                PaperProps={{
                    sx: { width: NAVBAR.BASE_DRAWER_WIDTH },
                }}
            >
                <FormWrapperStyle>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Stack height="inherit" sx={{ p: 2.5 }}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                <Typography variant="subtitle1">
                                    {`${translate('orders_21')}`}
                                </Typography>
                            </Stack>

                            <Scrollbar>
                                <Stack spacing={3.75} sx={{ py: 2.5 }}>
                                    <Stack spacing={2.5}>
                                        <Typography variant="h3">{`${translate('orders_22')}`}</Typography>

                                        <Stack spacing={3.75}>
                                            <RHFTextField name="orderNumber" label={`${translate('orders_23')}`} size="small"/>

                                            <RHFTextField name="itemName" label={`${translate('orders_24')}`} size="small"/>

                                            <RHFTextField name="itemSKU" label="SKU" size="small"/>

                                            <RHFTextField name="itemQuantity" label={`${translate('orders_25')}`} size="small" type="number"/>

                                            <RHFSelect
                                                name="warehouseID" label={`${translate('orders_26')}`} size="small"
                                                SelectProps={{
                                                    native: false,
                                                    MenuProps: {
                                                        sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                                                    },
                                                }}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '4px',
                                                    },
                                                }}
                                            >
                                                {warehouseOptions.map((option, index) => (
                                                    <MenuItem
                                                        key={`warehouseID-option-${index}`}
                                                        value={option.value}
                                                        sx={{
                                                            mx: 1,
                                                            my: 0.5,
                                                            borderRadius: 0.75,
                                                            typography: 'body2',
                                                        }}
                                                    >
                                                        {`${translate(option.label)}`}
                                                    </MenuItem>
                                                ))}
                                            </RHFSelect>

                                            <RHFSelect
                                                name="shippingArea" label={`${translate('orders_27')}`} size="small"
                                                SelectProps={{
                                                    native: false,
                                                    MenuProps: {
                                                        sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                                                    },
                                                }}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '4px',
                                                    },
                                                }}
                                            >
                                                {SHIPPING_AREA_OPTIONS.map((option, index) => (
                                                    <MenuItem
                                                        key={`shippingArea-option-${index}`}
                                                        value={option.value}
                                                        sx={{
                                                            mx: 1,
                                                            my: 0.5,
                                                            borderRadius: 0.75,
                                                            typography: 'body2',
                                                        }}
                                                    >
                                                        {`${translate(option.label)}`}
                                                    </MenuItem>
                                                ))}
                                            </RHFSelect>

                                            <Controller
                                                name="isCombined"
                                                control={control}
                                                render={({ field }) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox color="success" checked={field.value}{...field}/>
                                                        }
                                                        label={`${translate('orders_28')}`}
                                                        labelPlacement="start"
                                                        sx={{
                                                            marginTop: '22px !important',
                                                            justifyContent: 'space-between',
                                                            '& .MuiFormControlLabel-label': {
                                                                fontWeight: 400,
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />

                                            <Controller
                                                name="isHold"
                                                control={control}
                                                render={({ field }) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox color="success" checked={field.value}{...field}/>
                                                        }
                                                        label={`${translate('orders_29')}`}
                                                        labelPlacement="start"
                                                        sx={{
                                                            marginTop: '14px !important',
                                                            marginBottom: '-8px !important',
                                                            justifyContent: 'space-between',
                                                            '& .MuiFormControlLabel-label': {
                                                                fontWeight: 400,
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Stack>

                                    <Stack spacing={2.5}>
                                        <Typography variant="h3">{`${translate('orders_30')}`}</Typography>

                                        <Stack spacing={3.75}>
                                            <RHFTextField name="trackingCode" label={`${translate('orders_31')}`} size="small"/>

                                            <RHFTextField name="clientName" label={`${translate('orders_32')}`} size="small"/>

                                            <RHFTextField name="clientCity" label={`${translate('orders_33')}`} size="small"/>

                                            <RHFTextField name="clientZipcode" label={`${translate('orders_34')}`} size="small"/>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Scrollbar>

                            <Stack justifyContent="center" spacing={2.5}>
                                <Button
                                    size="large" variant="contained" onClick={() => onSearch({ ...watched })}
                                    startIcon={
                                        <Iconify icon={'eva:search-fill'}/>
                                    }
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 400,
                                    }}
                                    fullWidth
                                >
                                    {`${translate('orders_35')}`}
                                </Button>

                                <Button
                                    size="large" color="inherit" variant="outlined" onClick={handleOnReset}
                                    startIcon={
                                        <Iconify icon={'ic:round-clear-all'}/>
                                    }
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 400,
                                    }}
                                    fullWidth
                                >
                                    {`${translate('orders_36')}`}
                                </Button>
                            </Stack>
                        </Stack>
                    </FormProvider>
                </FormWrapperStyle>
            </Drawer>
        </>
    );
}
