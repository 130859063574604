import PropTypes from "prop-types";
import React from "react";
// hooks
import useLocales from "../../hooks/useLocales";
// components
import Label from "../Label";

// ----------------------------------------------------------------------

DefaultLabel.propTypes = {
    sx: PropTypes.object,
};

export default function DefaultLabel({ sx, ...other }) {
    const { translate } = useLocales();

    return (
        <Label variant="filled" sx={{ width: '73px', height: '30px', bgcolor: 'info.main', color: 'white', fontSize: '14px', fontWeight: 'normal', ...sx }} {...other}>
            {`${translate('settings_account_1_2_2_8')}`}
        </Label>
    )
}
