import { createSlice } from '@reduxjs/toolkit';
// api
import {
    getCompanyAutomation as getCompanyAutomationAPI
} from "../../api/automation";
//
import { dispatch } from '../store';

const initialState = {
    isLoading: false,
    error: null,
    automationList: null,
};

const slice = createSlice({
    name: 'automation',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state, action) {
            state.isLoading = action.payload;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET AUTOMATION SUCCESS
        getAutomationSuccess(state, action) {
            state.isLoading = false;
            state.automationList = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAutomationList() {
    return async () => {
        dispatch(slice.actions.startLoading(true));
        try {
            const response = await getCompanyAutomationAPI();
            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getAutomationSuccess(data ?? null));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}