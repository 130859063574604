import PropTypes from "prop-types";
import React from "react";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { Autocomplete, Box, TextField } from "@mui/material";
// constants
import { CA_STATES, US_STATES } from "../../constants/country";
// hooks
import useLocales from "../../hooks/useLocales";
// components
import { RHFTextField } from "../hook-form";

// ----------------------------------------------------------------------

/**
 * @function FormState
 * @description if has default, country {String} - e.g "US" or "CA"
 * @param {string} [name=state] 默认值为state
 * @param {string} [label]
 *
 */

FormState.propTypes = {
    name: PropTypes.string,
    countryName: PropTypes.string,
    label: PropTypes.string,
};

export default function FormState({ name = 'state', countryName = 'country', label = 'form_state', ...other }) {
    const { translate } = useLocales();

    const { control, watch } = useFormContext();

    const watched = watch();

    return (
        <>
            {(watched[countryName] === 'US' || watched[countryName] === 'United States' || watched[countryName] === 'CA' || watched[countryName] === 'Canada') ? (
                <Controller
                    name={name}
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                        const optionList = (watched[countryName] === 'US' || watched[countryName] === 'United States') ? US_STATES : CA_STATES;

                        const code = "value" in other ? other.value : field?.value || '';
                        const selected = optionList.find(_item => (_item.label === code || _item.code === code));

                        return (
                            <Autocomplete
                                autoHighlight
                                value={selected?.code ?? code}
                                onChange={(event, newValue) => field.onChange(newValue)}
                                options={optionList.map(item => item.code)}
                                renderOption={(props, option) => {
                                    const selectedOption = optionList.find(_item => (_item.label === option || _item.code === option));

                                    return (
                                        <Box component="li"  {...props}>
                                            {`${selectedOption.label} (${selectedOption.code})`}
                                        </Box>
                                    );
                                }}
                                renderInput={(params) => {
                                    return (
                                        <TextField
                                            {...params}
                                            label={`${translate(label)}`}
                                            error={!!error}
                                            helperText={error?.message}
                                            inputProps={{
                                                ...params?.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                        />
                                    );
                                }}
                                size="small"
                                {...other}
                            />
                        );
                    }}
                />
            ) : (
                <RHFTextField name={name} label={`${translate(label)}`} {...other}/>
            )}
        </>
    );
}
