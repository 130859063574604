import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
//
import ProgressBar from './ProgressBar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    right: 0,
    bottom: 0,
    zIndex: 99999,
    width: '100%',
    height: '100%',
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,

    "@keyframes animation-loader-44": {
        "0%": {
            boxShadow: '14px 0 0 -2px,  38px 0 0 -2px,    -14px 0 0 -2px,  -38px 0 0 -2px'
        },
        '25%': {
            boxShadow: '14px 0 0 -2px,  38px 0 0 -2px,    -14px 0 0 -2px,  -38px 0 0 2px'
        },
        '50%': {
            boxShadow: '14px 0 0 -2px,  38px 0 0 -2px,    -14px 0 0 2px,  -38px 0 0 -2px'
        },
        '75%': {
            boxShadow: '14px 0 0 2px,  38px 0 0 -2px,    -14px 0 0 -2px,  -38px 0 0 -2px'
        },
        '100%': {
            boxShadow: '14px 0 0 -2px,  38px 0 0 2px,    -14px 0 0 -2px,  -38px 0 0 -2px'
        }
    },

    '& .loader-44': {
        width: '12px',
        height: '12px',
        borderRadius: '50%',
        display: 'inline-block',
        position: 'relative',
        color: 'primary.main',
        animation: 'animation-loader-44 1.5s linear infinite'
    }
}));

// ----------------------------------------------------------------------

LoadingScreen.propTypes = {
    isDashboard: PropTypes.bool,
};

export default function LoadingScreen({ isDashboard, ...other }) {
    return (
        <>
            <ProgressBar/>

            {!isDashboard && (
                <RootStyle {...other}>
                    <div className='loader-44'/>
                </RootStyle>
            )}
        </>
    );
}
