import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
// @mui
import { Box, IconButton, Stack, Typography } from "@mui/material";
// hooks
import useLocales from "../../../../hooks/useLocales";
// redux
import { useSelector } from "../../../../redux/store";
// components
import Image from "../../../../components/Image";
import Iconify from "../../../../components/Iconify";
import MoreActions from "../../../../components/customized/MoreActions";
// sections
import { OrderSelectPackage } from "./index";

// ----------------------------------------------------------------------

OrderShippedPackageList.propTypes = {
    packageInfoList: PropTypes.array,
};

function OrderShippedPackageList({ packageInfoList }) {
    const { translate } = useLocales();

    return (
        <Stack spacing={1.25}>
            <Typography variant="h3">{`${translate('orders_6_4_12')}`}</Typography>

            <Stack spacing={2}>
                {packageInfoList.map((_package, index) => (
                    <Stack key={`package-${index}-${_package.id}`} direction="row" spacing={2}>
                        <Typography variant="body1">{`#${index + 1}:`}</Typography>

                        <Stack>
                            <Typography variant="body1">{`${_package?.name || '-'}`}</Typography>
                            {_package?.type === "PACKAGE" && (
                                <Typography variant="body1">{`${translate('orders_6_4_13')}${_package?.length || '-'} x ${_package?.width || '-'} x ${_package?.height || '-'} in`}</Typography>
                            )}
                            <Typography variant="body1">{`${translate('orders_6_4_14')}${_package?.weight || '-'} lb`}</Typography>
                        </Stack>
                    </Stack>
                ))}
            </Stack>
        </Stack>
    );
}

// ----------------------------------------------------------------------

OrderTableCellPackageInfo.propTypes = {
    detail: PropTypes.object,
    onRefresh: PropTypes.func,
    page: PropTypes.string,
};

export default function OrderTableCellPackageInfo({ detail, onRefresh, page }) {
    const { translate } = useLocales();

    const { id, client_country: toCountry, combined_order: combinedOrder, shipment_rate: shipmentRate, shipments, status, store } = detail;

    const { allPackageList } = useSelector((state) => state.package);
    const { warehouseList } = useSelector(({ warehouse }) => warehouse);

    const [packageInfo, setPackageInfo] = useState([]);
    const [showSelectPackageActions, setShowSelectPackageActions] = useState(null);

    const [isMissingInfo, setIsMissingInfo] = useState(false);
    const [isDefaultInternational, setIsDefaultInternational] = useState(false);

    const handleOpenSelectPackage = (event) => {
        if (event?.currentTarget)
            setShowSelectPackageActions(event.currentTarget);
    };

    const handleCloseSelectPackage = () => setShowSelectPackageActions(null);

    // ----------------------------------

    useEffect(() => {
        if (shipmentRate && shipmentRate?.rate_request?.parcels && shipmentRate?.rate_request?.parcels[0]) {
            // 已报价 Order
            if (!shipmentRate?.rate_request?.parcels[0]?.predefined_package || shipmentRate?.rate_request?.parcels[0]?.predefined_package === 'PACKAGE') {
                setPackageInfo([{
                    pid: 1,
                    name: "Custom Package",
                    carrier: "USER",
                    type: "custom_package",
                    code: "PACKAGE",
                    height: shipmentRate.rate_request.parcels[0]?.height || '',
                    width: shipmentRate.rate_request.parcels[0]?.width || '',
                    length: shipmentRate.rate_request.parcels[0]?.length || '',
                    weight: shipmentRate.rate_request.parcels[0]?.weight || '',
                }]);
            } else {
                const tempPackageInfo = allPackageList.find((_p) => _p.code === shipmentRate?.rate_request?.parcels[0]?.predefined_package);

                setPackageInfo([{ ...tempPackageInfo, weight: shipmentRate.rate_request.parcels[0]?.weight || '' }]);

                // setPackageInfo([{
                //     pid: shipmentRate?.rate_request?.parcels[0]?.pid ?? '',
                //     carrier: shipmentRate?.rate_request?.parcels[0]?.predefined_package_carrier ?? '',
                //     code: shipmentRate?.rate_request?.parcels[0]?.predefined_package ?? '',
                //     name: shipmentRate?.rate_request?.parcels[0]?.predefined_package ?? '',
                //     weight: shipmentRate.rate_request.parcels[0]?.weight || '',
                // }]);
            }
        } else if (shipments && shipments.length > 0) {
            // Shipped Order已有Label, display only.
            const tempPackageInfoList = [];

            shipments.forEach(ship => {
                if (ship.predefined_package) {
                    tempPackageInfoList.push({
                        name: ship?.predefined_package ?? '',
                        weight: ship?.weight ?? '',
                    });
                } else {
                    tempPackageInfoList.push({
                        name: "Custom Package",
                        code: "PACKAGE",
                        height: ship?.height ?? '',
                        width: ship?.width ?? '',
                        length: ship?.length ?? '',
                        weight: ship?.weight ?? '',
                    });
                }
            });

            setPackageInfo(tempPackageInfoList);
        } else if (shipmentRate === null && id) {
            setPackageInfo([]);
        }
    }, [allPackageList, shipmentRate, shipments, status]);

    useEffect(() => {
        if (warehouseList && warehouseList.length > 0 && store.warehouse_id) {
            const warehouse = warehouseList.find(_w => _w.id === store.warehouse_id);

            if (toCountry && (warehouse?.country ?? "")) {
                setIsDefaultInternational(warehouse.country !== toCountry);
                setIsMissingInfo(false);
            } else {
                setIsMissingInfo(true);
            }
        }
    }, [toCountry, store, warehouseList]);

    return (
        <Box height="46px">
            {status === 'awaiting' && (
                <Stack direction="row" spacing={0.25} alignItems="flex-start">
                    {packageInfo.length === 0 && (
                        <>
                            {/* 无Package */}
                            <Stack width="140px">
                                <Typography variant="body1" noWrap>{`${translate('orders_6_4_10')}`}</Typography>
                            </Stack>

                            <MoreActions
                                open={combinedOrder === 1 ? null : showSelectPackageActions}
                                onOpen={handleOpenSelectPackage}
                                onClose={handleCloseSelectPackage}
                                marginThreshold={100}
                                display={
                                    <IconButton sx={{ mt: '-12px', width: '20px', height: '20px', padding: 0 }} disabled={combinedOrder === 1 || isMissingInfo}>
                                        <Image
                                            src="/assets/icon/orders/ic_add.svg" alt="add" sx={{ width: '20px', height: '20px', filter: `grayscale(${(combinedOrder === 1 || isMissingInfo) ? '100%' : '0%'})`, '& img': { objectFit: 'contain' } }}
                                            disabledEffect
                                        />
                                    </IconButton>
                                }
                                disabled={combinedOrder === 1 || isMissingInfo}
                                actions={
                                    <OrderSelectPackage orderId={id} onClose={handleCloseSelectPackage} onRefresh={onRefresh} page={page} isInternational={isDefaultInternational}/>
                                }
                                sxMenuPopover={{
                                    width: '100%',
                                    maxWidth: '360px',
                                    px: '16px',
                                    pt: '16px',
                                    pb: '30px',
                                }}
                                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            />
                        </>
                    )}

                    {packageInfo.length > 0 && (
                        <>
                            {/* 有Package */}
                            <Stack spacing={0.25} width="140px">
                                <Typography variant="body1" noWrap>
                                    {packageInfo[0]?.name || `${translate('orders_7_2')}`}
                                </Typography>

                                <Typography variant="body1" sx={{ color: 'text.disabled' }} noWrap>
                                    {packageInfo[0]?.code === 'PACKAGE' && (
                                        `${packageInfo[0]?.length || '-'} x ${packageInfo[0]?.width || '-'} x ${packageInfo[0]?.height || '-'} in, `
                                    )}

                                    {`${packageInfo[0]?.weight || '-'} lb`}
                                </Typography>
                            </Stack>

                            <MoreActions
                                open={combinedOrder === 1 ? null : showSelectPackageActions}
                                onOpen={handleOpenSelectPackage}
                                onClose={handleCloseSelectPackage}
                                marginThreshold={100}
                                display={
                                    <IconButton sx={{ mt: '-12px', width: '20px', height: '20px', minWidth: 'unset', padding: 0 }} disabled={combinedOrder === 1 || isMissingInfo}>
                                        <Iconify icon="eva:arrow-ios-downward-fill" sx={{ width: 16, height: 16, color: (combinedOrder === 1 || isMissingInfo) ? '#C9C9C9' : '#292929' }}/>
                                    </IconButton>
                                }
                                disabled={combinedOrder === 1 || isMissingInfo}
                                actions={
                                    <OrderSelectPackage
                                        orderId={id}
                                        currentDetail={{
                                            ...packageInfo[0],
                                        }}
                                        onClose={handleCloseSelectPackage}
                                        onRefresh={onRefresh}
                                        page={page}
                                        isInternational={isDefaultInternational}
                                    />
                                }
                                sxMenuPopover={{
                                    width: '100%',
                                    maxWidth: '360px',
                                    px: '16px',
                                    pt: '16px',
                                    pb: '30px',
                                }}
                                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            />
                        </>
                    )}
                </Stack>
            )}

            {(status === 'shipped' || status === 'canceled') && (
                <>
                    {packageInfo.length === 1 && (
                        <Stack spacing={0.25} width="140px">
                            <Typography variant="body1" noWrap>
                                {packageInfo[0]?.name ?? `${translate('orders_7_2')}`}
                            </Typography>

                            <Typography variant="body1" sx={{ color: 'text.disabled' }} noWrap>
                                {packageInfo[0]?.code === 'PACKAGE' && (
                                    `${packageInfo[0]?.length || '-'} x ${packageInfo[0]?.width || '-'} x ${packageInfo[0]?.height || '-'} in, `
                                )}

                                {`${packageInfo[0]?.weight || '-'} lb`}
                            </Typography>
                        </Stack>
                    )}

                    {packageInfo.length > 1 && (
                        <Stack direction="row" spacing={0.25} alignItems="flex-start">
                            <Stack spacing={0.25} width="140px">
                                <Typography variant="body1" noWrap>
                                    {`${packageInfo.length}${translate('orders_6_4_11')}`}
                                </Typography>

                                <Typography variant="body1" sx={{ color: 'text.disabled' }} noWrap>
                                    {`${translate('orders_6_4_2')}`}
                                </Typography>
                            </Stack>

                            <MoreActions
                                open={showSelectPackageActions}
                                onOpen={handleOpenSelectPackage}
                                onClose={handleCloseSelectPackage}
                                display={
                                    <IconButton sx={{ mt: '-12px', width: '20px', height: '20px', minWidth: 'unset', padding: 0 }} disabled={isMissingInfo}>
                                        <Iconify icon="eva:arrow-ios-downward-fill" sx={{ width: 16, height: 16, color: isMissingInfo ? '#C9C9C9' : '#292929' }}/>
                                    </IconButton>
                                }
                                disabled={isMissingInfo}
                                actions={
                                    <OrderShippedPackageList packageInfoList={packageInfo}/>
                                }
                                sxMenuPopover={{
                                    width: '100%',
                                    maxWidth: '280px',
                                    p: '20px',
                                }}
                                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            />
                        </Stack>
                    )}
                </>
            )}
        </Box>
    );
}

