// utils
import axios from '../utils/axios';

// ----------------------------------------------------------------------

const getShippingRulesList = (filter) => axios.post('/shipping_rules/list', {
    per_page: filter?.rowsPerPage || 5,
    current_page: filter?.page || 0,
    order_by: [
        {
            name: "created_at",
            sorting: filter?.sort || "desc"
        }
    ]
});

const getShippingRule = (id) => axios.get(`/shipping_rules/info?id=${id}`, {
});

// ----------------------------------------------------------------------------


const addShippingRule = (data) => axios.post('/shipping_rules/add', { ...data });


const modifyShippingRule = (data) => axios.post('/shipping_rules/modify', { ...data });


const deleteShippingRule = (id) => axios.delete(`/shipping_rules/delete?id=${id}`, );


// ---------------------------------------------------------------------------------


export {
    getShippingRulesList,
    getShippingRule,
    addShippingRule,
    modifyShippingRule,
    deleteShippingRule,
}
