import { createSlice } from '@reduxjs/toolkit';
// api
import { getTour as getTourAPI, modifyTour as modifyTourAPI } from "../../api/user";
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    tour: {
        company_tutorial: false,
        company_store: false,
        company_address: false,
        company_package: false
    }
};

const slice = createSlice({
    name: 'tour',
    initialState,
    reducers: {
        // GET TOUR
        getTourSuccess(state, action) {
            state.tour = { ...state.tour, ...action.payload };
        },

        // MODIFY TOUR
        modifyTourSuccess(state, action) {
            state.tour = { ...state.tour, [action.payload]: false };
        },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getTour() {
    return async () => {
        try {
            const response = await getTourAPI();

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getTourSuccess(data ?? {}));
            }
        } catch (error) {
            console.error(error)
        }
    }
}

// ----------------------------------------------------------------------

export function modifyTour(type) {
    return async () => {
        try {
            const response = await modifyTourAPI(type);

            const { status, data } = response;

            if (status === 200 && data?.result === 'success') {
                dispatch(slice.actions.modifyTourSuccess(type));
            }
        } catch (error) {
            console.error(error)
        }
    }
}
