import React from "react";
// @mui
import { Box, Stack, Typography } from '@mui/material';
// hooks
import useLocales from "../../hooks/useLocales";
// components
import Image from "../Image";

// ----------------------------------------------------------------------

export default function BlockContent({ purpose }) {
    const { translate } = useLocales();

    return (
        <>
            {purpose === 'order_form' ? (
                <Stack
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                    sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
                >
                    <Image
                        src="/assets/icon/shipments/ic_excel_screenshot.svg"
                        alt="excel_screenshot"
                        sx={{ width: '54px', height: '58px', '& img': { objectFit: 'contain' } }}
                        visibleByDefault
                    />

                    <Box textAlign="center" sx={{ px: 3 }}>
                        <Typography variant="body1">
                            {`${translate('orders_18_4')}`}

                            <Typography
                                variant="body1"
                                component="span"
                                sx={{ color: '#2C6CD2', textDecoration: 'underline' }}
                            >
                                {`${translate('orders_18_5')}`}
                            </Typography>
                        </Typography>

                        <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                            {`${translate('orders_18_6')}`}
                        </Typography>
                    </Box>
                </Stack>
            ) : (
                <Stack
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                    sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
                >
                    <Image
                        src="/assets/image/billing/zelle_screenshot.svg"
                        alt="zelle_screenshot"
                        sx={{ width: '80px', height: '96px', '& img': { objectFit: 'contain' } }}
                        visibleByDefault
                    />

                    <Box textAlign="center" sx={{ px: 3 }}>
                        <Typography variant="body1">
                            {`${translate('dialog_recharge_by_zelle_5_1')}`}
                            <Typography
                                variant="body1"
                                component="span"
                                sx={{ color: '#2C6CD2', textDecoration: 'underline' }}
                            >
                                {`${translate('dialog_recharge_by_zelle_5_2')}`}
                            </Typography>
                        </Typography>

                        <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                            {`${translate('dialog_recharge_by_zelle_5_3')}`}
                        </Typography>
                    </Box>
                </Stack>
            )}
        </>

    );
}
