import React from "react";
import Slider from "react-slick";
import { Link as RouterLink } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Container, Link, Stack, Typography } from '@mui/material';
// hooks
import useLocales from "../../hooks/useLocales";
// routes
import { PATH_AUTH } from '../../routes/paths';
// layouts
import LanguagePopover from "../../layouts/dashboard/header/LanguagePopover";
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from "../../components/Image";
// sections
import { LoginForm } from '../../sections/auth/login';
// import RightsReserved from "../../sections/auth/RightsReserved";

// ----------------------------------------------------------------------

const ContentStyle = styled( 'div' )( ( {theme} ) => ({
    position: 'relative',
    width: '100%',
    // maxWidth: 576,
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    padding: theme.spacing( 4, 3.75 ),
    backgroundColor: 'white',
    overflow: 'auto',
}) );

// ----------------------------------------------------------------------

export default function Login() {
    const {translate} = useLocales();

    const settings = {
        dots: true,
        arrows: false,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true,
    };

    return (
        <GoogleReCaptchaProvider reCaptchaKey="6LcRsvokAAAAADKquTBfv0I66_AePIGuJKaXiTY_" useRecaptchaNet>
            <Page
                title="Login - ShipSaving"
                meta={
                    <meta name="description" content="Efficient shipping is just a login away. Enter your ShipSaving account to manage, track, and unlock your shipping discounts."/>
                }
            >
                <Stack sx={ {position: {xs: 'relative', lg: 'absolute'}, left: '0', top: '0', zIndex: '1', width: {xs: '100%', lg: '38%'}, maxWidth: {xs: 'unset', lg: "480px"}} }>
                    <ContentStyle>
                        <Stack direction="row" justifyContent="space-between">
                            <Logo sx={ {mr: 2.5} }/>

                            <LanguagePopover hasArrow/>
                        </Stack>

                        <Stack flex={ 1 } sx={ {mt: '30%'} }>
                            <Stack direction="row" alignItems="center" sx={ {mb: 5} }>
                                <Box sx={ {flexGrow: 1} }>
                                    <Typography variant="h1" gutterBottom>{ `${ translate( 'auth_login_1' ) }` }</Typography>
                                    <Typography variant="body1" sx={ {color: 'text.secondary'} }>{ `${ translate( 'auth_login_2' ) }` }</Typography>
                                </Box>
                            </Stack>

                            <LoginForm/>

                            <Typography variant="body2" align="center" sx={ {mt: 3, fontWeight: 400} }>
                                { `${ translate( 'auth_login_8' ) } ` }
                                <Link variant="subtitle2" component={ RouterLink } to={ PATH_AUTH.register }>
                                    { `${ translate( 'auth_login_9' ) }` }
                                </Link>
                            </Typography>
                        </Stack>

                        {/* <Stack mt={3}> */ }
                        {/*     <RightsReserved/> */ }
                        {/* </Stack> */ }

                        {/* background slider */ }
                        <Image disabledEffect visibleByDefault alt="banner" src="/assets/image/auth/banner_d.png"
                               sx={{ width: '100%', my: 'auto', mr: 'auto'}} />
                    </ContentStyle>
                </Stack>

                {/* background slider */ }
                <Stack
                    sx={ {
                        display: {xs: 'none', lg: 'block'},
                        '& .slick-slide>div': {height: '100vh'},
                        '& .slick-dots': {bottom: "45px", left: 'clamp(0px, 38vw, 576px)', width: '-webkit-fill-available', 'li.slick-active button:before': {color: '#fff'}, 'li button:before': {color: '#fff'}},
                    } }
                >
                    <Slider { ...settings }>
                        <Container
                            maxWidth={ false } sx={ {
                            position: 'relative',
                            display: 'flex',
                            p: {xs: 0},
                            minHeight: '100vh',
                            background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.0), 80%, rgba(0, 0, 0, 1)), url(https://shipsaving.s3.us-west-2.amazonaws.com/resources/public/assets/image/auth/banner_a.jpg) center / cover no-repeat ',
                        } }
                        >
                            <Box sx={ {position: 'absolute', bottom: '88px', left: 'clamp(0px, 38vw, 576px)', width: '-webkit-fill-available'} }>
                                <Typography
                                    sx={ {
                                        color: '#fff',
                                        lineHeight: '1.5',
                                        fontStyle: 'italic',
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        margin: 'auto',
                                        maxWidth: {lg: '660px', xxl: '900px'},
                                        fontSize: {xs: '2em', lg: '2.6em', xxl: '3em'},
                                    } }
                                >"Whenever I ship I love going to ShipSaving, I have used it for three years, and it saved me a lot of time. It is very fast and convenient, it helps give me the price so quickly."</Typography>
                                <Typography sx={ {color: '#fff', textAlign: 'center', mt: 3} }>Retail / Owner</Typography>
                            </Box>
                        </Container>

                        <Container
                            maxWidth={ false } sx={ {
                            position: 'relative',
                            display: 'flex',
                            p: {xs: 0},
                            minHeight: '100vh',
                            background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.0), 80%, rgba(0, 0, 0, 1)), url(https://shipsaving.s3.us-west-2.amazonaws.com/resources/public/assets/image/auth/banner_b.jpg) center / cover no-repeat',
                        } }
                        >
                            <Box sx={ {position: 'absolute', bottom: '88px', left: 'clamp(0px, 38vw, 576px)', width: '-webkit-fill-available'} }>
                                <Typography
                                    sx={ {
                                        color: '#fff',
                                        lineHeight: '1.5',
                                        fontStyle: 'italic',
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        margin: 'auto',
                                        maxWidth: {lg: '660px', xxl: '900px'},
                                        fontSize: {xs: '2em', lg: '2.6em', xxl: '3em'},
                                    } }
                                >"Easy to use, B2C integration, brings great convenience. I recommend this to everyone and think everyone should use it. It saves you time and money. Customer service is also very helpful."</Typography>
                                <Typography sx={ {color: '#fff', textAlign: 'center', mt: 3} }>Wholesale / Shipping Department</Typography>
                            </Box>
                        </Container>

                        <Container
                            maxWidth={ false } sx={ {
                            position: 'relative',
                            display: 'flex',
                            p: {xs: 0},
                            minHeight: '100vh',
                            background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.0), 80%, rgba(0, 0, 0, 1)), url(https://shipsaving.s3.us-west-2.amazonaws.com/resources/public/assets/image/auth/banner_c.jpg) center / cover no-repeat',
                        } }
                        >
                            <Box sx={ {position: 'absolute', bottom: '88px', left: 'clamp(0px, 38vw, 576px)', width: '-webkit-fill-available'} }>
                                <Typography
                                    sx={ {
                                        color: '#fff',
                                        lineHeight: '1.5',
                                        fontStyle: 'italic',
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        margin: 'auto',
                                        maxWidth: {lg: '660px', xxl: '900px'},
                                        fontSize: {xs: '2em', lg: '2.6em', xxl: '3em'},
                                    } }
                                >"Excellent UI interface. Minimalistic and functional. Responsive customer service - strong execution. Since I switched to ShipSaving I eliminated the need for any other logistics providers."</Typography>
                                <Typography sx={ {color: '#fff', textAlign: 'center', mt: 3} }>Information Technology and Services / CTO</Typography>
                            </Box>
                        </Container>
                    </Slider>
                </Stack>
            </Page>
        </GoogleReCaptchaProvider>
    );
}
