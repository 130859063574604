import PropTypes from 'prop-types';
import React from 'react';
// form
import { useForm } from 'react-hook-form';
// @mui
import { Box, Button, Dialog, Stack, Typography } from '@mui/material';
import { LoadingButton } from "@mui/lab";
// hooks
import useLocales from "../../../../../hooks/useLocales";
// components
import Image from "../../../../../components/Image";
import { FormProvider } from '../../../../../components/hook-form';

// ----------------------------------------------------------------------

ValidateDialog.propTypes = {
    open: PropTypes.bool,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    message: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default function ValidateDialog({ open, onClose, onConfirm, title, message, content }) {
    const { translate } = useLocales();

    const methods = useForm();

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = () => {
        try {
            onConfirm();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Dialog fullWidth open={open} onClose={onClose}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ my: 5, mx: { md: 3.75, sm: 1.5, xs: 1.875 } }}>
                    <Stack direction="row" spacing={2.5} mb={3.75}>
                        <Image visibleByDefault src={'/assets/icon/system/ic_danger.svg'} alt="danger" sx={{ width: '40px', height: '40px', '& img': { objectFit: 'contain' } }} disabledEffect/>

                        <Stack flex={1}>
                            <Typography variant="h5" mb={1.25}>{`${translate(title)}`}</Typography>
                            <Typography variant="body1" mb={1.25} color="grey.500">{`${translate(message)}`}</Typography>
                            <Typography variant="body1">{`${translate(content)}`}</Typography>
                        </Stack>
                    </Stack>

                    <Stack justifyContent="flex-end" direction="row" spacing={3}>
                        <Button variant="contained" onClick={onClose}>
                            {`${translate(`dialog_validate_address_3`)}`}
                        </Button>

                        <LoadingButton color="error" variant="contained" loading={isSubmitting} type="submit">
                            {`${translate(`dialog_validate_address_4`)}`}
                        </LoadingButton>
                    </Stack>
                </Box>
            </FormProvider>
        </Dialog>
    );
}
