import { pxToRem, responsiveFontSizes } from '../utils/getFontValue';

// ----------------------------------------------------------------------

const FONT_PRIMARY = 'Roboto, sans-serif'; // Google Font
// const FONT_PRIMARY = 'Public Sans, sans-serif'; // Google Font
// const FONT_SECONDARY = 'CircularStd, sans-serif'; // Local Font

const typography = {
    fontFamily: FONT_PRIMARY,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
        fontWeight: 700,
        lineHeight: pxToRem(28),
        fontSize: pxToRem(24),
        letterSpacing: 0,
        // ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
    },
    h2: {
        fontWeight: 700,
        lineHeight: pxToRem(26),
        fontSize: pxToRem(22),
        // ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
    },
    h3: {
        fontWeight: 500,
        lineHeight: pxToRem(19),
        fontSize: pxToRem(16),
        // ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
    },
    h4: {
        fontWeight: 500,
        lineHeight: pxToRem(16),
        fontSize: pxToRem(14),
        // ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
    },
    h5: {
        fontWeight: 700,
        lineHeight: pxToRem(19),
        fontSize: pxToRem(16),
        // ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
    },
    h6: {
        fontWeight: 700,
        lineHeight: pxToRem(18),
        fontSize: pxToRem(12),
        // ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
    },
    subtitle1: {
        fontWeight: 500,
        lineHeight: 1,
        fontSize: pxToRem(16),
    },
    subtitle2: {
        fontWeight: 500,
        lineHeight: 1,
        fontSize: pxToRem(14),
    },
    body1: {
        fontWeight: 400,
        lineHeight: pxToRem(22),
        fontSize: pxToRem(14),
    },
    body2: {
        fontWeight: 500,
        lineHeight: pxToRem(22),
        fontSize: pxToRem(14),
    },
    caption: {
        fontWeight: 400,
        lineHeight: pxToRem(18),
        fontSize: pxToRem(12),
    },
    caption2: {
        fontWeight: 700,
        lineHeight: pxToRem(18),
        fontSize: pxToRem(12),
    },
    overline: {
        fontWeight: 700,
        lineHeight: 1,
        fontSize: pxToRem(14),
        // textTransform: 'uppercase',
        letterSpacing: 0.5,
    },
    button: {
        fontWeight: 700,
        lineHeight: 1,
        fontSize: pxToRem(14),
        textTransform: 'capitalize',
    },
};

export default typography;
