import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
// @mui
import { Dialog, Grid, Stack, Typography } from "@mui/material";
// hooks
import useResponsive from "../../hooks/useResponsive";
import useLocales from "../../hooks/useLocales";
// redux
import { modifyTour } from "../../redux/slices/tour";
import { useDispatch } from "../../redux/store";
// components
import Image from "../../components/Image";
//
import {
    // AddCreditCard,
    AddShipFrom, AddPackage,
} from "./tutorial/standard/detail";

// ----------------------------------------------------------------------

StandardTutorialDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onFinish: PropTypes.func,
};

export default function StandardTutorialDialog({ open, onClose, onFinish }) {
    const isDesktop = useResponsive('up', 'md');

    const { translate } = useLocales();

    const dispatch = useDispatch();

    const [step, setStep] = useState(1);

    useEffect(() => dispatch(modifyTour('company_tutorial')), [dispatch]);

    return (
        <Dialog fullWidth open={open} sx={{ '& .MuiPaper-root': { ...(isDesktop ? { maxWidth: '900px' } : { maxWidth: '360px' }) } }}>
            <Grid container sx={{ maxHeight: "inherit" }}>
                <Grid item xs={12} md={5} sx={{ maxHeight: "inherit" }}>
                    {(() => {
                        switch (step) {
                            // case 1:
                            //     return <AddCreditCard setStep={setStep}/>;
                            case 1:
                                return <AddShipFrom setStep={setStep}/>;
                            case 2:
                                return <AddPackage onClose={onClose} onFinish={onFinish}/>;
                            default:
                                return null;
                        }
                    })()}
                </Grid>

                {isDesktop &&
                    <Grid item xs={12} md={7} pb="50px" sx={{ bgcolor: '#F8F8F8' }}>
                        <Stack>
                            <Image
                                src={(() => {
                                    switch (step) {
                                        case 1:
                                            return `/assets/image/tutorials/tour_4.png`;
                                        case 2:
                                            return '/assets/image/tutorials/tour_5.png';
                                        // case 3:
                                        //     return '/assets/image/tutorials/tour_3.gif';
                                        default:
                                            return '/assets/image/tutorials/tour_4.png';
                                    }
                                })()}
                                alt="welcome" sx={{ width: '100%', height: '100%', '& img': { objectFit: 'contain' } }}
                            />
                            <Stack maxWidth="400px" mx="auto" mt={2} alignItems="center">
                                <Typography variant="body1" align="center" sx={{ px: 1.875 }}>
                                    <Typography variant="h1">
                                        {(() => {
                                            switch (step) {
                                                case 1:
                                                    return `${translate('dialog_standard_tutorial_1_3')}`;
                                                // case 2:
                                                //     return `${translate('dialog_standard_tutorial_2_6')}`;
                                                case 2:
                                                    return `${translate('dialog_standard_tutorial_3_8')}`;
                                                default:
                                                    return `${translate('dialog_standard_tutorial_1_3')}`;
                                            }
                                        })()}
                                    </Typography>
                                    <Typography sx={{ mt: 1 }} variant="body1">
                                        {(() => {
                                            switch (step) {
                                                case 1:
                                                    return (
                                                        <>
                                                            {translate('dialog_standard_tutorial_1_4')}
                                                            <Typography component="span" variant="body2" sx={{ fontStyle: 'italic' }}>{translate('dialog_standard_tutorial_1_5')}</Typography>
                                                            {translate('dialog_standard_tutorial_1_6')}
                                                            <Typography component="span" variant="body2" sx={{ fontStyle: 'italic' }}>{translate('dialog_standard_tutorial_1_7')}</Typography>
                                                            {translate('dialog_standard_tutorial_1_8')}
                                                        </>
                                                    );
                                                // case 2:
                                                //     return (
                                                //         <>
                                                //             {translate('dialog_standard_tutorial_2_7')}
                                                //             <Typography component="span" sx={{ fontWeight: 'bold' }}>{translate('dialog_standard_tutorial_2_8')}</Typography>
                                                //             {translate('dialog_standard_tutorial_2_9')}
                                                //         </>
                                                //     );
                                                case 2:
                                                    return (
                                                        <>
                                                            {translate('dialog_standard_tutorial_3_11')}
                                                            <Typography component="span" sx={{ fontWeight: 'bold' }}>{translate('dialog_standard_tutorial_3_10')}</Typography>
                                                        </>
                                                    );
                                                default:
                                                    return (
                                                        <>
                                                            {translate('dialog_standard_tutorial_1_4')}
                                                            <Typography component="span" variant="body2" sx={{ fontStyle: 'italic' }}>{translate('dialog_standard_tutorial_1_5')}</Typography>
                                                            {translate('dialog_standard_tutorial_1_6')}
                                                            <Typography component="span" variant="body2" sx={{ fontStyle: 'italic' }}>{translate('dialog_standard_tutorial_1_7')}</Typography>
                                                            {translate('dialog_standard_tutorial_1_8')}
                                                        </>
                                                    );
                                            }
                                        })()}
                                    </Typography>
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack mt={3.75}>
                            <Image
                                src={(() => {
                                    switch (step) {
                                        case 1:
                                            return `/assets/image/tutorials/tour_ic_1.svg`;
                                        // case 2:
                                        //     return '/assets/image/tutorials/tour_ic_2.svg';
                                        case 2:
                                            return '/assets/image/tutorials/tour_ic_3.svg';
                                        default:
                                            return '/assets/image/tutorials/tour_ic_1.svg';
                                    }
                                })()}
                                alt="welcome" sx={{ width: "100%", height: "48px", '& img': { objectFit: 'contain' } }}
                            />
                        </Stack>
                    </Grid>
                }
            </Grid>
        </Dialog>
    );
}
