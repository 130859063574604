import PropTypes from 'prop-types';
// hooks
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

PermissionGuard.propTypes = {
    replacement: PropTypes.node,
    permission: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default function PermissionGuard({ replacement, permission, children }) {
    const { user, permissions } = useAuth();

    const tempPermissions = [...permissions, 'partner'];

    const grantedPermission = permission === 'default' ? 'default' : (tempPermissions || []).find(_p => _p === permission);
    // const grantedPermission = permission === 'default' ? 'default' : (permissions || []).find(_p => _p === permission);

    if (!grantedPermission) {
        return replacement ? <>{replacement}</> : null;
    }

    return <>{children}</>;
}
