import React from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
// @mui
import { Stack, Button, Typography, Link } from '@mui/material';
// hooks
import useAuth from '../../../hooks/useAuth';
import useLocales from '../../../hooks/useLocales';
// routes
import { PATH_AUTH } from "../../../routes/paths";
// assets
import { PackageIllustration } from '../../../assets';

// ----------------------------------------------------------------------

export default function NavbarDocs() {
    const navigate = useNavigate();

    const {translate} = useLocales();

    const {logout} = useAuth();

    const {enqueueSnackbar} = useSnackbar();

    const handleLogout = () => {
        try {
            logout().then( () => navigate( PATH_AUTH.login, {replace: true} ) );
        } catch (error) {
            console.error( error );
            enqueueSnackbar( 'Unable to logout!', {variant: 'error'} );
        }
    };

    // return (
    //     <Stack spacing={9} sx={{ px: 2.5, pb: 5, mt: 10, width: 1, textAlign: 'left', display: 'block' }}>
    //         <Button variant="text" sx={{ fontWeight: 500, textTransform: 'uppercase' }} onClick={logout}>
    //             {`${translate('logout')}`}
    //         </Button>
    //     </Stack>
    // );

    return (
        <Stack spacing={ 57 / 8 } sx={ {px: 2.5, pb: 5, mt: 10, width: 1, textAlign: 'left', display: 'block'} }>
            <Stack width="100%" maxWidth="200px" padding="10px" bgcolor="white" alignItems="flex-start" borderRadius="8px" overflow="hidden">
                <Stack spacing={ 1.25 } zIndex={ 1 }>
                    <Stack spacing={ 0.6 }>
                        <Typography variant="h4" sx={ {lineHeight: '22px'} } noWrap>
                            { `${ translate( 'navbar_4' ) }` }
                        </Typography>

                        <Typography variant="body1" color="text.disabled" maxWidth="200px">
                            { `${ translate( 'navbar_5' ) }` }
                        </Typography>
                    </Stack>

                    <Link variant="body1" underline="always" color="#4568E5" target="_blank" href={ `${ translate( 'navbar_6' ) }` }>
                        { `${ translate( 'navbar_3' ) }` }
                    </Link>
                </Stack>

                <PackageIllustration sx={ {width: '200px', mt: '-30px', ml: 'auto', transform: 'translateX(-10px) translateY(10px)'} }/>
            </Stack>

            <Button variant="text" sx={ {fontWeight: 500} } onClick={ handleLogout }>
                { `${ translate( 'logout' ) }` }
            </Button>
        </Stack>
    );
}
