import { CA_STATES, COUNTRIES, US_STATES } from "../constants/country";

// ----------------------------------------------------------------------

export function getStateCode(country, state) {
    const selectedCountry = COUNTRIES.find(c => (c.code === country || c.label === country))?.code;

    // eslint-disable-next-line no-nested-ternary
    return state ? (selectedCountry === 'US' || selectedCountry === 'United States') ?
        US_STATES.find(s => (s.code === state || s.label === state))?.code : selectedCountry === 'CA' ?
            CA_STATES.find(s => (s.code === state || s.label === state))?.code : '' : '';
}

export function getCountryCode(country) {
    return country ? COUNTRIES.find(c => (c.code === country || c.label === country))?.code || 'US' : 'US';
}