import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
// @mui
import { Box, Dialog, Link, Stack, Typography } from '@mui/material';
import { LoadingButton } from "@mui/lab";
// routes
import { Link as RouterLink } from "react-router-dom";
import { PATH_DASHBOARD } from "../../routes/paths";
// components
import Image from "../../components/Image";
import Scrollbar from "../../components/Scrollbar";
// hooks
import useLocales from "../../hooks/useLocales";
// utils
import { fDateLockNotification } from "../../utils/formatTime";

// ----------------------------------------------------------------------

LockedBuyLabelNotification.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    errorMsg: PropTypes.object,
};

export default function LockedBuyLabelNotification({ open, onClose, errorMsg }) {
    const { translate } = useLocales();

    const [displayDefault, setDisplayDefault] = useState(false);

    useEffect(() => {
        let count = 0;

        errorMsg?.errors?.forEach((_error) => {
            if (_error.field === 'plan' || _error.field === 'adjustment') {
                count += 1;
            }
        });

        if (count <= 0) {
            setDisplayDefault(true);
        }
    }, [errorMsg]);

    return (
        <Dialog fullWidth open={open} onClose={onClose}>
            <Scrollbar>
                <Box sx={{ my: 5, mx: { md: 3.75, sm: 1.875, xs: 1.875 } }}>
                    <Stack direction="row" spacing={2.5} mb={1.25}>
                        <Image visibleByDefault src={'/assets/icon/system/ic_danger.svg'} alt="danger" sx={{ width: '40px', height: '40px', '& img': { objectFit: 'contain' } }} disabledEffect/>

                        <Stack flex={1}>
                            <Typography variant="h5" mb={1}>{`${translate('dialog_buy_label_locked_1')}`}</Typography>

                            <Typography component="ul" variant="body1" pl={3}>
                                {errorMsg?.errors?.map((_error, index) => {
                                    if (_error.field === 'plan') {
                                        return (
                                            <Typography key={`error-msg-${index}`} component="li" variant="body1" mb={2.5}>
                                                {`${translate('dialog_buy_label_locked_2_1')}`}
                                                {fDateLockNotification(_error.message)}
                                                {`${translate('dialog_buy_label_locked_2_2')}`}
                                                <Link component={RouterLink} variant="body1" underline='always' color="info.main" to={`${PATH_DASHBOARD.settings.account}/#billing`} onClick={onClose}>
                                                    {`${translate('dialog_buy_label_locked_2_3')}`}
                                                </Link>
                                                {`${translate('dialog_buy_label_locked_2_4')}`}
                                            </Typography>
                                        )
                                    }

                                    if (_error.field === 'adjustment') {
                                        return (
                                            <Typography key={`error-msg-${index}`} component="li" variant="body1" mb={2.5}>
                                                {`${translate('dialog_buy_label_locked_3_1')}`}
                                                <Link href='mailto:payment@shipsaving.com' underline="always" color="info.main">
                                                    {`${translate('dialog_buy_label_locked_3_2')}`}
                                                </Link>
                                                {`${translate('dialog_buy_label_locked_3_3')}`}
                                            </Typography>
                                        )
                                    }

                                    return ''
                                })}

                                {Boolean(displayDefault) && (
                                    <Typography component="li" variant="body1" mb={2.5}>
                                        {`${translate('dialog_buy_label_locked_4_1')}`}
                                        <Link href='mailto:payment@shipsaving.com' underline="always" color="info.main">
                                            {`${translate('dialog_buy_label_locked_4_2')}`}
                                        </Link>
                                        {`${translate('dialog_buy_label_locked_4_3')}`}
                                    </Typography>
                                )}
                            </Typography>
                        </Stack>
                    </Stack>

                    <Stack justifyContent="flex-end" direction="row" spacing={3}>
                        <LoadingButton variant="contained" onClick={onClose} sx={{ px: 4 }}>
                            {`${translate('dialog_buy_label_locked_5')}`}
                        </LoadingButton>
                    </Stack>
                </Box>
            </Scrollbar>
        </Dialog>
    );
}