// ----------------------------------------------------------------------

export function getURLQuery() {
    const query0 = getSearchQuery();
    const query1 = getSearchParam();

    return { ...query0, ...query1 }
}

export function getURLQueryByName(key) {
    const query = getURLQuery();

    return query[key] || '';
}

export function queryURLByName(key) {
    const query = getURLQuery();

    return query[key] || null;
}

// ----------------------------------------------------------------------

function getSearchQuery() {
    return decodeURI(window.location.search)
        .replace('?', '')
        .split('&')
        .map(param => param.split('='))
        .reduce((values, [key, value]) => {
            values[key] = value
            return values
        }, {})
}

function getSearchParam() {
    return Object.fromEntries(new URLSearchParams(window.location.search));
}
