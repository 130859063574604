// utils
import axios from '../utils/axios';

// ----------------------------------------------------------------------

const getReportOrders = (data) => axios.post('/report/orders', {
    start_time: data?.startTime || '',
    end_time: data?.endTime || ''
});

const getReportShipmentsCost = (data) => axios.post('/report/shipments/cost', {
    start_time: data?.startTime || '',
    end_time: data?.endTime || ''
});

const getReportShipmentsRefund = (data) => axios.post('/report/shipments/refund', {
    start_time: data?.startTime || '',
    end_time: data?.endTime || ''
});

const getReportShipmentsMonthCost = (data) => axios.post('/report/shipments/month_cost', {
    start_time: data?.startTime || '',
    end_time: data?.endTime || ''
});

const getReportShipmentsService = (data) => axios.post('/report/shipments/service', {
    start_time: data?.startTime || '',
    end_time: data?.endTime || ''
});

// ----------------------------------------------------------------------

export {
    getReportOrders,
    getReportShipmentsCost,
    getReportShipmentsRefund,
    getReportShipmentsMonthCost,
    getReportShipmentsService,
}
