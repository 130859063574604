import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fBalance(num) {
	const balance = parseFloat(num) || 0;

	return balance > -0.5 && balance <= 0 ? 0 : balance;
}

export function fNumberString(num, size) {
	num = num.toString();

	while (num.length < size) num = `0${num}`;

	return num;
}

export function fAbsoluteNumber(number) {
	return numeral(Math.abs(number)).format('$0,0.00');
}

export function fCurrencySign(number) {
	const mark = Math.sign(number);
	let sign = '';

	if (mark === 1) {
		sign = '+';
	} else if (mark === -1) {
		sign = '-';
	}

	// return sign + numeral(Math.abs(number)).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
	return sign + numeral(Math.abs(number)).format('$0,0.00');
}

export function fCurrencySign2(number) {
	const mark = Math.sign(number);
	let sign = '';

	if (mark === -1) {
		sign = '- ';
	}

	return sign + numeral(Math.abs(number)).format('$0,0.00');
}

export function fCurrencySignAlter(number) {
	const mark = Math.sign(number);
	let sign = '';

	if (mark === 1) {
		sign = '- ';
	} else if (mark === -1) {
		sign = '';
	}

	return sign + numeral(Math.abs(number)).format('$0,0.00');
}

export function fCurrencySignAlter2(number) {
	const mark = Math.sign(number);
	let sign = '';

	if (mark === 1) {
		sign = '- ';
	} else if (mark === -1) {
		sign = '+ ';
	}

	return sign + numeral(Math.abs(number)).format('$0,0.00');
}

export function fCurrency(number) {
	// return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
	return numeral(number).format('$0,0.00');
}

export function fPercent(number) {
	return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
	return numeral(number).format();
}

export function fNumber2(number) {
	return numeral(number).format('0.00');
}

export function fShortenNumber(number) {
	return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
	return numeral(number).format('0.0 b');
}
