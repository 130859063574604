import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import * as Yup from "yup";
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Button, Dialog, Stack, Typography } from '@mui/material';
import { LoadingButton } from "@mui/lab";
// hooks
import useLocales from "../../../../../hooks/useLocales";
// redux
import { useDispatch, useSelector } from "../../../../../redux/store";
import { addWarehouse, modifyWarehouse } from "../../../../../redux/slices/warehouse";
import { validateAddress } from "../../../../../redux/slices/address";
// components
import { DialogHeader } from "../../../../../components/dialog";
import { FormProvider, RHFCheckbox, RHFTextField } from '../../../../../components/hook-form';
import { FormAddress } from "../../../../../components/form";
// sections
import ValidateDialog from "./ValidateDialog";
// utils
import { getCountryCode, getStateCode } from "../../../../../utils/getCode";

// ----------------------------------------------------------------------

/**
 * @function NewEditWarehouseDialog
 * @description
 */

NewEditWarehouseDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    isEdit: PropTypes.bool,
    currentDetail: PropTypes.object,
};

export default function NewEditWarehouseDialog( {open, onClose, isEdit, currentDetail} ) {
    const dispatch = useDispatch();

    const {translate} = useLocales();

    const {isLoading} = useSelector( ( {warehouse} ) => warehouse );

    const [validateDialog, setValidateDialog] = useState( null );

    const WarehouseSchema = Yup.object().shape( {
        firstName: Yup.string().required( `${ translate( 'input_schema_address_form_1' ) }` ),
        lastName: Yup.string().required( `${ translate( 'input_schema_address_form_2' ) }` ),
        phone: Yup.string().required( `${ translate( 'input_schema_address_form_3' ) }` ),
        street1: Yup.string().required( `${ translate( 'input_schema_address_form_4' ) }` ),
        city: Yup.string().required( `${ translate( 'input_schema_address_form_5' ) }` ),
        state: Yup.string().nullable().required( `${ translate( 'input_schema_address_form_6' ) }` ),
        zipcode: Yup.string().required( `${ translate( 'input_schema_address_form_7' ) }` ),
        country: Yup.string().nullable().required( `${ translate( 'input_schema_address_form_8' ) }` ),
    } );

    const getDefaultValues = ( detail ) => {
        const {name, first_name: firstName, last_name: lastName, phone, email, street, street2, city, state, zip, country, default_warehouse: defaultWarehouse, residential} = detail || {};

        return {
            firstName: firstName || '',
            lastName: lastName || '',
            company: name || '',
            phone: phone || '',
            email: email || '',
            street1: street || '',
            street2: street2 || '',
            city: city || '',
            state: getStateCode( country, state ),
            zipcode: zip || '',
            country: country || 'United States',
            isDefault: defaultWarehouse || false,
            residential: residential || false,
        };
    };

    const defaultValues = useMemo( () => getDefaultValues( currentDetail ), [currentDetail] );

    const methods = useForm( {
        resolver: yupResolver( WarehouseSchema ),
        // mode: 'onChange',
        defaultValues,
    } );

    const {
        setValue,
        handleSubmit,
    } = methods;

    const handleSaveWarehouse = async ( detail ) => {
        try {
            let result;

            if (isEdit) {
                result = await dispatch( modifyWarehouse( {id: currentDetail.id, ...detail} ) );
            } else {
                result = await dispatch( addWarehouse( {...detail} ) );
            }

            if (result) onClose();
        } catch (error) {
            console.error( error );
        }
    };

    const handleOnValidate = ( detail, address ) => {
        setValidateDialog(
            <ValidateDialog
                open={ Boolean( true ) }
                onClose={ () => setValidateDialog( null ) }
                onConfirm={ () => handleSaveWarehouse( address ) }
                title={ detail?.title ?? '' }
                message={ detail?.message ?? '' }
                content={ detail?.content ?? '' }
            />,
        );
    };

    const onSubmit = async ( values ) => {
        try {
            const warehouseDetail = {
                name: values.company || '',
                first_name: values.firstName || '',
                last_name: values.lastName || '',
                phone: values.phone || '',
                email: values.email || '',
                street: values.street1 || '',
                street2: values.street2 || '',
                city: values.city || '',
                state: values.state || '',
                zipcode: values.zipcode || '',
                country: getCountryCode( values.country ),
                is_default: values.isDefault || false,
                residential: values.residential || false,
                verified: false,
            };

            const requireValidate = warehouseDetail.country === 'US';

            let result = null;

            if (requireValidate) {
                result = await dispatch( validateAddress( {...warehouseDetail} ) );
            }

            if (requireValidate && result) {
                let title;
                let message;
                let content;

                setValue( 'residential', Boolean( result?.residential ) );

                warehouseDetail.residential = Boolean( result?.residential );

                warehouseDetail.verified = Boolean( !result?.errored );

                if (result?.errored) {
                    title = 'dialog_validate_address_1';
                    message = result?.errors[0]?.message || '';
                    content = 'dialog_validate_address_1_1';

                    handleOnValidate( {title, message, content}, {...warehouseDetail} );
                } else if (result?.corrected) {
                    title = 'dialog_validate_address_2';
                    message = result?.corrections[0]?.message || '';
                    content = 'dialog_validate_address_2_1';

                    handleOnValidate( {title, message, content}, {...warehouseDetail} );
                } else {
                    await handleSaveWarehouse( warehouseDetail );
                }
            } else {
                await handleSaveWarehouse( warehouseDetail );
            }
        } catch (error) {
            console.error( error );
        }
    };

    return (
        <>
            <Dialog fullWidth open={ open } onClose={ onClose }>
                <DialogHeader
                    title={ isEdit ? `${ translate( 'dialog_add_ship_from_address_2' ) }` : `${ translate( 'dialog_add_ship_from_address_1' ) }` }
                    content={ `${ translate( 'dialog_add_ship_from_address_1_1' ) }` }
                    src="/assets/image/function/address.svg"
                />

                <FormProvider methods={ methods } onSubmit={ handleSubmit( onSubmit ) } sx={ {height: 'calc( 100% - 120px )', overflow: "auto"} }>
                    <Box sx={ {pt: 3.75, pb: 7.5, px: {xs: 1.875, sm: 3.75}} }>
                        <Stack mb={ 3.75 } spacing={ 3.75 }>
                            <Box display="grid" rowGap={ 3.75 } columnGap={ 2.5 } gridTemplateColumns={ {xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)'} }>
                                <RHFTextField name="firstName" label={ `${ translate( 'dialog_add_ship_from_address_1_2_1' ) }` } size="small"/>

                                <RHFTextField name="lastName" label={ `${ translate( 'dialog_add_ship_from_address_1_2_2' ) }` } size="small"/>
                            </Box>

                            <RHFTextField name="company" label={ `${ translate( 'dialog_add_ship_from_address_1_3' ) }` } size="small"/>

                            <Box display="grid" rowGap={ 3.75 } columnGap={ 2.5 } gridTemplateColumns={ {xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)'} }>
                                <RHFTextField name="phone" label={ `${ translate( 'dialog_add_ship_from_address_1_5' ) }` } size="small"/>

                                <RHFTextField name="email" label={ `${ translate( 'dialog_add_ship_from_address_1_4' ) }` } size="small"/>
                            </Box>

                            <Stack spacing={ 2.5 }>
                                <FormAddress/>

                                <RHFCheckbox
                                    name="isDefault"
                                    label={
                                        <Typography variant="body1">{ `${ translate( 'dialog_add_ship_from_address_1_6' ) }` }</Typography>
                                    }
                                    color="success"
                                    sx={ {width: 'fit-content', ml: 0, alignItems: 'flex-start', '& .MuiCheckbox-root': {padding: "0", marginRight: '8px'}} }
                                />

                                <RHFCheckbox
                                    name="residential"
                                    label={
                                        <Typography variant="body1">{ `${ translate( 'dialog_add_ship_from_address_1_7' ) }` }</Typography>
                                    }
                                    color="success"
                                    sx={ {width: 'fit-content', '& .MuiCheckbox-root': {padding: "0", marginRight: '8px'}} }
                                />
                            </Stack>
                        </Stack>
                    </Box>

                    <Stack
                        position="absolute" width={ 1 } height="60px" bottom={ 0 } padding="10px 30px" bgcolor="white"
                        direction="row" justifyContent="flex-end" spacing={ 3 }
                        boxShadow="-40px 40px 80px 10px rgba(0, 0, 0, 0.24)" zIndex={ 1 }
                    >
                        <Button color="inherit" variant="outlined" onClick={ onClose }>
                            { `${ translate( 'dialog_add_ship_from_address_1_8' ) }` }
                        </Button>

                        <LoadingButton variant="contained" loading={ isLoading } type="submit">
                            { isEdit ? `${ translate( 'dialog_add_ship_from_address_2_1' ) }` : `${ translate( 'dialog_add_ship_from_address_1_9' ) }` }
                        </LoadingButton>
                    </Stack>
                </FormProvider>
            </Dialog>

            { validateDialog }
        </>
    );
}
