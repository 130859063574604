import React, { useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import parse from "autosuggest-highlight/parse";
import { throttle } from "lodash";
// @mui
import { Autocomplete, Stack, Typography } from "@mui/material";
// form
import { useFormContext } from "react-hook-form";
import { RHFTextField } from "./hook-form";
// constants
import { COUNTRIES } from "../constants/country";
//
import Image from "./Image";

// ----------------------------------------------------------------------

const GOOGLE_MAPS_API_KEY = 'AIzaSyDDxH3T3L_tNaf7CBBxMoT0xDkR0F9hTrg';

function loadScript(src, position, id) {
    if (!position) return;

    if (document.querySelector('#google-maps')) return;

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };
const placesService = { current: null };

// ----------------------------------------------------------------------

AutocompleteAddress.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    values: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    country: PropTypes.string,
};

export default function AutocompleteAddress({ name, label, values, city, state, zip, country, ...other }) {
    const loaded = useRef(false);

    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);

    const { setValue } = useFormContext();

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places&language=en`,
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    const fetch = useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        [],
    );

    const changeAddress = async (newValue) => {
        // setOptions(options);
        let result = null;
        if (newValue?.place_id) {
            result = await placesService.current(newValue.place_id);
        }

        let address1 = "";
        let postcode = "";
        let citycode = "";

        result?.address_components.forEach((component) => {
            const componentType = component.types[0];

            switch (componentType) {
                case "street_number": {
                    address1 = `${component.long_name}${address1}`;
                    break;
                }
                case "route": {
                    address1 = `${address1} ${component.long_name}`;
                    break;
                }
                case "sublocality_level_1": {
                    address1 = `${address1} ${component.long_name}`;
                    break;
                }
                case "sublocality_level_2": {
                    address1 = `${address1} ${component.long_name}`;
                    break;
                }
                case "postal_code": {
                    postcode = `${component.long_name}${postcode}`;
                    break;
                }
                // case "postal_code_suffix": {
                //     postcode = `${postcode}-${component.long_name}`;
                //     break;
                // }
                case "locality": {
                    citycode = component.long_name;
                    break;
                }
                case "administrative_area_level_1": {
                    setValue(state, component.short_name, { shouldValidate: true });
                    break;
                }
                case "country": {
                    const countryCode = COUNTRIES.find(c => (c.label === component.long_name || c.code === component.long_name)) || COUNTRIES.find(c => c.code === 'US');

                    setValue(country, countryCode.label, { shouldValidate: true });
                    break;
                }
                default: {
                    break;
                }
            }
        });

        if (!address1) {
            address1 = newValue?.structured_formatting?.main_text ?? "";
        }

        setValue(name, address1, { shouldValidate: true });
        setValue(zip, postcode, { shouldValidate: true });
        setValue(city, citycode, { shouldValidate: true });
    };

    useEffect(() => {
        window.document.querySelector(`input[name=${name}]`).setAttribute('autocomplete', 'disable');
        window.document.querySelector(`input[name=${name}]`).setAttribute('aria-autocomplete', 'off');
    }, []);

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }

        if (!autocompleteService.current) {
            return undefined;
        }

        if (!placesService.current && window.google) {
            placesService.current = async placeId =>
                new Promise((resolve, reject) => {
                    try {
                        new window.google.maps.places.PlacesService(
                            document.createElement("div"),
                        ).getDetails(
                            {
                                placeId,
                                fields: ["address_components"],
                            },
                            details => {
                                return resolve(details);
                            },
                        );
                    } catch (e) {
                        reject(e);
                    }
                });
        }

        if (inputValue === '') {
            return undefined;
        }

        if (inputValue.length > 6) {
            fetch({ input: inputValue, componentRestrictions: { country: ['us', 'ca', 'mx'] } }, (results) => {
                if (active) {
                    let newOptions = [];

                    if (results) {
                        newOptions = [...newOptions, ...results];
                    }

                    setOptions(newOptions);
                }
            });
        } else {
            setOptions([]);
        }

        return () => {
            active = false;
        };
    }, [inputValue, fetch]);

    return (
        <Autocomplete
            sx={{ width: '100%' }}
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.description
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete={false}
            includeInputInList
            filterSelectedOptions
            freeSolo
            value={values}
            onChange={(event, newValue) => changeAddress(newValue)}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                setValue(name, newInputValue);
            }}
            renderInput={(params) => (
                <RHFTextField {...params} name={name} label={label} fullWidth autoComplete="off"/>
            )}
            renderOption={(props, option) => {
                const matches = option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [match.offset, match.offset + match.length]),
                );

                return (
                    <li {...props}>
                        <Stack direction="row" alignItems="center">
                            <Image disabledEffect sx={{ width: '24px', borderRadius: 0.5 }} src="/assets/icon/system/ic_address.svg" alt=""/>
                            &nbsp;
                            {parts.map((part, index) => (
                                <Typography
                                    key={index}
                                    variant="span"
                                    sx={{
                                        fontWeight: part.highlight ? 700 : 400,
                                        whiteSpace: "pre",
                                    }}
                                >
                                    {part.text}
                                </Typography>
                            ))}
                            &nbsp;
                            <Typography variant="span" color="#919EAB">
                                {option.structured_formatting.secondary_text}
                            </Typography>
                        </Stack>
                    </li>
                );
            }}
            {...other}
        />
    );
}
