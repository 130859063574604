import PropTypes from "prop-types";
import React, { useState, useEffect } from 'react';
import { useFormContext } from "react-hook-form";
// @mui
import { Autocomplete, Box, CircularProgress, Stack, TextField, Typography } from "@mui/material";
// api
import { getItemList as getItemListAPI } from "../api/item";
// components
import Image from "./Image";

// ----------------------------------------------------------------------

function sleep(delay = 1000) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

Asynchronous.prototype = {
    delayMs: PropTypes.number,
    storeId: PropTypes.number,
    selectedItem: PropTypes.object,
    setValue: PropTypes.func,
    index: PropTypes.number,
    isEdit: PropTypes.bool,
};

export default function Asynchronous({ delayMS, storeId, selectedItem, setValue, index, isEdit }) {
    const { watch } = useFormContext();

    const watched = watch();

    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(open && options.length === 0);

    const loadMoreItems = async () => {
        await sleep(delayMS);

        if (!storeId) {
            setOptions([]);
        } else {
            const result = await getItemListAPI({ storeId, keyword: null, rowsPerPage: 25, page });

            const { status, data } = result;

            if (status === 200) {
                // const tempResult = data?.data || [];
                const tempResult = (data?.data || []).filter((el1) => !(watched.orderItems || []).some((el2) => el1.id === (el2.selectedItem?.id ?? "")));

                setOptions([...options, ...tempResult]);
            }
        }

    };

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            await sleep(delayMS);

            if (active) {
                await loadMoreItems();
            }
            setLoading(false);
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
            setPage(0);
        } else {
            setLoading(true);
        }
    }, [open]);

    useEffect(() => {
        if (storeId) {
            setOptions([]);
            setPage(0);
        }
    }, [storeId]);

    return (
        <Autocomplete
            defaultValue={selectedItem ?? null}
            sx={{ width: 300 }}
            autoHighlight
            open={open}
            disabled={isEdit}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onChange={(event, value) => {
                setValue(`orderItems[${index}].searchKeyword`, value?.sku || value?.title);
                setValue(`orderItems[${index}].selectedItem`, value);
            }}
            isOptionEqualToValue={(option, value) => option.title === value.title}
            getOptionLabel={(option) => option.title}
            options={options}
            noOptionsText={storeId ? 'no items' : 'no store selected'}
            ListboxProps={{
                role: "list-box",
                style: { maxHeight: 350 },
                onScroll: async (event) => {
                    const listBoxNode = event.currentTarget;
                    if (listBoxNode.scrollTop + listBoxNode.clientHeight === listBoxNode.scrollHeight) {
                        setPage((page) => page + 1);
                        setLoading(true);
                    }
                },
            }}
            loading={loading}
            selectOnFocus
            filterOptions={(options, { inputValue }) => options.filter(item => item.title.toLowerCase().includes(inputValue) || item.sku.toString().includes(inputValue))}
            renderOption={(props, option) => (
                <Box component="li" key={`search-item-${option.id}`} direction="row" sx={{ my: 1 }} flex={1} alignItems="center" {...props}>
                    <Image
                        src={option?.thumbnail || ''}
                        alt={`product-${option.title}`}
                        onError={(event) => {
                            event.target.src = `/assets/icon/system/ic_default_image.svg`;
                        }}
                        sx={{ width: '40px', height: '40px', borderRadius: '4px', border: '1px solid #292929', flexShrink: 0, '& img': { objectFit: 'contain' } }} disabledEffect
                        disabledBase
                    />
                    <Stack sx={{ ml: 1 }}>
                        <Typography
                            variant="body1"
                            sx={{ overflow: "hidden", textOverflow: "ellipsis", display: '-webkit-box', WebkitLineClamp: '1', WebkitBoxOrient: 'vertical' }}
                        >
                            {option.title}
                        </Typography>
                        <Typography
                            variant="caption"
                            sx={{ overflow: "hidden", textOverflow: "ellipsis", display: '-webkit-box', WebkitLineClamp: '1', WebkitBoxOrient: 'vertical', color: 'text.disabled' }}
                        >
                            {option.sku}
                        </Typography>
                    </Stack>
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Item"
                    placeholder="Search SKU or Title"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? (
                                    <Box display="flex" alignItems="center" pl="12px" pr="36px">
                                        <CircularProgress color="inherit" size={20}/>
                                    </Box>
                                ) : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            size="small"
        />
    );
}
