import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material';
// guards
import PermissionGuard from "../../../guards/PermissionGuard";
// routes
import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useLocales from "../../../hooks/useLocales";
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    {
        label: 'account',
        linkTo: PATH_DASHBOARD.settings.account,
        key: 'account',
    }, {
        label: 'shipments',
        linkTo: PATH_DASHBOARD.shipments.root,
        key: 'shipments',
    }, {
        label: 'orders',
        linkTo: PATH_DASHBOARD.orders.root,
        key: 'orders',
    }, {
        label: 'billing',
        linkTo: `${PATH_DASHBOARD.settings.account}/#billing`,
        key: 'account.billing',
    }, {
        label: 'configuration',
        linkTo: PATH_DASHBOARD.settings.configuration,
        key: 'configuration',
    },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
    const navigate = useNavigate();

    const { translate } = useLocales();

    const { user, logout } = useAuth();

    const isMountedRef = useIsMountedRef();

    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(null);

    const handleOpen = (event) => setOpen(event.currentTarget);

    const handleClose = () => setOpen(null);

    const handleLogout = () => {
        try {
            logout().then(() => navigate(PATH_AUTH.login, { replace: true }));

            if (isMountedRef.current) {
                handleClose();
            }
        } catch (error) {
            console.error(error);
            enqueueSnackbar('Unable to logout!', { variant: 'error' });
        }
    };

    return (
        <>
            <IconButtonAnimate
                onClick={handleOpen}
                sx={{
                    p: 0,
                    ml: 0.75,
                    border: '1px solid black',
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            boxSizing: 'content-box',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.4),
                        },
                    }),
                }}
            >
                <MyAvatar sx={{ width: '32px', height: '32px' }}/>
            </IconButtonAnimate>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Stack mb={0.25} direction="row" justifyContent="space-between">
                        <Typography variant="h5" noWrap>
                            {user?.first_name} {user?.last_name}
                        </Typography>

                        <Typography variant="caption" textTransform="capitalize" display="inline-block" ml={0.5} py={0.5} px={0.5} sx={{ color: 'white', lineHeight: '12px', bgcolor: '#00AB55', borderRadius: '4px' }}>
                            Starter
                        </Typography>

                        {/* {user?.plan_type && ( */}
                        {/*     <Typography variant="caption" textTransform="capitalize" display="inline-block" ml={0.5} py={0.5} px={0.5} sx={{ color: 'white', lineHeight: '12px', bgcolor: '#00AB55', borderRadius: '4px' }}> */}
                        {/*         {user.plan_type} */}
                        {/*     </Typography> */}
                        {/* )} */}
                    </Stack>

                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {user?.email}
                    </Typography>
                </Box>

                <Divider sx={{ borderStyle: 'dashed' }}/>

                <Stack sx={{ p: 1 }}>
                    {MENU_OPTIONS.map((option, index) => {
                        return (
                            <PermissionGuard key={`account-${option.label}-${index}`} permission={option.key}>
                                <MenuItem component={RouterLink} to={option.linkTo} onClick={handleClose} sx={{ py: '8px', textTransform: 'capitalize' }}>
                                    {`${translate(option.label)}`}
                                </MenuItem>
                            </PermissionGuard>
                        );
                    })}
                </Stack>

                <Divider sx={{ borderStyle: 'dashed' }}/>

                <MenuItem onClick={handleLogout} sx={{ m: 1, textTransform: 'capitalize' }}>
                    {`${translate('logout')}`}
                </MenuItem>
            </MenuPopover>
        </>
    );
}
