import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from "notistack";
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useLocales from "../../../hooks/useLocales";
// api
import { verifyRecaptcha } from "../../../api/auth";
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';
// redux
import { useDispatch, useSelector } from "../../../redux/store";
import { resetStoreLocation } from "../../../redux/slices/store";
import { clearLocation, } from "../../../redux/slices/permission";
// hooks
import useToggle from "../../../hooks/useToggle";
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
// sections
import LockedAccountNotification from "../LockedAccountNotification";
import { VerificationCodeDialog } from "../register/dialog";

// ----------------------------------------------------------------------

export default function LoginForm() {
    const { translate } = useLocales();

    const dispatch = useDispatch();

    const { storeLocation } = useSelector(({ store }) => store);
    const { requestedLocation } = useSelector(({ permission }) => permission);

    const navigate = useNavigate();

    const { executeRecaptcha } = useGoogleReCaptcha();
    const { enqueueSnackbar } = useSnackbar();
    const { getData } = useVisitorData(
        { extendedResult: true },
        { immediate: true },
    );

    const { toggle: openLockedNotification, onOpen: onOpenLockedNotification, onClose: onCloseLockedNotification } = useToggle();

    const { login, checkPermission } = useAuth();

    const isMountedRef = useIsMountedRef();

    const [verificationCodeDialog, setVerificationCodeDialog] = useState(null);
    //
    const [showPassword, setShowPassword] = useState(false);

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email(`${translate('input_schema_login_form_1_1')}`).required(`${translate('input_schema_login_form_1_2')}`),
        password: Yup.string().required(`${translate('input_schema_login_form_2')}`),
    });

    const defaultValues = {
        email: '',
        password: '',
        remember: true,
    };

    const methods = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });

    const {
        watch,
        setError,
        clearErrors,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = methods;

    const watched = watch();

    const onSubmit = async (values) => {
        try {
            if (!executeRecaptcha) {
                enqueueSnackbar('Execute recaptcha not yet available', { variant: 'warning' });
                return;
            }

            // recaptcha
            const token = await executeRecaptcha('login');

            const responseRecaptcha = await verifyRecaptcha(token);

            const { status: statusRecaptcha, data: dataRecaptcha } = responseRecaptcha;

            if (statusRecaptcha === 200 && dataRecaptcha) {
                if (dataRecaptcha.success) {
                    // fingerprint
                    const responseFingerprint = await getData({ ignoreCache: true }).catch(() => null);
                    const responseLogin = await login(values.email, values.password, values.remember, values.code, responseFingerprint);
                    const { storeList, permissionList } = responseLogin;

                    if (storeLocation) {
                        dispatch(resetStoreLocation());

                        navigate(storeLocation);
                    } else if (requestedLocation) {
                        dispatch(clearLocation());

                        if ((requestedLocation === "/partner-tool" || requestedLocation === "/dashboard") && permissionList && await checkPermission("carrier_referral", permissionList)) {
                            navigate(PATH_DASHBOARD.partner.root, { replace: true });
                        } else if ((requestedLocation === "/items" || requestedLocation === "/reports") && (storeList && storeList.length === 0)) {
                            navigate(PATH_DASHBOARD.root);
                        } else {
                            navigate(requestedLocation);
                        }
                    } else if (permissionList && await checkPermission("carrier_referral", permissionList)) {
                        navigate(PATH_DASHBOARD.partner.root, { replace: true });
                    } else {
                        navigate(PATH_DASHBOARD.root);
                    }
                } else {
                    enqueueSnackbar(dataRecaptcha['error-codes'][0], { variant: 'warning' });
                }
            }
        } catch (error) {
            if (error?.code === 'com.shipsaving.user.inactivated') {
                setVerificationCodeDialog(
                    <VerificationCodeDialog
                        email={values.email}
                        open={Boolean(true)}
                        onClose={() => setVerificationCodeDialog(null)}
                    />
                )
            } else if (error?.code === "com.shipsaving.user.login.code_required") {
                setVerificationCodeDialog(
                    <VerificationCodeDialog
                        email={values.email}
                        open={Boolean(true)}
                        onComplete={async (code) => {
                            await onSubmit({ ...watched, code })
                        }}
                        onResendEmail={async () => {
                            await onSubmit({ ...watched })
                        }}
                        onClose={() => setVerificationCodeDialog(null)}
                    />
                )
            }

            if (isMountedRef.current) {
                setError('afterSubmit', {
                    ...error,
                    // eslint-disable-next-line no-nested-ternary
                    message: (error?.code === "com.shipsaving.company.type" ? "company.type" : (error?.code === 'com.shipsaving.auth.pwd.incorrect' || error?.code === 'com.shipsaving.user.no_exist') ? `${translate('com_shipsaving_auth_pwd_incorrect')}` : error?.message) ?? '',
                });

                setTimeout(() => clearErrors('afterSubmit'), 10000);
            }

            if (error?.code === 'com.shipsaving.company.locked') {
                onOpenLockedNotification();
            }
        }
    };

    const handleContactToUnlock = () => {
        onCloseLockedNotification();

        if (window) {
            window.location.href = 'mailto:support@shipsaving.com?subject=Unlock my ShipSaving account';
        }
    };

    // 测试弹窗用
    // useEffect(() => {
    //     setVerificationCodeDialog(
    //         <VerificationCodeDialog
    //             email=""
    //             open={Boolean(true)}
    //             onClose={() => setVerificationCodeDialog(null)}
    //         />
    //     )
    // }, []);

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={7.5}>
                    <Stack spacing={1}>
                        <Stack spacing={3.75}>
                            {!!errors.afterSubmit && (
                                <Alert severity="error">
                                    {errors.afterSubmit.message === "company.type" ? (
                                        <Typography variant="body1">
                                            {`${translate('com_shipsaving_user_no_exist_1')}`}
                                            <Link variant="body1" color="error.main" underline="always" href={`${translate('com_shipsaving_user_no_exist_2_2')}`} target="_blank">
                                                {`${translate('com_shipsaving_user_no_exist_2')}`}
                                            </Link>
                                            {`${translate('com_shipsaving_user_no_exist_3')}`}
                                        </Typography>
                                    ) : errors.afterSubmit.message}
                                </Alert>
                            )}

                            <RHFTextField name="email" label={`${translate('auth_login_3')}`} size="small"/>

                            <RHFTextField
                                name="password"
                                label={`${translate('auth_login_4')}`}
                                size="small"
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Stack>

                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <RHFCheckbox
                                name="remember"
                                label={
                                    <Typography variant="body1">{`${translate('auth_login_5')}`}</Typography>
                                }
                                color="success"
                            />

                            <Link component={RouterLink} variant="body1" underline="always" to={PATH_AUTH.resetPassword}>
                                {`${translate('auth_login_6')}`}
                            </Link>
                        </Stack>
                    </Stack>

                    <LoadingButton type="submit" variant="contained" loading={isSubmitting} fullWidth>
                        {`${translate('auth_login_7')}`}
                    </LoadingButton>
                </Stack>
            </FormProvider>

            <LockedAccountNotification
                open={openLockedNotification}
                onClose={onCloseLockedNotification}
                onClickButton={handleContactToUnlock}
            />

            {verificationCodeDialog}
        </>
    );
}
