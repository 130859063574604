import { COUNTRIES } from "../constants/country";

// ----------------------------------------------------------------------

export function fAddress(data) {
	const address = [`${data?.street1} ${data?.street2}`, `${data?.city}`, `${data?.state} ${data?.zip}`, `${data?.country}`];

	const result = address.map(a => a.trim()).filter(item => item);

	return result.join(", ")
}

export function fAddressWrap(data, middleIndex) {
	// eslint-disable-next-line no-nested-ternary
	const country = data?.country ? data.country.toLowerCase() === 'united states' ? data.country : COUNTRIES.find(c => c.code === data?.country)?.label : '';

	const address = [`${data?.street1 ?? ""} ${data?.street2 ?? ""}`, `${data?.city ?? ""}`, `${data?.state ?? ""} ${data?.zip ?? ""}`, `${country}`];

	const result = address.map(a => a.trim()).filter(item => item);

	const firstHalf = result.slice(0, middleIndex).join(", ");
	const secondHalf = result.slice(middleIndex - result.length).join(", ");

	return result.length === 0 ? "" : [firstHalf, secondHalf].join(",\n\r")
}

export function fRecipientAddress(data) {
	const address = [`${data?.city}`, `${data?.state} ${data?.zip}`];

	const result = address.map(a => a.trim()).filter(item => item);

	return result.join(",\n")
}

export function fAddressOrder(data) {
	const address = [`${data?.client_name} · ${data?.client_street} ${data?.client_street2}`, `${data?.client_city}`, `${data?.client_state} ${data?.client_zip}`, `${data?.client_country}`];

	const result = address.map(a => a.trim()).filter(item => item);

	return result.join(", ");
}

export function fAddressShipment(data) {
	const address = [`${data?.name} · ${data?.street1} ${data?.street2}`, `${data?.city}`, `${data?.state} ${data?.zip}`, `${data?.country}`];

	const result = address.map(a => a.trim()).filter(item => item);

	return result.join(", ");
}

export function fPickupAddress(data) {
	const address = [`${data?.street1} ${data?.street2}`, `${data?.city}`, `${data?.state} ${data?.zip}`];

	const result = address.map(a => a.trim()).filter(item => item);

	return result.join(", ")
}

export function fAddressList(data) {
	const address = [`${data?.street} ${data?.street2}`, `${data?.city}`, `${data?.state} ${data?.zip}`, `${data?.country}`];

	const result = address.map(a => a.trim()).filter(item => item);

	return result.join(", ");
}

export function fAddressOrderDetail(data) {
	const address = [`${data?.client_city}`, `${data?.client_state} ${data?.client_zip}`, `${data?.client_country}`];

	const result = address.map(a => a.trim()).filter(item => item);

	return result.join(", ");
}
