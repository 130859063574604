import PropTypes from "prop-types";
import React from "react";
import { AnimatePresence, m } from "framer-motion";
// @mui
import { Box, Button, IconButton, LinearProgress, Link, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
// hooks
import useLocales from "../../hooks/useLocales";
// components
import Image from "../Image";
import Iconify from "../Iconify";
import { varFade } from "../animate";
// utils
import { fPercent } from "../../utils/formatNumber";
import getFileData from "../../utils/getFileData";

// ----------------------------------------------------------------------

FileList.propTypes = {
    fileType: PropTypes.string,
    detail: PropTypes.object,
    onRemove: PropTypes.func,
    onDownload: PropTypes.func,
};

export default function FileList({ fileType = 'xls', detail, onRemove, onDownload }) {
    const { translate } = useLocales();

    const { status, percentage, file } = detail || {};

    const { name, type = 'xls', blob } = getFileData(file, 0);

    return (
        <List disablePadding>
            <AnimatePresence>
                <ListItem
                    component={m.div}
                    {...varFade().inRight}
                    sx={{
                        mt: 2.5,
                        pl: 1.25,
                        pr: 1.5,
                        py: 1.25,
                        borderRadius: 0.75,
                        border: (theme) => `solid 1px ${theme.palette.divider}`,
                    }}
                >
                    <Box sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }} zIndex={0}>
                        <LinearProgress
                            variant="determinate" value={percentage || 0}
                            sx={{
                                height: 1,
                                bgcolor: 'transparent',
                                '& .MuiLinearProgress-bar': { bgcolor: status === 'failed' ? '#FFB9B7' : '#E6F0FF' },
                            }}
                        />
                    </Box>

                    <ListItemIcon sx={{ mr: 1.25, zIndex: 9 }}>
                        <Image
                            src={`/assets/icon/system/ic_upload_${fileType}.svg`}
                            alt="upload_image"
                            sx={{ width: '48px', height: '48px', '& img': { borderRadius: '8px', objectFit: 'contain' } }}
                            visibleByDefault
                        />
                    </ListItemIcon>

                    <ListItemText
                        primary={
                            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                <Typography
                                    sx={{
                                        maxWidth: '300px', overflow: "hidden", textOverflow: "ellipsis",
                                        // display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical'
                                    }}
                                >
                                    {typeof file === 'string' ? file : name}
                                </Typography>

                                {status === 'succeed' && onDownload ? (
                                    <Link
                                        component={Button}
                                        variant="body1"
                                        sx={{ width: 'fit-content', height: 'fit-content', minWidth: 'unset', bgcolor: 'transparent !important', m: 0, p: 0, textDecoration: 'underline', verticalAlign: 'baseline' }}
                                        onClick={() => onDownload(blob, name)}
                                        disableRipple
                                    >
                                        {`${translate('orders_18_9')}`}
                                    </Link>
                                ) : (
                                    <Typography sx={{ textTransform: 'capitalize', color: status === 'failed' ? 'error.darker' : 'inherit' }}>
                                        {/* eslint-disable-next-line no-nested-ternary */}
                                        {status ? `${translate(`orders_download_${status}`)}` : (percentage ? fPercent(percentage) : '')}
                                    </Typography>
                                )}
                            </Stack>
                        }
                        primaryTypographyProps={{ variant: 'body1' }}
                        sx={{ zIndex: 9, marginRight: '10px' }}
                    />

                    {onRemove && !onDownload && status !== 'succeed' && (
                        <IconButton
                            edge="end" size="small" onClick={onRemove}
                            sx={{
                                p: '2px',
                                color: '#292929',
                                bgcolor: 'transparent',
                                '&:hover': {
                                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.28),
                                },
                            }}
                        >
                            <Iconify icon={'eva:close-circle-outline'}/>
                        </IconButton>
                    )}
                </ListItem>
            </AnimatePresence>
        </List>
    );
}