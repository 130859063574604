import PropTypes from "prop-types";
import React, { useState } from 'react';
import * as Yup from "yup";
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { LoadingButton } from "@mui/lab";
// hooks
import useLocales from "../../../../../hooks/useLocales";
// redux
import { useDispatch, useSelector } from "../../../../../redux/store";
import { addWarehouse } from "../../../../../redux/slices/warehouse";
import { validateAddress } from "../../../../../redux/slices/address";
import { FormProvider, RHFTextField } from '../../../../../components/hook-form';
import { FormState, FormCountry } from "./index";
// components
// import { AutocompleteAddress } from "../../../../../components/autocomplete-address";
import AutocompleteAddress from "../../../../../components/AutocompleteAddress";
// sections
import { ValidateDialog } from "../../../setting/configuration/dialog";
// utils
import { getCountryCode } from "../../../../../utils/getCode";

// ----------------------------------------------------------------------

/**
 * @function AddShipFrom
 * @description
 */

AddShipFrom.propTypes = {
    setStep: PropTypes.func,
};

export default function AddShipFrom({ setStep }) {
    const dispatch = useDispatch();

    const { translate } = useLocales();

    const { isLoading } = useSelector(({ warehouse }) => warehouse);

    const [validateDialog, setValidateDialog] = useState(null);

    const StandardTutorialShipFromSchema = Yup.object().shape({
        firstName: Yup.string().required(`${translate('input_schema_address_form_1')}`),
        lastName: Yup.string().required(`${translate('input_schema_address_form_2')}`),
        phone: Yup.string().required(`${translate('input_schema_address_form_3')}`),
        street1: Yup.string().required(`${translate('input_schema_address_form_4')}`),
        city: Yup.string().required(`${translate('input_schema_address_form_5')}`),
        state: Yup.string().nullable().required(`${translate('input_schema_address_form_6')}`),
        zipcode: Yup.string().required(`${translate('input_schema_address_form_7')}`),
        country: Yup.string().nullable().required(`${translate('input_schema_address_form_8')}`),
    });

    const defaultValues = {
        firstName: '',
        lastName: '',
        company: '',
        phone: '',
        email: '',
        street1: '',
        street2: '',
        city: '',
        state: '',
        zipcode: '',
        country: 'United States',
        isDefault: false,
        residential: false,
    };

    const methods = useForm({
        resolver: yupResolver(StandardTutorialShipFromSchema),
        // mode: 'onChange',
        defaultValues,
    });

    const {
        setValue,
        watch,
        handleSubmit,
    } = methods;

    const watched = watch();

    const handleSaveWarehouse = async (detail) => {
        try {
            const result = await dispatch(addWarehouse({ ...detail }));

            if (result) setStep(2);
        } catch (error) {
            console.error(error);
        }
    };

    const handleOnValidate = (detail, address) => {
        setValidateDialog(
            <ValidateDialog
                open={Boolean(true)}
                onClose={() => setValidateDialog(null)}
                onConfirm={() => handleSaveWarehouse(address)}
                title={detail?.title ?? ''}
                message={detail?.message ?? ''}
                content={detail?.content ?? ''}
            />,
        );
    };

    const onSubmit = async (values) => {
        try {
            const warehouseDetail = {
                name: values.company || '',
                first_name: values.firstName || '',
                last_name: values.lastName || '',
                phone: values.phone || '',
                email: values.email || '',
                street: values.street1 || '',
                street2: values.street2 || '',
                city: values.city || '',
                state: values.state || '',
                zipcode: values.zipcode || '',
                country: getCountryCode(values.country),
                is_default: values.isDefault || false,
                residential: values.residential || false,
            };

            const requireValidate = warehouseDetail.country === 'US';

            let result = null;

            if (requireValidate) {
                result = await dispatch(validateAddress({ ...warehouseDetail }));
            }

            if (requireValidate && result) {
                let title;
                let message;
                let content;

                setValue('residential', Boolean(result?.residential));

                warehouseDetail.residential = Boolean(result?.residential);

                if (result?.errored) {
                    title = 'dialog_validate_address_1';
                    message = result?.errors[0]?.message || '';
                    content = 'dialog_validate_address_1_1';

                    handleOnValidate({ title, message, content }, { ...warehouseDetail });
                } else if (result?.corrected) {
                    title = 'dialog_validate_address_2';
                    message = result?.corrections[0]?.message || '';
                    content = 'dialog_validate_address_2_1';

                    handleOnValidate({ title, message, content }, { ...warehouseDetail });
                } else {
                    await handleSaveWarehouse(warehouseDetail);
                }
            } else {
                await handleSaveWarehouse(warehouseDetail);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <Stack sx={{ '& form': { height: '100%' }, position: 'relative', height: '100%', maxHeight: "inherit", overflow: 'auto' }}>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Stack sx={{ pt: 3.75, px: 2.5, height: '100%' }}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ mb: 2.5 }}>
                            <Typography variant="h3">{translate('dialog_standard_tutorial_2_1')}</Typography>
                            <Typography variant="body1">1/2</Typography>
                        </Stack>
                        
                        <Stack sx={{ overflowY: 'scroll', msOverflowStyle: 'none', scrollbarWidth: 'none', '&::-webkit-scrollbar': { display: 'none' } }}>
                            <Stack spacing={3.75} mb="90px">
                                {/* <Typography variant="caption">{translate('dialog_standard_tutorial_2_2')}</Typography> */}

                                {/* <Typography variant="h4">{translate('dialog_standard_tutorial_2_3')}</Typography> */}

                                <Box display="grid" rowGap={3.75} columnGap={2.5} gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }} mt={1}>
                                    <RHFTextField name="firstName" label={`${translate('dialog_add_address_1_2_1')}`} size="small"/>

                                    <RHFTextField name="lastName" label={`${translate('dialog_add_address_1_2_2')}`} size="small"/>
                                </Box>

                                <RHFTextField name="company" label={`${translate('dialog_add_address_1_3')}`} size="small"/>

                                <RHFTextField name="phone" label={`${translate('dialog_add_address_1_5')}`} size="small"/>

                                <RHFTextField name="email" label={`${translate('dialog_add_address_1_4')}`} size="small"/>

                                {/* <AutocompleteAddress label={`${translate('form_street1')}`} size="small"/> */}

                                <AutocompleteAddress
                                    name="street1"
                                    label={`${translate('form_street1')}`}
                                    values={watched.street1}
                                    city="city"
                                    state="state"
                                    zip="zipcode"
                                    country="country"
                                    size="small"
                                />

                                <RHFTextField name="street2" label={`${translate(`form_street2`)}`} size="small"/>

                                <RHFTextField name="city" label={`${translate('form_city')}`} size="small"/>

                                <Box display="grid" rowGap={3.75} columnGap={2.5} gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}>
                                    <FormState name="state" countryName="country" label={`${translate('form_state')}`} size="small"/>

                                    <RHFTextField name="zipcode" label={`${translate('form_zipcode')}`} size="small"/>
                                </Box>

                                <FormCountry name="country"/>
                            </Stack>
                        </Stack>
                    </Stack>

                    <Stack
                        justifyContent="center" alignItems="center"
                        sx={{
                            position: 'absolute',
                            bottom: '0',
                            backdropFilter: 'blur(2px)',
                            zIndex: '1',
                            height: '60px',
                            width: '100%',
                            borderTop: '1px solid',
                            borderColor: 'grey.200',
                            boxShadow: '4px 0px 10px rgba(0, 0, 0, 0.1)',
                            bgcolor: 'rgba(255, 255, 255, 0.9)',
                        }}
                    >
                        <Stack width={1} direction="row" justifyContent="space-between" sx={{ px: 1.5 }}>
                            <Button
                                sx={{ cursor: 'pointer', width: "fit-content", minWidth: "unset", color: 'text.disabled', '&:hover': { backgroundColor: 'transparent' } }} onClick={() => setStep(2)}
                            >{translate('dialog_standard_tutorial_2_4')}</Button>

                            <LoadingButton
                                loading={isLoading}
                                loadingIndicator={
                                    <CircularProgress sx={{ color: '#292929' }} size={20} thickness={5}/>
                                }
                                type="submit"
                                sx={{ width: "fit-content", minWidth: "unset", bgcolor: 'transparent', '&:hover': { backgroundColor: 'transparent' }, color: '#292929' }}
                            >
                                {translate('dialog_standard_tutorial_2_5')}
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </FormProvider>
            </Stack>

            {validateDialog}
        </>
    );
}
