// utils
import axios from '../utils/axios';

// ----------------------------------------------------------------------

const getOrderList = (filter) => axios.post('/orders/list', {
    store_id: filter?.storeId === 'all' ? null : (filter?.storeId || null),
    carrier_account_id: filter?.carrierAccountId === 'all' ? null : (filter?.carrierAccountId || null),
    // eslint-disable-next-line no-nested-ternary
    keyword: filter?.keyword === 'all' ? null : (filter?.keyword ? filter.keyword.trim() : null),
    status: filter?.status === 'all' ? null : (filter?.status || null),
    order_number: filter?.orderNumber || null,
    item_name: filter?.itemName || null,
    item_sku: filter?.itemSKU || null,
    item_quantity: filter?.itemQuantity || null,
    warehouse_id: filter?.warehouseID === 'all' ? null : (filter?.warehouseID || null),
    // assign_user_id: null,
    shipping_area: filter?.shippingArea === 'all' ? null : (filter?.shippingArea || null),
    is_combin: filter?.isCombined ? true : null,
    is_hold: filter?.isHold ? true : null,
    tracking_code: filter?.trackingCode || null,
    client_name: filter?.clientName || null,
    client_city: filter?.clientCity || null,
    client_zipcode: filter?.clientZipcode || null,
    start_time: filter.startTime || '',
    end_time: filter.endTime || null,
    per_page: filter?.rowsPerPage || 25,
    current_page: filter?.page || 0,
    order_by: [{
        name: "created_at",
        sorting: filter?.sort || "desc",
    }],
});

const getOrder = (id) => axios.get('/orders/info', {
    params: {
        id,
    },
});

const deleteOrder = (id) => axios.delete('/orders/delete', {
    params: {
        id,
    },
});

const addOrder = (detail) => axios.post('/orders/add', { ...detail });

const modifyOrder = (detail) => axios.post('/orders/modify', { ...detail });

const getOrderAwaitingNumberA = () => axios.get('/orders/get_awaiting_number');

const getOrderAwaitingNumberB = () => axios.get('/orders/stat');

const getOrderPreRatesList = (detail) => axios.post('/orders/pre_rate', { ...detail });

const modifyBatchOrderStatus = (detail) => axios.post('/orders/batch/modify_status', { ...detail });

const modifyBatchOrderHold = (detail) => axios.post('/orders/batch/modify_hold', { ...detail });

const getOrderCombineList = () => axios.get('/orders/combin/list');

const addOrderCombine = (list) => axios.post('/orders/combin/add', { order_ids: list });

const getPickingList = () => axios.get('/orders/picking_list/list');

const addPickingList = (list) => axios.post('/orders/picking_list/add', { add_order_ids: list });

const deletePickingList = (id) => axios.delete('/orders/picking_list/delete', {
    params: {
        id,
    },
});

const getPickingListInfo = (id) => axios.get('/orders/picking_list/info', {
    params: {
        id,
    },
});

const modifyPickingListOrder = (detail) => axios.post('/orders/picking_list/modify_order', { ...detail });

const deletePickingListOrder = (detail) => axios.delete('/orders/picking_list/delete_order', {
    data: {
        ...detail,
    },
});

// ----------------------------------------------------------------------

const ordersImportTemplate = () => axios.get('/orders/import_template', { responseType: 'blob' });

const ordersImport = (storeId, file) => {
    const formData = new FormData();
    formData.append('store_id', storeId);
    formData.append('file', file);

    return axios.post('/orders/import', formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

const ordersExport = (detail) => axios.post('/orders/export', {
    ...detail,
    status: detail.status === 'all' ? null : (detail.status || null),
});

// ----------------------------------------------------------------------

export {
    getOrderList,
    getOrder,
    addOrder,
    modifyOrder,
    deleteOrder,
    getOrderAwaitingNumberA,
    getOrderAwaitingNumberB,
    getOrderPreRatesList,
    modifyBatchOrderStatus,
    modifyBatchOrderHold,
    // ----------------------------------
    getOrderCombineList,
    addOrderCombine,
    // ----------------------------------
    getPickingList,
    addPickingList,
    deletePickingList,
    getPickingListInfo,
    modifyPickingListOrder,
    deletePickingListOrder,
    // ----------------------------------
    ordersImportTemplate,
    ordersImport,
    ordersExport,
};
