import PropTypes from "prop-types";
import React from "react";
// form
import { Controller, useFormContext, useFieldArray } from 'react-hook-form';
// @mui
import { Box, Button, FormHelperText, IconButton, Link, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
// hooks
import useTabs from "../../../../hooks/useTabs";
import useLocales from "../../../../hooks/useLocales";
// components
import Image from "../../../../components/Image";
import Asynchronous from "../../../../components/Asynchronous";

// ----------------------------------------------------------------------

OrderItemList.propTypes = {
    index: PropTypes.number,
    isEdit: PropTypes.bool,
};

function OrderItemList({ index, isEdit }) {
    const { translate } = useLocales();

    const { control, setValue, watch, formState: { errors } } = useFormContext();

    const watched = watch();

    return (
        <Controller
            control={control}
            name="orderItems"
            render={({ field }) => {
                return (
                    <Stack ref={field.ref} spacing={1.25}>
                        <Box display="flex" columnGap={2.5} gridTemplateColumns="2fr auto 1fr" alignItems="center">
                            <Asynchronous
                                storeId={watched.storeId}
                                delayMS={750}
                                selectedItem={watched.orderItems[index].selectedItem}
                                setValue={setValue}
                                index={index}
                                isEdit={isEdit}
                            />

                            <Typography variant="h4" width='10px'>{`X`}</Typography>

                            <TextField
                                label={`${translate('dialog_new_edit_order_4_4')}`}
                                name={`orderItems[${index}].qty`}
                                value={watched.orderItems[index].qty}
                                onChange={(event) => setValue(`orderItems[${index}].qty`, event.target.value)}
                                size="small"
                                type="number"
                                inputProps={{
                                    min: 0,
                                    // max: watched.orderItems[index].selectedItem?.quantity || 'unset',
                                    max: 999999999,
                                    step: 1,
                                }}
                                disabled={!watched.storeId || !watched.orderItems[index].searchKeyword || isEdit}
                            />
                        </Box>

                        {errors.orderItems && <FormHelperText error sx={{ margin: '8px 14px 0 4px !important' }}>{errors.orderItems.message}</FormHelperText>}
                    </Stack>
                )
            }}
        />
    )
}

// ----------------------------------------------------------------------

OrderNewItemDetail.prototype = {
    isEdit: PropTypes.bool,
};

export default function OrderNewItemDetail({ isEdit }) {
    const { translate } = useLocales();

    const { control, watch } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'orderItems',
    });

    const watched = watch();

    const { currentTab, onChangeTab } = useTabs('0');

    const handleOnAdd = () => {
        append({
            searchKeyword: '',
            searchResult: [],
            searchPage: 0,
            selectedItem: null,
            qty: '',
        });
    };

    const handleOnRemove = (index) => {
        remove(index);

        if (index === fields.length - 1) {
            if (index === Number(currentTab)) onChangeTab(null, `${index - 1}`);
        } else if (index < Number(currentTab)) {
            onChangeTab(null, `${Number(currentTab) - 1}`);
        }
    };

    return (
        <Stack spacing={2.5}>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h4">{`${translate('dialog_new_edit_order_4')}`}</Typography>
                {!isEdit && (
                    <Link
                        component={Button}
                        variant="h4"
                        sx={{ height: 'fit-content', p: 0, bgcolor: 'transparent !important', color: '#2C6CD2', fontWeight: '400' }}
                        onClick={handleOnAdd}
                        disableRipple
                    >
                        {`+ ${translate('dialog_new_edit_order_4_1')}`}
                    </Link>
                )}
            </Stack>

            <Stack position="relative">
                <Tabs
                    allowScrollButtonsMobile
                    variant="scrollable"
                    scrollButtons="auto"
                    value={currentTab}
                    TabIndicatorProps={{ color: 'primary' }}
                    sx={{ minHeight: 'unset', borderBottom: '1px solid #E6E6E6' }}
                >
                    {(watched?.orderItems ?? []).map((tab, index) => (
                        <Tab
                            key={`subTab-head-${index}`}
                            component="div"
                            label={
                                <Button
                                    variant="text"
                                    sx={{
                                        minWidth: 'unset', width: 'fit-content', height: 'fit-content', mr: 0, p: 0, fontSize: '14px', fontWeight: 400, bgcolor: 'transparent !important',
                                        '&:first-of-type': { mr: '0 !important' },
                                    }}
                                    onClick={() => onChangeTab(null, `${index}`)}
                                    disableRipple
                                >
                                    {`${translate('dialog_new_edit_order_4_2')} #${index + 1}`}
                                </Button>
                            }
                            icon={index > 0 && !isEdit ? (
                                <IconButton sx={{ position: 'absolute', right: '-4px', padding: 0, transform: 'translate(100%)', '&.MuiTab-iconWrapper': { mx: 0 } }} onClick={() => handleOnRemove(index)}>
                                    <Image src="/assets/icon/system/ic_close_tab.svg" alt="close_tab" sx={{ width: "12px", height: "12px" }}/>
                                </IconButton>
                            ) : ''}
                            value={`${index}`}
                            iconPosition="end"
                            sx={{
                                height: '24px',
                                minHeight: 'unset',
                                textTransform: 'capitalize',
                                overflow: 'visible',
                                '&:not(:last-of-type)': {
                                    marginRight: (theme) => theme.spacing(5),
                                },
                            }}
                            disableRipple
                        />
                    ))}
                </Tabs>

                <Box sx={{ mb: 2.5 }}/>

                {(watched?.orderItems ?? []).map((tab, index) => {
                    const isMatched = `${index}` === currentTab;
                    return isMatched && (
                        <Box key={index}>
                            <OrderItemList index={index} isEdit={isEdit}/>
                        </Box>
                    )
                })}
            </Stack>
        </Stack>
    )
}
