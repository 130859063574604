import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
// @mui
import { Box, IconButton, Link, Stack, Typography } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
// api
import { getOrderList as getOrderListAPI, getOrderPreRatesList as getOrderPreRatesListAPI } from "../../../../api/order";
// redux
import { useDispatch, useSelector } from "../../../../redux/store";
import { modifyAllOrderSuccess, modifyAwaitingOrderSuccess } from "../../../../redux/slices/order";
// hooks
import useLocales from "../../../../hooks/useLocales";
// components
import MoreActions from "../../../../components/customized/MoreActions";
import Image from "../../../../components/Image";
import Iconify from "../../../../components/Iconify";
import CopyClipboard from "../../../../components/CopyClipboard";
// sections
import { OrderSelectService } from "./index";
// utils
import { fDeliveryService } from "../../../../utils/formatData";
import { fCurrency } from "../../../../utils/formatNumber";
import { getGroupByService } from "../../../../utils/getShipmentsData";

// ----------------------------------------------------------------------

OrderShippedServiceList.propTypes = {
    serviceInfoList: PropTypes.array,
};

function OrderShippedServiceList({ serviceInfoList }) {
    const { translate } = useLocales();

    const [serviceList, setServiceList] = useState({});

    useEffect(() => {
        const groupByService = getGroupByService(serviceInfoList);

        setServiceList(groupByService);
    }, [serviceInfoList]);

    return (
        <Stack spacing={1.25}>
            <Typography variant="h3">{`${translate('orders_6_5_8')}`}</Typography>

            {Object.keys(serviceList?.regular ?? {}).map((_key, index) => (
                <Stack key={`reguar-list-${index}`}>
                    <Typography variant="body1" noWrap>{_key}</Typography>
                    {serviceList.regular[_key].map((_service, index) => (
                        <Stack key={`regular-${_service.id}-${index}`} spacing={2}>
                            <ShipmentTrackingCode info={_service} index={index}/>
                        </Stack>
                    ))}
                </Stack>
            ))}

            {Object.keys(serviceList?.isReturn ?? {}).length > 0 &&
                <Typography variant="h3">Return Labels</Typography>
            }

            {Object.keys(serviceList?.isReturn ?? {}).map((_key, index) => (
                <Stack key={`return-list-${index}`}>
                    <Typography variant="body1" noWrap>{_key}</Typography>
                    {serviceList.isReturn[_key].map((_service, index) => (
                        <Stack key={`regular-${_service.id}-${index}`} spacing={2}>
                            <ShipmentTrackingCode info={_service} index={index}/>
                        </Stack>
                    ))}
                </Stack>
            ))}

            {Object.keys(serviceList?.isVoid ?? {}).length > 0 &&
                <Typography variant="h3">{`${translate('orders_6_5_8_1')}`}</Typography>
            }

            {Object.keys(serviceList?.isVoid ?? {}).map((_key, index) => (
                <Stack key={`void-list-${index}`}>
                    <Typography variant="body1" noWrap>{_key}</Typography>
                    {serviceList.isVoid[_key].map((_service, index) => (
                        <Stack key={`void-${_service.id}-${index}`} spacing={2}>
                            <ShipmentTrackingCode info={_service} index={index} isVoid={Boolean(true)}/>
                        </Stack>
                    ))}
                </Stack>
            ))}

        </Stack>
    );
}

// ----------------------------------------------------------------------

ShipmentTrackingCode.propTypes = {
    info: PropTypes.object,
    index: PropTypes.number,
    isVoid: PropTypes.bool,
};

function ShipmentTrackingCode({ info, index, isVoid }) {
    const trackingCode = info?.tracking_code || '-';
    let rowCarrierTrackingURL = '';

    switch (info?.carrier.toLowerCase() || '') {
        case 'ups':
            rowCarrierTrackingURL = 'https://www.ups.com/track?trackingNumber=';
            break;
        case 'usps':
            rowCarrierTrackingURL = 'https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=';
            break;
        case 'dhl express':
            rowCarrierTrackingURL = 'http://www.dhl.com/en/express/tracking.html?brand=DHL&AWB=';
            break;
        case 'dhl ecommerce':
            rowCarrierTrackingURL = 'http://www.dhl.com/en/express/tracking.html?brand=DHL&AWB=';
            break;
        case 'ontrac':
            rowCarrierTrackingURL = 'https://www.ontrac.com/trackingresults.asp?tracking_number=';
            break;
        case 'fedex':
            rowCarrierTrackingURL = 'https://www.fedex.com/fedextrack/system-error?trknbr=';
            break;
        default:
            rowCarrierTrackingURL = '';
    }

    return (
        <Stack direction="row" spacing={2}>
            <Stack direction="row" flex={1} spacing={2}>
                <Typography variant="body1">{`#${index + 1}:`}</Typography>

                {trackingCode ? (
                    <>
                        {rowCarrierTrackingURL ? (
                            <Link variant="body1" underline="always" type="button" href={rowCarrierTrackingURL + trackingCode} target="_blank" sx={isVoid && { textDecoration: 'line-through' }} noWrap>
                                {`${trackingCode}`}
                            </Link>
                        ) : (
                            <Typography variant="body1" sx={isVoid && { textDecoration: 'line-through' }} noWrap>
                                {`${trackingCode}`}
                            </Typography>
                        )}
                    </>
                ) : (
                    <Typography variant="body1" noWrap sx={{ color: 'text.disabled' }}>-</Typography>
                )}
            </Stack>

            <CopyClipboard copyValue={trackingCode}/>
        </Stack>
    );
}

// ----------------------------------------------------------------------

OrderTableCellCarrierService.propTypes = {
    detail: PropTypes.object,
    onRefresh: PropTypes.func,
};

export default function OrderTableCellCarrierService({ detail, onRefresh, page }) {
    const dispatch = useDispatch();

    const { translate } = useLocales();

    const { awaitingFilter, filter } = useSelector(({ order }) => order);
    const { allPackageList } = useSelector((state) => state.package);

    const { id, shipment_rate: shipmentRate, shipments, status } = detail;

    const [ratesExpire, setRatesExpire] = useState(false);
    const [serviceInfo, setServiceInfo] = useState(null);
    const [showSelectServiceActions, setShowSelectServiceActions] = useState(null);

    const [trackingCode, setTrackingCode] = useState('');
    const [rowCarrierTrackingURL, setRowCarrierTrackingURL] = useState('');
    const [loading, setLoading] = React.useState(false);
    
    const handleClick = () => {
        setLoading(true);
        handleOnRefresh();
    };

    const handleOpenSelectService = (event) => {
        if (event?.currentTarget)
            setShowSelectServiceActions(event.currentTarget);
    };

    const handleCloseSelectService = () => setShowSelectServiceActions(null);

    const checkOrderInfo = async () => {
        try {
            const result = page === "awaiting" ? await getOrderListAPI(awaitingFilter) : await getOrderListAPI(filter);
            const { status, data } = result;

            if (status === 200) {
                const dataArray = data.data;
                const orderInfo = dataArray.filter((dataItem) => dataItem.id === detail.id);
                dispatch(page === "awaiting" ? modifyAwaitingOrderSuccess(orderInfo?.length ? orderInfo[0] : null) : modifyAllOrderSuccess(orderInfo?.length ? orderInfo[0] : null));
                setLoading(false);
            }

        } catch (error) {
            console.error(error);

            setLoading(false);
        }
    };

    const handleOnRefresh = async () => {
        try {
            let selectedPackage = null;
            selectedPackage = allPackageList.find((p) => p?.id && p?.name === shipments[0]?.predefined_package);

            const tempDetail = {
                order_id: id,
                packages_info: [
                    selectedPackage ? {
                            height: selectedPackage?.height || 0,
                            length: selectedPackage?.length || 0,
                            predefined_package_id: selectedPackage?.id ?? '',
                            predefined_package: selectedPackage?.code || 'PACKAGE',
                            predefined_package_carrier: selectedPackage?.carrier || 'USER',
                            weight: shipments[0].weight || 0,
                            width: selectedPackage?.width || 0,
                        } :
                        {
                            height: (shipmentRate?.rate_request.parcels[0]?.height ?? shipments[0]?.height) || 0,
                            length: (shipmentRate?.rate_request.parcels[0]?.length ?? shipments[0]?.length) || 0,
                            predefined_package_id: shipmentRate?.rate_request.parcels[0]?.predefined_package_id || null,
                            predefined_package: shipmentRate?.rate_request.parcels[0]?.predefined_package || 'PACKAGE',
                            predefined_package_carrier: shipmentRate?.rate_request.parcels[0]?.predefined_package_carrier || 'USER',
                            weight: (shipmentRate?.rate_request.parcels[0]?.weight ?? shipments[0].weight) || 0,
                            width: (shipmentRate?.rate_request.parcels[0]?.width ?? shipments[0].width) || 0,
                        },
                ],
            };

            const result = await getOrderPreRatesListAPI({ ...tempDetail });

            const { status } = result;

            if (status === 200) {
                setRatesExpire(false);
                // onRefresh();
                checkOrderInfo();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const standardizeRatesList = (data) => {
        const tempArrayA = [];
        const tempArrayB = [];

        const tempArray = data.popular_rate.concat(data.rates);
        tempArray.forEach(rate => {
            if (rate.service_level === 'LIBRARY_MAIL' || rate.service_level === 'MEDIA_MAIL') {
                tempArrayB.push({ ...rate });
            } else {
                tempArrayA.push({ ...rate });
            }
        });

        tempArrayA.sort((a, b) => a.your_rate - b.your_rate);
        tempArrayB.sort((a, b) => a.your_rate - b.your_rate);

        return tempArrayA.concat(tempArrayB);
    };

    useEffect(() => {
        if ((status === 'shipped' || status === 'canceled') && shipments && shipments.length > 0) {
            let url = '';

            switch (shipments[0]?.carrier.toLowerCase() || '') {
                case 'ups':
                    url = 'https://www.ups.com/track?trackingNumber=';
                    break;
                case 'usps':
                    url = 'https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=';
                    break;
                case 'dhl express':
                    url = 'http://www.dhl.com/en/express/tracking.html?brand=DHL&AWB=';
                    break;
                case 'dhl ecommerce':
                    url = 'http://www.dhl.com/en/express/tracking.html?brand=DHL&AWB=';
                    break;
                case 'ontrac':
                    url = 'https://www.ontrac.com/trackingresults.asp?tracking_number=';
                    break;
                case 'fedex':
                    url = 'https://www.fedex.com/fedextrack/system-error?trknbr=';
                    break;
                default:
                    url = '';
            }

            setRowCarrierTrackingURL(url);
            setTrackingCode(shipments[0]?.tracking_code || '');
        } else if ((status === 'awaiting' || status === 'canceled') && shipmentRate) {
            if (shipmentRate?.rate_request?.from_address?.country !== "US" || shipmentRate?.rate_request?.to_address?.country !== "US") {
                setServiceInfo(["international"]);
            } else if (shipmentRate?.rate_result) {
                // const tempArray = shipmentRate.rate_result.popular_rate.concat(shipmentRate.rate_result.rates);
                const obj = standardizeRatesList(shipmentRate?.rate_result || null);
                const tempServiceInfo = JSON.parse(JSON.stringify(obj));
                // tempServiceInfo.sort((a, b) => a.your_rate - b.your_rate);
                setServiceInfo(tempServiceInfo);
                const time = new Date().getTime();
                // 限时两小时
                setRatesExpire(time - ((shipmentRate?.updated_at || shipmentRate?.created_at) * 1000 || 0) > 7200000);
            } else {
                setServiceInfo([]);
                setRatesExpire(true);
            }
        } else if ((status === 'awaiting' || status === 'canceled') && !shipmentRate && shipments.length !== 0) {
            setRatesExpire(true);
            setServiceInfo([]);
        } else {
            setServiceInfo(null);
            setRatesExpire(false);
        }
    }, [shipmentRate, shipments, status]);

    return (
        <Box height="46px">
            {status === 'awaiting' && (
                <>
                    {!serviceInfo && (
                        <Stack spacing={0.25} width="160px">
                            <Typography variant="body1" noWrap>{`${translate('orders_6_5_1')}`}</Typography>
                            <Typography variant="body1" noWrap sx={{ color: 'text.disabled' }}>{`${translate('orders_6_5_2')}`}</Typography>
                        </Stack>
                    )}

                    {serviceInfo && serviceInfo?.[0] === "international" && (
                        <Stack spacing={0.25} width="160px">
                            <Typography variant="body1" noWrap>{`${translate('orders_6_5_1')}`}</Typography>
                            <Typography variant="body1" noWrap sx={{ color: 'text.disabled' }}>{`${translate('orders_6_5_10')}`}</Typography>
                        </Stack>
                    )}

                    {serviceInfo && serviceInfo?.[0] !== "international" && serviceInfo.length > 0 && Boolean(!ratesExpire) && (
                        <Stack direction="row" spacing={0.25} alignItems="flex-start">
                            <Stack spacing={0.25} width="160px">
                                <Typography variant="body1" noWrap>
                                    {`${fDeliveryService(serviceInfo[0]?.carrier, serviceInfo[0]?.service_level)}`}
                                </Typography>

                                <Typography variant="body1" noWrap sx={{ color: 'text.disabled' }}>
                                    {`${fCurrency(serviceInfo[0]?.your_rate)}, ${serviceInfo[0]?.delivery_days}${translate('orders_6_5_7')}`}
                                </Typography>
                            </Stack>

                            <MoreActions
                                open={showSelectServiceActions}
                                onOpen={handleOpenSelectService}
                                onClose={handleCloseSelectService}
                                display={
                                    <IconButton
                                        sx={{ mt: '-12px', width: '20px', height: '20px', minWidth: 'unset', padding: 0 }}
                                        // onClick={() => showSelectPackageActions ? handleCloseSelectPackage() : handleOpenSelectPackage()}
                                    >
                                        <Iconify icon="eva:arrow-ios-downward-fill" sx={{ width: 16, height: 16, color: '#292929' }}/>
                                    </IconButton>
                                }
                                actions={
                                    <OrderSelectService currentDetail={serviceInfo}/>
                                }
                                sxMenuPopover={{
                                    width: '100%',
                                    maxWidth: '360px',
                                    maxHeight: '370px',
                                    px: '16px',
                                    pt: '16px',
                                    pb: '30px',
                                    // overflowY: 'hidden'
                                }}
                                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            />
                        </Stack>
                    )}

                    {/* 已过期 */}
                    {serviceInfo && serviceInfo?.[0] !== "international" && Boolean(ratesExpire) && (
                        <Stack direction="row" spacing={0.25} alignItems="flex-start">
                            <Stack spacing={0.25} width="160px">
                                <Typography variant="body1" noWrap>{`${translate('orders_6_5_3')}`}</Typography>

                                <Typography variant="body1" noWrap sx={{ color: 'text.disabled' }}>{`${translate('orders_6_5_4')}`}</Typography>
                            </Stack>

                            <Box sx={{ position: 'relative' }}>
                                {!loading ?
                                    <IconButton sx={{ mt: '-12px', width: '20px', height: '20px', minWidth: 'unset', padding: 0 }} onClick={handleClick}>
                                        <Image src="/assets/icon/orders/ic_refresh.svg" alt="refresh" sx={{ width: '20px', height: '20px', '& img': { objectFit: 'contain' } }} disabledEffect/>
                                    </IconButton>
                                    :
                                    <Box sx={{ backgroundColor: '#2C6CD2', width: '20px', height: '20px', borderRadius: '100%', position: 'absolute', top: '-1px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <CircularProgress size={13} sx={{ color: '#FFF' }}/>
                                    </Box>
                                }
                            </Box>
                        </Stack>
                    )}
                </>
            )}

            {/* 已有Shipment */}
            {(status === 'shipped' || (status === 'canceled' && shipments.length > 0)) && (
                <>
                    {shipments.length === 1 && (
                        <Stack direction="row" spacing={0.25} alignItems="flex-start">
                            <Stack spacing={0.25} width="160px">
                                <Typography variant="body1" noWrap>
                                    {shipments[0]?.service_level_nick_name}
                                    {shipments[0]?.require_saturday_delivery && `${translate('quickrate_10_3')}`}
                                </Typography>

                                {trackingCode ? (
                                    <>
                                        {rowCarrierTrackingURL ? (
                                            <Link variant="body1" underline="always" type="button" href={rowCarrierTrackingURL + trackingCode} target="_blank" noWrap>
                                                {`${trackingCode}`}
                                            </Link>
                                        ) : (
                                            <Typography variant="body1" noWrap>
                                                {`${trackingCode}`}
                                            </Typography>
                                        )}
                                    </>
                                ) : (
                                    <Typography variant="body1" noWrap sx={{ color: 'text.disabled' }}>-</Typography>
                                )}
                            </Stack>

                            <CopyClipboard copyValue={trackingCode}/>
                        </Stack>
                    )}

                    {shipments.length > 1 && (
                        <Stack direction="row" spacing={0.25} alignItems="flex-start">
                            <Stack spacing={0.25} width="160px">
                                <Typography variant="body1" noWrap>{`${shipments.length}${translate('orders_6_5_5')}`}</Typography>

                                <Typography variant="body1" noWrap sx={{ color: 'text.disabled' }}>{`${translate('orders_6_5_6')}`}</Typography>
                            </Stack>

                            <MoreActions
                                open={showSelectServiceActions}
                                onOpen={handleOpenSelectService}
                                onClose={handleCloseSelectService}
                                display={
                                    <IconButton sx={{ mt: '-12px', width: '20px', height: '20px', minWidth: 'unset', padding: 0 }} disabled={Boolean(true)}>
                                        <Iconify icon="eva:arrow-ios-downward-fill" sx={{ width: 16, height: 16, color: '#292929' }}/>
                                    </IconButton>
                                }
                                actions={
                                    <OrderShippedServiceList serviceInfoList={shipments}/>
                                }
                                sxMenuPopover={{
                                    width: '100%',
                                    maxWidth: 'fit-content',
                                    p: '20px',
                                }}
                                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            />
                        </Stack>
                    )}
                </>
            )}

            {(status === 'canceled' && shipments.length === 0) && (
                <>
                    {(serviceInfo && !ratesExpire) && (
                        <Stack spacing={0.25} width="160px">
                            <Typography variant="body1" noWrap>
                                {`${fDeliveryService(serviceInfo[0]?.carrier, serviceInfo[0]?.service_level)}`}
                            </Typography>

                            <Typography variant="body1" noWrap sx={{ color: 'text.disabled' }}>
                                {`${fCurrency(serviceInfo[0]?.your_rate)}, ${serviceInfo[0]?.delivery_days}${translate('orders_6_5_7')}`}
                            </Typography>
                        </Stack>
                    )}

                    {(serviceInfo && ratesExpire) && (
                        <Stack spacing={0.25} width="160px">
                            <Typography variant="body1" noWrap>{`${translate('orders_6_5_3')}`}</Typography>

                            <Typography variant="body1" noWrap sx={{ color: 'text.disabled' }}>{`${translate('orders_6_5_4')}`}</Typography>
                        </Stack>
                    )}
                </>
            )}
        </Box>
    );
}
