// utils
import axios from '../utils/axios';

// ----------------------------------------------------------------------

const login = (email, password, isRemember, code, fingerprint) =>
    axios.post('/auth/login', code ? {
        email,
        password,
        code,
        is_remember: isRemember,
        fingerprint
    } : {
        email,
        password,
        is_remember: isRemember,
        fingerprint
    });

const register = (detail) => axios.post('/auth/register', {
    ...detail
});

const logout = () => axios.delete('/auth/logout');

const forgotPassword = (detail) => axios.post('/auth/forgot_password', {
    ...detail
});

const resetPassword = (key, password) => axios.post('/auth/reset_passsword', {
    secret_key: key,
    new_password: password
});

const activeUser = ({ code, email }) => axios.get('/auth/active_user', {
    params: {
        code: code || '',
        email: email || '',
    }
});

const resendEmail = (email) => axios.get('/auth/resend_active_email', {
    params: {
        email
    }
});

const verifyRecaptcha = (token) => axios.get('/google/recaptcha/verify', {
    params: {
        response: token
    }
});

const verifyToken = () => axios.post('/auth/token/check');

// ----------------------------------------------------------------------

export { login, register, logout, forgotPassword, resetPassword, activeUser, resendEmail, verifyRecaptcha, verifyToken };
