import { Link as RouterLink } from "react-router-dom";
// @mui
import { Button, Typography } from '@mui/material';
// guards
// import PermissionGuard from "../../../guards/PermissionGuard";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// hooks
// import useAuth from "../../../hooks/useAuth";
// redux
import useResponsive from "../../../hooks/useResponsive";
import useLocales from "../../../hooks/useLocales";
import { useSelector } from "../../../redux/store";
// components
import Image from '../../../components/Image';
// utils
import { fCurrency } from "../../../utils/formatNumber";

// ----------------------------------------------------------------------

export default function BalancePopover() {
    const upMd = useResponsive('up', 'md');

    const { translate } = useLocales();

    // const { user } = useAuth();
    // const { plan_id: planID = 0 } = user || {};

    const { balance } = useSelector(({ payment }) => payment);


    return (
        <>
            {upMd && (
                // {upMd && planID > 1 && (
                // <PermissionGuard permission="account.billing">
                <Button
                    component={RouterLink}
                    to={`${PATH_DASHBOARD.settings.account}/#billing`}
                    startIcon={
                        <Image disabledEffect src='/assets/icon/navbar/ic_balance_circle.svg' alt='balance' sx={{ width: '24px', height: '24px', '& img': { objectFit: 'contain' } }}/>
                    }
                    sx={{
                        px: 1.5
                    }}
                >
                    <Typography variant='body1'>
                        {`${translate('header_balance')}`}:&nbsp;
                        <Typography component='span' variant='body2'>{fCurrency(balance)}</Typography>
                    </Typography>
                </Button>
                // </PermissionGuard>
            )}
        </>
    );
}
