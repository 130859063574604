// utils
import axios from '../utils/axios';

// ----------------------------------------------------------------------

const getPartnerReports = (data) => axios.post('/company/report', {
    company_id: data?.companyId ?? null,
    start_date: data?.startTime ?? null,
    end_date: data?.endTime ?? null,
    period: data?.period ?? null,
});

const getClientList = (filter) => axios.post('/company/report/ref_by_me/list', {
    // eslint-disable-next-line no-nested-ternary
    has_shipment: filter?.hasShipment === 'all' ? null : filter?.hasShipment === 'true' ? true : filter?.hasShipment === 'false' ? false : null,
    search_key: filter?.keyword === 'all' ? null : (filter?.keyword || null),
    start_date: filter?.startTime ?? null,
    end_date: filter?.endTime ?? null,
    per_page: filter?.rowsPerPage || 5,
    current_page: filter?.page || 0,
    order_by: [
        {
            name: (filter?.sort === 'desc' || filter?.sort === 'asc') ? "companies.created_at" : "stat_day",
            sorting: (filter?.sort === 'asc' || filter?.sort === 'p_asc') ? 'asc' : "desc",
        },
    ],
});

const getClientCount = (filter) => axios.post('/company/report/ref_by_me/count', {
    // eslint-disable-next-line no-nested-ternary
    has_shipment: filter?.hasShipment === 'all' ? null : filter?.hasShipment === 'true' ? true : filter?.hasShipment === 'false' ? false : null,
    search_key: filter?.keyword === 'all' ? null : (filter?.keyword || null),
    start_date: filter?.startTime ?? null,
    end_date: filter?.endTime ?? null,
});

const exportClientList = (filter) => axios.post('/company/report/ref_by_me/export', {
    // eslint-disable-next-line no-nested-ternary
    has_shipment: filter?.hasShipment === 'all' ? null : filter?.hasShipment === 'true' ? true : filter?.hasShipment === 'false' ? false : null,
    search_key: filter?.keyword === 'all' ? null : (filter?.keyword || null),
    start_date: filter?.startTime ?? null,
    end_date: filter?.endTime ?? null,
});

const getClientPurchasedList = (filter) => axios.post('/shipments/puchased/list/ref_by_me', {
    company_id: filter?.companyId ?? null,
    start_time: filter?.startTime ?? null,
    end_time: filter?.endTime ?? null,
    label_type: filter?.labelType === 'all' ? null : (filter?.labelType || null),
    // store_id: filter?.storeId === 'all' ? null : (filter?.storeId || null),
    // eslint-disable-next-line no-nested-ternary
    status: filter?.status === 'label_created' ? ['created', 'pre_transit'] :
        // eslint-disable-next-line no-nested-ternary
        filter?.status === 'in_transit' ? ['in_transit'] :
            // eslint-disable-next-line no-nested-ternary
            filter?.status === 'delivered' ? ['delivered', 'available_for_pickup'] :
                // eslint-disable-next-line no-nested-ternary
                filter?.status === 'unsuccessful' ? ['return_to_sender', 'failure', 'voided', 'error', 'failed', 'cancelled'] :
                    // eslint-disable-next-line no-nested-ternary
                    filter?.status === 'unknown' ? ['unknown'] :
                        // eslint-disable-next-line no-nested-ternary
                        filter?.status === 'all' ? null :
                            (filter?.status || null) ? [filter?.status] : null,
    // carrier_account_name: "USPS",
    carrier_account_id: filter?.carrierAccountId === 'all' ? null : (filter?.carrierAccountId || null),
    keyword: filter?.keyword === 'all' ? null : (filter?.keyword || null),
    per_page: filter?.rowsPerPage || 5,
    current_page: filter?.page || 0,
    order_by: [
        {
            name: "created_at",
            sorting: filter?.sort || "desc",
        },
    ],
});

const labelDownload = (detail) => axios.post('/shipments/label/download/ref_by_me', {
    ...detail,
});

const labelPrint = (detail) => axios.post('/shipments/label/batch_print/ref_by_me', {
    ...detail,
}, { responseType: 'blob' });

const getCompany = (id) => axios.get('/company/info/ref_by_me', {
    params: {
        id,
    },
});

// ----------------------------------------------------------------------

export {
    getPartnerReports,
    getClientList,
    getClientCount,
    exportClientList,
    getClientPurchasedList,
    labelDownload,
    labelPrint,
    getCompany,
};
