import { createSlice } from '@reduxjs/toolkit';
// api
import {
    getAddressList as getAddressListAPI,
    addAddress as addAddressAPI,
    validateAddress as validateAddressAPI,
    modifyAddress as modifyAddressAPI,
    deleteAddress as deleteAddressAPI,
} from "../../api/address";
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    addressList: [],
    addressFilter: {
        residential: 'all',
        keyword: '',
        sort: 'desc',
        page: 0,
        rowsPerPage: 5,
        total: 0,
    },
    isOpenModal: false,
};

const slice = createSlice({
    name: 'address',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET ADDRESSES
        getAddressesSuccess(state, action) {
            state.isLoading = false;
            state.addressList = action.payload;

            // // FOR ADD ADDRESS && MODIFIED ADDRESS
            state.isOpenModal = false;
        },

        // MODIFY ADDRESS
        modifyAddressSuccess(state, action) {
            const { id: addressId, name, last_name: lastName, company_name: companyName, email, phone, street, street2, city, state: addressState, zipcode, country, residential, is_default: isDefault } = action.payload;

            const modifiedAddressList = state.addressList.map((_address) => {
                if (isDefault) _address.is_default = false;

                if (_address.id === addressId) {
                    _address.name = name;
                    _address.last_name = lastName;
                    _address.company_name = companyName;
                    _address.email = email;
                    _address.phone = phone;
                    _address.street = street;
                    _address.street2 = street2;
                    _address.city = city;
                    _address.state = addressState;
                    _address.zip = zipcode;
                    _address.country = country;
                    _address.residential = residential;
                    _address.is_default = isDefault;
                }

                return _address;
            });

            state.isLoading = false;
            state.addressList = modifiedAddressList;
            state.isOpenModal = false;
        },

        // UPDATE ADDRESS LIST FILTER
        updateAddressListFilterSuccess(state, action) {
            state.addressFilter = {
                ...state.addressFilter,
                ...action.payload,
            };
        },

        // DELETE ADDRESS
        deleteAddressSuccess(state, action) {
            state.isLoading = false;
            state.addressList = state.addressList.filter((_address) => `${_address.id}` !== `${action.payload}`) || [];
        },

        // VALIDATE SUCCESS
        validateAddressSuccess(state) {
            state.isLoading = false;
        },

        // OPEN MODAL
        openModal(state) {
            state.isOpenModal = true;
        },

        // CLOSE MODAL
        closeModal(state) {
            state.isOpenModal = false;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal } = slice.actions;

// ----------------------------------------------------------------------

export function getAddressList(filter) {
    return async () => {
        try {
            const response = await getAddressListAPI(filter);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getAddressesSuccess(data?.data ?? []));
                dispatch(slice.actions.updateAddressListFilterSuccess({
                    total: data?.total || 0,
                    rowsPerPage: data?.per_page || 5,
                    page: data?.current_page || 0,
                }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateAddressListFilter(filter) {
    return () => {
        dispatch(slice.actions.updateAddressListFilterSuccess({
            residential: filter?.residential || 'all',
            keyword: filter?.keyword || '',
            sort: filter?.sort || 'desc',
            page: filter?.page || 0,
            rowsPerPage: filter?.rowsPerPage || 5,
            total: filter?.total || 0,
        }));
    };
}

// ----------------------------------------------------------------------

export function resetAddressFilter() {
    return async () => {
        const defaultFilter = {
            residential: 'all',
            keyword: '',
            sort: 'desc',
            page: 0,
            rowsPerPage: 5,
            total: 0,
        };

        dispatch(slice.actions.updateAddressListFilterSuccess({ ...defaultFilter }));

        return defaultFilter;
    };
}

// ----------------------------------------------------------------------

export function addAddress(detail) {
    return async (dispatch, getState) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await addAddressAPI(detail);

            const { status, data } = response;

            if (status === 200 && data?.result === 'success') {
                dispatch(getAddressList(getState().address.addressFilter));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function validateAddress(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await validateAddressAPI({
                street: detail?.street || '',
                street2: detail?.street2 || '',
                city: detail?.city || '',
                state: detail?.state || '',
                country: detail?.country || '',
                zip: detail?.zipcode || '',
            });

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.validateAddressSuccess());
                return data;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function modifyAddress(detail) {
    return async (dispatch, getState) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await modifyAddressAPI(detail);

            const { status, data } = response;

            if (status === 200 && data?.result === 'success') {
                dispatch(getAddressList(getState().address.addressFilter));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function deleteAddress(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await deleteAddressAPI(id);

            const { status, data } = response;

            if (status === 200 && data?.result === 'success') {
                dispatch(slice.actions.deleteAddressSuccess(id));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}