import * as Yup from "yup";
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Checkbox, Link, Stack, Typography } from '@mui/material';
import { LoadingButton } from "@mui/lab";
// guards
import PermissionGuard from "../../../../guards/PermissionGuard";
// hooks
import useLocales from "../../../../hooks/useLocales";
// routes
import { PATH_DASHBOARD } from "../../../../routes/paths";
// redux
import { useDispatch, useSelector } from "../../../../redux/store";
import { addOrderCombine, getOrderAwaitingList, getOrderCancelledList, getOrderList, getOrderShippedList } from "../../../../redux/slices/order";
// components
import { FormProvider } from "../../../../components/hook-form";
// utils
import { fAddress } from "../../../../utils/formatAddress";
import { fDateOrderCombine } from "../../../../utils/formatTime";

// ----------------------------------------------------------------------

OrderCombineItem.propTypes = {
    numOfList: PropTypes.number,
    detail: PropTypes.object,
    onSuccess: PropTypes.func,
    currentTab: PropTypes.string,
};

export default function OrderCombineItem({ numOfList, detail, onSuccess, currentTab }) {
    const { translate } = useLocales();

    const dispatch = useDispatch();

    const { filter, awaitingFilter, shippedFilter, cancelledFilter } = useSelector(({ order }) => order);

    const { combin_address: combineAddress, combin_orders: combineOrders } = detail;

    const defaultValues = {
        orderList: [],
    };

    const methods = useForm({
        defaultValues,
    });

    const {
        reset,
        setValue,
        watch,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const watched = watch();

    const handleCheckList = (id) => {
        const tempArray = JSON.parse(JSON.stringify(watched.orderList));

        const index = tempArray.indexOf(id);

        if (index < 0) {
            tempArray.push(id);
        } else {
            tempArray.splice(index, 1);
        }

        setValue('orderList', tempArray);
    };

    const onSubmit = async (values) => {
        try {
            const result = await dispatch(addOrderCombine(values.orderList, numOfList));

            if (result) {
                onSuccess(result);

                if (currentTab === 'orderAll') {
                    dispatch(getOrderList(filter));
                } else if (currentTab === 'orderAwaiting') {
                    dispatch(getOrderAwaitingList(awaitingFilter));
                } else if (currentTab === 'orderShipped') {
                    dispatch(getOrderShippedList(shippedFilter));
                } else if (currentTab === 'orderCancelled') {
                    dispatch(getOrderCancelledList(cancelledFilter));
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => combineOrders && combineOrders.length > 0 && reset({
        orderList: combineOrders.map(item => item.id),
    }), [combineOrders, reset]);

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2.5}>
                <Stack direction="row" spacing={2.5} pb={2} justifyContent="space-between" sx={{ borderBottom: '1px dashed rgba(41, 41, 41, 0.2)' }}>
                    <Stack spacing={0.25}>
                        <Typography variant="h4">
                            {combineAddress?.name || ''}
                        </Typography>

                        <Typography variant="caption">
                            {fAddress(combineAddress)}
                        </Typography>
                    </Stack>

                    <LoadingButton type="submit" variant="contained" loading={isSubmitting} sx={{ mt: '8px !important' }} disabled={watched.orderList.length < 2}>
                        {`${translate('dialog_combine_order_2')}`}
                    </LoadingButton>
                </Stack>

                <Stack display="grid" rowGap={2} columnGap={3} gridTemplateColumns="repeat(auto-fill, minmax(180px, 1fr))" pb={2} sx={{ borderBottom: '1px solid rgba(41, 41, 41, 0.2)' }}>
                    {combineOrders.map((item, indexB) => {
                        const { carrier, order_number: orderNumber, reference_number: referenceNumber, created_at: createdAt } = item;

                        return (
                            <Stack key={`order-${indexB}`} direction="row" spacing={0.25}>
                                <Checkbox
                                    checked={watched.orderList.indexOf(item.id) > -1}
                                    onChange={(event) => handleCheckList(item.id)}
                                    sx={{ padding: 0 }}
                                    color="success"
                                />

                                <PermissionGuard
                                    permission="order.rate"
                                    replacement={
                                        <Stack direction="row" flex={1} justifyContent="space-between" gap={0.5} flexWrap='nowrap'>
                                            <Typography variant="body1" margin="auto">
                                                {referenceNumber ? `#${referenceNumber.replace('#', '')}` : 'N/A'}
                                            </Typography>

                                            <Typography flex={1} textAlign="right" variant="body1" color="text.disabled" noWrap>
                                                {fDateOrderCombine(createdAt)}
                                            </Typography>
                                        </Stack>
                                    }
                                >
                                    <Stack direction="row" flex={1} justifyContent="space-between" gap={0.5} flexWrap='nowrap'>
                                        <Link component={RouterLink} variant="body1" margin="auto" underline="always" target="_blank" to={item.id ? `${PATH_DASHBOARD.shipments.create}?orderId=${item.id}` : null}>
                                            {/* {carrier === "shopify" ? `#${referenceNumber.replace('#', '')}` : `#${orderNumber}`} */}
                                            {referenceNumber ? `#${referenceNumber.replace('#', '')}` : 'N/A'}
                                        </Link>

                                        <Typography flex={1} textAlign="right" variant="body1" color="text.disabled" noWrap>
                                            {fDateOrderCombine(createdAt)}
                                        </Typography>
                                    </Stack>
                                </PermissionGuard>
                            </Stack>
                        );
                    })}
                </Stack>
            </Stack>
        </FormProvider>
    );
}
