import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import * as Yup from "yup";
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Button, Dialog, Stack, Typography } from '@mui/material';
import { LoadingButton } from "@mui/lab";
// hooks
import useLocales from "../../../../../hooks/useLocales";
// redux
import { useDispatch, useSelector } from "../../../../../redux/store";
import { addAddress, modifyAddress, validateAddress } from "../../../../../redux/slices/address";
// components
import { DialogHeader } from "../../../../../components/dialog";
import { FormProvider, RHFCheckbox, RHFTextField } from '../../../../../components/hook-form';
import { FormAddress } from "../../../../../components/form";
// sections
import ValidateDialog from "./ValidateDialog";
// utils
import { getCountryCode, getStateCode } from "../../../../../utils/getCode";

// ----------------------------------------------------------------------

/**
 * @function NewEditAddressDialog
 * @description
 */

NewEditAddressDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    isEdit: PropTypes.bool,
    currentDetail: PropTypes.object,
};

export default function NewEditAddressDialog({ open, onClose, isEdit, currentDetail }) {
    const dispatch = useDispatch();

    const { translate } = useLocales();

    const { isLoading } = useSelector((state) => state.address);

    const [validateDialog, setValidateDialog] = useState(null);

    const AddressSchema = Yup.object().shape({
        firstName: Yup.string().required(`${translate('input_schema_address_form_1')}`),
        lastName: Yup.string().required(`${translate('input_schema_address_form_2')}`),
        phone: Yup.string().required(`${translate('input_schema_address_form_3')}`),
        street1: Yup.string().required(`${translate('input_schema_address_form_4')}`),
        city: Yup.string().required(`${translate('input_schema_address_form_5')}`),
        state: Yup.string().nullable().required(`${translate('input_schema_address_form_6')}`),
        zipcode: Yup.string().required(`${translate('input_schema_address_form_7')}`),
        country: Yup.string().required(`${translate('input_schema_address_form_8')}`),
    });

    const getDefaultValues = (detail) => {
        const { name, last_name: lastName, company_name: company, phone, email, street, street2, city, state, zip, country, is_default: isDefault, residential } = detail || {};

        return {
            firstName: name || '',
            lastName: lastName || '',
            company: company || '',
            phone: phone || '',
            email: email || '',
            street1: street || '',
            street2: street2 || '',
            city: city || '',
            state: getStateCode(country, state),
            zipcode: zip || '',
            country: country || 'United States',
            isDefault: isDefault || false,
            residential: residential || false,
        };
    };

    const defaultValues = useMemo(() => getDefaultValues(currentDetail), [currentDetail]);

    const methods = useForm({
        resolver: yupResolver(AddressSchema),
        defaultValues,
    });

    const {
        setValue,
        handleSubmit,
    } = methods;

    const handleSaveAddress = async (detail) => {
        try {
            if (isEdit) {
                const result = await dispatch(modifyAddress({ id: currentDetail.id, ...detail }));

                if (result) onClose();
            } else {
                const result = await dispatch(addAddress({ ...detail }));

                if (result) onClose();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleOnValidate = (detail, address) => {
        setValidateDialog(
            <ValidateDialog
                open={Boolean(true)}
                onClose={() => setValidateDialog(null)}
                onConfirm={() => handleSaveAddress(address)}
                title={detail?.title ?? ''}
                message={detail?.message ?? ''}
                content={detail?.content ?? ''}
            />,
        );
    };

    const onSubmit = async (values) => {
        try {
            const addressDetail = {
                name: values.firstName || '',
                last_name: values.lastName || '',
                company_name: values?.company || '',
                phone: values.phone || '',
                email: values.email || '',
                street: values.street1 || '',
                street2: values.street2 || '',
                city: values.city || '',
                state: values.state || '',
                zipcode: values.zipcode || '',
                country: getCountryCode(values.country),
                is_default: values.isDefault || false,
                residential: values.residential || false,
                verified: false,
            };

            // const requireValidate = addressDetail.country === 'US';
            const requireValidate = false;

            let result = null;

            if (requireValidate) {
                result = await dispatch(validateAddress({ ...addressDetail }));
            }

            if (requireValidate && result) {
                let title;
                let message;
                let content;

                setValue('residential', Boolean(result?.residential));

                addressDetail.residential = Boolean(result?.residential);

                addressDetail.verified = Boolean(!result?.errored);

                if (result?.errored) {
                    title = 'dialog_validate_address_1';
                    message = result?.errors[0]?.message || '';
                    content = 'dialog_validate_address_1_1';

                    handleOnValidate({ title, message, content }, { ...addressDetail });
                } else if (result?.corrected) {
                    title = 'dialog_validate_address_2';
                    message = result?.corrections[0]?.message || '';
                    content = 'dialog_validate_address_2_1';

                    handleOnValidate({ title, message, content }, { ...addressDetail });
                } else {
                    await handleSaveAddress(addressDetail);
                }
            } else {
                await handleSaveAddress(addressDetail);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <Dialog fullWidth open={open} onClose={onClose}>
                <DialogHeader
                    title={`${translate(`${isEdit ? 'dialog_add_address_2' : 'dialog_add_address_1'}`)}`}
                    content={`${translate('dialog_add_address_1_1')}`}
                    src="/assets/image/function/address.svg"
                />

                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} sx={{ height: 'calc( 100% - 120px )', overflow: "auto" }}>
                    <Box sx={{ pt: 3.75, pb: 7.5, px: { xs: 1.875, sm: 3.75 } }}>
                        <Stack mb={3.75} spacing={3.75}>
                            <Box display="grid" rowGap={3.75} columnGap={2.5} gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}>
                                <RHFTextField name="firstName" label={`${translate('dialog_add_address_1_2_1')}`} size="small"/>

                                <RHFTextField name="lastName" label={`${translate('dialog_add_address_1_2_2')}`} size="small"/>
                            </Box>

                            <RHFTextField name="company" label={`${translate('dialog_add_address_1_3')}`} size="small"/>

                            <Box display="grid" rowGap={3.75} columnGap={2.5} gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}>
                                <RHFTextField name="phone" label={`${translate('dialog_add_address_1_5')}`} size="small"/>

                                <RHFTextField name="email" label={`${translate('dialog_add_address_1_4')}`} size="small"/>
                            </Box>

                            <Stack spacing={2.5}>
                                <FormAddress/>

                                <RHFCheckbox
                                    name="isDefault"
                                    label={
                                        <Typography variant="body1">{`${translate('dialog_add_address_1_6')}`}</Typography>
                                    }
                                    color="success"
                                    sx={{ width: 'fit-content', ml: 0, alignItems: 'flex-start', '& .MuiCheckbox-root': { padding: "0", marginRight: '8px' } }}
                                />

                                <RHFCheckbox
                                    name="residential"
                                    label={
                                        <Typography variant="body1">{`${translate('dialog_add_address_1_7')}`}</Typography>
                                    }
                                    color="success"
                                    sx={{ width: 'fit-content', '& .MuiCheckbox-root': { padding: "0", marginRight: '8px' } }}
                                />
                            </Stack>
                        </Stack>
                    </Box>

                    <Stack
                        position="absolute" width={1} height="60px" bottom={0} padding="10px 30px" bgcolor="white"
                        direction="row" justifyContent="flex-end" spacing={3}
                        boxShadow="-40px 40px 80px 10px rgba(0, 0, 0, 0.24)" zIndex={1}
                    >
                        <Button color="inherit" variant="outlined" onClick={onClose}>
                            {`${translate('dialog_add_address_1_8')}`}
                        </Button>

                        <LoadingButton variant="contained" loading={isLoading} type="submit">
                            {`${translate(`${isEdit ? 'dialog_add_address_2_1' : 'dialog_add_address_1_9'}`)}`}
                        </LoadingButton>
                    </Stack>
                </FormProvider>
            </Dialog>

            {validateDialog}
        </>
    );
}
