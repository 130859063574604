import { createSlice } from '@reduxjs/toolkit';
// api
import {
    getItemList as getItemListAPI,
    getItem as getItemAPI,
    addItem as addItemAPI,
    modifyItem as modifyItemAPI,
    deleteItem as deleteItemAPI,
} from "../../api/item";
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    showTutorial: false,
    itemList: [],
    itemFilter: {
        storeId: 'all',
        keyword: '',
        sort: 'desc',
        page: 0,
        rowsPerPage: 25,
        total: 0,
    },
    item: {
        id: null,
        store_id: null,
        title: null,
        sku: null,
        thumbnails: null,
        price: null,
        quantity: null,
        length: null,
        width: null,
        height: null,
        weight: null,
        hs_tariff_number: null,
        origin_country: null,
        style: null,
        color: null,
        size: null,
    },
};

const slice = createSlice({
    name: 'item',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET ITEM LIST
        getItemsSuccess(state, action) {
            state.isLoading = false;
            state.itemList = action.payload;
        },

        // UPDATE ITEM LIST FILTER
        updateItemListFilterSuccess(state, action) {
            state.itemFilter = {
                ...state.itemFilter,
                ...action.payload,
            };
        },

        // RESET ITEM
        resetItem(state) {
            state.order = null;
        },

        // MODIFY Item
        modifyItemSuccess(state) {
            state.isLoading = false;
        },

        // DELETE ITEM
        deleteItemSuccess(state, action) {
            state.isLoading = false;
            state.itemList = state.itemList.filter((_item) => !action.payload.some((_id) => `${_item.id}` === `${_id}`));
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    resetItem,
} = slice.actions;

// ----------------------------------------------------------------------

export function getItemList(filter) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await getItemListAPI(filter);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getItemsSuccess(data?.data || []));
                dispatch(slice.actions.updateItemListFilterSuccess({
                    total: data?.total || 0,
                    rowsPerPage: data?.per_page || 25,
                }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function addItem(detail) {
    return async (dispatch, getState) => {
        dispatch(slice.actions.startLoading(true));
        try {
            const response = await addItemAPI(detail);

            const { status, data } = response;

            if (status === 200 && data?.result === 'success') {
                dispatch(getItemList(getState().item.itemFilter));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

//---------------------------------------------------------------------------------------

export function modifyItem(detail) {
    return async (dispatch, getState) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await modifyItemAPI(detail);

            const { status, data } = response;

            if (status === 200 && data?.result === 'success') {
                dispatch(slice.actions.modifyItemSuccess());
                dispatch(getItemList(getState().item.itemFilter));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function deleteItems(ids) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await deleteItemAPI(ids);

            const { status, data } = response;

            if (status === 200 && data?.result === 'success') {
                dispatch(slice.actions.deleteItemSuccess(ids));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

// export function getItemInfo(id) {
//     return async () => {
//         try {
//             const response = await getItemAPI(id);
//             const { status, data } = response;
//
//             if (status === 200 && data) {
//                 dispatch(slice.actions.getItemsSuccess(data ?? null));
//             }
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     }
// }

// ----------------------------------------------------------------------

export function updateItemListFilter(filter) {
    return () => {
        dispatch(slice.actions.updateItemListFilterSuccess({
            storeId: filter?.storeId || 'all',
            keyword: filter?.keyword || '',
            sort: filter?.sort || 'desc',
            page: filter?.page || 0,
            rowsPerPage: filter?.rowsPerPage || 25,
            total: filter?.total || 0,
        }));
    };
}

// ----------------------------------------------------------------------

export function resetItemFilter() {
    return async () => {
        const defaultFilter = {
            storeId: 'all',
            keyword: '',
            sort: 'desc',
            page: 0,
            rowsPerPage: 25,
            total: 0,
        };

        dispatch(slice.actions.updateItemListFilterSuccess({ ...defaultFilter }));

        return defaultFilter;
    };
}
