import * as Yup from 'yup';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { endOfDay } from "date-fns";
// form
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, Typography, Button, Link, MenuItem, IconButton } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// api
import { ordersExport as ordersExportAPI } from "../../../../api/order";
import FileList from "../../../../components/customized/FileList";
// hooks
import useLocales from "../../../../hooks/useLocales";
// redux
import { useSelector } from "../../../../redux/store";
// components
import { FormProvider, RHFSelect, RHFTextField } from "../../../../components/hook-form";
import Image from "../../../../components/Image";
// utils
import { getCustomDate } from "../../../../utils/getFilterDate";
import { handleTask, handleDownload, downloadFile } from "../../../../utils/handleTask";

// ----------------------------------------------------------------------

const STORES_STATUS_OPTIONS = [
    { value: 'all', label: "option_default" },
    { value: 'awaiting', label: "option_order_awaiting" },
    { value: 'shipped', label: "option_order_shipped" },
    { value: 'canceled', label: "option_order_canceled" },
];

// ----------------------------------------------------------------------

OrderExport.propTypes = {
    onClose: PropTypes.func,
};

export default function OrderExport({ onClose }) {
    const { enqueueSnackbar } = useSnackbar();

    const { translate } = useLocales();

    const { optionStoreList } = useSelector(({ store }) => store);

    const [storeOptions, setStoreOptions] = useState([]);

    const ExportSchema = Yup.object().shape({
        startDate: Yup.string().nullable().required(`${translate('input_schema_order_export_form_1')}`),
        endDate: Yup.string().nullable().required(`${translate('input_schema_order_export_form_2')}`),
        storeId: Yup.string().required(`${translate('input_schema_order_export_form_3')}`),
        status: Yup.string().required(`${translate('input_schema_order_export_form_4')}`),
    });

    const defaultValues = {
        startDate: '',
        endDate: '',
        storeId: '',
        status: '',
        orderFile: null,
    };

    const methods = useForm({
        resolver: yupResolver(ExportSchema),
        defaultValues,
    });

    const {
        control,
        setValue,
        watch,
        handleSubmit,
    } = methods;

    const watched = watch();

    const handleOnRemove = () => {
        setValue('orderFile', null);
    };

    const handleDownloadOnProgress = (percentage) => {
        setValue(`orderFile.percentage`, Math.round(percentage));
        return true;
    };

    const handleDownloadOnSuccess = async (name) => {
        setValue(`orderFile.status`, 'succeed');
        setValue(`orderFile.percentage`, 100);
        setValue(`orderFile.file.name`, name);

        const file = await handleDownload(name);

        if (file) {
            setValue(`orderFile.file.blob`, file);
        }
    };

    const handleDownloadOnFailed = (errors) => {
        setValue(`orderFile.status`, 'failed');
        setValue(`orderFile.percentage`, 100);

        enqueueSnackbar('There was an error downloading this file. Please try again later.', { variant: 'warning' });
    };

    const onSubmit = async (values) => {
        try {
            setValue('orderFile', {
                status: '',
                percentage: 0,
                file: {
                    name: '',
                    size: null,
                    path: '',
                    type: 'xls',
                    preview: '',
                },
            });

            const exportDetail = {
                store_id: values.storeId,
                status: values.status,
                start_time: getCustomDate(values.startDate),
                end_time: getCustomDate(endOfDay(new Date(values.endDate))),
            };

            const result = await ordersExportAPI({ ...exportDetail });

            const { status, data } = result;

            if (status === 200) {
                await handleTask(data?.id, handleDownloadOnProgress, handleDownloadOnSuccess, handleDownloadOnFailed);
            } else {
                handleDownloadOnFailed();
            }
        } catch (error) {
            console.error(error);
            handleDownloadOnFailed();
        }
    };

    // ----------------------------------

    useEffect(() => {
        const tempOptions = [];

        (optionStoreList || []).forEach(store => tempOptions.push({ value: store.id, label: store.name }));

        setStoreOptions(tempOptions);
    }, [optionStoreList]);

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2.5}>
                <Stack spacing={3.75}>
                    <Typography variant="h3">
                        {`${translate('orders_19_1')}`}
                    </Typography>

                    <Controller
                        name="startDate"
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                            return (
                                <DatePicker
                                    label={`${translate('orders_19_2')}`}
                                    inputFormat="MM/dd/yy"
                                    value={field.value}
                                    onChange={(newValue) => field.onChange(newValue)}
                                    renderInput={(params) => {
                                        const endAdornmentParams = params?.InputProps?.endAdornment?.props?.children?.props ?? null;

                                        return (
                                            <RHFTextField {...params} size="small" error={!!error} helperText={error?.message}
                                                          InputProps={{
                                                              endAdornment: (
                                                                  <IconButton{...endAdornmentParams}>
                                                                      <Image src={'/assets/icon/shipments/ic_time_picker.svg'} alt="ic_time_picker" sx={{ width: '24px', height: '24px' }}/>
                                                                  </IconButton>
                                                              ),
                                                          }}
                                            />
                                        );
                                    }}
                                />
                            );
                        }}
                    />

                    <Controller
                        name="endDate"
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                            return (
                                <DatePicker
                                    label={`${translate('orders_19_3')}`}
                                    inputFormat="MM/dd/yy"
                                    value={field.value}
                                    minDate={watched.startDate}
                                    onChange={(newValue) => field.onChange(newValue)}
                                    renderInput={(params) => {
                                        const endAdornmentParams = params?.InputProps?.endAdornment?.props?.children?.props ?? null;

                                        return (
                                            <RHFTextField {...params} size="small" error={!!error} helperText={error?.message}
                                                          InputProps={{
                                                              endAdornment: (
                                                                  <IconButton{...endAdornmentParams}>
                                                                      <Image src={'/assets/icon/shipments/ic_time_picker.svg'} alt="ic_time_picker" sx={{ width: '24px', height: '24px' }}/>
                                                                  </IconButton>
                                                              ),
                                                          }}
                                            />
                                        );
                                    }}
                                />
                            );
                        }}
                    />

                    <RHFSelect
                        name="storeId" label={`${translate('orders_19_4')}`} size="small"
                        SelectProps={{
                            native: false,
                            MenuProps: {
                                sx: {
                                    maxHeight: '236px',
                                },
                            },
                        }}
                    >
                        {storeOptions.map((option, index) => {
                            return (
                                <MenuItem key={`store-option-${option.value}-${index}`} value={option.value} sx={{ mx: 1, my: 0.5, borderRadius: 0.75, typography: 'body2' }}>
                                    {`${translate(option.label)}`}
                                </MenuItem>
                            );
                        })}
                    </RHFSelect>

                    <RHFSelect
                        name="status" label={`${translate('orders_19_5')}`} size="small"
                        SelectProps={{
                            native: false,
                            MenuProps: {
                                sx: {
                                    maxHeight: '236px',
                                },
                            },
                        }}
                    >
                        {STORES_STATUS_OPTIONS.map((option, index) => {
                            return (
                                <MenuItem key={`status-option-${option.value}-${index}`} value={option.value} sx={{ mx: 1, my: 0.5, borderRadius: 0.75, typography: 'body2' }}>
                                    {`${translate(option.label)}`}
                                </MenuItem>
                            );
                        })}
                    </RHFSelect>
                </Stack>

                <Stack>
                    <Link
                        component={Button} type="submit" variant="h4" underline="always" sx={{ width: 'fit-content', height: 'fit-content', mx: 'auto', p: 0, bgcolor: 'transparent !important', color: '#2C6CD2', fontWeight: '400' }}
                        onClick={() => null} disableRipple
                    >
                        {`${translate('orders_19_6')}`}
                    </Link>

                    {watched.orderFile && (
                        <FileList detail={watched.orderFile} onRemove={handleOnRemove} onDownload={downloadFile}/>
                    )}
                </Stack>
            </Stack>
        </FormProvider>
    );
}
