// utils
import axios from '../utils/axios';

// ----------------------------------------------------------------------

const getPackageList = () => axios.get('/packages/list');

const getPackage = (id) => axios.get('/packages/list', {
    params: {
        id
    }
});

const addPackage = (data) => axios.post('/packages/add', { ...data });

const modifyPackage = (data) => axios.post('/packages/modify', { ...data });

const deletePackage = (id) => axios.delete('/packages/delete', {
    params: {
        id
    }
});

const getPredefinedPackageList = (isAll) => axios.get('/packages/predifined_packages', {
    params: {
        is_all: isAll
    }
});

// ----------------------------------------------------------------------

export {
    getPackageList,
    getPackage,
    addPackage,
    modifyPackage,
    deletePackage,
    getPredefinedPackageList
}
