import { createSlice } from '@reduxjs/toolkit';
// api
import {
    getBalance as getBalanceAPI,
    getBalanceIncreaseTimes as getBalanceIncreaseTimesAPI,
    balancePayoff as balancePayoffAPI,
    // ----------------------------------
    rechargeBalance as rechargeBalanceAPI,
    rechargeBalanceByThirdParty as rechargeBalanceByThirdPartyAPI,
    rechargeBalanceByZelle as rechargeBalanceByZelleAPI,
    // ----------------------------------
    getPaymentMethodList as getPaymentMethodListAPI,
    addPaymentMethod as addPaymentMethodAPI,
    modifyPaymentMethod as modifyPaymentMethodAPI,
    deletePaymentMethod as deletePaymentMethodAPI,
    verifyPaymentMethod as verifyPaymentMethodAPI,
    makeDefaultPaymentMethod as makeDefaultPaymentMethodAPI,
    // ----------------------------------
    getTransactionList as getTransactionListAPI,
    getZelleList as getZelleListAPI,
    // ----------------------------------
    getInvoice as getInvoiceAPI,
} from "../../api/billing";
//
import { dispatch } from '../store';
// utils
import { fBalance } from "../../utils/formatNumber";

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    balance: '0',
    balanceIncreaseTimes: '0',
    transactionList: [],
    filter: {
        transactionType: 'all',
        searchYear: `${ new Date().getFullYear() }`,
        keyword: '',
        sort: 'desc',
        page: 0,
        rowsPerPage: 5,
        total: 0,
    },
    zelleList: [],
    zelleFilter: {
        offlineType: "ZELLE",
        offlineStatus: 'all',
        sort: 'desc',
        page: 0,
        rowsPerPage: 5,
    },
    // ---------------------------
    methodList: [],
    // ---------------------------
    rebateList: [],
    // ---------------------------
    isOpenModal: false,
    isOpenRechargeModal: false,
    formatMethods: null,
};

const slice = createSlice( {
    name: 'payment',
    initialState,
    reducers: {
        // START LOADING
        startLoading( state ) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError( state, action ) {
            state.isLoading = false;
            state.error = action.payload;
        },

        clearError( state ) {
            state.error = null;
        },

        // GET BALANCE
        getBalanceSuccess( state, action ) {
            state.isLoading = false;
            state.balance = action.payload;
        },

        // GET BALANCE INCREASE TIMES
        getBalanceIncreaseTimesSuccess( state, action ) {
            state.balanceIncreaseTimes = action.payload;
        },

        // GET PAYMENT METHODS
        getMethodsSuccess( state, action ) {
            state.isLoading = false;
            state.methodList = action.payload;

            // FOR ADD PAYMENT METHOD && MODIFIED PAYMENT METHOD
            state.isOpenModal = false;
        },

        // DELETE PAYMENT METHOD
        deleteMethodSuccess( state, action ) {
            const methodId = action.payload;
            const deletedMethodList = state.methodList.filter( ( method ) => method.id !== methodId );

            state.isLoading = false;
            state.methodList = deletedMethodList;
        },

        // MAKE DEFAULT PAYMENT METHOD
        makeDefaultMethodSuccess( state, action ) {
            const methodId = action.payload;
            const modifiedMethodList = state.methodList.map( ( _method ) => {
                _method.is_default = _method.id === methodId;

                return _method;
            } );

            state.isLoading = false;
            state.methodList = modifiedMethodList;
        },

        // RECHARGE BALANCE
        rechargeSuccess( state, action ) {
            state.isLoading = false;
            state.isOpenRechargeModal = false;
        },

        // GET TRANSACTION LIST
        getTransactionsSuccess( state, action ) {
            state.isLoading = false;
            state.transactionList = action.payload;
        },

        // UPDATE TRANSACTION LIST FILTER
        updateTransactionListFilterSuccess( state, action ) {
            state.filter = {
                ...state.filter,
                ...action.payload,
            };
        },

        // GET ZELLE LIST
        getZelleSuccess( state, action ) {
            state.isLoading = false;
            state.zelleList = action.payload;
        },

        // UPDATE ZELLE LIST FILTER
        updateZelleListFilterSuccess( state, action ) {
            state.zelleFilter = {
                ...state.zelleFilter,
                ...action.payload,
            };
        },

        // OPEN MODAL
        openModal( state ) {
            state.isOpenModal = true;
        },

        // CLOSE MODAL
        closeModal( state ) {
            state.isOpenModal = false;
        },

        // OPEN RECHARGE MODAL
        openRechargeModal( state ) {
            state.isOpenRechargeModal = true;
        },

        // CLOSE RECHARGE MODAL
        closeRechargeModal( state ) {
            state.isOpenRechargeModal = false;
        },

        // GET FORMAT PAYMENT METHODS
        getFormatMethodsSuccess( state, action ) {
            state.isLoading = false;
            state.formatMethods = action.payload;
        },

    },
} );

// Reducer
export default slice.reducer;

// Actions
export const {clearError} = slice.actions;

// ----------------------------------------------------------------------

export function payoffBalance( detail, amount ) {
    return async () => {
        dispatch( slice.actions.startLoading() );

        try {
            const responseA = await modifyPaymentMethodAPI( detail );

            const {status: statusA, data: dataA} = responseA;

            if (statusA === 200 && dataA?.result === 'success') {
                await dispatch( getMethodList() );

                const responseB = await balancePayoffAPI( {method: detail.id, amount} );

                const {status: statusB, data: dataB} = responseB;

                return statusB === 200 && fBalance( dataB?.balance ?? 0 ) >= 0;
                // return statusB === 200 && dataB?.balance >= 0;
            }
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function getBalance() {
    return async () => {
        try {
            const response = await getBalanceAPI();

            const {status, data} = response;
            if (status === 200) {
                dispatch( slice.actions.getBalanceSuccess( `${ fBalance( data?.balance ?? 0 ) }` ) );
            }
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
        }
    };
}

// ----------------------------------------------------------------------

export function getBalanceIncreaseTimes() {
    return async () => {
        try {
            const response = await getBalanceIncreaseTimesAPI();

            const {status, data} = response;

            if (status === 200) {
                dispatch( slice.actions.getBalanceIncreaseTimesSuccess( data?.balance_increase_times ?? '0' ) );
            }
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
        }
    };
}

// ----------------------------------------------------------------------

export function getMethodList() {
    return async () => {
        try {
            const response = await getPaymentMethodListAPI();

            const {status, data} = response;

            if (status === 200) {
                dispatch( slice.actions.getMethodsSuccess( data?.data || [] ) );
                dispatch( slice.actions.getFormatMethodsSuccess( standardizeMethodList( data?.data || [] ) ) );

                return data?.data || []
            }
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
        }
    };
}

// ----------------------------------------------------------------------

export function getFormatMethodList() {
    return async () => {
        try {
            const response = await getPaymentMethodListAPI();

            const {status, data} = response;

            if (status === 200) {
                dispatch( slice.actions.getFormatMethodsSuccess( standardizeMethodList( data?.data || [] ) ) );
            }
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
        }
    };
}

// ----------------------------------------------------------------------

export function addMethod( detail ) {
    return async () => {
        dispatch( slice.actions.startLoading() );
        try {
            const response = await addPaymentMethodAPI( detail );

            const {status, data} = response;

            if (status === 200 && data?.payment_method_id) {
                await dispatch( getMethodList() );

                return data.payment_method_id;
            }
            return false;
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function modifyMethod( detail ) {
    return async () => {
        dispatch( slice.actions.startLoading() );
        try {
            const response = await modifyPaymentMethodAPI( detail );

            const {status, data} = response;

            if (status === 200 && data?.result === 'success') {
                await dispatch( getMethodList() );

                return true;
            }
            return false;
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function verifyMethod( detail ) {
    return async () => {
        dispatch( slice.actions.startLoading() );
        try {
            const response = await verifyPaymentMethodAPI( detail );

            const {status, data} = response;

            await dispatch( getMethodList() );

            return status === 200 && data?.result === 'success';
        } catch (error) {
            await dispatch( getMethodList() );

            dispatch( slice.actions.hasError( error ) );

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function deleteMethod( id ) {
    return async () => {
        dispatch( slice.actions.startLoading() );
        try {
            const response = await deletePaymentMethodAPI( id );

            const {status, data} = response;

            if (status === 200 && data?.result === 'success') {
                const methodList = await dispatch( getMethodList() );

                const modifiedMethodList = methodList.filter( ( _method ) => _method.id !== id );

                if (modifiedMethodList.length > 0) {
                    await dispatch( makeDefaultMethod( modifiedMethodList[0].id ) )
                } else {
                    dispatch( slice.actions.deleteMethodSuccess( id ) );
                }
            }
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
        }
    };
}

// ----------------------------------------------------------------------

export function makeDefaultMethod( id ) {
    return async () => {
        dispatch( slice.actions.startLoading() );
        try {
            const response = await makeDefaultPaymentMethodAPI( id );

            const {status, data} = response;

            if (status === 200 && data?.result === 'success') {
                dispatch( slice.actions.makeDefaultMethodSuccess( id ) );

                return true;
            }
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
        }
    };
}

// ----------------------------------------------------------------------

export function rechargeBalance( detail ) {
    return async () => {
        dispatch( slice.actions.startLoading() );
        try {
            const response = await rechargeBalanceAPI( detail );

            const {status, data} = response;

            if (status === 200 && data?.balance) {
                dispatch( slice.actions.rechargeSuccess() );
                dispatch( slice.actions.getBalanceSuccess( `${ fBalance( data?.balance ?? 0 ) }` ) );

                return true;
            }
            return false;
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function rechargeBalanceByThirdParty( detail ) {
    return async () => {
        dispatch( slice.actions.startLoading() );
        try {
            const response = await rechargeBalanceByThirdPartyAPI( detail );

            const {status, data} = response;

            if (status === 200) {
                dispatch( slice.actions.rechargeSuccess() );

                if (data?.cashier_url) {
                    window.open( data.cashier_url, '_blank' );
                }
                return true;
            }
            return false;
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function rechargeBalanceByZelle( detail ) {
    return async () => {
        dispatch( slice.actions.startLoading() );
        try {
            const response = await rechargeBalanceByZelleAPI( detail );

            const {status, data} = response;

            if (status === 200 && data?.result === 'success') {
                dispatch( slice.actions.rechargeSuccess() );

                return true;
            }
            return false;
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function getTransactionList( filter ) {
    return async () => {
        dispatch( slice.actions.startLoading() );
        try {
            const response = await getTransactionListAPI( filter );

            const {status, data} = response;

            if (status === 200) {
                dispatch( slice.actions.getTransactionsSuccess( data?.data || [] ) );
                dispatch( slice.actions.updateTransactionListFilterSuccess( {
                    total: data?.total || 0,
                    rowsPerPage: data?.per_page || 5,
                } ) );
            }
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
        }
    };
}

// ----------------------------------------------------------------------

export function updateTransactionListFilter( filter ) {
    return () => {
        dispatch( slice.actions.updateTransactionListFilterSuccess( {
            transactionType: filter?.transactionType || 'all',
            searchYear: filter?.searchYear || `${ new Date().getFullYear() }`,
            keyword: filter?.keyword || '',
            sort: filter?.sort || 'desc',
            page: filter?.page || 0,
            rowsPerPage: filter?.rowsPerPage || 5,
            total: filter?.total || 0,
        } ) );
    };
}

// ----------------------------------------------------------------------

export function getZelleList( filter ) {
    return async () => {
        dispatch( slice.actions.startLoading() );
        try {
            const response = await getZelleListAPI( filter );

            const {status, data} = response;

            if (status === 200) {
                dispatch( slice.actions.getZelleSuccess( data?.data || [] ) );
                dispatch( slice.actions.updateZelleListFilterSuccess( {
                    total: data?.total || 0,
                    rowsPerPage: data?.per_page || 5,
                } ) );
            }
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
        }
    };
}

// ----------------------------------------------------------------------

export function updateZelleListFilter( filter ) {
    return () => {
        dispatch( slice.actions.updateZelleListFilterSuccess( {
            offlineStatus: filter?.offlineStatus || 'all',
            sort: filter?.sort || 'desc',
            page: filter?.page || 0,
            rowsPerPage: filter?.rowsPerPage || 5,
        } ) );
    };
}

// ----------------------------------------------------------------------

function standardizeMethodList( methodList ) {
    let countFailed = 0;
    const resultArr = [];
    let payByCard = false;
    let defaultVerifiedCard = null;
    let selectedMethod = null;

    methodList.forEach( method => {
        switch (method.status) {
            case 'VERIFIED':
                payByCard = true;
                if (method.is_default) {
                    defaultVerifiedCard = method;
                }
                resultArr.unshift( method );
                break;
            case 'NEW':
                resultArr.push( method );
                break;
            case 'VERIFICATION_FAILED':
                countFailed += 1;
                break;
            default:
                break;
        }
    } );

    if (resultArr.length > 0) {
        selectedMethod = defaultVerifiedCard ? defaultVerifiedCard.id : resultArr[0].id;
    }

    return {
        methods: resultArr,
        payByCard,
        selectedMethod,
        unableToAdd: countFailed > 1 && resultArr.length <= 0,
    };
}

// ----------------------------------------------------------------------

export function getTransactionInvoice( detail ) {
    return async () => {
        dispatch( slice.actions.startLoading() );
        try {
            const response = await getInvoiceAPI( detail );

            const {status, data} = response;

            // if (status === 200) {
            //
            // }
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
        }
    };
}

// ----------------------------------------------------------------------

export function resetTransactionFilter() {
    return async () => {
        const defaultFilter = {
            transactionType: 'all',
            searchYear: `${ new Date().getFullYear() }`,
            keyword: '',
            sort: 'desc',
            page: 0,
            rowsPerPage: 5,
            total: 0,
        };

        dispatch( slice.actions.updateTransactionListFilterSuccess( {...defaultFilter} ) );

        return defaultFilter;
    };
}

// ----------------------------------------------------------------------

export function resetZelleFilter() {
    return async () => {
        const defaultFilter = {
            offlineStatus: 'all',
            sort: 'desc',
            page: 0,
            rowsPerPage: 5,
        };

        dispatch( slice.actions.updateZelleListFilterSuccess( {...defaultFilter} ) );

        return defaultFilter;
    };
}
