import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
// @mui
import { Box, Stack, Typography } from "@mui/material";
// routes
import { PATH_AUTH } from "../routes/paths";
// hooks
import useLocales from "../hooks/useLocales";
import useAuth from "../hooks/useAuth";
// sections
import LockedBuyLabelNotification from "../sections/auth/LockedBuyLabelNotification";
import LockedAccountNotification from "../sections/auth/LockedAccountNotification";
// utils
import axios from "../utils/axios";

// ----------------------------------------------------------------------

export default function ErrorContainer( {children} ) {
    const {enqueueSnackbar} = useSnackbar();

    const {translate} = useLocales();

    const navigate = useNavigate();

    const {logout} = useAuth();

    const [errorResponse, setErrorResponse] = useState( null );
    const [errorDialog, setErrorDialog] = useState( null );

    // axios.interceptors.request.use((config) => config, (error) => Promise.reject(error));
    axios.interceptors.response.use(
        ( response ) => response,
        ( error ) => {
            const errors = window.localStorage.getItem( 'error' );

            if (!errors) return Promise.reject( error );

            const _error = JSON.parse( errors );

            setErrorResponse( _error );

            return Promise.reject( error );
        },
    );

    const handleContactToUnlock = ( e ) => {
        e.preventDefault();

        setErrorDialog( null );

        if (window) {
            window.location.href = 'mailto:support@shipsaving.com?subject=Unlock my ShipSaving account';
        }

        logout().then( () => navigate( PATH_AUTH.login, {replace: true} ) );
    };

    useEffect( () => {
        if (errorResponse) {
            const accessToken = localStorage.getItem( 'accessToken' );

            if (errorResponse?.code === 'com.shipsaving.company.locked') {
                setErrorDialog(
                    <LockedAccountNotification
                        open={ Boolean( true ) }
                        onClose={ handleContactToUnlock }
                        onClickButton={ handleContactToUnlock }
                    />
                )

            } else if (errorResponse?.code === 'com.shipsaving.shipment.create.lock') {
                setErrorDialog(
                    <LockedBuyLabelNotification
                        open={ Boolean( true ) }
                        onClose={ () => setErrorDialog( null ) }
                        errorMsg={ errorResponse }
                    />,
                );
            } else if (errorResponse?.code === 'com.shipsaving.auth.token.expired' || errorResponse?.code === 'com.shipsaving.auth.token.invalid' || errorResponse?.code === 'com.shipsaving.company.inactivated') {
                // logout().then(() => navigate(PATH_AUTH.login, { replace: true }));
            } else if (errorResponse?.code !== 'com.shipsaving.auth.pwd.incorrect' || errorResponse?.code !== 'com.shipsaving.company.type' || accessToken !== null) {
                if (errorResponse?.code) {
                    if (
                        errorResponse.code === "com.shipsaving.plan.shipments.exceed_limit" ||
                        errorResponse.code === "payment.payment_methods.verify_first_fail" ||
                        errorResponse.code === "payment.payment_methods.verify_second_fail" ||
                        errorResponse.code === "payment.paymentmethods.verify.fail"
                    ) {
                        //
                    } else if (errorResponse.code === 'com.shipsaving.auth.pwd.incorrect') {
                        enqueueSnackbar( `${ translate( "com_shipsaving_auth_pwd_incorrect_account" ) }`, {variant: 'warning', preventDuplicate: true} );
                    } else if (errorResponse.code !== 'com.shipsaving.company.type') {
                        enqueueSnackbar( (
                            <Stack direction="column">
                                <Box>
                                    <Typography fontSize="inherit" fontWeight="inherit" fontFamily="inherit" color="inherit">
                                        {/* eslint-disable-next-line no-nested-ternary */ }
                                        { `${ translate( errorResponse?.code.replaceAll( ".", "_" ).replaceAll( " ", "_" ) ) }` === errorResponse?.code.replaceAll( ".", "_" ).replaceAll( " ", "_" ) ?
                                            (errorResponse?.message ? errorResponse.message : `${ translate( 'default_error_msg' ) }`) :
                                            `${ translate( errorResponse?.code.replaceAll( ".", "_" ).replaceAll( " ", "_" ) ) }` }
                                    </Typography>
                                </Box>
                                { (errorResponse?.errors ?? []).length > 0 && (
                                    <ul>
                                        { errorResponse.errors.map( ( error, index ) => {
                                            if (!(error?.message ?? "")) return null;
                                            return (
                                                <li key={ index }>{ error?.message }</li>
                                            )
                                        } ) }
                                    </ul>
                                ) }
                            </Stack>
                        ), {variant: 'warning', preventDuplicate: true} );
                    }
                } else {
                    enqueueSnackbar( `${ translate( 'default_error_msg' ) }`, {variant: 'warning', preventDuplicate: true,} );
                }
            } else {
                enqueueSnackbar( `${ translate( 'default_error_msg' ) }`, {variant: 'warning', preventDuplicate: true,} );
            }

            setErrorResponse( null );
        }
    }, [errorResponse] );

    return <>
        { children }

        { errorDialog }
    </>
}
