import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
// form
import { FormProvider as Form } from 'react-hook-form';
import { Stack } from "@mui/material";

// ----------------------------------------------------------------------

const FormProvider = forwardRef(({ id, children, onSubmit, methods, sx, style }, ref) => {
    return (
        <Form {...methods}>
            <Stack component="form" id={id} ref={ref} onSubmit={onSubmit} sx={{ ...sx, ...style }}>{children}</Stack>
        </Form>
    );
});

FormProvider.propTypes = {
    id: PropTypes.string,
    children: PropTypes.node.isRequired,
    methods: PropTypes.object.isRequired,
    onSubmit: PropTypes.func,
    sx: PropTypes.object,
    style: PropTypes.object,
};

export default FormProvider;
