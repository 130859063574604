// utils
import axios from '../utils/axios';

// ----------------------------------------------------------------------

const getPlanInfo = () => axios.get('/company/plan/info');

const getPlanList = () => axios.get('/options/sys/plan');

const modifyPlan = (id) => axios.post('/company/plan/modify', {
    new_plan_id: id,
});

const modifyPlanPaymentMethod = (id) => axios.post('/company/plan/payment_method/modify', {
    payment_method_id: id,
});

const getPlanChargeHistoryList = (filter) => axios.post('/company/plan/charge_history', {
    per_page: filter?.rowsPerPage || 5,
    current_page: filter?.page || 0,
    order_by: [
        {
            name: "created_at",
            sorting: filter?.sort || "desc",
        },
    ],
});

const getInvoice = (detail) => axios.post('/company/plan/get_invoice', {
        plan_charge_history_id: detail?.planChargeHistoryId ?? '',
    }, { responseType: 'blob' },
);

// ----------------------------------------------------------------------

export {
    getPlanInfo,
    modifyPlan,
    modifyPlanPaymentMethod,
    getPlanList,
    getPlanChargeHistoryList,
    getInvoice,
}
