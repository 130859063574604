// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icon/navbar/${name}.svg`} sx={{ width: 1, height: 1 }}/>;

const ICONS = {
    blog: getIcon('ic_blog'),
    cart: getIcon('ic_cart'),
    user: getIcon('ic_user'),
    banking: getIcon('ic_banking'),
    booking: getIcon('ic_booking'),
    invoice: getIcon('ic_invoice'),
    calendar: getIcon('ic_calendar'),
    analytics: getIcon('ic_analytics'),
    dashboard: getIcon('ic_dashboard'),
    menuItem: getIcon('ic_menu_item'),
    shipments: getIcon('ic_shipments'),
    orders: getIcon('ic_orders'),
    items: getIcon('ic_items'),
    reports: getIcon('ic_reports'),
    usps: getIcon('ic_usps'),
    settings: getIcon('ic_settings'),
};

const navConfig = [
    // Shipsaving
    // ----------------------------------------------------------------------
    {
        items: [
            // { title: 'usps', path: PATH_DASHBOARD.partner.root, icon: ICONS.usps, key: 'default' },
            // { title: 'usps', path: PATH_DASHBOARD.partner.root, icon: ICONS.usps, key: 'usps' },
            { title: 'usps', path: PATH_DASHBOARD.partner.root, icon: ICONS.usps, key: 'carrier_referral' },
            { title: 'dashboard', path: PATH_DASHBOARD.root, icon: ICONS.dashboard, key: 'default' },
            { title: 'shipments', path: PATH_DASHBOARD.shipments.root, icon: ICONS.shipments, key: 'shipments' },
            { title: 'orders', path: PATH_DASHBOARD.orders.root, icon: ICONS.orders, key: 'orders' },
            { title: 'items', path: PATH_DASHBOARD.items.root, icon: ICONS.items, key: 'items' },
            { title: 'reports', path: PATH_DASHBOARD.reports.root, icon: ICONS.reports, key: 'reports' },
            {
                title: 'settings', path: PATH_DASHBOARD.settings.root, icon: ICONS.settings,
                children: [
                    { title: 'account', path: PATH_DASHBOARD.settings.account, key: 'default' },
                    { title: 'integration', path: PATH_DASHBOARD.settings.integration, key: 'integration' },
                    { title: 'configuration', path: PATH_DASHBOARD.settings.configuration, key: 'configuration' },
                ],
            },
        ],
    },
];

export default navConfig;
