import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Typography, Stack, Button } from '@mui/material';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useAuth from "../../hooks/useAuth";
import useLocales from "../../hooks/useLocales";
// redux
import { batch, useDispatch, useSelector } from "../../redux/store";
import { getTour, modifyTour } from "../../redux/slices/tour";
import { getOptionStoreList, getStoreList } from "../../redux/slices/store";
import { getAddressList } from "../../redux/slices/address";
import { getAllPackageList } from "../../redux/slices/package";
import { getBalance, getMethodList } from "../../redux/slices/payment";
import { getOrderList } from "../../redux/slices/order";
import { getPermissionList } from "../../redux/slices/permission";
// routes
import { PATH_AUTH, PATH_DASHBOARD } from "../../routes/paths";
// config
import { HEADER, NAVBAR } from '../../config';
// components
import HeaderAlert from "../../components/customized/HeaderAlert";
// sections
// import FirstTimeTutorialDialog from "../../sections/@dashboard/FirstTimeTutorialDialog";
import StandardTutorialDialog from "../../sections/@dashboard/StandardTutorialDialog";
import PayNowDialog from "../../sections/@dashboard/PayNowDialog";
//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import PayOffBalanceSuccessDialog from "../../sections/@dashboard/setting/account/dialog/PayOffBalanceSuccessDialog";

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
    shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
    position: 'relative',
    flexGrow: 1,
    paddingTop: HEADER.MOBILE_HEIGHT + 30,
    paddingBottom: HEADER.MOBILE_HEIGHT + 30,
    [theme.breakpoints.up('lg')]: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 30,
        paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 30,
        width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
        transition: theme.transitions.create('margin-left', {
            duration: theme.transitions.duration.shorter,
        }),
        ...(collapseClick && {
            marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
        }),
    },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
    const navigate = useNavigate();

    const { translate } = useLocales();

    const dispatch = useDispatch();

    const { user, needCompletePlanConfig, company, logout } = useAuth();

    const [payNowDialog, setPayNowDialog] = useState(null);
    const [payoffSuccessDialog, setPayoffSuccessDialog] = useState(null);

    const { collapseClick, isCollapse } = useCollapseDrawer();

    const { tour } = useSelector((state) => state.tour);
    const { balance } = useSelector((state) => state.payment);

    const [open, setOpen] = useState(false);

    // const [starterTutorial, setStarterTutorial] = useState(null);
    // const [standardTutorial, setStandardTutorial] = useState(null);
    const [tutorial, setTutorial] = useState(null);

    const handleOpenPayNow = () => {
        setPayNowDialog(
            <PayNowDialog
                open={Boolean(true)}
                onClose={() => setPayNowDialog(null)}
                onSuccess={(result) => {
                    if (result) {
                        setPayNowDialog(null);
                        setPayoffSuccessDialog(
                            <PayOffBalanceSuccessDialog
                                open={Boolean(true)}
                                onClose={() => {
                                    setPayoffSuccessDialog(null);

                                    logout().then(() => navigate(PATH_AUTH.login, { replace: true }))
                                }}
                            />
                        )
                    }
                }}
            />
        )
    }

    // const handleSeeTutorial = () => {
    //     setStarterTutorial(null);
    //
    //     dispatch(modifyTour('company_tutorial'));
    // };

    useEffect(() => {
        window.initGeoTools = function initGeoTools() {
            console.log('callback invoked');
        };
    }, []);

    // useEffect(() => batch(() => {
    //     dispatch(getTour());
    //
    //     dispatch(getAddressList());
    //     dispatch(getAllPackageList());
    //     dispatch(getMethodList());
    //
    //     dispatch(getStoreList());
    //     dispatch(getOrderList({}));
    //
    //     dispatch(getPermissionList());
    //
    //     dispatch(getBalance());
    //
    //     dispatch(getOptionStoreList());
    // }), [dispatch]);

    // useEffect(() => {
    //     if (needCompletePlanConfig === true || needCompletePlanConfig === 'true') {
    //         setStandardTutorial(
    //             <StandardTutorialDialog
    //                 open={Boolean(true)}
    //                 onClose={() => setStandardTutorial(null)}
    //             />,
    //         );
    //     } else if (company?.current_plan?.id === 1 && tour?.company_tutorial) {
    //         setStarterTutorial(
    //             <FirstTimeTutorialDialog
    //                 open={Boolean(true)}
    //                 onClose={handleSeeTutorial}
    //                 onFinish={handleSeeTutorial}
    //             />,
    //         );
    //     }
    // }, [tour, user]);

    // useEffect(() => {
    // if (tour?.company_tutorial) {
    // if (company?.current_plan?.id === 1) {
    //     setStarterTutorial(
    //         <FirstTimeTutorialDialog
    //             open={Boolean(true)}
    //             onClose={handleSeeTutorial}
    //             onFinish={handleSeeTutorial}
    //         />,
    //     );
    // } else {
    //     setTutorial(
    //         <StandardTutorialDialog
    //             open={Boolean(true)}
    //             onClose={() => setTutorial(null)}
    //             onFinish={() => dispatch(modifyTour('company_tutorial'))}
    //         />,
    //     );
    // }
    // }
    // }, [company?.current_plan?.id, tour?.company_tutorial]);

    useEffect(() => {
        if (tour?.company_tutorial && !tutorial) {
            setTutorial(
                <StandardTutorialDialog
                    open={Boolean(true)}
                    onClose={() => setTutorial(null)}
                    onFinish={() => dispatch(modifyTour('company_tutorial'))}
                />,
            );
        }
    }, [dispatch, tour?.company_tutorial, tutorial]);

    // 测试用
    // useEffect(() => {
    //     setTutorial(
    //         <StandardTutorialDialog
    //             open={Boolean(true)}
    //             onClose={() => setTutorial(null)}
    //             onFinish={() => dispatch(modifyTour('company_tutorial'))}
    //         />,
    //     );
    // }, []);

    return (
        <>
            <Box sx={{ display: { lg: 'flex' }, minHeight: { lg: 1 } }}>
                <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)}/>

                <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)}/>

                <MainStyle collapseClick={collapseClick}>
                    <HeaderAlert active={balance < 0}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1.25}>
                            <Typography variant="body1" color="white" flex={1}>
                                {`${translate('header_alert_adjustment_fee_1')}`}
                                <br/>
                                {`${translate('header_alert_adjustment_fee_2')}`}
                            </Typography>

                            <Button
                                variant="contained" onClick={() => handleOpenPayNow()}
                                sx={{ bgcolor: 'white', color: 'black', height: '30px', py: 0.5, px: 2.5, whiteSpace: "nowrap", '&:hover': { backgroundColor: 'white', opacity: 0.85 } }}
                            >
                                {`${translate('header_alert_adjustment_fee_3')}`}
                            </Button>
                        </Stack>
                    </HeaderAlert>

                    <Outlet/>
                </MainStyle>
            </Box>

            {tutorial}

            {/* {starterTutorial} */}

            {/* {standardTutorial} */}

            {payNowDialog}

            {payoffSuccessDialog}
        </>
    );
}
