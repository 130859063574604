// utils
import axios from '../utils/axios';

// ----------------------------------------------------------------------

const getCompanyPermission = () => axios.get('/company/permissions');

// ----------------------------------------------------------------------

export {
    getCompanyPermission,
};
