// ----------------------------------------------------------------------

export default function Autocomplete(theme) {
    return {
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root ': {
                        '& .MuiAutocomplete-endAdornment': {
                            top: 'auto',
                            right: '15px'
                        }
                    }
                },
                paper: {
                    boxShadow: theme.customShadows.dropdown,
                },
                listbox: {
                    padding: theme.spacing(0, 1),
                    '& .MuiAutocomplete-option': {
                        padding: theme.spacing(1),
                        margin: theme.spacing(1, 0),
                        borderRadius: theme.shape.borderRadius,
                    },
                },
                popupIndicator: {
                    "& svg": {
                        width: '1em',
                        height: '1em',
                        fontSize: '16px',
                        "& path": {
                            d: "path('M 12 16 C 11.7663 16.0005 11.5399 15.9191 11.36 15.77 L 5.36 10.77 C 4.93474 10.4165 4.87654 9.78526 5.23 9.36 C 5.58346 8.93474 6.21474 8.87654 6.64 9.23 L 12 13.71 L 17.36 9.39 C 17.5666 9.22223 17.8315 9.14373 18.0962 9.17188 C 18.3608 9.20004 18.6033 9.33252 18.77 9.54 C 18.9551 9.74786 19.0453 10.0235 19.0187 10.3006 C 18.9921 10.5776 18.8513 10.8311 18.63 11 L 12.63 15.83 C 12.4449 15.9555 12.2231 16.0154 12 16 Z')"
                        }
                    }
                }
            },
        },
    };
}
