import PropTypes from 'prop-types';
import * as Yup from "yup";
import React, { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Button, CircularProgress, InputAdornment, MenuItem, Stack, Typography } from '@mui/material';
import { LoadingButton } from "@mui/lab";
// api
import { modifyCompanyAutomation } from "../../../../../api/automation";
// redux
import { useDispatch } from "../../../../../redux/store";
import { addPackage } from "../../../../../redux/slices/package";
// hooks
import useLocales from "../../../../../hooks/useLocales";
// components
import Image from "../../../../../components/Image";
import { FormProvider, RHFSelect, RHFTextField } from '../../../../../components/hook-form';
// constants
import { LABEL_SIZE_OPTIONS } from "../../../../../constants/options";

// ----------------------------------------------------------------------

/**
 * @function AddPackage
 * @description
 */

AddPackage.propTypes = {
    onClose: PropTypes.func,
    onFinish: PropTypes.func,
};

const WEIGHT_OPTIONS = [
    { value: 'lb', label: "lb" },
    { value: 'g', label: "g" },
    { value: 'kg', label: "kg" },
    { value: 'oz', label: "oz" },
];

export default function AddPackage({ onClose, onFinish }) {
    const dispatch = useDispatch();

    const { translate } = useLocales();

    const [isLoading, setIsLoading] = useState(false);

    const StandardTutorialPackageSchema = Yup.object().shape({
        nickName: Yup.string().max(20, `${translate('input_schema_package_form_1_1')}`).required(`${translate('input_schema_package_form_1_2')}`),
        length: Yup.number().transform(value => value || 0).min(6, `${translate('input_schema_package_form_2_1')}`)
            .test('checkLengthValue', (value, item) => {
                const perimeter = (item.parent.length + item.parent.width + item.parent.height) * 2 - Math.max(item.parent.length, item.parent.width, item.parent.height);

                return perimeter > 165 ? item.createError({
                    message: `${translate('input_schema_package_form_2_2')}${perimeter}"`,
                }) : true;
            })
            .required(`${translate('input_schema_package_form_2_3')}`),
        width: Yup.number().transform(value => value || 0).min(3, `${translate('input_schema_package_form_3_1')}`)
            .test('checkWidthValue', (value, item) => {
                const perimeter = (item.parent.length + item.parent.width + item.parent.height) * 2 - Math.max(item.parent.length, item.parent.width, item.parent.height);

                return perimeter > 165 ? item.createError({
                    message: `${translate('input_schema_package_form_3_2')}${perimeter}"`,
                }) : true;
            })
            .required(`${translate('input_schema_package_form_3_3')}`),
        height: Yup.number().transform(value => value || 0).min(0.25, `${translate('input_schema_package_form_4_1')}`)
            .test('checkHeightValue', (value, item) => {
                const perimeter = (item.parent.length + item.parent.width + item.parent.height) * 2 - Math.max(item.parent.length, item.parent.width, item.parent.height);

                return perimeter > 165 ? item.createError({
                    message: `${translate('input_schema_package_form_4_2')}${perimeter}"`,
                }) : true;
            })
            .required(`${translate('input_schema_package_form_4_3')}`),
    });

    const defaultValues = {
        nickName: '',
        type: 'USER',
        length: '',
        width: '',
        height: '',
        weightUnit: 'lb',
        labelSize: 0,
    };

    const methods = useForm({
        resolver: yupResolver(StandardTutorialPackageSchema),
        mode: 'onChange',
        defaultValues,
    });

    const {
        handleSubmit,
        setValue,
    } = methods;

    const handleInputChange = (type, value) => {
        if (value !== '') setValue(type, Math.round(value * 100) / 100);
    };

    const onSubmit = async (values) => {
        try {
            setIsLoading(true);

            const packageDetail = {
                nick_name: values.nickName || '',
                package_type: values.type || '',
                is_default: true,
                dimension: {
                    length: values.length || '',
                    width: values.width || '',
                    height: values.height || '',
                },
            };

            const resultA = dispatch(addPackage({ ...packageDetail }));

            const resultB = await modifyCompanyAutomation({
                weight_unit: values.weightUnit || 'lb',
                label_size: LABEL_SIZE_OPTIONS[values.labelSize]?.value.size,
                label_position: LABEL_SIZE_OPTIONS[values.labelSize]?.value.position,
            });

            if (resultA && resultB) {
                onFinish();
                onClose();
            }

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error(error);
        }
    };

    return (
        <Stack sx={{ '& form': { height: '100%' }, position: 'relative', height: '100%', maxHeight: '600px', overflow: 'auto' }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack sx={{ pt: 3.75, px: 2.5, height: '100%' }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ mb: 2.5 }}>
                        <Typography variant="h3">{translate('dialog_standard_tutorial_3_1')}</Typography>
                        <Typography variant="body1">2/2</Typography>
                    </Stack>
                    
                    <Stack sx={{ overflowY: 'scroll', msOverflowStyle: 'none', scrollbarWidth: 'none', '&::-webkit-scrollbar': { display: 'none' } }}>
                        <Stack spacing={3.75} mb="90px">
                            {/* <Typography variant="caption">{translate('dialog_standard_tutorial_3_2')}</Typography> */}

                            {/* <Typography variant="h4">{translate('dialog_standard_tutorial_3_3')}</Typography> */}

                            <Stack spacing={2.5} mt={1}>
                                <RHFSelect
                                    name="labelSize"
                                    label={`${translate('dialog_standard_tutorial_3_4')}`}
                                    size="small"
                                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                                >
                                    {LABEL_SIZE_OPTIONS.map((option, index) => (
                                        <MenuItem
                                            key={`label-${index}`}
                                            value={index}
                                            sx={{ mx: 1, my: 0.5, borderRadius: 0.75, typography: 'body2' }}
                                        >
                                            {option.size}
                                        </MenuItem>
                                    ))}
                                </RHFSelect>

                                <RHFSelect
                                    name="weightUnit"
                                    label={`${translate('dialog_standard_tutorial_3_5')}`}
                                    size="small"
                                    SelectProps={{ native: false }}
                                >
                                    {WEIGHT_OPTIONS.map((option) => (
                                        <MenuItem
                                            key={`weight-${option.value}`}
                                            value={option.value}
                                            sx={{ mx: 1, my: 0.5, borderRadius: 0.75, typography: 'body2' }}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </RHFSelect>
                            </Stack>

                            <Typography variant="h4">{translate('dialog_standard_tutorial_3_6')}</Typography>

                            <Stack spacing={2.5}>
                                <Stack direction="row" spacing={2} sx={{ px: 2, py: 1, border: '1px solid rgba(41, 41, 41, 0.2)', borderRadius: '4px' }}>
                                    <Image src="/assets/image/packages/custom_package.svg" alt="custom_package" sx={{ flexShrink: 0, width: '80px', height: '80px', '& img': { objectFit: 'contain' } }} visibleByDefault disabledEffect/>

                                    <Stack justifyContent="center">
                                        <Typography variant="body1" minHeight="21px">{`${translate('option_custom_package_name')}`}</Typography>
                                        <Typography variant="body1" minHeight="21px" color="text.secondary">{`${translate('option_custom_package_desc')}`}</Typography>
                                    </Stack>
                                </Stack>

                                <RHFTextField name="nickName" label={`${translate('dialog_add_package_1_9')}`} size="small"/>

                                <Box display="grid" rowGap={2.5} columnGap={0.785} gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: '1fr max-content 1fr max-content 1fr' }}>
                                    <RHFTextField
                                        name="length" label={`${translate('dialog_item_4_1')}`} type="number" size="small"
                                        onWheel={(e) => e.target.blur()}
                                        onBlur={(e) => handleInputChange("length", e.target.value)}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{`${translate(`dialog_item_4_4`)}`}</InputAdornment>,
                                        }}
                                    />

                                    <Typography variant="h6" display={{ xs: 'none', sm: 'block' }} m="auto">X</Typography>

                                    <RHFTextField
                                        name="width" label={`${translate('dialog_item_4_2')}`} type="number" size="small"
                                        onWheel={(e) => e.target.blur()}
                                        onBlur={(e) => handleInputChange("width", e.target.value)}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{`${translate(`dialog_item_4_4`)}`}</InputAdornment>,
                                        }}
                                    />

                                    <Typography variant="h6" display={{ xs: 'none', sm: 'block' }} m="auto">X</Typography>

                                    <RHFTextField
                                        name="height" label={`${translate('dialog_item_4_3')}`} type="number" size="small"
                                        onWheel={(e) => e.target.blur()}
                                        onBlur={(e) => handleInputChange("height", e.target.value)}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{`${translate(`dialog_item_4_4`)}`}</InputAdornment>,
                                        }}
                                    />
                                </Box>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack
                    justifyContent="center" alignItems="center"
                    sx={{
                        position: 'absolute',
                        bottom: '0',
                        backdropFilter: 'blur(2px)',
                        zIndex: '1',
                        height: '60px',
                        width: '100%',
                        borderTop: '1px solid',
                        borderColor: 'grey.200',
                        boxShadow: '4px 0px 10px rgba(0, 0, 0, 0.1)',
                        bgcolor: 'rgba(255, 255, 255, 0.9)',
                    }}
                >
                    <Stack width={1} direction="row" justifyContent="space-between" sx={{ px: 1.5 }}>
                        <Button
                            sx={{ cursor: 'pointer', width: "fit-content", minWidth: "unset", color: 'text.disabled', '&:hover': { backgroundColor: 'transparent' } }} onClick={onClose}
                        >{translate('dialog_standard_tutorial_2_4')}</Button>
                        <LoadingButton
                            loading={isLoading}
                            loadingIndicator={
                                <CircularProgress sx={{ color: '#292929' }} size={20} thickness={5}/>
                            }
                            type="submit"
                            sx={{ width: "fit-content", minWidth: "unset", bgcolor: 'transparent', '&:hover': { backgroundColor: 'transparent' }, color: '#292929' }}
                        >
                            {translate('dialog_standard_tutorial_3_7')}
                        </LoadingButton>
                    </Stack>
                </Stack>
            </FormProvider>
        </Stack>
    );
}
