import { createSlice } from '@reduxjs/toolkit';
// api
import {
    getPartnerReports as getPartnerReportsAPI,
    getClientList as getClientListAPI,
    getClientCount as getClientCountAPI,
} from "../../api/partner";
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    dataRange: 'last7d',
    clientList: [],
    filter: {
        hasShipment: 'all',
        keyword: '',
        sort: 'desc',
        page: 0,
        rowsPerPage: 25,
        total: 0,
    },
    count: 0,
};

const slice = createSlice({
    name: 'partner',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET PARTNER REPORTS
        getPartnerReportsSuccess(state, action) {
            state.isLoading = false;
        },

        // UPDATE REPORT DATE RANGE
        updateReportDateRangeSuccess(state, action) {
            state.dataRange = action.payload;
        },

        // GET CLIENT
        getClientSuccess(state, action) {
            state.isLoading = false;
            state.clientList = action.payload;
        },

        // UPDATE CLIENT LIST FILTER
        updateClientListFilterSuccess(state, action) {
            state.filter = {
                ...state.filter,
                ...action.payload,
            };
        },

        // GET CLIENT COUNT
        getClientCountSuccess(state, action) {
            state.isLoading = false;
            state.count = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getPartnerReport(date) {
    return async () => {
        try {
            const response = await getPartnerReportsAPI(date);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getPartnerReportsSuccess(data || null));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateReportDateRange(range) {
    return () => {
        dispatch(slice.actions.updateReportDateRangeSuccess(range));
    };
}

// ----------------------------------------------------------------------

export function getClientList(filter) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await getClientListAPI({ ...filter });

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getClientSuccess(data?.data || []));
                dispatch(slice.actions.updateClientListFilterSuccess({
                    total: data?.total || 0,
                    rowsPerPage: data?.per_page || 5,
                }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateClientListFilter(filter) {
    return () => {
        dispatch(slice.actions.updateClientListFilterSuccess({
            hasShipment: filter?.hasShipment || 'all',
            keyword: filter?.keyword || '',
            sort: filter?.sort || 'desc',
            page: filter?.page || 0,
            rowsPerPage: filter?.rowsPerPage || 5,
            total: filter?.total || 0,
        }));
    };
}

// ----------------------------------------------------------------------

export function resetFilter() {
    return async () => {
        const defaultFilter = {
            hasShipment: 'all',
            keyword: '',
            sort: 'desc',
            page: 0,
            rowsPerPage: 25,
            total: 0,
        };

        dispatch(slice.actions.updateClientListFilterSuccess({ ...defaultFilter }));
        // dispatch(slice.actions.updateReportDateRangeSuccess('last7d'));

        return defaultFilter;
    };
}

// ----------------------------------------------------------------------

export function getClientCount(filter) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await getClientCountAPI(filter);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getClientCountSuccess(data?.result ?? 0));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
