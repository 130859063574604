// ----------------------------------------------------------------------

export function getGroupByService(list) {
    if (!list || list.length === 0) return {};

    return list.reduce((group, item) => {
        if (item.refund_status !== 'refunded') {
            if (item.is_return) {
                group.isReturn[item.service_level] = [...(group.isReturn[item.service_level] ?? []), item];
            } else {
                group.regular[item.service_level] = [...(group.regular[item.service_level] ?? []), item];
            }
        } else {
            group.isVoid[item.service_level] = [...(group.isVoid[item.service_level] ?? []), item];
        }

        return group;
    }, { regular: {}, isReturn: {}, isVoid: {} });
}

export function getGroupByTracking(list) {
    if (!list || list.length === 0) return {};

    return list.reduce((group, item) => {
        if (item.refund_status !== 'refunded') {
            if (item.is_return) {
                if (item.master_tracking_code) {
                    group.isReturn[item.master_tracking_code] = [...(group.isReturn[item.master_tracking_code] ?? []), item];
                } else {
                    group.isReturn[item.tracking_code] = [...(group.isReturn[item.tracking_code] ?? []), item];
                }
            } else if (item.master_tracking_code) {
                group.regular[item.master_tracking_code] = [...(group.regular[item.master_tracking_code] ?? []), item];
            } else {
                group.regular[item.tracking_code] = [...(group.regular[item.tracking_code] ?? []), item];
            }
        } else if (item.master_tracking_code) {
            group.isVoid[item.master_tracking_code] = [...(group.isVoid[item.master_tracking_code] ?? []), item];
        } else {
            group.isVoid[item.tracking_code] = [...(group.isVoid[item.tracking_code] ?? []), item];
        }

        return group;
    }, { regular: {}, isReturn: {}, isVoid: {} });
}

export function getShipmentList(list, type) {
    if (!list || list.length === 0) return [];

    const _list = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(list)) {
        let _createdAt = "";
        let _carrier = "";
        let _serviceLevel = "";
        const _amount = value.reduce((total, { rate: costRate, shipment_insurance: shipmentInsurance }) => total + costRate + (shipmentInsurance?.fee ?? 0), 0);

        const _idList = [];

        // eslint-disable-next-line array-callback-return,no-loop-func
        value.map((_d, index) => {
            _idList.push(_d.id);

            if (index === 0) {
                _createdAt = _d.created_at;
                _carrier = _d.carrier;
                _serviceLevel = _d.service_level;

                // eslint-disable-next-line array-callback-return
                _d.transactions.map(_tL => {
                    if (_tL.type === "adjustment") {
                        _list.push({
                            ..._tL,
                            actual_dimension: _d?.bill_dimension ?? "",
                            actual_weight: _d?.bill_weight ?? "",
                            reason: _d?.bill_description ?? "",
                        })
                    } else if (_tL.type !== "purchase") {
                        _list.push({
                            ..._tL
                        })
                    }
                })

                // if (type === "isVoid" && _d.bill_status === "completed") {
                //     _list.push({
                //         type: "refund",
                //         amount: _d.rate + _d.shipment_insurance?.fee ?? 0,
                //         created_at: _d.updated_at
                //     })
                // }

                // if (type === "isVoid") {
                //     _list.push({
                //         type: "refund",
                //         amount: _d.rate + _d.shipment_insurance?.fee ?? 0,
                //         created_at: _d.updated_at
                //     })
                // }

                if (type !== "isVoid" && _d.refund_status === 'rejected') {
                    _list.push({
                        type: "refund_rejected",
                        amount: _d.rate + _d.shipment_insurance?.fee ?? 0,
                        created_at: ""
                    })
                }
            }
        })

        _list.push({
            type: type === "isReturn" ? "return" : "purchase",
            ids: _idList,
            created_at: _createdAt,
            amount: _amount,
            carrier: _carrier,
            service_level: _serviceLevel
        })
    }

    return _list;
}
