import { createSlice } from '@reduxjs/toolkit';
// api
import {
    getCarrierAccountList as getCarrierAccountListAPI,
    activeCarrierAccount as activeCarrierAccountAPI,
    modifyCarrierAccount as modifyCarrierAccountAPI,
    deleteCarrierAccount as deleteCarrierAccountAPI,
    addCarrierBillingAccount as addCarrierBillingAccountAPI,
    modifyCarrierBillingAccount as modifyCarrierBillingAccountAPI,
    deleteCarrierBillingAccount as deleteCarrierBillingAccountAPI,
} from "../../api/carrier";
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    carrierList: [],
    requireMP: false,
    isOpenAddModal: false,
    isOpenEditModal: false,
    isOpenDeleteModal: false,
    isOpenAddFedExModal: false,
};

const slice = createSlice({
    name: 'carrier',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET CARRIER
        getCarrierSuccess(state, action) {
            state.isLoading = false;
            state.carrierList = action.payload;
        },

        // MODIFY CARRIER
        modifyCarrierSuccess(state, action) {
            const { id: carrierId, name } = action.payload;
            const modifiedCarrierList = state.carrierList.map((_carrier) => {
                if (_carrier.id === carrierId) {
                    _carrier.name = name;
                }

                return _carrier;
            });

            state.isLoading = false;
            state.carrierList = modifiedCarrierList;
            state.isOpenEditModal = false;
        },

        // DELETE CARRIER
        deleteCarrierSuccess(state, action) {
            const carrierId = action.payload;
            const deletedCarrierList = state.carrierList.filter((_carrier) => _carrier.id !== carrierId);

            state.isLoading = false;
            state.carrierList = deletedCarrierList;
            state.isOpenDeleteModal = false;
        },

        // ACTIVE / DE-ACTIVE CARRIER
        changeCarrierStatusSuccess(state, action) {
            const { id: carrierId, isActive } = action.payload;
            const modifiedCarrierList = state.carrierList.map((_carrier) => {
                if (_carrier.id === carrierId) {
                    _carrier.is_active = isActive;
                }

                return _carrier;
            });

            state.isLoading = false;
            state.carrierList = modifiedCarrierList;
        },

        // OPEN ADD MODAL
        openAddModal(state) {
            state.isOpenAddModal = true;
        },

        // CLOSE ADD MODAL
        closeAddModal(state) {
            state.isOpenAddModal = false;
            state.isOpenAddFedExModal = false;
            state.isOpenAddDHLexpressModal = false;
            state.isOpenAddDHLecommerceModal = false;
        },

        // OPEN EDIT MODAL
        openEditModal(state) {
            state.isOpenEditModal = true;
        },

        // CLOSE EDIT MODAL
        closeEditModal(state) {
            state.isOpenEditModal = false;
        },

        // OPEN DELETE MODAL
        openDeleteModal(state) {
            state.isOpenDeleteModal = true;
        },

        // CLOSE DELETE MODAL
        closeDeleteModal(state) {
            state.isOpenDeleteModal = false;
        },

        // OPEN ADD FEDEX MODAL
        openAddFedExModal(state) {
            state.isOpenAddFedExModal = true;
        },

        // OPEN ADD DHL MODAL
        openAddDHLexpressModal(state) {
            state.isOpenAddDHLexpressModal = true;
        },

        // OPEN ADD DHL MODAL
        openAddDHLecommerceModal(state) {
            state.isOpenAddDHLecommerceModal = true;
        },

        // UPDATE MULTI PIECE AVAILABILITY
        updateRequireMP(state, action) {
            state.requireMP = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { openAddModal, closeAddModal, openEditModal, closeEditModal, openDeleteModal, closeDeleteModal, openAddFedExModal } = slice.actions;

// ----------------------------------------------------------------------

export function getCarrierList() {
    return async () => {
        try {
            const response = await getCarrierAccountListAPI();

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getCarrierSuccess(data ?? []));

                let domesticMP = false;

                data?.forEach((carrier) => {
                    if (carrier.is_active && carrier.carriers.code === 'UPS') {
                        domesticMP = true;
                    }
                });

                dispatch(slice.actions.updateRequireMP(domesticMP));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

// ----------------------------------------------------------------------

export function changeCarrierStatus(id, isActive) {
    return async () => {
        try {
            const response = await activeCarrierAccountAPI(id, isActive);

            const { status, data } = response;

            if (status === 200 && data?.result === 'success') {
                dispatch(slice.actions.changeCarrierStatusSuccess({ id, isActive }))
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

// ----------------------------------------------------------------------

export function modifyCarrier(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await modifyCarrierAccountAPI(detail);

            const { status, data } = response;

            if (status === 200 && data?.result === 'success') {
                dispatch(slice.actions.modifyCarrierSuccess(detail));

                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    }
}

// ----------------------------------------------------------------------

export function deleteCarrier(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await deleteCarrierAccountAPI(id);

            const { status, data } = response;
            if (status === 200 && data?.result === 'success') {
                dispatch(slice.actions.deleteCarrierSuccess(id));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    }
}

// ----------------------------------------------------------------------

export function addBillAccount(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await addCarrierBillingAccountAPI(detail);

            const { status, data } = response;
            if (status === 200 && data?.result === 'success') {
                dispatch(getCarrierList());

                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    }
}

// ----------------------------------------------------------------------

export function modifyBillAccount(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await modifyCarrierBillingAccountAPI(detail);

            const { status, data } = response;
            if (status === 200 && data?.result === 'success') {
                dispatch(getCarrierList());

                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    }
}

// ----------------------------------------------------------------------

export function deleteBillAccount(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await deleteCarrierBillingAccountAPI(id);

            const { status, data } = response;
            if (status === 200 && data?.result === 'success') {
                dispatch(getCarrierList());

                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    }
}
