import { createSlice } from '@reduxjs/toolkit';
// api
import {
    getPackageList as getPackageListAPI,
    getPredefinedPackageList as getPredefinedPackageListAPI,
    addPackage as addPackageAPI,
    modifyPackage as modifyPackageAPI,
    deletePackage as deletePackageAPI,
} from "../../api/package";
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    isGetPredefinedLoading: false,
    error: null,
    packageList: [],
    allPackageList: [],
    isOpenModal: false,
};

const slice = createSlice({
    name: 'package',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        startGetPredefinedLoading(state) {
            state.isGetPredefinedLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET PACKAGES
        getPackagesSuccess(state, action) {
            state.isLoading = false;
            state.packageList = action.payload;

            // // FOR ADD PACKAGE && MODIFIED PACKAGE
            state.isOpenModal = false;
        },

        // GET ALL PACKAGES
        getAllPackagesSuccess(state, action) {
            state.allPackageList = action.payload;
        },

        // MODIFY PACKAGE
        modifyPackagesSuccess(state, action) {
            const { id: packageId, is_default: isDefault } = action.payload;

            const modifiedPackageList = state.packageList.map((_package) => {
                if (isDefault) _package.is_default = false;

                if (_package.id === packageId) {
                    return {
                        ..._package,
                        ...action.payload,
                    };
                }

                return _package;
            });

            state.isLoading = false;
            state.packageList = modifiedPackageList;
            state.isOpenModal = false;
        },

        // DELETE PACKAGE
        deletePackageSuccess(state, action) {
            state.isLoading = false;
            state.packageList = state.packageList.filter((_package) => `${_package.id}` !== `${action.payload}`) || [];
        },

        // OPEN MODAL
        openModal(state) {
            state.isOpenModal = true;
        },

        // CLOSE MODAL
        closeModal(state) {
            state.isOpenModal = false;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal } = slice.actions;

// ----------------------------------------------------------------------

export function getPackageList() {
    return async () => {
        try {
            const response = await getPackageListAPI();

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getPackagesSuccess(data ?? []));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getAllPackageList() {
    return async () => {
        dispatch(slice.actions.startGetPredefinedLoading());
        try {
            const response = await getPredefinedPackageListAPI(true);

            const { status, data } = response;

            if (status === 200) {
                const resultList = data ?? [];

                let count = 1;
                const tempList = [{
                    /* eslint-disable-next-line no-plusplus */
                    pid: count++,
                    nick_name: 'Custom Package',
                    description: 'Any custom box or thick parcel',
                    type: 'custom_package',
                    code: 'PACKAGE',
                    is_default: false,
                    length: '',
                    width: '',
                    height: '',
                    carrier: 'USER',
                    isSubheader: false,
                }];

                resultList.forEach(r => {
                    tempList.push({
                        ...r,
                        isSubheader: true,
                    });

                    if (r.code === 'MY PACKAGES') {
                        r?.predefined_packages.forEach(p => tempList.push({
                            ...p,
                            /* eslint-disable-next-line no-plusplus */
                            pid: count++,
                            isSubheader: false,
                            type: 'my_package',
                            code: "PACKAGE",
                            description: `${p.length} x ${p.width} x ${p.height} in`,
                            is_default: p.is_default,
                            carrier: 'USER',
                        }));
                    } else {
                        r?.predefined_packages.forEach(p => tempList.push({
                            ...p,
                            /* eslint-disable-next-line no-plusplus */
                            pid: count++,
                            carrier: r.code,
                            isSubheader: false,
                            is_default: false,
                        }));
                    }
                });

                setTimeout(() => dispatch(slice.actions.getAllPackagesSuccess(tempList)), 600);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function addPackage(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await addPackageAPI(detail);

            const { status, data } = response;

            if (status === 200 && data?.result === 'success') {
                dispatch(getPackageList());
                dispatch(getAllPackageList());
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function modifyPackage(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await modifyPackageAPI(detail);

            const { status, data } = response;

            if (status === 200 && data?.result === 'success') {
                dispatch(slice.actions.modifyPackagesSuccess(detail));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function deletePackage(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await deletePackageAPI(id);

            const { status, data } = response;

            if (status === 200 && data?.result === 'success') {
                dispatch(slice.actions.deletePackageSuccess(id));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}
