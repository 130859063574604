import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
// api
import {getStoreAuthURL as getStoreAuthURLAPI} from "../api/store";
// constants
import {PERMISSIONS_ROUTE} from "../constants/permissions";
// hooks
import useAuth from '../hooks/useAuth';
// routes
import {PATH_DASHBOARD} from "../routes/paths";
// redux
import {useDispatch, useSelector} from "../redux/store";
import {setStoreLocation} from "../redux/slices/store";
import {setLocation} from "../redux/slices/permission";
// pages
import Login from '../pages/auth/Login';
// components
import LoadingScreen from '../components/LoadingScreen';
// utils
import {getURLQueryByName} from "../utils/getURLQuery";

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
    children: PropTypes.node,
};

export default function AuthGuard({children}) {
    const {isAuthenticated, isInitialized, checkPermission} = useAuth();

    const dispatch = useDispatch();

    const {pathname, search} = useLocation();

    const {storeList} = useSelector(({store}) => store);
    const {requestedLocation} = useSelector(({permission}) => permission);

    useEffect(() => {
        if (!isAuthenticated && pathname !== requestedLocation) {
            dispatch(setLocation(pathname));
        }
    }, [isAuthenticated, pathname])

    if (!isInitialized) {
        return <LoadingScreen/>;
    }

    if (!isAuthenticated) {
        if (pathname && pathname.includes('/stores/')) {
            if (pathname.includes('shopify-c-redirect')) {
                getStoreAuthURLAPI({
                    platform: "shopify-c",
                    domain: getURLQueryByName('shop'),
                }).then((response) => {
                    const {status, data} = response;

                    if (status === 200 && window && data?.authorize_url) {
                        window.location.href = data.authorize_url;
                    }
                });
            } else {
                dispatch(setStoreLocation(pathname + search));
            }
        }

        return <Login/>;
    }

    const permission = PERMISSIONS_ROUTE[pathname];

    if (permission && !checkPermission(permission)) {
        if (pathname === "/partner-tool" && checkPermission('carrier_referral')) {
            return <Navigate to={ `${ PATH_DASHBOARD.partner.root }` }/>;
        }
        return <Navigate to={ `${ PATH_DASHBOARD.root }` }/>;
    }

    if ((pathname === "/items" || pathname === "/reports") && (storeList && storeList.length === 0)) {
        return <Navigate to={ `${ PATH_DASHBOARD.root }` }/>;
    }

    return <>{ children }</>;
}
