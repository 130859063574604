import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader } from '@mui/material';
// hooks
import useLocales from '../../../hooks/useLocales';
import useAuth from "../../../hooks/useAuth";
// redux
import { batch, useSelector } from "../../../redux/store";
//
import { NavListRoot } from './NavList';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
    ({ theme }) => ({
        ...theme.typography.overline,
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        color: theme.palette.text.primary,
        transition: theme.transitions.create('opacity', {
            duration: theme.transitions.duration.shorter,
        }),
    }),
);

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
    isCollapse: PropTypes.bool,
    navConfig: PropTypes.array,
};

export default function NavSectionVertical({ navConfig, isCollapse = false, ...other }) {
    const { translate } = useLocales();

    const { permissions } = useAuth();

    const { storeList } = useSelector(({ store }) => store);

    const [navList, setNavList] = useState([...navConfig]);

    useEffect(() => {
        if (storeList.length === 0) {
            const tempVanList = navConfig.map(nav => ({
                items: (nav?.items ?? []).filter(_item => (_item.title !== 'items' && _item.title !== 'reports')),
            }));

            setNavList([...tempVanList]);
        } else {
            setNavList([...navConfig]);
        }
    }, [navConfig, storeList]);

    return (
        <Box {...other}>
            {navList.map((group, index) => (
                <List key={group.subheader + index} disablePadding sx={{ px: 2 }}>
                    {group.subheader && (
                        <ListSubheaderStyle
                            sx={{
                                ...(isCollapse && {
                                    opacity: 0,
                                }),
                            }}
                        >
                            {`${translate(group.subheader)}`}
                        </ListSubheaderStyle>
                    )}

                    {group.items.map((list, index) => (
                        <NavListRoot key={list.title + list.path + index} list={list} permission={permissions} isCollapse={isCollapse}/>
                    ))}
                </List>
            ))}
        </Box>
    );
}
