import React from "react";
import { Outlet } from 'react-router-dom';
// @mui
import { Box, Stack } from '@mui/material';

// ----------------------------------------------------------------------

export default function AuthLayout() {
    return (
        <Stack sx={{ minHeight: 1 }}>
            <Outlet/>

            <Box sx={{ flexGrow: 1 }}/>
        </Stack>
    );
}
