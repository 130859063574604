import React from "react";
import PropTypes from 'prop-types';
// @mui
import { Box, Button, Dialog, Stack, Typography } from '@mui/material';
// components
import Image from "../../../../../components/Image";
import Scrollbar from "../../../../../components/Scrollbar";
// hooks
import useLocales from "../../../../../hooks/useLocales";

// ----------------------------------------------------------------------

PayOffBalanceSuccessDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

export default function PayOffBalanceSuccessDialog({ open, onClose }) {
    const { translate } = useLocales();

    return (
        <Dialog fullWidth open={open} onClose={onClose} sx={{ '& .MuiDialog-paper': { maxWidth: '360px' } }}>
            <Box sx={{ py: 5, px: 2 }}>
                <Scrollbar sx={{ "&::-webkit-scrollbar": { display: "none" } }}>
                    <Stack alignItems="center" maxWidth="560px" margin="auto" spacing={3.75}>
                        <Image visibleByDefault src={'/assets/image/system/success.svg'} alt="recharge success" sx={{ width: '134px', height: '100px', margin: 'auto', '& img': { objectFit: 'contain' } }} disabledEffect/>

                        <Stack spacing={1.25} textAlign="center">
                            <Typography variant="h2">{`${translate('dialog_balance_payoff_1')}`}</Typography>

                            <Typography variant="body1" width="100%" maxWidth="450px" margin="auto">
                                {`${translate('dialog_balance_payoff_2')}`}
                            </Typography>
                        </Stack>

                        <Stack direction="row" justifyContent="center" spacing={2.5}>
                            <Button variant="contained" sx={{ minWidth: '200px', px: '10px' }} onClick={onClose}>
                                {`${translate('dialog_balance_payoff_3')}`}
                            </Button>
                        </Stack>
                    </Stack>
                </Scrollbar>
            </Box>
        </Dialog>
    );
}
