import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fPartnerToolChartDate(data) {
    if (!data) return '';

    const dateArray = data.split('-');

    return dateArray[2] ? `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}` : `${dateArray[1]}/${dateArray[0]}`;
}

export function diffToGMT(date) {
    return `${(-date.getTimezoneOffset() < 0 ? '-' : '+') + (Math.abs(date.getTimezoneOffset() / 60) < 10 ? '0' : '') + (Math.abs(date.getTimezoneOffset() / 60))}00`;
}

export function fDate(date) {
    return format(new Date(date), 'dd MMM yyyy');
}

// export function fDateTrail(date) {
//     return format(new Date(date), 'MM/dd');
// }

export function fDateTrail(date) {
    if (!date) return '';

    const dataArray = date.split("-");

    return `${dataArray[1]}/${dataArray[2]}`;
}

export function fDateTransaction(date) {
    return format(new Date(date), 'MMM dd yyyy');
}

export function fDateHistory(date) {
    return format(new Date(date), 'MM/dd/yy');
}

export function fDateShipment(date) {
    return format(new Date(date), 'MM/dd/yyyy');
}

export function fDateStore(date) {
    return format(new Date(date), 'MM/dd/yyyy');
}

export function fDateBilling(date) {
    if (!date) return '';

    const dataArray = date.split("-");

    return `${dataArray[1]}/${dataArray[2]}/${dataArray[0]}`;
}

export function fDateScanFormShip(date) {
    return format(new Date(date), 'MM/dd/yyyy');
}

export function fDateOrderNote(date) {
    return format(new Date(date), 'MM/dd/yyyy');
}

export function fDateLockNotification(date) {
    if (!date) return;
    const tempDate = date.replaceAll('-', '/');
    return format(new Date(tempDate), 'MM/dd/yyyy');
}

export function fDateScanFormCreate(date) {
    return format(new Date(date), 'MM/dd/yyyy p');
}

export function fDateShipmentTime(date) {
    return format(new Date(date), 'p');
}

export function fDateCarrier(date) {
    return format(new Date(date), 'yyyyMMdd');
}

export function fDateBuyLabelPeriodEnd(date) {
    if (!date) return `XX/XX/XXXX`;

    return format(new Date(date), 'MM/dd/yyyy');
}

export function fDatePlan(date) {
    if (!date) return "";

    const tempDate = date.replaceAll('-', '/');
    return format(new Date(tempDate), 'PP');
}

export function fDatePlanLong(date) {
    if (!date) return "";

    return format(new Date(date), 'MMMM dd, Y');
}

export function fDatePlanShort(date) {
    if (!date) return "";

    return format(new Date(date), 'MMMM dd');
}

export function fDateTime(date) {
    return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fTimestamp(date) {
    return getTime(new Date(date));
}

export function fDateTimeSuffixE(date) {
    return format(new Date(date), 'MM/dd/yyyy');
}

export function fDateTimeSuffix(date) {
    return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fDateTimeSuffixS(date) {
    return format(new Date(date), 'MM/dd/yyyy p');
}

export function fToNow(date) {
    return formatDistanceToNow(new Date(date), {
        addSuffix: true,
    });
}

export function fDateNotificationTime(date) {
    return format(new Date(date * 1000), 'MMM dd, p yyyy');
}

export function fDateScanFormShipDate(date) {
    return format(new Date(date), 'yyyy-MM-dd');
}

export function fDateOrderCombine(date) {
    return format(new Date(date), 'MM/dd');
}
