export const CONTENT_OPTIONS = [
    { key: 'MERCHANDISE', name: 'shipments_create_4_4_1' },
    { key: 'DOCUMENTS', name: 'shipments_create_4_4_2' },
    { key: 'RETURNED_GOODS', name: 'shipments_create_4_4_3' },
    { key: 'GIFT', name: 'shipments_create_4_4_4' },
    { key: 'SAMPLE', name: 'shipments_create_4_4_5' },
];

export const WEIGHT_UNIT_OPTIONS = [
    { key: 'lb', name: 'lb' },
    { key: 'oz', name: 'oz' },
    { key: 'kg', name: 'kg' },
    { key: 'g', name: 'g' },
];

export const NON_DELIVERY_OPTIONS = [
    { key: 'RETURN', name: 'shipments_create_4_5_1' },
    { key: 'ABANDON', name: 'shipments_create_4_5_2' },
];

export const EXEMPTION_CODE_OPTIONS = [
    { key: "30.2(d)(1)", name: "NOEEI 30.2(d)(1)" },
    { key: "30.2(d)(2)", name: "NOEEI 30.2(d)(2)" },
    { key: "30.2(d)(4)", name: "NOEEI 30.2(d)(4)" },
    { key: "30.26(a)", name: "NOEEI 30.26(a)" },
    { key: "30.30(j)", name: "NOEEI 30.30(j)" },
    { key: "30.36", name: "NOEEI 30.36" },
    { key: "30.37(a)", name: "NOEEI 30.37(a)" },
    { key: "30.37(b)", name: "NOEEI 30.37(b)" },
    { key: "30.37(c)", name: "NOEEI 30.37(c)" },
    { key: "30.37(d)", name: "NOEEI 30.37(d)" },
    { key: "30.37(e)", name: "NOEEI 30.37(e)" },
    { key: "30.37(f)", name: "NOEEI 30.37(f)" },
    { key: "30.37(g)", name: "NOEEI 30.37(g)" },
    { key: "30.37(h)", name: "NOEEI 30.37(h)" },
    { key: "30.37(i)", name: "NOEEI 30.37(i)" },
    { key: "30.37(j)", name: "NOEEI 30.37(j)" },
    { key: "30.37(k)", name: "NOEEI 30.37(k)" },
    { key: "30.37(l)", name: "NOEEI 30.37(l)" },
    { key: "30.37(m)", name: "NOEEI 30.37(m)" },
    { key: "30.37(n)", name: "NOEEI 30.37(n)" },
    { key: "30.37(o)", name: "NOEEI 30.37(o)" },
    { key: "30.37(p)", name: "NOEEI 30.37(p)" },
    { key: "30.37(q)", name: "NOEEI 30.37(q)" },
    { key: "30.37(r)", name: "NOEEI 30.37(r)" },
    { key: "30.37(s)", name: "NOEEI 30.37(s)" },
    { key: "30.37(t)", name: "NOEEI 30.37(t)" },
    { key: "30.37(u)", name: "NOEEI 30.37(u)" },
    { key: "30.37 (v)", name: "NOEEI 30.37 (v)" },
    { key: "30.37 (w)", name: "NOEEI 30.37 (w)" },
    { key: "30.37(x)", name: "NOEEI 30.37(x)" },
    { key: "30.37(y)", name: "NOEEI 30.37(y)" },
    { key: "30.37(y)(1)", name: "NOEEI 30.37(y)(1)" },
    { key: "30.37(y)(2)", name: "NOEEI 30.37(y)(2)" },
    { key: "30.37(y)(3)", name: "NOEEI 30.37(y)(3)" },
    { key: "30.37(y)(4)", name: "NOEEI 30.37(y)(4)" },
    { key: "30.37(y)(5)", name: "NOEEI 30.37(y)(5)" },
    { key: "30.37(y)(6)", name: "NOEEI 30.37(y)(6)" },
    { key: "30.39", name: "NOEEI 30.39" },
    { key: "30.40(a)", name: "NOEEI 30.40(a)" },
    { key: "30.40(b)", name: "NOEEI 30.40(b)" },
    { key: "30.40(c)", name: "NOEEI 30.40(c)" },
    { key: "30.40(d)", name: "NOEEI 30.40(d)" },
    { key: "30.8(b)", name: "NOEEI 30.8(b)" }
];

export const EEL_PFC_OPTIONS = [
    { key: "EXEMPTION_CODE", name: "shipments_create_4_7_2" },
    { key: "ITN", name: "shipments_create_4_7_3" },
];

export const TAX_ID_OPTIONS = [
    { key: "TIN", name: "TIN" },
    { key: "EIN", name: "EIN" },
    { key: "SSN", name: "SSN" },
    { key: "VAT", name: "VAT" },
    { key: "EORI", name: "EORI" },
    { key: "IOSS", name: "IOSS" },
    { key: "PAN", name: "PAN" },
];

export const UNIT_OPTIONS = [
    { key: 'lb', name: 'lb' },
    { key: 'oz', name: 'oz' },
    { key: 'kg', name: 'kg' },
    { key: 'g', name: 'g' },
];

export const INSURANCE_OPTIONS = [
    { key: 'NONE', name: 'shipments_create_5_3_1_1' },
    { key: 'CARRIER', name: 'shipments_create_5_3_1_2' },
    { key: 'SHIPSURANCE', name: 'Shipsurance' },
];

export const SIGNATURE_OPTIONS = [
    { key: 'NO_SIGNATURE', name: 'shipments_create_5_3_3_1' },
    { key: 'SIGNATURE', name: 'shipments_create_5_3_3_2' },
    { key: 'ADULT_SIGNATURE', name: 'shipments_create_5_3_3_3' },
];

export const RETURN_TYPE_OPTIONS = [
    { key: 'UPS_PRINT_RETURN_LABEL', name: 'shipments_create_5_5_3'},
    { key: 'UPS_ELECTRONIC_RETURN_LABEL', name: 'shipments_create_5_5_4'},
    { key: 'UPS_PICKUP_1_ATTEMPT', name: 'shipments_create_5_5_5'},
    { key: 'UPS_PICKUP_3_ATTEMPT', name: 'shipments_create_5_5_6'},
]

export const LABEL_SIZE_OPTIONS = [
    {
        size: '8.5x11" - 1 Shipping Label per Page - Right Side',
        desc: 'One 4x6" label on the right side of a letter-sized page',
        value: {
            size: '8.5*11',
            position: 'RIGHT'
        },
        img: '/assets/icon/shipments/ic_8x115_right.svg'
    },
    {
        size: '8.5x11" - 1 Shipping Label per Page - Left Side',
        desc: 'One 4x6" label on the left side of a letter-sized page',
        value: {
            size: '8.5*11',
            position: 'LEFT'
        },
        img: '/assets/icon/shipments/ic_8x115_left.svg'
    },
    {
        size: '4x6” Shipping Label',
        desc: 'Formatted for Thermal Label Printers',
        value: {
            size: '4*6',
            position: null
        },
        img: '/assets/icon/shipments/ic_4x6.svg'
    },
    {
        size: '4x8” Shipping Label',
        desc: 'Formatted for Thermal Label Printers',
        value: {
            size: '4*8',
            position: null
        },
        img: '/assets/icon/shipments/ic_4x6.svg'
    },
];

export default {
    CONTENT_OPTIONS,
    WEIGHT_UNIT_OPTIONS,
    NON_DELIVERY_OPTIONS,
    EXEMPTION_CODE_OPTIONS,
    EEL_PFC_OPTIONS,
    TAX_ID_OPTIONS,
    UNIT_OPTIONS,
    INSURANCE_OPTIONS,
    SIGNATURE_OPTIONS,
    LABEL_SIZE_OPTIONS
}
