// utils
import axios from '../utils/axios';

// ----------------------------------------------------------------------

const getItemList = (filter) => axios.post('/items/list', {
    store_id: filter?.storeId === 'all' ? null : (filter?.storeId || null),
    // eslint-disable-next-line no-nested-ternary
    keyword: filter?.keyword === 'all' ? null : (filter?.keyword ? filter.keyword.trim() : null),
    per_page: filter?.rowsPerPage || 25,
    current_page: filter?.page || 0,
    order_by: [{
        name: "created_at",
        sorting: filter?.sort || "desc"
    }]
});

const getItem = (id) => axios.get(`/items/info?id=${id}`, {});

// ----------------------------------------------------------------------------

const addItem = (data) => axios.post('/items/add', { ...data });


const modifyItem = (data) => axios.post('/items/modify', { ...data });


const deleteItem = (ids) => axios.delete(`/items/delete`, {
    data: {
        ids
    }
});

// ---------------------------------------------------------------------------------

export {
    getItemList,
    getItem,
    addItem,
    modifyItem,
    deleteItem,
}
