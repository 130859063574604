import PropTypes from 'prop-types';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { List, Collapse } from '@mui/material';
// guards
import PermissionGuard from "../../../guards/PermissionGuard";
//
import { NavItemRoot, NavItemSub } from './NavItem';
import { getActive } from '..';

// ----------------------------------------------------------------------

NavListRoot.propTypes = {
    list: PropTypes.object,
    permission: PropTypes.array,
    isCollapse: PropTypes.bool,
};

export function NavListRoot({ list, permission, isCollapse }) {
    const { pathname } = useLocation();

    const active = getActive(list.path, pathname);

    const [open, setOpen] = useState(active);

    const hasChildren = list.children;

    if (hasChildren) {
        let isDisplay = false;

        list.children.forEach(_item => {
            if (isDisplay) return;

            const grantedPermission = _item.key === 'default' ? 'default' : (permission || []).find(_p => _p === _item.key);

            if (grantedPermission) isDisplay = true;
        });

        return isDisplay ? (
            <>
                <NavItemRoot item={list} isCollapse={isCollapse} active={active} open={open} onOpen={() => setOpen(!open)}/>

                {!isCollapse && (
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {(list.children || []).map((_item) => (
                                <PermissionGuard key={_item.title + _item.path} permission={_item.key}>
                                    <NavListSub list={_item}/>
                                </PermissionGuard>
                            ))}
                        </List>
                    </Collapse>
                )}
            </>
        ) : null;
    }

    return (
        <PermissionGuard permission={list.key}>
            <NavItemRoot item={list} active={active} isCollapse={isCollapse}/>
        </PermissionGuard>
    );
}

// ----------------------------------------------------------------------

NavListSub.propTypes = {
    list: PropTypes.object,
};

function NavListSub({ list }) {
    const { pathname } = useLocation();

    const active = getActive(list.path, pathname);

    const [open, setOpen] = useState(active);

    const hasChildren = list.children;

    if (hasChildren) {
        return (
            <>
                <NavItemSub item={list} onOpen={() => setOpen(!open)} open={open} active={active}/>

                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ pl: 3 }}>
                        {(list.children || []).map((item) => (
                            <NavItemSub key={item.title + item.path} item={item} active={getActive(item.path, pathname)}/>
                        ))}
                    </List>
                </Collapse>
            </>
        );
    }

    return <NavItemSub item={list} active={active}/>;
}
