// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
    demo: {
        title: `English`,
        introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
    },
    docs: {
        hi: `Hi`,
        description: `Need help? \n Please check our docs.`,
        documentation: `documentation`,
    },
    app: `app`,
    user: `user`,
    list: `list`,
    edit: `edit`,
    shop: `shop`,
    blog: `blog`,
    post: `post`,
    cards: `cards`,
    posts: `posts`,
    create: `create`,
    general: `general`,
    banking: `banking`,
    booking: `booking`,
    profile: `profile`,
    product: `product`,
    invoice: `invoice`,
    details: `details`,
    checkout: `checkout`,
    calendar: `calendar`,
    analytics: `analytics`,
    management: `management`,
    menu_level_1: `menu level 1`,
    menu_level_2: `menu level 2`,
    menu_level_3: `menu level 3`,
    item_disabled: `item disabled`,
    item_label: `item label`,
    item_caption: `item caption`,
    description: `description`,
    other_cases: `other cases`,
    item_by_roles: `item by roles`,
    only_admin_can_see_this_item: `Only admin can see this item`,
    // ----------------------------------------------------------------------
    header_balance: `Balance`,
    // ----------------------------------------------------------------------
    option_default: `All`,
    // Settings Transaction Type
    option_recharge: `Recharge`,
    option_bank_account: `Recharge`,
    option_credit_card: `Recharge`,
    option_offline: `Recharge`,
    option_alipay: `Recharge`,
    option_wechatpay: `Recharge`,
    option_purchase: `Purchase`,
    option_refund: `Refund`,
    option_void: `Void`,
    option_adjustment: `Adjustment`,
    // option_zelle_status
    option_pending: `Pending`,
    option_declined: `Declined`,
    // option_credit_card: `Credit Card`,
    option_cancel: `Void`,
    // Settings Payment Type
    option_card: `Credit Card`,
    option_balance: `Account Balance`,
    // Shipment My Shipment Tracking
    option_created: `Label Created`,
    option_in_transit: `In Transit`,
    option_out_for_delivery: `Out for Delivery`,
    option_delivered: `Delivered / Pickup Ready`,
    option_unsuccessful: `Unsuccessful`,
    option_unknown: `Unknown`,
    // Shipment My Pickups Status
    option_confirmed: `Confirmed`,
    option_canceled: `Canceled`,
    // Shipment Pickups Location
    option_front_door: 'Front door',
    option_back_door: `Back Door`,
    option_side_door: `Side Door`,
    option_knock_on_door_ring_bell: `Knock On Door Ring Bell`,
    option_mail_room: `Mail Room`,
    option_office: `Office`,
    option_reception: `Reception`,
    option_in_at_mail_box: `In At Mail Box`,
    option_other: `Other (additional instructions required)`,
    // Package Type
    option_subheader_MY_PACKAGES: `MY PACKAGES`,
    option_subheader_USPS: `USPS`,
    option_subheader_UPS: `UPS`,
    option_subheader_ONTRAC: `ONTRAC`,
    option_subheader_FedEx: `FEDEX`,
    option_custom_package_name: `Custom Package`,
    option_custom_package_desc: `Any custom box or thick parcel`,
    // Billing Status
    option_current: `Current`,
    option_expired: `Expired`,
    option_changed: `Changed`,
    option_depleted: `Depleted`,
    // Order Shipping Area
    option_domestic: `Domestic`,
    option_international: `International`,
    // Order Store Status
    option_order_awaiting_payment: `Awaiting Payment`,
    option_order_awaiting: `Awaiting`,
    option_order_shipped: `Shipped`,
    option_order_canceled: `Canceled`,
    option_order_cancelled: `Canceled`,
    // ACH Business Type
    option_individual: `Individual`,
    option_company: `Company`,
    // Order Date
    option_all_time: `All Time`,
    option_today: `Today`,
    option_last24h: `Last 24 Hours`,
    option_last7d: `Last 7 days`,
    option_last30d: `Last 30 days`,
    option_last45d: `Last 45 days`,
    option_this1m: `This month`,
    option_last1m: `Last Month`,
    option_custom: `Custom`,
    // Label Size
    option_4x6: `4x6"`,
    option_4x8: `4x8"`,
    option_8_5x11_r: `8.5x11" - Right Side`,
    option_8_5x11_l: `8.5x11" - Left Side`,
    // Residential type
    option_residential: `Residential`,
    option_commercial: `Commercial`,
    // Label Printing Option
    option_ask: `Ask`,
    option_label: `Label`,
    option_qr_code: `QR Code/Barcode`,
    // Started Shipping
    option_started_shipping_true: `Yes`,
    option_started_shipping_false: `No`,
    // ----------------------------------------------------------------------
    dashboard: `dashboard`,
    shipments: `shipments`,
    orders: `orders`,
    billing: `billing`,
    items: `items`,
    reports: `reports`,
    settings: `settings`,
    account: `account`,
    integration: `integration`,
    configuration: `configuration`,
    copied: `copied`,
    logout: `logout`,
    // ----------------------------------------------------------------------
    auth_login_1: `Sign in to ShipSaving`,
    auth_login_2: `Enter your details below.`,
    auth_login_3: `Email address`,
    auth_login_4: `Password`,
    auth_login_5: `Remember me`,
    auth_login_6: `Forgot password?`,
    auth_login_7: `Login`,
    auth_login_8: `Don’t have an account?`,
    auth_login_9: `Get started`,
    // ----------------------------------------------------------------------
    auth_signup_1: `Create your account`,
    auth_signup_2: `One step between you and discounted shipping labels.`,
    auth_signup_12: ``,
    auth_signup_12_1: `Starter (Free plan)`,
    auth_signup_12_2: `Standard (Paid Plan, 14-day Free Trial)`,
    auth_signup_3: `First name`,
    auth_signup_4: `Last name`,
    auth_signup_5: `Email address`,
    auth_signup_6: `Password`,
    auth_signup_13: `Company (optional)`,
    auth_signup_7_1: `At least 8 letters and at most 20 letters`,
    auth_signup_7_2: `At least 1 Uppercase, 1 Lowercase, 1 Number or 1 Symbol Letter`,
    auth_signup_7_3: `Do not contain exact same letters before @ of email`,
    auth_signup_7_4: `Do not contain more than 3 consecutive or repeating numbers / letters`,
    auth_signup_14: `Confirm Password`,
    auth_signup_15: `Offer Code (optional)`,
    auth_signup_16: `Set up your Ship-From address`,
    auth_signup_17: `Specify your default Ship From address for rate calculating and schedule pickups.`,
    auth_signup_18: `Phone`,
    auth_signup_19: `Street 1`,
    auth_signup_20: `Street 2 (optional)`,
    auth_signup_21: `City`,
    auth_signup_22: `State`,
    auth_signup_23: `Zipcode`,
    auth_signup_8_1: `By clicking on Create Account below, you agree to the ShipSaving `,
    auth_signup_8_2: `Terms`,
    auth_signup_8_3: ` and `,
    auth_signup_8_4: `Privacy Policy`,
    auth_signup_8_5: `. ShipSaving prohibits any form of fraudulent activities.`,
    auth_signup_8_6: `Please be advised that our platform strictly prohibits any form of fraudulent activities. To ensure the rights and interests of all users, please ensure your actions are lawful and honest.`,
    auth_signup_9: `Create Account`,
    auth_signup_10: `Already have an account?`,
    auth_signup_11: `Login`,
    // ----------------------------------------------------------------------
    auth_signup_partner_1: `Create Your Partner Account`,
    auth_signup_partner_2: `Join our Affiliate Program, share your exclusive referral code, and help your network thrive with ShipSaving.`,
    // ----------------------------------------------------------------------
    auth_forgot_password_1: `Forgot your password?`,
    auth_forgot_password_2: `Please enter the email address associated with your account, and we'll email you a link to reset your password.`,
    auth_forgot_password_3: `Email address`,
    auth_forgot_password_4: `Reset Password`,
    auth_forgot_password_5: `Back`,
    // ----------------------------------------------------------------------
    auth_reset_password_1: `Create a new password`,
    auth_reset_password_2: `Enter a new password to reset the password on your account.`,
    auth_reset_password_3: `New Password`,
    auth_reset_password_4_1: `At least 8 letters and at most 20 letters`,
    auth_reset_password_4_2: `At least 1 Uppercase, 1 Lowercase, 1 Number or 1 Symbol Letter`,
    auth_reset_password_4_3: `Do not contain exact same letters before @ of email`,
    auth_reset_password_4_4: `Do not contain more than 3 consecutive or repeating numbers / letters`,
    auth_reset_password_5: `Confirm Password`,
    auth_reset_password_6: `Reset Password`,
    auth_reset_password_7: `Back`,
    // ----------------------------------------------------------------------
    navbar_1_1: `Upgrade to `,
    navbar_1_2: `PRO`,
    navbar_2: `Get access to the powerful automation workflows, free WMS and other advanced features.`,
    navbar_3: `Try Now`,
    navbar_4: `SHIPSAVING NEWSROOM`,
    navbar_5: `Download the ShipSaving App now and enjoy unparalleled convenience in managing your packages.`,
    navbar_6: `https://apps.apple.com/us/app/shipsaving-ship-save/id6502415198`,
    // ----------------------------------------------------------------------
    header_alert_adjustment_fee_1: `您的账户因未成功支付运费调整费而余额不足。导致账户暂时锁定。请确认您的支付方式并补缴相关费用以解锁账户。`,
    header_alert_adjustment_fee_2: `Your account is temporarily locked due to an unsuccessful payment of this shipping adjustment fee, resulting in insufficient funds. Please update your payment method and pay the required fee to unlock your account.`,
    header_alert_adjustment_fee_3: `Pay Now`,
    // ----------------------------------------------------------------------
    dashboard_1_1: `Hi`,
    dashboard_1_2: `WELCOME TO SHIPSAVING!`,
    dashboard_2: `Send a package can be more simple and more productive.`,
    dashboard_3_1_1: `Quick Rate`,
    dashboard_3_1_2: `Use our shipping rate calculator to estimate your savings in minutes!`,
    dashboard_3_2_1: `Buy a Label`,
    dashboard_3_2_2: `Create a shipment in 2 simple steps. Save up to 89% off from your favorite carriers.`,
    dashboard_3_3_1: `Connect a Store`,
    dashboard_3_3_2: `Connect your sales channels in no-time. All orders, in one place.`,
    dashboard_4_1_1: `Recent Shipments`,
    dashboard_4_1_2: `View all`,
    dashboard_4_2_1: `Shipment ID`,
    dashboard_4_2_2: `Recipient`,
    dashboard_4_2_3: `Rate`,
    dashboard_4_2_4: `Tracking Status`,
    dashboard_4_2_5: `Upload Status`,
    dashboard_5_1: `Let's get started`,
    dashboard_5_2: `New to ShipSaving? Here are a few tips before you dive into our system.`,
    dashboard_5_3_1: `What is ShipSaving?`,
    dashboard_5_3_2: `How to ship your first package?`,
    dashboard_5_3_3: `How to connect your store to ShipSaving?`,
    dashboard_5_3_4: `How to set your Ship From Address?`,
    dashboard_5_3_5: `How to save a package template?`,
    dashboard_5_3_6: `How to connect your UPS account?`,
    dashboard_5_4: `See more tutorials`,
    dashboard_5_5: `Here are a few tips to ship like a pro with ShipSaving.`,
    dashboard_5_6: `Tutorials.`,
    dashboard_6_1: `Your ShipSaving account is temporarily unable to buy labels. Please `,
    // dashboard_6_2: `Plan page`,
    dashboard_6_3: `update your payment method first.`,
    dashboard_6_4: `Update Payment Method`,
    // ----------------------------------------------------------------------
    tutorial_store_1: `Connect a Store`,
    tutorial_store_2: `To import your orders from your stores, you first need to connect them to ShipSaving.`,
    tutorial_store_3: `Got it`,
    // ----------------------------------------------------------------------
    quickrate_1: `Quick Rate`,
    quickrate_2_1: `Ship Within The U.S.`,
    quickrate_2_2: `Ship International`,
    quickrate_3_1: `Ship From`,
    quickrate_3_1_1: `Zipcode`,
    quickrate_3_1_2: `Select Country`,
    quickrate_3_1_3: `City`,
    quickrate_3_2: `Ship To`,
    quickrate_3_2_1: `Zipcode`,
    quickrate_3_2_2: `Select Country`,
    quickrate_3_2_3: `City`,
    quickrate_4: `Package Type`,
    quickrate_4_1: `Default`,
    quickrate_5: `Dimension & Weight`,
    quickrate_5_1_1: `Length (in)`,
    quickrate_5_1_2: `Width (in)`,
    quickrate_5_1_3: `Height (in)`,
    quickrate_5_2: `Weight`,
    quickrate_6: `Get Rate`,
    quickrate_7: `Sorry, we're temporarily unable to get rates for you. Please confirm that all the above information has been filled out, or contact our customer service for further assistance.`,
    quickrate_7_1: `The selected package's shipping services are only available with our Standard plan.`,
    quickrate_8_1: `Cheapest`,
    quickrate_8_1_1: ` Business Day(s)`,
    quickrate_8_1_1_1: ` Day(s)`,
    quickrate_8_1_2: ` Retail`,
    quickrate_8_1_3: `No Tracking#`,
    quickrate_8_1_4: `Free Tracking#`,
    quickrate_8_1_12: `Claims Supported`,
    quickrate_8_1_13: `UPS Claims Supported / No claims with USPS`,
    quickrate_8_1_9: `User's Carrier`,
    quickrate_8_1_10_1: ``,
    quickrate_8_1_10_2: ` Insurance Included`,
    quickrate_8_1_11: `, UPS-Only Coverage`,
    quickrate_8_1_5: `Content restrictions apply.`,
    quickrate_8_1_6: `Learn more`,
    quickrate_8_1_7: `USPS First-Class Mail Letters and Large Envelopes/Flats are not eligible for refunds if the label is unused.`,
    quickrate_8_1_8: `ShipSaving provides no after-service for FedEx Ground Economy due to carrier limitations.`,
    quickrate_8_1_14_1: `Please note this package `,
    quickrate_8_1_14_2: `must be tendered directly to UPS. `,
    quickrate_8_1_14_3: `Find a convenient UPS drop off point to ship this package on `,
    quickrate_8_1_14_4: `Find Locations | UPS - United States`,
    quickrate_8_1_14_5: `.`,
    quickrate_8_2: `More Services`,
    quickrate_8_3: `Rate Result`,
    quickrate_8_4: `Buy a Label`,
    quickrate_8_4_1: `The following `,
    quickrate_8_4_2: `shipping fees don't include customs duties, taxes, or brokerage fees`,
    quickrate_8_4_3: `. Please be aware ShipSaving is a third-party shipping platform and we don't oversee customs clearance processes.`,
    quickrate_8_5: `Save up to 89% off`,
    quickrate_8_6: `Save up to 76% off`,
    quickrate_8_7: `Base on your own carrier discounts`,
    quickrate_8_8: `Residential and fuel surcharges waived`,
    quickrate_9_1: `Content Type`,
    quickrate_9_2: `Documents`,
    quickrate_9_3: `Non Documents`,
    quickrate_10_3: ` • Saturday Delivery`,
    // ----------------------------------------------------------------------
    shipments_create_0: `Back to Previous Page`,
    shipments_create_1: `Step 1 of 2 Create New Label`,
    shipments_create_2: `Ship To`,
    shipments_create_2_14: `Paste Address`,
    shipments_create_2_14_1: `Paste the full or partial address here and edit as needed`,
    shipments_create_2_0_1: `Edit Address`,
    shipments_create_2_0_2: `Hide Address`,
    shipments_create_2_1: `Name`,
    shipments_create_2_2: `Company (optional)`,
    shipments_create_2_3: `Email (optional)`,
    shipments_create_2_3_1: `*Send shipping confirmation email to recipient. `,
    shipments_create_2_3_2: `View template`,
    shipments_create_2_3_3: `OK`,
    shipments_create_2_4: `Phone (optional for domestic)`,
    shipments_create_2_5: `Street 1`,
    shipments_create_2_6: `Street 2 (optional)`,
    shipments_create_2_7: `City`,
    shipments_create_2_8: `State`,
    shipments_create_2_9: `Zipcode`,
    shipments_create_2_10: `Select Country`,
    shipments_create_2_11_1: `Residential address`,
    shipments_create_2_11_2: `(Check the box if this is a residential address)`,
    shipments_create_2_12: `First Name`,
    shipments_create_2_13: `Last Name`,
    shipments_create_3: `Ship From`,
    shipments_create_3_1_1: `Don't use saved Ship From Address`,
    shipments_create_3_1_2: `Create new Ship From Address`,
    shipments_create_3_1_3: `Default`,
    shipments_create_3_2: `Name`,
    shipments_create_3_2_1: `First Name`,
    shipments_create_3_2_2: `Last Name`,
    shipments_create_3_3: `Company (optional)`,
    shipments_create_3_4: `Email (optional)`,
    shipments_create_3_5: `Phone (optional for domestic)`,
    shipments_create_3_6: `Street 1`,
    shipments_create_3_7: `Street 2 (optional)`,
    shipments_create_3_8: `City`,
    shipments_create_3_9: `State`,
    shipments_create_3_10: `Zipcode`,
    shipments_create_3_11: `Select Country`,
    shipments_create_3_12_1: `Save Ship From Address`,
    shipments_create_3_12_2: `(Save this address for future use)`,
    shipments_create_4: `Custom Form`,
    shipments_create_4_1: `Learn more about `,
    shipments_create_4_1_1: `how to fill out a customs form`,
    shipments_create_4_2_0: `Customs Information`,
    shipments_create_4_2: `Add Item`,
    shipments_create_4_2_1: `Custom Item`,
    shipments_create_4_3: `Signing Person`,
    shipments_create_4_4: `Content Type`,
    shipments_create_4_4_1: `Merchandise`,
    shipments_create_4_4_2: `Documents`,
    shipments_create_4_4_3: `Returned Merchandise`,
    shipments_create_4_4_4: `Gift`,
    shipments_create_4_4_5: `Sample`,
    shipments_create_4_5: `If Undeliverable`,
    shipments_create_4_5_1: `Return to Sender`,
    shipments_create_4_5_2: `Abandon`,
    shipments_create_4_6: `EEl / PFC & Tax ID`,
    shipments_create_4_7: `EEL / PFC (optional)`,
    shipments_create_4_7_1: `None`,
    shipments_create_4_7_2: `Exemption Code`,
    shipments_create_4_7_3: `ITN`,
    shipments_create_4_8: `Exemption Type`,
    shipments_create_4_9: `ITN Code`,
    shipments_create_4_10: `Tax ID (optional)`,
    shipments_create_4_10_1: `None`,
    shipments_create_4_11: `Number`,
    shipments_create_4_12: `Custom Form #`,
    shipments_create_4_12_1: `Remove`,
    shipments_create_4_13: `Item (please be descriptive of the item, including the use and what it’s made of)`,
    shipments_create_4_13_1: `*Please ensure your package does not contain `,
    shipments_create_4_13_2: `prohibited or restricted items`,
    shipments_create_4_13_3: ` to avoid potential legal issues.`,
    shipments_create_4_14: `Quantity`,
    shipments_create_4_15: `Unit Price in ($)`,
    shipments_create_4_16: `Harmonization # (Required for ship to EU countries)`,
    shipments_create_4_17: `Select Item Origin`,
    shipments_create_4_18: `Find My Harmonization Number`,
    shipments_create_5: `Package Info`,
    shipments_create_5_1: `Package`,
    // shipments_create_5_1_1: `Custom Package`,
    // shipments_create_5_1_2: `Any custom box or thick parcel`,
    // shipments_create_5_1_3: `My Packages`,
    shipments_create_5_1_4: `Default`,
    shipments_create_5_2: `Dimension & Weight`,
    shipments_create_5_2_1: `Length (in)`,
    shipments_create_5_2_2: `Width (in)`,
    shipments_create_5_2_3: `Height (in)`,
    shipments_create_5_2_4: `Weight`,
    shipments_create_5_3: `Shipping Options`,
    shipments_create_5_3_1: `Insurance`,
    shipments_create_5_3_1_1: `None`,
    shipments_create_5_3_1_2: `Carrier`,
    shipments_create_5_3_1_3: `Shipsurance (Per Parcel Limit of Liability: $5,000)`,
    shipments_create_5_3_2: `Declared Package Value`,
    shipments_create_5_3_3: `Signature`,
    shipments_create_5_3_3_1_1: `*Please note you will receive a Shipsurance refund only when you void the label`,
    shipments_create_5_3_3_1_2: ` within 24 hours `,
    shipments_create_5_3_3_1_3: `after you create the label. By choosing Shipsurance, I agree to and understand the `,
    shipments_create_5_3_3_1_4: `Shipsurance terms and conditions`,
    shipments_create_5_3_3_1: `No Signature`,
    shipments_create_5_3_3_2: `Signature`,
    shipments_create_5_3_3_3: `Adult Signature`,
    shipments_create_5_3_3_4: `Delivery Confirmation (UPS Only)`,
    shipments_create_5_4: `Saturday Delivery`,
    shipments_create_5_4_1: `(Check this box only if you request UPS delivery on Saturday)`,
    shipments_create_5_5: `Additional Handling`,
    shipments_create_5_5_1: `(For unusual package types like tubes, wooden crates, tires, etc.)`,
    shipments_create_5_5_1_1: `Hazardous Materials`,
    shipments_create_5_5_1_2: `(This shipment contains dangerous goods or hazardous materials `,
    shipments_create_5_5_1_3: `Learn More`,
    shipments_create_5_5_1_4: `)`,
    shipments_create_5_5_2_0: `Return Label`,
    shipments_create_5_5_2: `Return Type`,
    shipments_create_5_5_3: `Print Return Label`,
    shipments_create_5_5_4: `Electronic Return Label`,
    shipments_create_5_5_5: `1 Pickup Attempt`,
    shipments_create_5_5_6: `3 Pickup Attempts`,
    shipments_create_5_5_7: `UPS Return Label Email`,
    shipments_create_5_6: `UPS Carbon Neutral Shipment`,
    shipments_create_5_6_1: `(Perfume, nail polish, hair spray, dry ice, lithium batteries, firearms, lighters, fuels, etc.`,
    shipments_create_5_6_2: `Learn more`,
    shipments_create_5_6_3: `)`,
    shipments_create_5_10_1: `UPS Direct Delivery `,
    shipments_create_5_10_2: `Only ensures your package is delivered directly to the provided residential or commercial address. This service prevents recipients from redirecting packages and UPS drivers from rerouting deliveries when recipients are not available.`,
    shipments_create_5_7: `Sorry, we're temporarily unable to get rates for you. Please confirm that all the above information has been filled out, or contact our customer service for further assistance.`,
    shipments_create_5_8: `Get Rate`,
    shipments_create_5_9_1: `To ensure accurate shipping rate quotes, please be sure to enter the actual weight and dimensions of your package.`,
    shipments_create_5_9_2: `Discrepancies may result in additional adjustment charges.`,
    shipments_create_6: `Step 2 of 2 Label Details`,
    shipments_create_6_1: `Back to Previous Step`,
    shipments_create_7_1: `Ship From`,
    shipments_create_7_2: `Package Details`,
    shipments_create_7_2_1: `Package Type`,
    shipments_create_7_2_2: `Dimensions`,
    shipments_create_7_2_3: `Weight`,
    shipments_create_7_3: `Label Details`,
    shipments_create_7_3_1: `Label Size`,
    shipments_create_7_4: `Customs Form`,
    shipments_create_7_4_1: `Tax ID`,
    shipments_create_7_4_2: `EEl / PFC`,
    shipments_create_8: `Choose a Service`,
    shipments_create_8_1: `Cheapest`,
    shipments_create_8_1_1: ` Business Day(s)`,
    shipments_create_8_1_2: ` Retail`,
    shipments_create_8_1_3: `No Tracking`,
    shipments_create_8_1_4: `Free Tracking`,
    shipments_create_8_1_5: `Content restrictions apply.`,
    shipments_create_8_1_6: `Learn more`,
    shipments_create_8_1_7: `USPS First-Class Mail Letters and Large Envelopes/Flats are not eligible for refunds if the label is unused.`,
    shipments_create_8_2: `More Services`,
    shipments_create_8_3: `Popular`,
    shipments_create_8_3_1: `Most Popular & Cheapest`,
    shipments_create_8_4_1: `Save up to 89% off`,
    shipments_create_8_4_2: `Save up to 76% off`,
    shipments_create_8_4_3: `Base on your own carrier discounts`,
    shipments_create_9: `Ship Date`,
    shipments_create_9_1: `🧐 Why can’t I see other shipping services? `,
    shipments_create_9_2: `Learn more`,
    shipments_create_10: `Rate Details`,
    shipments_create_10_1: `Shipping Label`,
    shipments_create_10_1_1: `Additional fees include`,
    shipments_create_10_1_1_1: `Insurance`,
    shipments_create_10_1_2: `Estimated delivery time`,
    shipments_create_10_1_2_1: ` Business Day(s)`,
    shipments_create_10_2: `Shipment Insurance`,
    shipments_create_10_2_1: `Shipsurance`,
    shipments_create_10_3: `Amount total`,
    shipments_create_10_3_1: `This may not be your final price. `,
    shipments_create_10_3_2: `Learn more`,
    shipments_create_10_3_3: `Shipping rates are determined by the size and the weight of your shipment. `,
    shipments_create_10_3_3_1: `A Shipping Adjustment`,
    shipments_create_10_3_3_2: ` is when an incorrect shipping label is generated due to inaccurately inputted shipment information, causing a shipping carrier to readjust the shipping information to get the package to its final destination and ensure correct shipping costs are paid.`,
    shipments_create_10_3_3_3: `In most cases, the carrier will double-check the shipping label details of your package, as well as the actual package sent, and charge you additional fees for any inaccuracies found.`,
    shipments_create_10_3_3_4: `Here are the 5 major reasons for shipping adjustments:`,
    shipments_create_10_3_3_5: `Package weight`,
    shipments_create_10_3_3_6: `Package dimensions`,
    shipments_create_10_3_3_7: `Non-matching Ship from/to Zipcode`,
    shipments_create_10_3_3_8: `Incorrect package type`,
    shipments_create_10_3_3_9: `Incorrect service type`,
    shipments_create_10_3_3_10: `How to avoid Shipping Adjustments?`,
    shipments_create_10_3_3_11: `Be sure to enter your shipment's package details so you can get the best, most accurate shipping rates and avoid costly adjustments.`,
    shipments_create_10_3_3_12: `The bill from the carriers determines the final price for a package.`,
    shipments_create_10_3_3_13: ` We will return credit to your account balance or charge an additional fee from your payment method depending on the bill.`,
    shipments_create_10_3_3_14: `OK`,
    shipments_create_11: `Payment Methods`,
    shipments_create_11_1_1: `Label purchasing supports mixed payment mode. ShipSaving will charge your default credit card for the remaining amount when your account balance is low. The final record can be viewed on your `,
    shipments_create_11_1_2: `Transaction History page`,
    shipments_create_11_1_3: `.`,
    shipments_create_11_2: `This label will bill to your own UPS account`,
    shipments_create_11_3: `Pay by Balance`,
    shipments_create_11_3_1: `(default)`,
    shipments_create_11_3_2: `Current Balance`,
    shipments_create_11_4: `Pay by Card`,
    shipments_create_11_4_1: `ending in`,
    shipments_create_11_4_2: `Add New Card`,
    shipments_create_11_4_3: `You have not added any cards yet.\nTo make transaction please add a card.`,
    shipments_create_11_5: `Payment Total`,
    shipments_create_12_1: `Save Draft For Later`,
    shipments_create_12_2: `Save Draft`,
    shipments_create_13_1: `Cancel & Delete Label`,
    shipments_create_13_2: `Cancel Label`,
    shipments_create_14: `Buy Label`,
    shipments_create_15: `No valid credit card to complete the payment, please go to the `,
    shipments_create_15_1: `Billing page`,
    shipments_create_15_2: ` to manage your payment method.`,
    shipments_create_16: `Verify Your Payment Method`,
    shipments_create_16_1: `Your card has not been verified. To ensure the security of your payment, please complete the verification first.`,
    shipments_create_16_2: `Cancel`,
    shipments_create_16_3: `Verify`,
    shipments_create_17: `*Choose this only when you've already added your own UPS account.`,
    shipments_create_18: `Custom Text on Label`,
    shipments_create_18_0: `Add “`,
    shipments_create_18_0_1: `Custom Text on Label`,
    shipments_create_18_0_2: `” allows you to print custom text directly onto the shipment label. You can use it to print out the order SKUs instead of printing a separate packing slip. These additional texts are convenient for you to allocate packages.`,
    shipments_create_18_1: `#1 (optional)`,
    shipments_create_18_2: `#2 (optional)`,
    shipments_create_19_1: `Insufficient balance. Please `,
    shipments_create_19_2: `add funds`,
    shipments_create_19_3: ` to your account first`,
    shipments_create_20: `To ensure the safe and efficient delivery of your packages, the Ship from and Ship to addresses need to be different.`,
    // ----------------------------------------------------------------------
    shipments_1: `Shipments`,
    shipments_2_1: `My Shipment`,
    shipments_2_2: `My Draft`,
    shipments_2_3: `My Pickups`,
    shipments_2_4: `SCAN Form`,
    shipments_3_1: `Buy a Label`,
    shipments_3_2: `Schedule Pickup`,
    shipments_3_2_1: `USPS`,
    shipments_3_2_2: `DHL Express`,
    shipments_3_2_3: `FedEx`,
    shipments_3_2_4: `UPS`,
    // Shipment My Shipment
    shipments_2_1_1: `Stores`,
    shipments_2_1_15: `Label Type`,
    shipments_2_1_2: `Carriers`,
    shipments_2_1_3: `Tracking Status`,
    shipments_2_1_4_1: `Shipments ID, Recipient, Tracking number...`,
    shipments_2_1_4_2: `Search`,
    shipments_2_1_5: `Reset`,
    shipments_2_1_6_1: `Sort:`,
    shipments_2_1_6_2: `Newest Shipment`,
    shipments_2_1_6_3: `Oldest Shipment`,
    shipments_2_1_7: `Batch`,
    shipments_2_1_8: `Download Label`,
    // shipments_2_1_9: `Rate`,
    shipments_2_1_10_pending: `Pending`,
    shipments_2_1_10_succeed: `Succeed`,
    shipments_2_1_10_failed: `Failed`,
    shipments_2_1_11: `View Details`,
    shipments_2_1_11_1: `Refund Pending`,
    shipments_2_1_11_2: `Refunded`,
    shipments_2_1_11_3: `Reject`,
    shipments_2_1_11_4: `Void Failed`,
    shipments_2_1_12: `Rate unavailable`,
    shipments_2_1_13_1: ` Days`,
    shipments_2_1_13_2: ` Day`,
    shipments_2_1_14: `Tracking#`,
    shipments_2_1_16: `Shipping Adjustment`,
    shipments_2_1_17_1: `Return Label`,
    shipments_2_1_17_2_1: `Reprint Label`,
    shipments_2_1_17_2_2: `View QR Code`,
    shipments_2_1_17_2_3: `View Barcode`,
    shipments_2_1_17_3: `Duplicate Label`,
    shipments_2_1_17_4: `Void Label`,
    // Shipment My Draft
    shipments_2_2_1: `Carries`,
    shipments_2_2_2_1: `Draft ID, Recipient...`,
    // shipments_2_2_2_2: `Search`,
    shipments_2_2_3: `Reset`,
    shipments_2_2_4_1: `Sort:`,
    shipments_2_2_4_2: `Newest Draft`,
    shipments_2_2_4_3: `Oldest Draft`,
    shipments_2_2_5: `Draft ID`,
    shipments_2_2_6: `Recipient`,
    shipments_2_2_7: `Rate`,
    shipments_2_2_8: `Latest Rate Date`,
    shipments_2_2_9: `Buy Label`,
    shipments_2_2_9_1: `Edit Draft`,
    shipments_2_2_9_2: `Delete Draft`,
    // Shipment My Pickups
    shipments_2_3_1: `Status`,
    shipments_2_3_2_1: `Confirmation #`,
    shipments_2_3_3: `Reset`,
    shipments_2_3_4_1: `Sort:`,
    shipments_2_3_4_2: `Newest Pickup Date`,
    shipments_2_3_4_3: `Oldest Pickup Date`,
    shipments_2_3_5: `Pickup Date`,
    shipments_2_3_6: `Pickup Location`,
    shipments_2_3_7: `Quantity`,
    shipments_2_3_7_1: `Shipment ID`,
    shipments_2_3_8: `Total Weight (lb)`,
    shipments_2_3_8_1: `No Data`,
    shipments_2_3_9: `Confirmation #`,
    shipments_2_3_10: `Status`,
    shipments_2_3_11: `Closed`,
    shipments_2_3_12: `Cancel`,
    // Shipment SCAN Form
    shipments_2_4_1: `Ship From`,
    shipments_2_4_2: `Reset`,
    shipments_2_4_3_1: `Sort:`,
    shipments_2_4_3_2: `Newest SCAN Form`,
    shipments_2_4_3_3: `Oldest SCAN Form`,
    shipments_2_4_4: 'Ship From',
    shipments_2_4_5: 'Shipment',
    shipments_2_4_5_1: `Shipment ID`,
    shipments_2_4_6: 'Ship Date',
    shipments_2_4_7: 'Create Time',
    shipments_2_4_8: 'Print SCAN Form',
    shipments_2_4_9: 'https://www.shipsaving.com/blog/5-reasons-why-you-must-use-usps-scan-form',
    // ----------------------------------------------------------------------
    shipment_batch_label_download_1: `Download Label`,
    shipment_batch_label_download_2: `Store`,
    shipment_batch_label_download_3: `Print Size`,
    shipment_batch_label_download_4: `Date`,
    shipment_batch_label_download_5: `Request Download`,
    shipment_batch_label_download_6: `Other`,
    shipment_batch_label_download_7: `Start Date`,
    shipment_batch_label_download_8: `End Date`,
    // ----------------------------------------------------------------------
    shipment_viewDetails_0: `Back to Previous Page`,
    shipment_viewDetails_1_1: `Ship From`,
    shipment_viewDetails_1_2: `Package Details`,
    shipment_viewDetails_1_2_1: `Package Type`,
    shipment_viewDetails_1_2_1_1: `My Packages`,
    shipment_viewDetails_1_2_2: `Dimensions`,
    shipment_viewDetails_1_2_3: `Weight`,
    shipment_viewDetails_1_2_4: `Custom Print #1`,
    shipment_viewDetails_1_2_5: `Custom Print #2`,
    shipment_viewDetails_1_2_6: ` Insurance`,
    shipment_viewDetails_1_3: `Label Details`,
    shipment_viewDetails_1_3_1: `Label Size`,
    shipment_viewDetails_1_3_1_1: `4x6”, 4x8” or 8.5x11”`,
    shipment_viewDetails_1_3_1_2: `QR Code`,
    shipment_viewDetails_2: `Tracking Information`,
    shipment_viewDetails_2_1: `Drop Off`,
    shipment_viewDetails_2_2: `Schedule Pickup`,
    shipment_viewDetails_2_3: `Tracking#`,
    shipment_viewDetails_2_4: `Master Tracking#`,
    shipment_viewDetails_3: `Rate Details`,
    shipment_viewDetails_3_1: `Shipping Label`,
    shipment_viewDetails_3_1_1: `Additional fees include`,
    shipment_viewDetails_3_1_2: `Estimated delivery time`,
    shipment_viewDetails_3_1_2_1: ` Business Day(s)`,
    shipment_viewDetails_3_2: `Total Cost`,
    shipment_viewDetails_4: `Payment Methods`,
    shipment_viewDetails_4_1: `Paid With`,
    shipment_viewDetails_4_1_1: `ending in`,
    shipment_viewDetails_4_1_2: `Account Balance`,
    shipment_viewDetails_4_2: `Payment Total`,
    shipment_viewDetails_4_3_1: `Label purchasing supports mixed payment mode. ShipSaving will charge your default credit card for the remaining amount when your account balance is low. The final record can be viewed on your `,
    shipment_viewDetails_4_3_2: `Transaction History page`,
    shipment_viewDetails_4_3_3: `.`,
    shipment_viewDetails_5: `Transaction History`,
    shipment_viewDetails_10: `Return Label`,
    shipment_viewDetails_6: `Reprint Label`,
    shipment_viewDetails_7: `View QR Code`,
    shipment_viewDetails_13: `View Barcode`,
    shipment_viewDetails_8_1: `Void Label`,
    shipment_viewDetails_8_2: `Refund Pending`,
    shipment_viewDetails_8_3: `Refunded`,
    shipment_viewDetails_8_4: `Refund Reject`,
    shipment_viewDetails_8_5: `You cannot void this label or get a refund.`,
    shipment_viewDetails_9: `Transaction History`,
    shipment_viewDetails_9_1: `Rate unavailable`,
    shipment_viewDetails_9_2_1: `Estimated delivery in `,
    shipment_viewDetails_9_2_2: ` Day(s)`,
    shipment_viewDetails_9_3: `Estimated unavailable`,
    shipment_viewDetails_9_4_1: `Adjusted your shipment`,
    shipment_viewDetails_9_4_2: `Shipment refunded`,
    shipment_viewDetails_9_5: `Note`,
    shipment_viewDetails_11: `Duplicate Label`,
    shipment_viewDetails_12: `Service History`,
    // ----------------------------------------------------------------------
    service_list_purchase: `Purchase Label`,
    service_list_refund: `Void Label`,
    service_list_refund_rejected: `Void Label`,
    service_list_return: `Purchase Return Label`,
    service_list_adjustment: `Shipping Adjustment`,
    // ----------------------------------------------------------------------
    shipment_scan_form_1: `SCAN Form`,
    shipment_scan_form_1_1: `Learn More`,
    shipment_scan_form_1_2: `Print the entire day’s USPS labels and a single barcode to let the Postal worker scan all those packages in at once.`,
    shipment_scan_form_2: `Shipment Information`,
    shipment_scan_form_2_1: `There are no shipments available for SCAN form`,
    shipment_scan_form_2_2: `Ship Date`,
    shipment_scan_form_2_3: `Total Shipments`,
    shipment_scan_form_2_4: `Tracking#`,
    shipment_scan_form_3: `SCAN Form Location`,
    shipment_scan_form_3_1: `Don't use saved Ship From Address`,
    shipment_scan_form_3_2: `Create Ship From Address`,
    shipment_scan_form_3_3_1: `First Name`,
    shipment_scan_form_3_3_2: `Last Name`,
    shipment_scan_form_3_3_3: `Phone`,
    shipment_scan_form_3_3_4: `Email (optional)`,
    shipment_scan_form_4: `Create SCAN Form`,
    // ----------------------------------------------------------------------
    orders_1: `Orders`,
    orders_2_1: `Stores`,
    orders_2_2: `Carriers`,
    orders_17_1: `Date`,
    orders_3: `Order ID, Recipient...`,
    // orders_3_1: `Search`,
    orders_4: `Reset`,
    orders_5_1: `Sort:`,
    orders_5_2: `Newest Order`,
    orders_5_3: `Oldest Order`,
    orders_6_1: `Order Info`,
    orders_6_2: `Recipient`,
    orders_6_3: `Item(s)`,
    orders_6_4: `Package Info`,
    orders_6_4_1_1: `Includes `,
    orders_6_4_1_2: ` Items`,
    orders_6_4_2: `View Details`,
    orders_6_4_3_1: `Combine `,
    orders_6_4_3_2: ` orders`,
    orders_6_4_4: `Select Package`,
    orders_6_4_5: `Dimensions`,
    orders_6_4_6: `Weight`,
    orders_6_4_7: `Cancel`,
    orders_6_4_8: `Save`,
    orders_6_4_9: `Update`,
    orders_6_4_10: `Add Package Info`,
    orders_6_4_11: ` Packages`,
    orders_6_4_12: `Packages Info`,
    orders_6_4_13: `Dimensions: `,
    orders_6_4_14: `Weight: `,
    orders_6_5: `Carrier Service`,
    orders_6_5_1: `Rate Unavailable`,
    orders_6_5_2: `Package info required`,
    orders_6_5_3: `Refresh Rates`,
    orders_6_5_4: `Rate expired`,
    orders_6_5_5: ` Label`,
    orders_6_5_6: `View Details`,
    orders_6_5_7: ` Days`,
    orders_6_5_8: `Labels Info`,
    orders_6_5_8_1: `Void Labels`,
    orders_6_5_9: `Services`,
    orders_6_5_10: `Detailed info required`,
    orders_6_6: `No additional information`,
    orders_7_1: `Total:`,
    orders_7_2: `Custom Package`,
    orders_7_3: `Package Info Unavailable`,
    orders_7_4: `Rate unavailable`,
    orders_8_1: `Get Rate`,
    orders_8_2: `View Details`,
    orders_9: `Connect Your Store`,
    orders_10: `Combine`,
    orders_11: `Scan`,
    orders_12: `New Order`,
    orders_13_1: `All`,
    orders_14_1: `Awaiting`,
    orders_15_1: `Shipped`,
    orders_16_1: `Canceled`,
    orders_18: `Import`,
    orders_18_1: `Import Orders`,
    orders_18_2: `Download Template`,
    orders_18_4: `Drop your order form here or `,
    orders_18_5: `Browse`,
    orders_18_6: `Allowed *.xls, *.xlsx, *.xlsm, *.xlsb`,
    orders_18_7: `Cancel`,
    orders_18_8: `Upload`,
    orders_18_9: `Download`,
    orders_download_succeed: `Succeed`,
    orders_download_failed: `Failed`,
    orders_19: `Export`,
    orders_19_1: `Export Orders`,
    orders_19_2: `Start Date`,
    orders_19_3: `End Date`,
    orders_19_4: `Store`,
    orders_19_5: `Status`,
    orders_19_6: `Request Export Orders`,
    input_schema_order_1: `Please enter an integer`,
    input_schema_order_1_1: `Please ensure your input does not exceed 10 characters`,
    orders_20: `Filter`,
    orders_21: `Advanced Filter`,
    orders_22: `Order`,
    orders_23: `Order Number`,
    orders_24: `Item Name`,
    orders_25: `Item QTY`,
    orders_26: `Ship From`,
    orders_27: `Shipping Area`,
    orders_28: `Combined Order`,
    orders_29: `Hold Order`,
    orders_30: `Recipient`,
    orders_31: `Tracking Number`,
    orders_32: `Name`,
    orders_33: `City`,
    orders_34: `Zip Code`,
    orders_35: `Search`,
    orders_36: `Clear All`,
    orders_37: `View Order`,
    orders_38: `System`,
    orders_39_1: `Change Status`,
    orders_39_2: `Print`,
    orders_39_2_1: `Labels`,
    orders_39_2_2: `QR Code`,
    orders_39_2_3: `Invoices`,
    orders_39_3: `Tag to Hold`,
    orders_39_3_1: `Hold`,
    orders_39_3_2: `Unhold`,
    orders_39_4: `Edit Order`,
    orders_39_5: `Delete Order`,
    orders_39_6: `Create Return Label`,
    orders_39_7: `Void Label`,
    // ----------------------------------------------------------------------
    order_viewDetails_0: `Back to Previous Page`,
    order_viewDetails_1: `Order Information`,
    order_viewDetails_1_1: `Store`,
    order_viewDetails_1_2: `ID`,
    order_viewDetails_1_3: `Total Price`,
    order_viewDetails_2: `Order Items`,
    order_viewDetails_3: `Order Notes`,
    // ----------------------------------------------------------------------
    items_1: `Items`,
    items_2: `New Item`,
    items_3: `Item Name, SKU`,
    items_4_1: `Newest Item`,
    items_4_2: `Oldest Item`,
    items_5_1: `Item Info`,
    items_5_2: `Store`,
    items_5_3: `SKU`,
    items_5_4: `Price`,
    items_5_5: `Dimension (L x W x H)`,
    items_5_6: `Weight`,
    items_5_7: `No.`,
    items_6: `Delete`,
    items_7_1: `Edit`,
    items_7_2: `Delete`,
    // ----------------------------------------------------------------------
    settings_transactions_0: `Back to Previous Page`,
    settings_transactions_1: `Transaction History`,
    settings_transactions_2: `Transaction Type`,
    settings_transactions_3: `Transaction Year`,
    settings_transactions_4: `Reset`,
    settings_transactions_11: `Tracking number, Shipment ID`,
    settings_transactions_5_0: `Export`,
    settings_transactions_5: `Export Form`,
    settings_transactions_5_1: `Request Export Transaction History`,
    settings_transactions_6_1: `Sort:`,
    settings_transactions_6_2: `Newest Date`,
    settings_transactions_6_3: `Oldest Date`,
    settings_transactions_7_1: `Payment Methods`,
    settings_transactions_7_1_1: `Credit Card Ending in`,
    settings_transactions_7_1_2: `Bank Account in`,
    settings_transactions_7_1_5: `Account Balance`,
    settings_transactions_7_2: `Amount`,
    settings_transactions_7_3: `Account Balance`,
    settings_transactions_7_4: `Type`,
    settings_transactions_7_5: `Note`,
    settings_transactions_7_6: `Date`,
    settings_transactions_7_7: `Transaction#`,
    settings_transactions_7_8: `Shipment ID`,
    settings_transactions_8_1: `View Invoice`,
    settings_transactions_8_2: `View Details`,
    settings_transactions_9: `* To request a copy of your invoice, please contact our billing team.`,
    settings_transactions_10_1: `To request a copy of your invoice, contact our billing team via email: `,
    settings_transactions_10_2: ` with the following information included in the body of the email: `,
    settings_transactions_10_3: `Registered email`,
    settings_transactions_10_4: `Invoice title name or company name`,
    settings_transactions_10_5: `Address`,
    settings_transactions_10_6: `Request date period by monthly`,
    settings_transactions_12: `Reason`,
    settings_transactions_13: `Actual Dimension`,
    settings_transactions_14: `Actual Weight`,
    settings_transactions_15: `Adjust credit`,
    settings_transactions_16: `Description`,
    // ----------------------------------------------------------------------
    settings_billing_history_1: `Back to Previous Page`,
    settings_billing_history_2: `Billing History`,
    settings_billing_history_3_1: `Plan`,
    settings_billing_history_3_1_1: `Credit Card Ending in`,
    settings_billing_history_3_1_2: `Bank Account in`,
    settings_billing_history_3_2: `Status`,
    settings_billing_history_3_3: `Usage`,
    settings_billing_history_3_4: `Amount`,
    settings_billing_history_3_5: `Payment Methods`,
    settings_billing_history_3_6: `Starts (PST/PDT)`,
    settings_billing_history_3_7: `Expires (PST/PDT)`,
    settings_billing_history_4: `View Invoice`,
    // ----------------------------------------------------------------------
    settings_rebates_0: `Back to Previous Page`,
    settings_rebates_1: `Rebates`,
    settings_rebates_2: `Date create`,
    settings_rebates_3: `Due date`,
    settings_rebates_4: `Tracking number`,
    settings_rebates_5: `Reset`,
    settings_rebates_6_1: `Sort:`,
    settings_rebates_6_2: `Newest Date`,
    settings_rebates_6_3: `Oldest Date`,
    settings_rebates_7_1: `Amount`,
    settings_rebates_7_2: `Rebates Balance`,
    settings_rebates_7_3: `Tracking number`,
    settings_rebates_7_4: `Date`,
    settings_rebates_8: `Redeem`,
    // ----------------------------------------------------------------------
    shipments_pickup_schedule_0: `Back to Previous Page`,
    shipments_pickup_schedule_1: `Schedule a Pickup`,
    shipments_pickup_schedule_2: `Learn More`,
    shipments_pickup_schedule_3_1: `You can schedule a free USPS pickup for the next delivery day at the same time your mail is delivered by your carrier. If USPS misses your pickup, the best thing to do is visit your `,
    shipments_pickup_schedule_3_2: `local Post Office`,
    shipments_pickup_schedule_3_3: `.`,
    shipments_pickup_schedule_4: `Package Information`,
    shipments_pickup_schedule_4_1: `There are no packages available for pickup.`,
    shipments_pickup_schedule_5: `Pickup Date`,
    shipments_pickup_schedule_5_1: `Next delivery day`,
    shipments_pickup_schedule_6: `Total Number of Items`,
    shipments_pickup_schedule_7: `Estimated Total Weight`,
    shipments_pickup_schedule_8: `Pickup Location`,
    shipments_pickup_schedule_9: `Add Address`,
    shipments_pickup_schedule_10: `Default`,
    shipments_pickup_schedule_11: 'Package Location',
    shipments_pickup_schedule_12: `Additional Instructions`,
    shipments_pickup_schedule_13: `Request Pickup`,
    shipments_pickup_schedule_14_1: `First Name`,
    shipments_pickup_schedule_14_2: `Last Name`,
    shipments_pickup_schedule_14_3: `Phone`,
    shipments_pickup_schedule_14_4: `Email (optional)`,
    // ----------------------------------------------------------------------
    // Reports
    reports_0: `Reports`,
    reports_1: `Reports`,
    reports_11: `Orders`,
    reports_1_1: `Awaiting`,
    reports_1_2: `Shipped`,
    reports_1_3: `Canceled`,
    reports_1_4: `Hold`,
    reports_2: `Order Statistics`,
    reports_22: `Account Balance`,
    reports_2_1: `View Details`,
    reports_3: `Total Shipping Costs`,
    reports_3_1: `View Transactions`,
    reports_4: `Total Refunds`,
    reports_5: `Shipping Statistics`,
    reports_5_1: ` Labels`,
    reports_6: `Carrier Services`,
    reports_7_1: 'Jan',
    reports_7_2: 'Feb',
    reports_7_3: 'Mar',
    reports_7_4: 'Apr',
    reports_7_5: 'May',
    reports_7_6: 'Jun',
    reports_7_7: 'Jul',
    reports_7_8: 'Aug',
    reports_7_9: 'Sep',
    reports_7_10: 'Oct',
    reports_7_11: 'Nov',
    reports_7_12: 'Dec',
    reports_8: `Total Label`,
    reports_9: `Custom Date`,
    reports_9_1: `Start date`,
    reports_9_2: `End date`,
    reports_9_3: `Cancel`,
    reports_9_4: `Apply`,
    // ----------------------------------------------------------------------
    // Partner Tool
    partner_tool_1: `Partner Tool`,
    // ----------------------------------------------------------------------
    partner_tool_sales_report_1: `Sales Report`,
    partner_tool_sales_report_1_1: `Total Shipments`,
    partner_tool_sales_report_1_2: `Total Volume`,
    // ----------------------------------------------------------------------
    partner_tool_client_1: `Client`,
    partner_tool_client_1_1: `Started Shipping`,
    partner_tool_client_1_2: `Name, Email`,
    partner_tool_client_1_3: `Newest Signup Date`,
    partner_tool_client_1_4: `Oldest Signup Date`,
    partner_tool_client_1_5: `Newest Purchase Date`,
    partner_tool_client_1_6: `Oldest Purchase Date`,
    partner_tool_client_2_1: `Name`,
    partner_tool_client_2_2: `Signup Date`,
    partner_tool_client_2_3: `Started Shipping`,
    partner_tool_client_2_4: `Total Volume`,
    partner_tool_client_2_5: `Total Shipments`,
    partner_tool_client_2_6: `Last Purchase Date`,
    partner_tool_client_2_7: `View Details`,
    // ----------------------------------------------------------------------
    settings_account_1: `Account`,
    // Account General
    settings_account_1_1: `General`,
    settings_account_1_1_1: `PROFILE PHOTO`,
    settings_account_1_1_1_1: `Upload new photo`,
    settings_account_1_1_1_2: `Allowed *.jpeg, *.jpg, *.png, *.gif Max size of 1.0 MB`,
    settings_account_1_1_1_3: `File is larger than the maximum allowed size of 1 MB!`,
    settings_account_1_1_1_4: `Update success!`,
    settings_account_1_1_1_5: `Update success!`,
    settings_account_1_1_2: `PROFILE INFORMATION`,
    settings_account_1_1_2_1: `First Name`,
    settings_account_1_1_2_2: `Last Name`,
    settings_account_1_1_2_3: `Email Address`,
    settings_account_1_1_2_4: `Save Changes`,
    settings_account_1_1_2_5: `Company (optional)`,
    settings_account_1_1_3: `CHANGE PASSWORD`,
    settings_account_1_1_3_1: `Old Password`,
    settings_account_1_1_3_2: `New Password`,
    settings_account_1_1_3_3: `Confirm New Password`,
    settings_account_1_1_3_4: `Save Changes`,
    settings_account_1_1_4: `MANAGE COMPANY`,
    settings_account_1_1_4_1: `Company Name`,
    settings_account_1_1_4_2: `Phone`,
    settings_account_1_1_4_3: `Email`,
    settings_account_1_1_4_10: `Save Changes`,
    // Account Billing
    settings_account_1_2: `Billing`,
    settings_account_1_2_1: `ACCOUNT BALANCE`,
    settings_account_1_2_1_1: `Request Withdrawals`,
    settings_account_1_2_1_2: `Rebate`,
    settings_account_1_2_1_3: `Add Funds`,
    settings_account_1_2_2: `PAYMENT METHODS`,
    settings_account_1_2_2_1_1: `Add Methods`,
    settings_account_1_2_2_1_2: `Add New Payment Methods`,
    settings_account_1_2_2_10: `You have not added any payment methods yet.\nTo make transaction please add the payment methods. Add\nmaximum 2 payment methods allowed.`,
    settings_account_1_2_2_2: `Edit Method`,
    settings_account_1_2_2_3: `Delete Method`,
    settings_account_1_2_2_12: `Make Default`,
    settings_account_1_2_2_12_1: `Update success!`,
    settings_account_1_2_2_4: `Ending in`,
    settings_account_1_2_2_5: `Exp.`,
    settings_account_1_2_2_6: `Verify My Method`,
    settings_account_1_2_2_7: `Verify Expired/Failed`,
    settings_account_1_2_2_8: `Default`,
    settings_account_1_2_2_8_1: `A default payment method is the payment method that will be used for all created payments going forward.`,
    settings_account_1_2_2_9_0: `Unverified`,
    settings_account_1_2_2_9: `Verified`,
    settings_account_1_2_2_9_1: `The verified credit card can be used to buy labels, add funds to balance, and pay your monthly plan.\n\nThe verified ACH account can be used to buy labels and add funds to balance.`,
    settings_account_1_2_2_11: `Subscribe`,
    settings_account_1_2_2_11_1: `This card is used to pay for your monthly plan.`,
    settings_account_1_2_3: `TRANSACTION HISTORY`,
    settings_account_1_2_3_1: `View all Transactions`,
    settings_account_1_2_3_2: `No transactions in your history yet.`,
    // Account Plan
    settings_account_1_3: `Plan`,
    settings_account_1_3_1: `MY PLAN`,
    settings_account_1_3_2_1_1: `Free`,
    settings_account_1_3_2_1_2: ` days left in trial`,
    settings_account_1_3_2_1_3: ` hours left in trial`,
    settings_account_1_3_2_1_4: ` minutes left in trial`,
    settings_account_1_3_2_1_5: `Last minute left in trial`,
    settings_account_1_3_2_2_1: `Available Shipments:`,
    settings_account_1_3_2_2_2: `Unlimited`,
    settings_account_1_3_3_1_1: `The Starter plan is perfect for cutting off your costs with no monthly fees:`,
    settings_account_1_3_3_1_2_1: `With the Standard plan, you will get all features from the Starter plan,`,
    settings_account_1_3_3_1_2_2: ` PLUS`,
    settings_account_1_3_3_1: `Buy & print labels anytime and everywhere. 😌`,
    settings_account_1_3_3_2_1: `Access to USPS, UPS, and DHL Express discounts,`,
    settings_account_1_3_3_2_2: ` save up to 89% off`,
    settings_account_1_3_3_3_1: `Use`,
    settings_account_1_3_3_3_2: ` Address Auto-complete`,
    settings_account_1_3_3_3_3: ` feature to avoid delivery failure`,
    settings_account_1_3_3_4: `Connect your stores in no time 🛒 , sync orders seamlessly`,
    settings_account_1_3_3_5_1: `Integrate your carrier accounts, and`,
    settings_account_1_3_3_5_2: ` compare rates`,
    settings_account_1_3_3_5_3: ` instantly`,
    settings_account_1_3_3_6: `Auto-generate customs forms & commercial invoices`,
    settings_account_1_3_3_7: `✍️ Help center, Email, and live chat support`,
    settings_account_1_3_3_8_1: `📑 Generate `,
    settings_account_1_3_3_8_2: `USPS SCAN Form`,
    settings_account_1_3_3_9_1: `Apply specific`,
    settings_account_1_3_3_9_2: ` shipping rules`,
    settings_account_1_3_3_9_3: ` to orders depending on conditions`,
    settings_account_1_3_3_10_1: `Advanced OMS`,
    settings_account_1_3_3_10_2: `: combine, holds, change order status, etc.`,
    settings_account_1_3_3_11_1: `Create a single order or`,
    settings_account_1_3_3_11_2: ` import orders`,
    settings_account_1_3_3_11_3: ` for your customized store`,
    settings_account_1_3_3_12_1: `Ship `,
    settings_account_1_3_3_12_2: `Multi-Piece Shipments`,
    settings_account_1_3_3_12_3: ` to the same delivery address`,
    settings_account_1_3_3_13_1: `Invite users to your team, and set up`,
    settings_account_1_3_3_13_2: ` user permissions 👀`,
    settings_account_1_3_3_14_1: `Add funds`,
    settings_account_1_3_3_14_2: ` to your ShipSaving account balance by `,
    // settings_account_1_3_3_15_1: `More payment methods`,
    settings_account_1_3_3_15_2: `Credit Card, Zelle`,
    settings_account_1_3_3_16_1: `The 📊`,
    settings_account_1_3_3_16_2: ` Reports`,
    settings_account_1_3_3_16_3: ` deliver a complete overview of shipping performance`,
    settings_account_1_3_3_17_1: `Best for simple shipping needs`,
    settings_account_1_3_3_17_2: `I typically send`,
    settings_account_1_3_3_17_3: `shipments/month`,
    settings_account_1_3_3_18_1: `Free`,
    settings_account_1_3_3_18_2: `/mo`,
    settings_account_1_3_3_19_1: `Downgrade`,
    settings_account_1_3_3_19_2: `Subscription`,
    settings_account_1_3_3_19_3: `Try It Now`,
    settings_account_1_3_3_19_4: `Change Plan`,
    settings_account_1_3_3_20: `Learn more`,
    settings_account_1_3_3_21: `Upgrade to ShipSaving Pro`,
    settings_account_1_3_3_22: `Streamline inventory, boost logistics, and fast-track business growth.`,
    settings_account_1_3_3_23: `Learn More`,
    settings_account_1_3_3_23_1: `Upgrade to ShipSaving Pro`,
    settings_account_1_3_3_23_2: `Join the Pro Plan to easily handle orders and inventory, improve your logistics, and grow your business steadily and quickly.`,
    settings_account_1_3_3_23_3: `Powerful WMS`,
    settings_account_1_3_3_23_4: `E-commerce integration (e.g., Amazon)`,
    settings_account_1_3_3_23_5: `Multiple logistics channels`,
    settings_account_1_3_3_23_6: `Dedicated 1-on-1 customer service`,
    settings_account_1_3_3_23_7: `Flexible API access`,
    settings_account_1_3_3_23_8: `Close`,
    settings_account_1_3_3_23_9: `Contact Us Now`,
    settings_account_1_3_3_24_1: `Connect your `,
    settings_account_1_3_3_24_2: `own carrier accounts `,
    settings_account_1_3_3_24_3: `and see your negotiated rates`,
    settings_account_1_3_4: `BILLING AND PAYMENT`,
    settings_account_1_3_5_1: `Billing Information`,
    settings_account_1_3_5_1_1: `View billing history`,
    settings_account_1_3_5_1_2: `No transactions in your history yet.`,
    settings_account_1_3_5_1_3: `Estimated renewal date`,
    settings_account_1_3_5_1_4: `What you’ll be charged`,
    settings_account_1_3_5_2: `Payment Method`,
    settings_account_1_3_5_2_1: `Default`,
    // Account User
    settings_account_1_4: `User`,
    settings_account_1_4_1_1: `MANAGE USER`,
    settings_account_1_4_1_2: `Add New User`,
    settings_account_1_4_2_1: `Name`,
    settings_account_1_4_2_2: `Email`,
    settings_account_1_4_2_2_1: `Resend Verify Email`,
    settings_account_1_4_2_5: `Stores`,
    settings_account_1_4_2_5_1: `All Store`,
    settings_account_1_4_2_6: `Status`,
    settings_account_1_4_2_6_INACTIVATE: `Inactive`,
    settings_account_1_4_2_6_ACTIVATE: `Active`,
    settings_account_1_4_2_7: `Store`,
    settings_account_1_4_2_8: `Permission`,
    settings_account_1_4_2_8_1: `All Permission`,
    settings_account_1_4_2_9: `Verified`,
    settings_account_1_4_2_10: `Admin`,
    // Account Notifications
    settings_account_1_5: `Notifications`,
    settings_account_1_5_1: `EMAIL NOTIFICATIONS`,
    settings_account_1_5_1_1: `Choose the type of emails you'd like to receive from Shipsaving. Rest assured, we never sell or share your email address. See our `,
    settings_account_1_5_1_2: `Privacy Policy`,
    settings_account_1_5_1_3: ` for details.`,
    settings_account_1_5_2_1_1: `Carrier Fee Adjustments`,
    settings_account_1_5_2_1_2: `Receive an email when there's a shipping fee adjustment in your package's expected and actual fees.`,
    settings_account_1_5_2_2_1: `Refund Status Updates`,
    settings_account_1_5_2_2_2: `Track the status of your void label requests, whether they're refunded or rejected, with timely email updates.`,
    settings_account_1_5_2_3_1: `Payment Receipts`,
    settings_account_1_5_2_3_2: `Receive an email copy of your receipt every time a payment is processed.`,
    settings_account_1_5_2_4_1: `Payment Failures`,
    settings_account_1_5_2_4_2: `Receive an email whenever there's an issue with your payment method.`,
    settings_account_1_5_3_1: `On`,
    settings_account_1_5_3_2: `Off`,
    // ----------------------------------------------------------------------
    settings_Integration_1: `Integration`,
    // Account Integration Stores
    settings_Integration_2_1: `Stores`,
    settings_Integration_3: `MANAGE STORES`,
    settings_Integration_4: `Connect your store`,
    settings_Integration_5: `You haven't connected to a store yet`,
    settings_Integration_6: `Edit`,
    settings_Integration_7: `Reauthorize`,
    settings_Integration_8: `Delete`,
    settings_Integration_9_1: `Nickname`,
    settings_Integration_9_2: `Store`,
    settings_Integration_9_3: `Ship From`,
    settings_Integration_9_4: `Carrier Accounts`,
    settings_Integration_9_5: `Expiration Date`,
    settings_Integration_10: `Exp. Date`,
    settings_Integration_10_1: `Long-Term`,
    // Account Integration Carriers
    settings_Integration_2_2: `Carriers`,
    settings_Integration_2_3: `MANAGE CARRIERS`,
    settings_Integration_2_4_1: `Create `,
    settings_Integration_2_4_2: `NEW UPS ACCOUNT`,
    settings_Integration_2_4_3: ` with discounts!!`,
    settings_Integration_2_5: `Connect your carrier`,
    // Account Integration API
    settings_Integration_2_17: `API`,
    // - ShipSaving Accounts
    settings_Integration_2_6: `ShipSaving Accounts`,
    settings_Integration_2_6_1: `User’s Account`,
    settings_Integration_2_7_1: `Discounts up to 89% off`,
    settings_Integration_2_7_2: `Schedule package pickups`,
    settings_Integration_2_7_3: `Paperless shipping labels with QR Codes`,
    settings_Integration_2_7_4: `Learn more about `,
    settings_Integration_2_7_5: `USPS service levels`,
    settings_Integration_2_8: `Active`,
    settings_Integration_2_9: `Inactive`,
    settings_Integration_2_10: `Edit`,
    settings_Integration_2_11_1: `Discounts up to 76% off`,
    settings_Integration_2_11_2: `Schedule package pickups`,
    settings_Integration_2_11_3: `Pre-fill customs forms`,
    settings_Integration_2_11_4: `DHL Express service levels`,
    // settings_Integration_2_11: `Activate Account`,
    // settings_Integration_2_12: `Edit`,
    settings_Integration_2_12: `+ Add Bill Account`,
    // - Your Accounts
    settings_Integration_2_13: `Your Accounts`,
    settings_Integration_2_14_1: `Discounts up to 82% off`,
    settings_Integration_2_14_2: `$0 common surcharges for Ground shipments`,
    settings_Integration_2_14_3: `Drop off UPS packages at `,
    settings_Integration_2_14_4: `Access to your own pre-negotiated rates`,
    settings_Integration_2_14_5: `78,000+ locations`,
    settings_Integration_2_14_6: `UPS service levels`,
    // settings_Integration_2_15: `Activate Account`,
    settings_Integration_2_16: `Edit`,
    settings_Integration_2_18: `Bill Account`,
    settings_Integration_2_19: `Delete`,
    // UPS® Ground Saver
    settings_Integration_2_15_1: `An economy, residential, ground delivery service`,
    settings_Integration_2_15_2: `Delivered Monday – Sunday`,
    settings_Integration_2_15_3: `UPS door-to-door tracking`,
    settings_Integration_2_15_4: `UPS® Ground Saver`,
    // ShipSaving Accounts
    settings_Integration_2_20_1: `Access to your negotiated rates`,
    settings_Integration_2_20_2: `Compare rates from multi-carriers`,
    settings_Integration_2_20_3: `Bill to your own UPS account`,
    // FEDEX DEFAULT
    settings_Integration_2_21_1: `Discounts for all domestic shipments`,
    settings_Integration_2_21_2: `Avoid residential and fuel surcharges`,
    settings_Integration_2_21_3: `No volume requirements`,
    // ----------------------------------------------------------------------
    settings_configuration_1: `Configuration`,
    settings_configuration_2_1: `Address Book`,
    settings_configuration_2_1_1: `MANAGER ADDRESS`,
    settings_configuration_2_1_2: `Add Address Book`,
    settings_configuration_2_1_3: `You haven't added a Ship From address yet`,
    settings_configuration_2_1_4: `Default`,
    settings_configuration_2_1_5: `Edit`,
    settings_configuration_2_1_6: `Delete`,
    settings_configuration_2_2: `Packages`,
    settings_configuration_2_2_1: `MANAGE PACKAGES`,
    settings_configuration_2_2_2: `Add Packages`,
    settings_configuration_2_2_3: `Package Name`,
    settings_configuration_2_2_4: `Dimension (L x W x H)`,
    settings_configuration_2_2_5: `Package Type`,
    settings_configuration_2_2_5_1: `Custom Package`,
    settings_configuration_2_2_6: `Edit`,
    settings_configuration_2_2_7: `Delete`,
    settings_configuration_1_1: `Ship From`,
    settings_configuration_1_1_1: `Manage Ship From Address`,
    settings_configuration_1_1_2: `+ Add Ship From Address`,
    settings_configuration_1_1_3: `Address Type`,
    settings_configuration_1_1_4: `Name, Phone`,
    settings_configuration_1_1_5: `Newest Date`,
    settings_configuration_1_1_6: `Oldest Date`,
    settings_configuration_1_1_7: `Name`,
    settings_configuration_1_1_8: `Contact`,
    settings_configuration_1_1_9: `Address`,
    settings_configuration_1_1_10: `Residential`,
    settings_configuration_1_1_11: `Edit`,
    settings_configuration_1_1_12: `Delete`,
    settings_configuration_1_2: `Ship To`,
    settings_configuration_1_2_1: `MANAGE SHIP TO ADDRESS`,
    settings_configuration_1_2_2: `+ Add Ship To Address`,
    settings_configuration_1_3: `Shipping Rule`,
    settings_configuration_1_3_1: `MANAGE SHIPPING RULE`,
    settings_configuration_1_3_2: `Add Shipping Rule`,
    settings_configuration_1_3_3: `Item Info`,
    settings_configuration_1_3_3_1: `Includes `,
    settings_configuration_1_3_3_2: ` Items`,
    settings_configuration_1_3_3_3: `View details`,
    settings_configuration_1_3_4: `Package Types`,
    settings_configuration_1_3_4_1: `Custom Package`,
    settings_configuration_1_3_5: `Dimension(L x W x H)`,
    settings_configuration_1_3_6: `Weight`,
    settings_configuration_1_3_7: `Edit`,
    settings_configuration_1_3_8: `Delete`,
    settings_configuration_1_4: `Automation`,
    settings_configuration_1_4_1: `PRINT SETTING`,
    settings_configuration_1_4_1_1: `Default label size`,
    settings_configuration_1_4_1_2: `Default Label Printing Option`,
    settings_configuration_1_4_2: `SHIPMENT SETTING`,
    settings_configuration_1_4_2_1: `Default Customs Contents`,
    settings_configuration_1_4_2_2: `Default Customs Contents If undeliverable`,
    settings_configuration_1_4_2_3: `Weight Unit`,
    settings_configuration_1_4_3: `Other options`,
    settings_configuration_1_4_3_1: `Hold Orders with Address Problems`,
    settings_configuration_1_5: `Save Changes`,
    // ----------------------------------------------------------------------
    notifications_1: `Notifications`,
    notifications_2: `Mark all as read`,
    notifications_3: `View All`,
    notifications_4: `You have no messages yet`,
    notifications_5_1: `System Notice`,
    notifications_5_2: `Carriers News`,
    notifications_5_3: ` Message`,
    // ----------------------------------------------------------------------
    payment_complete_1: `Payment Completed`,
    payment_complete_2: `Your add funds request has been successfully and securely processed. The amount has been added to your ShipSaving account balance.`,
    payment_complete_3_1: `Check Transaction History`,
    payment_complete_3_2: `Go to Dashboard`,
    // ----------------------------------------------------------------------
    dialog_auth_send_email_0: `Verify Your Email`,
    dialog_auth_send_email_1: `Please check your email and follow the instructions to active your account. If you didn’t receive an email, you can resend one.`,
    dialog_auth_send_email_2: `OK`,
    dialog_auth_send_email_3: `Didn't received it? Try checking your span folder or `,
    dialog_auth_send_email_4: `Resend`,
    // ----------------------------------------------------------------------
    dialog_auth_verify_code_0: `Verification Code`,
    dialog_auth_verify_code_1_1: `Please check your email and enter the 4-digit code to active your account. If you didn’t receive an email, you can resend one.`,
    dialog_auth_verify_code_1_2: `For your security, a verification code has been sent to your registered email. Please enter the code below to continue.`,
    dialog_auth_verify_code_2: `Didn’t receive it? Try checking your spam folder or `,
    dialog_auth_verify_code_3: `Resend`,
    // ----------------------------------------------------------------------
    dialog_auth_reset_confirmed_1: `Email Verification Successful`,
    dialog_auth_reset_confirmed_2: `Thank you for verifying your email address. Your registration is now complete, and you can enjoy all the features of ShipSaving.`,
    dialog_auth_reset_confirmed_3: `Continue to ShipSaving!`,
    // ----------------------------------------------------------------------
    dialog_auth_reset_expired_1: `Email Verification Failed`,
    dialog_auth_reset_expired_2: `We're sorry, but the verification link you used has expired. Please click the button below to resend a new verification link to your email.`,
    dialog_auth_reset_expired_3: `Resend Verification Link`,
    // ----------------------------------------------------------------------
    dialog_auth_account_deleted_1: `Email Verification Failed`,
    dialog_auth_account_deleted_2: `We're sorry, but the verification link you used has expired. Please click the button below to resend a new verification link to your email.`,
    dialog_auth_account_deleted_3: `Back to Login`,
    // ----------------------------------------------------------------------
    dialog_auth_locked_account_1: `Help us protect your account`,
    dialog_auth_locked_account_2: `We have detected unusual activity on your account and have locked it temporarily. Please contact customer service for further assistance.`,
    dialog_auth_locked_account_3: `Contact us to unlock`,
    // ----------------------------------------------------------------------
    dialog_buy_label_locked_1: `Unable to Buy Labels`,
    dialog_buy_label_locked_2_1: `Your ShipSaving account is temporarily unable to buy labels due to the unsuccessful payment of your monthly subscription fee at `,
    dialog_buy_label_locked_2_2: `. Please go to your `,
    dialog_buy_label_locked_2_3: `Billing page`,
    dialog_buy_label_locked_2_4: ` and update your payment method.`,
    dialog_buy_label_locked_3_1: `Your ShipSaving account is temporarily unable to buy labels due to the unsuccessful payment of your adjustment fee. Please `,
    dialog_buy_label_locked_3_2: `contact us`,
    dialog_buy_label_locked_3_3: ` to resolve. `,
    dialog_buy_label_locked_4_1: `Please `,
    dialog_buy_label_locked_4_2: `contact us`,
    dialog_buy_label_locked_4_3: ` to resolve. `,
    dialog_buy_label_locked_5: `OK`,
    // ----------------------------------------------------------------------
    dialog_buy_label_reach_limit_notification_1: `Shipping Quota Full`,
    dialog_buy_label_reach_limit_notification_2_1: `You've used all shipping labels for this 30-day period! Your next quota will reset on `,
    dialog_buy_label_reach_limit_notification_2_2: `. 😊 Thank you for your support!`,
    dialog_buy_label_reach_limit_notification_3: `OK`,
    // ----------------------------------------------------------------------
    dialog_buy_label_downgrade_notification_plan_1: `You've Maxed Out Your Print Label Quota!`,
    dialog_buy_label_downgrade_notification_plan_2_1: `You've reached the print label limit under the Standard plan free trial. Your plan will be downgraded to Starter ahead of schedule. Please click `,
    dialog_buy_label_downgrade_notification_plan_2_2: `OK`,
    dialog_buy_label_downgrade_notification_plan_2_3: ` and then log in again.`,
    dialog_buy_label_downgrade_notification_plan_3: `OK`,
    // ----------------------------------------------------------------------
    dialog_first_time_tutorial_1_1: `Welcome to ShipSaving`,
    dialog_first_time_tutorial_2_1: `Of course, you could stand in a long line at the post office with a face mask on, or ship like a pro with ShipSaving,`,
    dialog_first_time_tutorial_2_2: ` for free.`,
    dialog_first_time_tutorial_3_1: `Buy labels with the best rates`,
    dialog_first_time_tutorial_4_1: `Buy a label in only two steps with instant access to our discounted USPS, UPS and DHL Express rates `,
    dialog_first_time_tutorial_4_2: `up to 89% off`,
    dialog_first_time_tutorial_4_3: `. No carrier account needed.`,
    dialog_first_time_tutorial_5_1: `Print labels with printer or QR code`,
    dialog_first_time_tutorial_6_1: `Print labels with any printer. Or you can generate a `,
    dialog_first_time_tutorial_6_2: `QR code`,
    dialog_first_time_tutorial_6_3: `, bring it to the Post Office, and USPS will do the label printing for free.`,
    dialog_first_time_tutorial_7: `Next`,
    dialog_first_time_tutorial_8: `Previous`,
    dialog_first_time_tutorial_9: `Skip`,
    dialog_first_time_tutorial_10: `Finish`,
    // ----------------------------------------------------------------------
    dialog_standard_tutorial_1_1: `Start Your Free Trail`,
    dialog_standard_tutorial_1_2: `Get Started`,
    dialog_standard_tutorial_1_3: `Welcome to ShipSaving`,
    dialog_standard_tutorial_1_4: `We empower the best shipping solution at all sizes for your `,
    dialog_standard_tutorial_1_5: `personal`,
    dialog_standard_tutorial_1_6: ` and `,
    dialog_standard_tutorial_1_7: `business`,
    dialog_standard_tutorial_1_8: ` shipping demands.`,
    dialog_standard_tutorial_2_1: `Setup Your Ship From Address`,
    dialog_standard_tutorial_2_2: `Add your default ship from location in ShipSaving to start creating labels and get discounted shipping rates.`,
    dialog_standard_tutorial_2_3: `Ship From address`,
    dialog_standard_tutorial_2_4: `Skip Now`,
    dialog_standard_tutorial_2_5: `Next`,
    dialog_standard_tutorial_2_6: `All orders, in one place`,
    dialog_standard_tutorial_2_7: `Sync orders directly from your sales channels and `,
    dialog_standard_tutorial_2_8: `create shipments instantly.`,
    dialog_standard_tutorial_2_9: ` No more switching around.`,
    dialog_standard_tutorial_3_1: `Setup Your Shipping Preferences`,
    dialog_standard_tutorial_3_2: `Tell us more about your shipping preferences so we can better streamline your shipping journey.`,
    dialog_standard_tutorial_3_3: `Shipment Setting`,
    dialog_standard_tutorial_3_4: `Default Label Size`,
    dialog_standard_tutorial_3_5: `Default Weight Unit`,
    dialog_standard_tutorial_3_6: `Package Setting`,
    dialog_standard_tutorial_3_7: `Finish Setup`,
    dialog_standard_tutorial_3_8: `Buy labels with the best rates`,
    dialog_standard_tutorial_3_9: `Access to our discounted rates on USPS, UPS, FedEx, and DHL Express, with savings of `,
    dialog_standard_tutorial_3_10: `up to 89% off.`,
    dialog_standard_tutorial_3_11: `Buy a label in only two steps with instant access to our discounted USPS and DHL Express rates `,
    // ----------------------------------------------------------------------
    dialog_select_payment_type_1_1: 'ACH Payments',
    dialog_select_payment_type_1_2_1: 'No convenience fee',
    dialog_select_payment_type_1_2_2_1: 'Minimum transfer amount: ',
    dialog_select_payment_type_1_2_2_2: '$1000',
    dialog_select_payment_type_1_2_3_1: 'Processing time: ',
    dialog_select_payment_type_1_2_3_2: '5 - 7 business days',
    dialog_select_payment_type_1_3: 'Add Account',
    dialog_select_payment_type_2_1: 'Credit Card Payment',
    dialog_select_payment_type_2_2_1: '3% convenience fee applies',
    dialog_select_payment_type_2_2_2_1: 'Minimum transfer amount: ',
    dialog_select_payment_type_2_2_2_2: '$50',
    dialog_select_payment_type_2_2_3_1: 'Processing time: ',
    dialog_select_payment_type_2_2_3_2: 'Immediately',
    dialog_select_payment_type_2_3: 'Add Card',
    dialog_select_payment_type_3_1: '0% convenience fee applies',
    dialog_select_payment_type_3_1_1: '$500',
    dialog_select_payment_type_3_1_2: '1 business day',
    // ----------------------------------------------------------------------
    dialog_redemption_account_balance_1: `Withdraw Funds`,
    dialog_redemption_account_balance_2_1: `Please request withdrawals within 180 days of your last label purchase. Note a 3% service fee for credit card transactions. Refunds process in 7-10 business days and return to your initial card. Email the completed form to `,
    dialog_redemption_account_balance_2_2: `To withdraw funds from your ShipSaving account balance, please download and fill out the request form, and then email the completed form to `,
    dialog_redemption_account_balance_3_1: `Download Request form`,
    // ----------------------------------------------------------------------
    dialog_add_bank_account_1: `Add Bank Account`,
    dialog_add_bank_account_1_1: `There is no transaction fees paid by ACH`,
    dialog_add_bank_account_1_2_1: `Follow our `,
    dialog_add_bank_account_1_2_2: `payment methods guide`,
    dialog_add_bank_account_1_2_3: ` to set up your ACH account.`,
    dialog_add_bank_account_1_2_4: `ACH payments take up to 4-5 business days to receive confirmation if the transaction proceeds successfully or not. Because of this, ACH payments might take up to 7 business days to be finalized in your ShipSaving Account Balance.`,
    dialog_add_bank_account_1_2_5: `Please be mindful that repeated payments in a short period of time might cause failure.`,
    dialog_add_bank_account_1_3: `Business Type`,
    dialog_add_bank_account_1_4: `Account Number`,
    dialog_add_bank_account_1_5: `Routing Number`,
    dialog_add_bank_account_1_6: `Invoice Information`,
    dialog_add_bank_account_1_6_1: `Name`,
    dialog_add_bank_account_1_6_2: `Street 1`,
    dialog_add_bank_account_1_6_3: `Street 2 (optional)`,
    dialog_add_bank_account_1_6_4: `City`,
    dialog_add_bank_account_1_6_5: `State`,
    dialog_add_bank_account_1_6_6: `Zipcode`,
    dialog_add_bank_account_1_7: `Save as default method`,
    dialog_add_bank_account_1_8: `Cancel`,
    dialog_add_bank_account_1_9: `Add Bank Account`,
    dialog_add_bank_account_2: `Edit Bank Account`,
    dialog_add_bank_account_2_1: `Update Bank Account`,
    // ----------------------------------------------------------------------
    dialog_add_card_1: `Add Card`,
    dialog_add_card_1_0: `Please fill out the form below to add a debit or credit card as your payment method.`,
    dialog_add_card_1_1: `Payment Information`,
    dialog_add_card_1_2: `Full Name`,
    dialog_add_card_1_3: `Card Number`,
    dialog_add_card_1_3_1: `We only accept major U.S. cards`,
    dialog_add_card_1_4: `Exp. Month`,
    dialog_add_card_1_5: `Exp. Year`,
    dialog_add_card_1_6: `Security Code (CVV)`,
    dialog_add_card_1_7: `Billing Address`,
    dialog_add_card_1_8: `Street 1`,
    dialog_add_card_1_9: `Street 2 (optional)`,
    dialog_add_card_1_10: `City`,
    dialog_add_card_1_11: `State`,
    dialog_add_card_1_12: `Zipcode`,
    dialog_add_card_1_13: `Save as default method`,
    dialog_add_card_1_14: `Cancel`,
    dialog_add_card_1_15: `Add Card`,
    dialog_add_card_2: `Edit Card`,
    dialog_add_card_2_1: `Update Card`,
    // ----------------------------------------------------------------------
    dialog_delete_card_1: `Delete Payment Method`,
    dialog_delete_card_2: `Are you sure you want to delete this payment method? This action cannot be undone.`,
    dialog_delete_card_3: `Cancel`,
    dialog_delete_card_4: `Delete`,
    // ----------------------------------------------------------------------
    dialog_recharge_1: `Add Funds`,
    dialog_recharge_1_1: `Use a payment method to add funds to your ShipSaving Account balance.`,
    dialog_recharge_1_2_1: `Payment Method`,
    dialog_recharge_1_2_1_1: `ACH`,
    dialog_recharge_1_2_1_2: `Credit Card`,
    dialog_recharge_1_2_1_3: `Zelle`,
    dialog_recharge_1_2_1_3_1: `Complete the first payment to unlock Zelle transfer.`,
    dialog_recharge_1_2_1_4: `Wechat Pay`,
    dialog_recharge_1_2_1_5: `Alipay`,
    dialog_recharge_1_2_2: `Payment Information`,
    dialog_recharge_1_2_2_1: `Ending in`,
    dialog_recharge_1_2_2_2: `Ending in`,
    dialog_recharge_1_2_2_3: `Default`,
    dialog_recharge_1_2_3: `Payment Amount`,
    dialog_recharge_1_2_4: `Cancel`,
    dialog_recharge_1_2_5: `Add Money`,
    // ----------------------------------------------------------------------
    dialog_recharge_by_zelle_1: `Zelle Transfer Information`,
    dialog_recharge_by_zelle_1_0: `Check your add funds process`,
    dialog_recharge_by_zelle_1_1: `Our Account: 626-295-3252`,
    dialog_recharge_by_zelle_1_2: `Company Name: Help Half Plus Inc.`,
    dialog_recharge_by_zelle_1_3: `After a successful deposit, `,
    dialog_recharge_by_zelle_1_4: `please help us identify your transaction by upload screenshot.`,
    dialog_recharge_by_zelle_1_5: ` Your transaction will be processed within 1 business day. (Service hours: Monday to Friday, 9am - 6pm PDT/PST)`,
    dialog_recharge_by_zelle_2: `Amount (minimum $500)`,
    dialog_recharge_by_zelle_3: `Transaction Information`,
    dialog_recharge_by_zelle_3_1: `Name or Company Name`,
    dialog_recharge_by_zelle_3_2_1: `Confirmation Number`,
    dialog_recharge_by_zelle_3_2_2: `* If you don't have number, you can leave it blank`,
    dialog_recharge_by_zelle_5_1: `Drop your payment screenshot here or `,
    dialog_recharge_by_zelle_5_2: `Browse`,
    dialog_recharge_by_zelle_5_3: `Allowed *.jpeg, *.jpg, *.png Max size of 5.0 MB`,
    // ----------------------------------------------------------------------
    settings_zelle_1: `Zelle History`,
    settings_zelle_2: `Status`,
    // ----------------------------------------------------------------------
    dialog_recharge_result_alipay_1: `Add Funds by Alipay`,
    dialog_recharge_result_alipay_2: `Please follow the steps on the newly opened page to complete your Alipay payment.`,
    dialog_recharge_result_alipay_3: `OK`,
    // ----------------------------------------------------------------------
    dialog_recharge_result_wechatpay_1: `Add Funds by WeChat Pay`,
    dialog_recharge_result_wechatpay_2: `Please follow the steps on the newly opened page to complete your WeChat Pay payment.`,
    dialog_recharge_result_wechatpay_3: `OK`,
    // ----------------------------------------------------------------------
    dialog_recharge_result_zelle_1: `Submission Successful`,
    dialog_recharge_result_zelle_2_1: `Your transaction will be processed within `,
    dialog_recharge_result_zelle_2_2: `1 business day`,
    dialog_recharge_result_zelle_2_3: `. (Service hours: 9am - 6pm PDT/PST)`,
    dialog_recharge_result_zelle_2_4: `. You can also `,
    dialog_recharge_result_zelle_2_5: `check the progress of your current recharge.`,
    dialog_recharge_result_zelle_3: `OK`,
    // ----------------------------------------------------------------------
    dialog_recharge_result_ach_1: `Add Funds by ACH`,
    dialog_recharge_result_ach_2_1: `Your transaction will be processed up to `,
    dialog_recharge_result_ach_2_2: `7 business day`,
    dialog_recharge_result_ach_2_3: `. (Service hours: 9am - 6pm PDT/PST)`,
    dialog_recharge_result_ach_3: `OK`,
    // ----------------------------------------------------------------------
    dialog_recharge_result_1: `Funds Added Successfully`,
    dialog_recharge_result_2_1: ``,
    dialog_recharge_result_2_2: ` has been added to your account balance. `,
    dialog_recharge_result_2_3: `Now you can continue buying labels on ShipSaving.`,
    dialog_recharge_result_2_3_1: `🎉 Now you‘ve `,
    dialog_recharge_result_2_3_1_1: `unlocked Zelle`,
    dialog_recharge_result_2_3_1_2: ` for your next payment method.`,
    dialog_recharge_result_3: `OK`,
    // ----------------------------------------------------------------------
    dialog_balance_payoff_1: `Account Unlocked`,
    dialog_balance_payoff_2: `Your negative balance has been successfully paid. You can now continue to use all ShipSaving services.`,
    dialog_balance_payoff_3: `OK`,
    // ----------------------------------------------------------------------
    dialog_zelle_annotation_1_1: `Navigate to Zelle within Online Banking or the Mobile Banking App. Click the `,
    dialog_zelle_annotation_1_2: `Activity`,
    dialog_zelle_annotation_1_3: ` tab to view pending or past transactions. Once you do this, you'll see your transaction confirmation number. 😊`,
    // ----------------------------------------------------------------------
    dialog_downgrade_plan_1: `Switch to Starter Plan`,
    dialog_downgrade_plan_2: `I understand that by switching to Starter (free plan), `,
    dialog_downgrade_plan_3: `I cannot access`,
    dialog_downgrade_plan_4: ` the SCAN form, advanced order and item management, report, user permission, and add funds to my balance. Any previously configured shipping rules will not be able to be recovered.`,
    dialog_downgrade_plan_5: `I HAVE READ AND AGREE TO THE INFORMATION ABOVE.`,
    dialog_downgrade_plan_6: `Cancel`,
    dialog_downgrade_plan_7: `Confirm to Switch`,
    // ----------------------------------------------------------------------
    dialog_downgrade_notification_plan_1: `Plan Downgrade Alert`,
    dialog_downgrade_notification_plan_2_1: `Your Standard plan will automatically downgrade to the Starter plan on `,
    dialog_downgrade_notification_plan_2_2: `. Until then, you can still enjoy all the features of the Standard plan. However, if you exhaust your print label quota before `,
    dialog_downgrade_notification_plan_2_3: `, your plan will be downgraded to Starter ahead of schedule.`,
    dialog_downgrade_notification_plan_3: `OK`,
    // ----------------------------------------------------------------------
    dialog_refund_failed_notification_1_1: `Your label has been successfully voided. An issue occurred during the refund process. Please contact us at `,
    dialog_refund_failed_notification_1_2: `payment@shipsaving.com`,
    dialog_refund_failed_notification_1_3: ` for prompt resolution.`,
    dialog_refund_failed_notification_2: `Contact Us`,
    // ----------------------------------------------------------------------
    dialog_add_user_1: `Add New User`,
    dialog_add_user_5: `To gives users access to your account, you need to add them to your teams.`,
    dialog_add_user_1_1: `Basic Information`,
    dialog_add_user_1_1_1: `First Name`,
    dialog_add_user_1_1_2: `Last Name`,
    dialog_add_user_1_1_3: `Email`,
    dialog_add_user_1_1_4: `Store`,
    dialog_add_user_1_2: `Permissions`,
    dialog_add_user_2_shipments: `Shipments`,
    dialog_add_user_2_orders: `Orders`,
    dialog_add_user_2_items: `Items`,
    dialog_add_user_2_reports: `Reports`,
    dialog_add_user_2_account: `Account`,
    dialog_add_user_2_integration: `Integration`,
    dialog_add_user_2_configuration: `Configuration`,
    dialog_add_user_2_shipments_my_shipments: `My Shipments & Draft`,
    dialog_add_user_2_shipments_scan_form: `Scan Form`,
    dialog_add_user_2_shipments_pick_up: `Pick Up`,
    dialog_add_user_2_shipments_buy_label: `Buy Label`,
    dialog_add_user_2_shipments_reprint_label: `Reprint Label`,
    dialog_add_user_2_shipments_batch_print: `Batch Print`,
    dialog_add_user_2_shipments_void_label: `Void Label`,
    dialog_add_user_2_shipments_scan_form_add: `Add`,
    dialog_add_user_2_shipments_scan_form_print: `Print`,
    dialog_add_user_2_shipments_pick_up_add: `Add`,
    dialog_add_user_2_shipments_pick_up_cancel: `Cancel`,
    dialog_add_user_2_order_combin_order: `Combine Order`,
    dialog_add_user_2_order_add: `Add`,
    dialog_add_user_2_order_import: `Import`,
    dialog_add_user_2_order_export: `Export`,
    dialog_add_user_2_order_filter: `Filter`,
    dialog_add_user_2_order_rate: `Rate`,
    dialog_add_user_2_order_change_status: `Change Status`,
    dialog_add_user_2_order_hold: `Tag to Hold`,
    dialog_add_user_2_order_return_label: `Create Return Label`,
    dialog_add_user_2_order_void_label: `Void Label`,
    dialog_add_user_2_order_download_label: `Download Label`,
    dialog_add_user_2_item_add: `Add`,
    dialog_add_user_2_item_delete: `Delete`,
    dialog_add_user_2_item_modify: `Edit`,
    dialog_add_user_2_reports_info: `Info`,
    dialog_add_user_2_account_billing: `Billing`,
    dialog_add_user_2_account_plan: `Plan`,
    dialog_add_user_2_account_user: `User`,
    dialog_add_user_2_account_billing_recharge: `Add Funds`,
    dialog_add_user_2_account_billing_bank_account: `Bank Account`,
    dialog_add_user_2_account_billing_card: `Add Payment Method`,
    dialog_add_user_2_account_billing_transaction: `Transaction`,
    dialog_add_user_2_account_billing_transaction_export: `export`,
    dialog_add_user_2_account_billing_transaction_invoice: `Invoice`,
    dialog_add_user_2_account_plan_info: `Info`,
    dialog_add_user_2_account_user_modify: `Edit`,
    dialog_add_user_2_integration_store: `Store`,
    dialog_add_user_2_integration_carrier: `Carrier`,
    dialog_add_user_2_integration_store_modify: `Edit`,
    dialog_add_user_2_integration_carrier_modify: `Edit`,
    dialog_add_user_2_configuration_ship_from: `Ship From`,
    dialog_add_user_2_configuration_ship_to: `Ship To`,
    dialog_add_user_2_configuration_package: `Package`,
    dialog_add_user_2_configuration_shipping_rule: `Shipping Rule`,
    dialog_add_user_2_configuration_automation: `Automation`,
    dialog_add_user_2_configuration_ship_from_modify: `Edit`,
    dialog_add_user_2_configuration_ship_to_modify: `Edit`,
    dialog_add_user_2_configuration_package_modify: `Edit`,
    dialog_add_user_2_configuration_shipping_rule_modify: `Edit`,
    dialog_add_user_2_usps: `USPS`,
    dialog_add_user_2: `Edit User`,
    dialog_add_user_3: `Cancel`,
    dialog_add_user_4_1: `Add User`,
    dialog_add_user_4_2: `Update`,
    // ----------------------------------------------------------------------
    dialog_combine_order_1: `Combine Orders`,
    dialog_combine_order_1_1: `Combine all orders with the same recipient so you can process and ship them as one.`,
    dialog_combine_order_2: `Combine`,
    dialog_combine_order_3: `You have no available orders to combine.`,
    dialog_combine_order_4: `OK`,
    // ----------------------------------------------------------------------
    dialog_new_edit_order_1_1: `Add New Order`,
    dialog_new_edit_order_1_2: `Edit Order`,
    dialog_new_edit_order_2: `Allow users to create new orders for your customized store.`,
    dialog_new_edit_order_3: `Store`,
    dialog_new_edit_order_3_1: `Select Customize Store`,
    dialog_new_edit_order_3_1_1: `Add New Customize Store`,
    dialog_new_edit_order_3_2: `Ship to`,
    dialog_new_edit_order_3_2_1: `Don't use saved ship to address`,
    dialog_new_edit_order_3_2_2: `Use new ship to address`,
    dialog_new_edit_order_3_3: `Residential Address`,
    dialog_new_edit_order_4: `Item`,
    dialog_new_edit_order_4_1: `Add item`,
    dialog_new_edit_order_4_2: `Item`,
    dialog_new_edit_order_4_3: `Search for item SKU & name`,
    dialog_new_edit_order_4_4: `Quantity`,
    dialog_new_edit_order_5: `Order Total Price`,
    dialog_new_edit_order_5_1: `Order Price`,
    dialog_new_edit_order_5_2: `Tax (optional)`,
    dialog_new_edit_order_5_3: `Shipping Fee (optional)`,
    dialog_new_edit_order_6: `Cancel`,
    dialog_new_edit_order_7: `Add to Order`,
    dialog_new_edit_order_8: `Save Changes`,
    // ----------------------------------------------------------------------
    dialog_pro_plan_1: `BECOME A PRO NOW`,
    dialog_pro_plan_2: `By this upgrade you will get all features from ShipSaving for Starter, plus:`,
    dialog_pro_plan_3_1: `Automated Workflow`,
    dialog_pro_plan_3_2: `Combine & Split Order, Batch Processing, Shipping Rules...`,
    dialog_pro_plan_3_3: `WMS & Inventory`,
    dialog_pro_plan_3_4: `Warehouse/Bin Location, Smart Scan, Multi-Location Support...`,
    dialog_pro_plan_3_5: `User Management`,
    dialog_pro_plan_3_6: `User Permissions Support, Assign Stores to Specific User...`,
    dialog_pro_plan_4: `Contact Us`,
    dialog_pro_plan_5_1: `Upgrade today to access `,
    dialog_pro_plan_5_2: `all the advanced features`,
    dialog_pro_plan_5_3: ` at ShipSaving.`,
    // ----------------------------------------------------------------------
    dialog_connect_store_1: `Connect Your Store`,
    dialog_connect_store_2: `Select your E-commerce store to connect and sync orders automatically`,
    dialog_connect_store_3: `Unlock more store integrations at ShipSaving`,
    dialog_connect_store_4: `Upgrade to Pro Plan`,
    dialog_connect_store_5: `See more stores`,
    dialog_connect_store_6: 'https://www.shipsaving.com/en/partners',
    dialog_connect_store_ecommerce_platforms: `Ecommerce Platforms`,
    dialog_connect_store_marketplaces: `Marketplaces`,
    dialog_connect_store_order_and_inventory_management: `Order and Inventory Management`,
    dialog_connect_store_customized_store: `Customized Store`,
    dialog_connect_store_7_1: `Unlock more store integrations at ShipSaving · `,
    dialog_connect_store_7_2: `Upgrade to Pro Plan`,
    // ----------------------------------------------------------------------
    dialog_add_store_1: `Back`,
    dialog_add_store_1_1: `Integrate with ShipSaving to boost sales.`,
    dialog_add_store_1_2: ` Integration Guide`,
    dialog_add_store_2: `Nickname`,
    dialog_add_store_3: `Ship From`,
    dialog_add_store_4: `Auto Setting`,
    dialog_add_store_5: `Download and Sync new orders`,
    dialog_add_store_6: `Upload tracking number to your stores`,
    dialog_add_store_7: `Cancel`,
    dialog_add_store_8: `Save Changes`,
    dialog_add_store_9: `Add Store`,
    dialog_add_store_9_1: `+ Add Your Ship From Address`,
    dialog_add_store_10: `Credentials`,
    dialog_add_store_notify_customer: `Notify Customer`,
    dialog_add_store_auto_email_tracking: `Send shipping confirmation email to customers`,
    dialog_add_store_notify_sales_channel: `Notify Sales Channel`,
    dialog_add_store_11_1: `Store nickname must be under 60 characters`,
    // ----------------------------------------------------------------------
    dialog_select_carrier_1: `Connect Carrier`,
    dialog_select_carrier_2: `Select a carrier to get a shipping discount`,
    dialog_select_carrier_3: `Unlock more carrier account integrations`,
    dialog_select_carrier_4: `Upgrade to Pro Plan`,
    dialog_select_carrier_5: `Coming soon`,
    // ----------------------------------------------------------------------
    dialog_bill_account_1: `Bill Account`,
    dialog_bill_account_1_1: `Select a carrier to get a shipping discount`,
    dialog_bill_account_2: `Bill Accounts`,
    dialog_bill_account_3: `Add bill account`,
    dialog_bill_account_4: `Delete Account`,
    dialog_bill_account_5_1: `Nickname`,
    dialog_bill_account_5_2: `Account Number`,
    dialog_bill_account_5_3: `Zip Code`,
    dialog_bill_account_5_4: `Country`,
    dialog_bill_account_6: `Cancel`,
    dialog_bill_account_7: `Save Change`,
    // ----------------------------------------------------------------------
    dialog_bill_account_delete_1: `Are you sure you want to delete this account ?`,
    dialog_bill_account_delete_2: `You are about to delete your bill account. This action cannot be undone, and you will no longer get UPS rates at ShipSaving.`,
    dialog_bill_account_delete_3: `Cancel`,
    dialog_bill_account_delete_4: `Delete Account`,
    // ----------------------------------------------------------------------
    dialog_add_ups_carrier_1: `Add UPS Account`,
    dialog_add_ups_carrier_1_1: `Please note that if you want to connect your own UPS account to the ShipSaving platform, you will `,
    dialog_add_ups_carrier_1_2: `not be able to use the ShipSaving-provided UPS discounts permanently.`,
    dialog_add_ups_carrier_2: `Account Nickname (optional)`,
    dialog_add_ups_carrier_3: `Contact Information`,
    dialog_add_ups_carrier_4: `Full Name`,
    dialog_add_ups_carrier_5: `Title`,
    dialog_add_ups_carrier_6: `Company`,
    dialog_add_ups_carrier_7: `Company URL`,
    dialog_add_ups_carrier_8: `Email`,
    dialog_add_ups_carrier_9: `Phone`,
    dialog_add_ups_carrier_10: `Billing Address`,
    dialog_add_ups_carrier_11: `Street 1`,
    dialog_add_ups_carrier_12: `Street 2 (optional)`,
    dialog_add_ups_carrier_13: `City`,
    dialog_add_ups_carrier_14: `State`,
    dialog_add_ups_carrier_15: `Zipcode`,
    dialog_add_ups_carrier_16: `Select Country`,
    dialog_add_ups_carrier_17: `Account Information`,
    dialog_add_ups_carrier_18: `Account Number`,
    dialog_add_ups_carrier_19: `Account Zip`,
    dialog_add_ups_carrier_20: `Select Country`,
    dialog_add_ups_carrier_21: `Invoice Information`,
    dialog_add_ups_carrier_22: `Invoiced Date`,
    dialog_add_ups_carrier_23: `Invoice Number`,
    dialog_add_ups_carrier_24: `Control ID`,
    dialog_add_ups_carrier_25: `Invoice Amount`,
    dialog_add_ups_carrier_26: `I have received an invoice in the last 90 days`,
    dialog_add_ups_carrier_27_1: `By checking this box, you agree to the `,
    dialog_add_ups_carrier_27_2: `UPS Technology Agreement`,
    dialog_add_ups_carrier_27_3: `.`,
    dialog_add_ups_carrier_28_1: `To be verified, please provide some information for a valid invoice. To be valid, the invoice must meet the following criteria`,
    dialog_add_ups_carrier_28_1_1: `Be one of the last 3 invoices issued.`,
    dialog_add_ups_carrier_28_1_2: `Be less than 90 days for US and CA.`,
    dialog_add_ups_carrier_28_1_3: `Be less than 45 days for all other countries.`,
    dialog_add_ups_carrier_28_2: `This information can be found at the top right of your invoice in "My UPS" account online. Please write the invoice number exactly as it appears on the bill.`,
    dialog_add_ups_carrier_29: `Cancel`,
    dialog_add_ups_carrier_30: `Add Carriers`,
    // ----------------------------------------------------------------------
    dialog_confirm_connect_ups_carrier_1: `Your UPS account is connected!`,
    dialog_confirm_connect_ups_carrier_2: `Please note your transactions at ShipSaving, including label purchases and shipping adjustments, will bill to your own UPS account.`,
    dialog_confirm_connect_ups_carrier_3: `OK`,
    // ----------------------------------------------------------------------
    dialog_failed_connect_ups_carrier_1: `Oops, something went wrong!`,
    dialog_failed_connect_ups_carrier_2: `We’re having some trouble connect your UPS account. Please try again later or contact us for any further assistance.`,
    dialog_failed_connect_ups_carrier_3: `Try Again`,
    // ----------------------------------------------------------------------
    dialog_delete_ups_carrier_1: 'Are you sure you want to delete this account ?',
    dialog_delete_ups_carrier_2: 'You are about to delete your UPS account. This action cannot be undone, and you will no longer get UPS rates at ShipSaving.',
    dialog_delete_ups_carrier_3: 'Cancel',
    dialog_delete_ups_carrier_4: 'Delete Account',
    // ----------------------------------------------------------------------
    dialog_upgrade_plan_1: 'Connect Your Carrier Account',
    dialog_upgrade_plan_2: 'You can connect your own carriers and see your pre-negotiated shipping rates inside ShipSaving by upgrading your plan.',
    dialog_upgrade_plan_3: 'Cancel',
    dialog_upgrade_plan_4: 'Learn More',
    dialog_upgrade_plan_5: `Ready for an Upgrade?`,
    dialog_upgrade_plan_6: `Your chosen package has a taste for luxury! The shipping services for your selected package is only available with our `,
    dialog_upgrade_plan_7: `Standard plan.`,
    dialog_upgrade_plan_8: `Upgrade with 14-day Free Trial`,
    // ----------------------------------------------------------------------
    dialog_add_ship_from_address_1: `Add New Address`,
    dialog_add_ship_from_address_1_1: `Ship From address is the location from which your shipment will be picked up for final delivery.`,
    dialog_add_ship_from_address_1_2_1: `First Name`,
    dialog_add_ship_from_address_1_2_2: `Last Name`,
    dialog_add_ship_from_address_1_2_3: `Name`,
    dialog_add_ship_from_address_1_3: `Company (optional)`,
    dialog_add_ship_from_address_1_4: `Email (optional)`,
    dialog_add_ship_from_address_1_5: `Phone`,
    dialog_add_ship_from_address_1_6: `Save as default address`,
    dialog_add_ship_from_address_1_7: `Residential Address`,
    dialog_add_ship_from_address_1_8: `Cancel`,
    dialog_add_ship_from_address_1_9: `Add Address`,
    dialog_add_ship_from_address_2: `Edit address`,
    dialog_add_ship_from_address_2_1: `Save Address`,
    // ----------------------------------------------------------------------
    dialog_add_address_1: `Add New Address`,
    dialog_add_address_1_1: `Ship To address is the location from which your shipment will be delivered to.`,
    dialog_add_address_1_2_1: `First Name`,
    dialog_add_address_1_2_2: `Last Name`,
    dialog_add_address_1_2_3: `Name`,
    dialog_add_address_1_3: `Company (optional)`,
    dialog_add_address_1_4: `Email (optional)`,
    dialog_add_address_1_5: `Phone`,
    dialog_add_address_1_6: `Save as default address`,
    dialog_add_address_1_7: `Residential Address`,
    dialog_add_address_1_8: `Cancel`,
    dialog_add_address_1_9: `Add Address`,
    dialog_add_address_2: `Edit Address`,
    dialog_add_address_2_1: `Save Address`,
    // ----------------------------------------------------------------------
    dialog_delete_address_1: 'Are you sure to delete this Address ?',
    dialog_delete_address_2: 'You will not be able to use this address for any future labels. Any previously purchased labels will not be affected.',
    dialog_delete_address_3: 'Cancel',
    dialog_delete_address_4: 'Delete Address',
    // ----------------------------------------------------------------------
    dialog_delete_item_1: 'Are you sure you want to delete this item ?',
    dialog_delete_item_2: 'Please note that deleting the item will cause the item image to disappear from the order list. Are you sure you want to continue?',
    dialog_delete_item_3: 'Cancel',
    dialog_delete_item_4: 'Delete',
    // ----------------------------------------------------------------------
    dialog_add_package_1: `Add New Packages`,
    dialog_add_package_1_1: `You can add your primarily used package to make it easy for shipping next time.`,
    dialog_add_package_1_2: `Package Info`,
    dialog_add_package_1_4: `Dimension`,
    dialog_add_package_1_5: `Length (in)`,
    dialog_add_package_1_6: `Width (in)`,
    dialog_add_package_1_7: `Height (in)`,
    dialog_add_package_1_8: `Package Nickname`,
    dialog_add_package_1_9: `Nickname`,
    dialog_add_package_1_10: `Save as default package for label purchases`,
    dialog_add_package_1_11: `Cancel`,
    dialog_add_package_1_12: `Add Package`,
    dialog_add_package_2: `Edit Packages`,
    dialog_add_package_2_1: `Save Package`,
    // ----------------------------------------------------------------------
    dialog_delete_package_1: 'Are you sure to delete this package ?',
    dialog_delete_package_2: 'You will not be able to use this package for any future labels. Any previously purchased labels will not be affected.',
    dialog_delete_package_3: 'Cancel',
    dialog_delete_package_4: 'Delete Package',
    // ----------------------------------------------------------------------
    dialog_add_shipping_rule_1: `Add New Shipping Rule`,
    dialog_add_shipping_rule_1_1: `Shipping Rules save you time and effort to automate order processing within ShipSaving.`,
    dialog_add_shipping_rule_1_2: `Store`,
    dialog_add_shipping_rule_1_2_1: `Select Store`,
    dialog_add_shipping_rule_1_2_2: `No customized store`,
    dialog_add_shipping_rule_1_3: `Package Info`,
    dialog_add_shipping_rule_1_4: `Shipping Option`,
    dialog_add_shipping_rule_1_5: `Cancel`,
    dialog_add_shipping_rule_1_6: `Add Shipping Rule`,
    dialog_add_shipping_rule_2: `Edit Shipping Rule`,
    dialog_add_shipping_rule_2_1: `Save Changes`,
    // ----------------------------------------------------------------------
    dialog_pickup_schedule_confirm_1: `Your Pickup Request is Confirmed!`,
    dialog_pickup_schedule_confirm_2: `Confirmation#`,
    dialog_pickup_schedule_confirm_3: `Pickup time`,
    dialog_pickup_schedule_confirm_4: `Please leave your packages with all labels and postage attached where you said it would be the location of your scheduled pickup day.`,
    dialog_pickup_schedule_confirm_5: `OK`,
    // ----------------------------------------------------------------------
    dialog_cancel_pickup_schedule_1: `Are you sure you want to cancel this pickup?`,
    dialog_cancel_pickup_schedule_2: `You are about to cancel your USPS scheduled pickup, please remember that this action can not be undo. An email will be sent to you with the updated change confirmation.`,
    dialog_cancel_pickup_schedule_3: `Cancel`,
    dialog_cancel_pickup_schedule_4: `Continue`,
    // ----------------------------------------------------------------------
    dialog_get_shipments_label_1_1: `Got a Printer?`,
    dialog_get_shipments_label_1_2: `Print your shipping label immediately if you have a printer near you, and attach the label to the box or envelope.`,
    dialog_get_shipments_label_1_3: `Print Label`,
    dialog_get_shipments_label_2_1: `No Printer?`,
    dialog_get_shipments_label_2_2: `Take your QR code with your shipment to the Post Office. USPS will scan the code and print the label at the counter.`,
    dialog_get_shipments_label_2_3: `Get QR Code`,
    dialog_get_shipments_label_3_1: `No Printer?`,
    dialog_get_shipments_label_3_2: `We got your back! Present the barcode at the UPS Store® locations to scan and ship your packed items.`,
    dialog_get_shipments_label_3_3: `Get Barcode`,
    // ----------------------------------------------------------------------
    dialog_custom_form_desc_1: `Customs Form Tips:`,
    dialog_custom_form_desc_2: `Please provide detailed descriptions for all the items enclosed in the package. Include a comprehensive overview of each item, detailing its use and what it’s made of. `,
    dialog_custom_form_desc_3: `A clear and accurate description of the items`,
    dialog_custom_form_desc_4: ` is the best way to ensure your package travels through customs smoothly.`,
    dialog_custom_form_desc_5: `Provide `,
    dialog_custom_form_desc_6: `an accurate unit price`,
    dialog_custom_form_desc_7: ` for each item. Customs authorities will check if you have any undervalued items and charge the correct amount, potentially adding extra handling fees for miss-declared items.`,
    dialog_custom_form_desc_8: `Print and attach the shipping label and all the related documents`,
    dialog_custom_form_desc_9: ` (invoices, custom forms, etc.) to the outside package. Make sure you use transparent tape (or put it in the plastic envelope attached to the package) so that the form can be read.`,
    // ----------------------------------------------------------------------
    dialog_download_label_1_1: `Download Label`,
    dialog_download_label_1_2: `Share Label Link`,
    dialog_download_label_2_1: `Drop Off`,
    dialog_download_label_2_2: `Schedule Pickup`,
    // ----------------------------------------------------------------------
    dialog_ups_return_pickup_1: `UPS Return Label - Pickup Attempt(s)`,
    dialog_ups_return_pickup_2: `UPS will print the return label for you and make attempt(s) to collect your shipment. If the shipment cannot be collected, UPS will leave the return label at the collection location.`,
    // ----------------------------------------------------------------------
    dialog_multi_packages_return_1: `Reset Your Package Info`,
    dialog_multi_packages_return_2: `You cannot create return label for multi-piece shipment. If you want to continue, your previous package info will be reset.`,
    dialog_multi_packages_return_3: `Cancel`,
    dialog_multi_packages_return_4: `Continue`,
    // ----------------------------------------------------------------------
    dialog_gwt_qr_code_1_1: `To avoid system delays, please scan your QR CODE at USPS the following day.`,
    dialog_gwt_qr_code_1_2: `Bring your QR code and package to the Post Office; USPS will scan and print the label.`,
    dialog_gwt_qr_code_2: `Save QR Code`,
    dialog_gwt_qr_code_3_1: `If you have access to a printer later, you can print labels directly from USPS.com. \nGo to `,
    dialog_gwt_qr_code_3_2: `USPS Label Broker`,
    // ----------------------------------------------------------------------
    dialog_gwt_bar_code_1: `Present this code at The UPS Store® locations to scan and ship your packed items.`,
    dialog_gwt_bar_code_2: `Save Barcode`,
    // ----------------------------------------------------------------------
    dialog_shipment_rates_desc_1: `Media Mail`,
    dialog_shipment_rates_desc_1_1: `You can send `,
    dialog_shipment_rates_desc_1_2: `books, videotapes, DVDs, CDs, printed music and other sound recordings`,
    dialog_shipment_rates_desc_1_3: ` through Media Mail as long as they weigh less than 70 pounds.`,
    dialog_shipment_rates_desc_1_4: `Please note USPS has a right to open and inspect your package to check for abuse of the system. Therefore, `,
    dialog_shipment_rates_desc_1_5: `you shouldn’t mail advertisements (except for a book announcement with a book) or other non-media material through Media Mail`,
    dialog_shipment_rates_desc_1_6: ` because you will risk having your package sent back to you for insufficient postage.`,
    dialog_shipment_rates_desc_2: `Library Mail`,
    dialog_shipment_rates_desc_2_1: `Library Mail is an inexpensive way `,
    dialog_shipment_rates_desc_2_2: `for libraries, academic institutions, museums, nonprofits and similar organizations can send items`,
    dialog_shipment_rates_desc_2_3: ` on loan to one another.`,
    dialog_shipment_rates_desc_2_4: `Just like Media Mail, `,
    dialog_shipment_rates_desc_2_5: `items sent through Library Mail cannot contain advertising or other non-media materials except for a packing slip.`,
    dialog_shipment_rates_desc_2_6: ` Items take 7 to 10 days to arrive to their final destination.`,
    dialog_shipment_rates_desc_3: `OK`,
    // ----------------------------------------------------------------------
    dialog_shipment_rates_fedex_desc_1: `Delivery time`,
    dialog_shipment_rates_fedex_desc_1_1: `Typically, delivery takes 2 to 7 business days, depending on the distance from the package’s origin to its destination. Expect longer times outside the contiguous 48 states.`,
    dialog_shipment_rates_fedex_desc_2: `Maximum package size and weight limit`,
    dialog_shipment_rates_fedex_desc_2_1: `Packages up to 70 lbs. and 130" in length plus girth can be shipped. The ideal package weight is 1–10 lbs.`,
    dialog_shipment_rates_fedex_desc_3: `Exceptions`,
    dialog_shipment_rates_fedex_desc_3_1: `The following options are not available for FedEx Ground Economy:`,
    // ----------------------------------------------------------------------
    dialog_shipment_no_service_desc_1: `Check out Why the Following Services are not Available`,
    dialog_shipment_no_service_desc_2: `OK`,
    // ----------------------------------------------------------------------
    dialog_usps_hazardous_dialog_1: `Are you shipping dangerous goods or hazardous materials?`,
    dialog_usps_hazardous_dialog_2: `Please note the newest changes to `,
    dialog_usps_hazardous_dialog_3: `USPS Publication 52 (Pub 52)`,
    dialog_usps_hazardous_dialog_4: ` will affect your shipments. The hazardous items must ship in separate packages. `,
    dialog_usps_hazardous_dialog_5: `Hazardous materials include batteries, dry ice, flammable liquids, aerosol sprays, air bags, ammunition, fireworks, gasoline, lighters, lithium batteries, matches, nail polish, paint, perfume and more. `,
    dialog_usps_hazardous_dialog_6: `View tutorial`,
    dialog_usps_hazardous_dialog_7: ` about how to package, label and ship HAZMAT for domestic shipments.`,
    dialog_usps_hazardous_dialog_8: `Yes`,
    dialog_usps_hazardous_dialog_9: `No`,
    // ----------------------------------------------------------------------
    dialog_usps_hazmat_dialog_1: `Ship HAZMAT with USPS`,
    dialog_usps_hazmat_dialog_2: `The selection of HAZMAT is exclusively available for USPS and not for other carriers. Please note the newest changes to `,
    dialog_usps_hazmat_dialog_3: `USPS Publication 52 (Pub 52)`,
    dialog_usps_hazmat_dialog_4: ` will affect your shipments.`,
    dialog_usps_hazmat_dialog_5: `Selecting this checkbox will lead to showing only the applicable USPS service levels. `,
    dialog_usps_hazmat_dialog_6: `For hazardous materials being shipped domestically, USPS solely provides ground transportation. As such, designating your package as “Hazardous Materials” will result in services like `,
    dialog_usps_hazmat_dialog_7: `Priority Mail or Priority Mail Express not being available.`,
    dialog_usps_hazmat_dialog_8: `View tutorial`,
    dialog_usps_hazmat_dialog_9: ` about how to package, label and ship HAZMAT for domestic shipments.`,
    // ----------------------------------------------------------------------
    dialog_validate_address_1: `Missing Partial Address`,
    dialog_validate_address_1_1: `The address you entered does not support the USPS Schedule Pickups service. We recommend that you check again.`,
    dialog_validate_address_2: `Address Recommendation`,
    dialog_validate_address_2_1: `"We recommend that you use the corrected address, or ignore it.\nNote: This address does not support the USPS Schedule Pickups service."`,
    dialog_validate_address_3: `Update Address`,
    dialog_validate_address_4: `Save Address`,
    // ----------------------------------------------------------------------
    dialog_disconnect_store_1_1: `Are you sure to disconnect `,
    dialog_disconnect_store_1_2: ` from shipsaving ?`,
    dialog_disconnect_store_2: `Orders from this channel will remain, but nothing will be synced once you confirm.`,
    dialog_disconnect_store_3: `Cancel`,
    dialog_disconnect_store_4: `Disconnect`,
    // ----------------------------------------------------------------------
    dialog_verify_payment_method_notification_1: 'Please verify your payment method',
    dialog_verify_payment_method_notification_2: 'Payment Security and Fraud Protection are important to us, that\'s why we charged ',
    dialog_verify_payment_method_notification_3: 'two temporary amounts(each under $1)',
    dialog_verify_payment_method_notification_4: ' from your payment method. Please go to your online banking to check the amounts. 💙',
    dialog_verify_payment_method_notification_5: 'Verify Now',
    dialog_verify_payment_method_notification_6: 'Cancel',
    dialog_verify_payment_method_notification_7: 'Save this shipment as a draft. (Go to Shipment - My Draft page to continue editing this shipment after you verified your card)',
    // ----------------------------------------------------------------------
    dialog_verify_payment_method_0: `How to Verify Your Card?`,
    dialog_verify_payment_method_0_1: `As part of this verification process,`,
    dialog_verify_payment_method_0_2: ` two temporary authorizations are sent to your card. `,
    dialog_verify_payment_method_0_3: `To verify your credit card:`,
    dialog_verify_payment_method_0_4: `1. Sign in to your bank`,
    dialog_verify_payment_method_0_5: `2. Find the two authorizations`,
    dialog_verify_payment_method_0_6: `3. Enter the exact amounts below`,
    dialog_verify_payment_method_1_1: `Verify Your Card Ending `,
    dialog_verify_payment_method_1_2: ``,
    dialog_verify_payment_method_2: `Log in to your online banking and locate the two amounts we've sent you.`,
    dialog_verify_payment_method_3: `Enter the last 2 digits of the amount:`,
    dialog_verify_payment_method_4: `Cancel`,
    dialog_verify_payment_method_5: `Verify Card`,
    // ----------------------------------------------------------------------
    dialog_success_connect_store_1: `Congratulations, your store is connected!`,
    dialog_success_connect_store_2_1: `Please go to `,
    dialog_success_connect_store_2_2: `Settings`,
    dialog_success_connect_store_2_3: `Integration`,
    dialog_success_connect_store_2_4: ` page if you'd like to manage your store setting. Or contact us for any further assistance.`,
    dialog_success_connect_store_3: `OK`,
    // ----------------------------------------------------------------------
    dialog_failed_connect_store_1: `Oops, Something Went Wrong!`,
    dialog_failed_connect_store_2_1: `Your request is failed, please go to `,
    dialog_failed_connect_store_2_2: `Settings`,
    dialog_failed_connect_store_2_3: `Integration`,
    dialog_failed_connect_store_2_4: ` page to connect your store again. Or contact us for any further assistance.`,
    // ----------------------------------------------------------------------
    dialog_void_label_1: `Void Label`,
    dialog_void_label_2_1_1: `This label will be submitted to USPS for a refund. USPS takes up to 30 days to ensure the label is unused before credit to your ShipSaving account balance.`,
    dialog_void_label_2_1_2: `If the label has been scanned already, your request will be ignored by USPS, and will NOT receive a refund.`,
    dialog_void_label_2_2_1: `This label will be submitted to UPS for a refund. If the label has been scanned already, your request will be ignored by UPS, and you will NOT receive a refund.`,
    dialog_void_label_2_2_2_1: `For more information, please `,
    dialog_void_label_2_2_2_2: `login`,
    dialog_void_label_2_2_2_3: ` to your UPS account.`,
    dialog_void_label_2_3_1: `This label will be submitted to DHL Express for a refund. The refund will be returned to your ShipSaving account balance within 1 hour.`,
    dialog_void_label_2_3_2: `If the label has been scanned already, your request will be ignored by DHL Express, and you will NOT receive a refund.`,
    dialog_void_label_3: `Would you like to continue?`,
    dialog_void_label_2_4_1: `This label will be submitted a refund. The refund will be returned to your ShipSaving account balance within 1 hour.`,
    dialog_void_label_2_4_2: `If the label has been scanned already, your request will be ignored, and will NOT receive a refund.`,
    dialog_void_label_2_5_1: `You can request a refund to 15 days from the date on which you purchased the label. `,
    dialog_void_label_2_5_2: `This label will be submitted to FedEx for a refund. FedEx takes up to 3 days to ensure the label is unused before credit to your ShipSaving account balance. If the label has been scanned already, your request will be ignored, and you will NOT receive a refund.`,
    dialog_void_label_4: `Cancel`,
    dialog_void_label_5: `Confirm`,
    // ----------------------------------------------------------------------
    dialog_void_template_1: `Void Label`,
    dialog_void_template_2: `Would you like to continue?`,
    dialog_void_template_3_1: `For more information, please `,
    dialog_void_template_3_2: `log in`,
    dialog_void_template_4_1: `Cancel`,
    dialog_void_template_4_2: `Void Now`,
    dialog_void_fedex_1: `FEDEX`,
    dialog_void_fedex_2_1: `Request a refund within 15 days of label purchase.`,
    dialog_void_fedex_2_2_1: `We will submit your label to FedEx for a refund check. If the label is unused `,
    dialog_void_fedex_2_2_2: `(confirmed by FedEx within 3 days)`,
    dialog_void_fedex_2_2_3: `, we'll credit your original payment method. No refund if the label is scanned.`,
    dialog_void_ups_1: `UPS`,
    dialog_void_ups_2_1: ` Request a refund within 23 days of label purchase.`,
    dialog_void_ups_2_2_1: `We will submit your label to UPS for a refund check. If the label is unused (`,
    dialog_void_ups_2_2_2: `confirmed by UPS within 3 days`,
    dialog_void_ups_2_2_3: `), we'll credit your original payment method. No refund if the label is scanned.`,
    dialog_void_ups_3_1: `This label will be submitted to UPS for a refund. If the label has been scanned already, your request will be ignored by UPS, and you will NOT receive a refund.`,
    dialog_void_ups_3_2: ` to your UPS account.`,
    dialog_void_usps_1: `USPS`,
    dialog_void_usps_2_1: `Request a refund within 23 days of label purchase.`,
    dialog_void_usps_2_2_1: `We will submit your label to USPS for a refund check. If the label is unused (`,
    dialog_void_usps_2_2_2: `confirmed by USPS within 30 days`,
    dialog_void_usps_2_2_3: `), we'll credit your original payment method. No refund if the label is scanned.`,
    dialog_void_dhl_express_1: `DHL Express`,
    dialog_void_dhl_express_2_1: `Request a refund within 23 days of label purchase.`,
    dialog_void_dhl_express_2_2_1: `We will submit your label to DHL Express for a refund check. If the label is unused (`,
    dialog_void_dhl_express_2_2_2: `confirmed by DHL Express within 3 days`,
    dialog_void_dhl_express_2_2_3: `), we'll credit your original payment method. No refund if the label is scanned.`,
    dialog_void_dhl_express_3_1: `This label will be submitted to DHL Express for a refund. If the label has been scanned already, your request will be ignored by DHL Express, and you will NOT receive a refund.`,
    dialog_void_dhl_express_3_2: ` to your DHL Express account.`,
    dialog_void_dhl_ecommerce_1: `DHL eCommerce`,
    dialog_void_dhl_ecommerce_3_1: `This label will be submitted to DHL eCOMMERCE for a refund. If the label has been scanned already, your request will be ignored by DHL eCOMMERCE, and you will NOT receive a refund.`,
    dialog_void_dhl_ecommerce_3_2: ` to your DHL eCOMMERCE account.`,
    dialog_void_ontrac_1: `OnTrac`,
    dialog_void_ontrac_3_1: `This label will be submitted to OnTrac for a refund. If the label has been scanned already, your request will be ignored by OnTrac, and you will NOT receive a refund.`,
    dialog_void_ontrac_3_2: ` to your OnTrac account.`,
    // ----------------------------------------------------------------------
    dialog_pickup_schedule_learn_more_1: `How Does Package Pickup Work?`,
    dialog_pickup_schedule_learn_more_1_1_1: `Package Pickup allows you to schedule a pickup for the next delivery day or a specified date, and mailpiece(s) `,
    dialog_pickup_schedule_learn_more_1_1_2: `must be postage-paid before scheduling a pickup`,
    dialog_pickup_schedule_learn_more_1_1_3: `. There is no limit to the number of packages left for package pickup. 😄`,
    dialog_pickup_schedule_learn_more_1_2_1: `When the mailman in your area delivers, they will stop by and free pick up your mailpiece(s). However, if you make an appointment for a specific time for pickup, this will be an additional charge. If your pickup needs to be scheduled for `,
    dialog_pickup_schedule_learn_more_1_2_2: `a specific date and time`,
    dialog_pickup_schedule_learn_more_1_2_3: `, you can pay a $25 fee directly to `,
    dialog_pickup_schedule_learn_more_1_2_4: `USPS`,
    dialog_pickup_schedule_learn_more_1_2_5: `.`,
    dialog_pickup_schedule_learn_more_2: `What Should You Know When You Make A Schedule?`,
    dialog_pickup_schedule_learn_more_2_1_1: `Priority Mail Express, Priority Mail, and international items `,
    dialog_pickup_schedule_learn_more_2_1_2: ` are eligible for free Package Pickup. Packages of other mail classes, such as `,
    dialog_pickup_schedule_learn_more_2_1_3: `First-Class Mail, 💿 Media Mail and Parcel Select Ground`,
    dialog_pickup_schedule_learn_more_2_1_4: ` can also be picked up, as long as they are combined with one of the above service items.`,
    dialog_pickup_schedule_learn_more_2_2_1: `You can schedule pickups `,
    dialog_pickup_schedule_learn_more_2_2_2: `Monday - Saturday until 2:00 AM CT`,
    dialog_pickup_schedule_learn_more_2_2_3: ` 🕑 on the day of the pickup.`,
    dialog_pickup_schedule_learn_more_2_2_4: ` After 2:00 AM CT`,
    dialog_pickup_schedule_learn_more_2_2_5: `, same-day pickup is not available. You can cancel a scheduled pickup request until `,
    dialog_pickup_schedule_learn_more_2_2_6: `2:00 AM CT`,
    dialog_pickup_schedule_learn_more_2_2_7: ` on the day of the pickup.`,
    dialog_pickup_schedule_learn_more_3: `Limitations On What Can Be Picked Up Using Package Pickup`,
    dialog_pickup_schedule_learn_more_3_1_1: `Individual items `,
    dialog_pickup_schedule_learn_more_3_1_2: `cannot exceed 70 pounds or 130 inches in total length and girth`,
    dialog_pickup_schedule_learn_more_3_1_3: `. 📦 And mailpieces weighing `,
    dialog_pickup_schedule_learn_more_3_1_4: `more than 10 oz. or more than 1/2-inch`,
    dialog_pickup_schedule_learn_more_3_1_5: ` in thickness bearing postage stamps for postage must be presented by the customer to an employee at a retail counter at a Post Office™ location.`,
    dialog_pickup_schedule_learn_more_4: `After Scheduled`,
    dialog_pickup_schedule_learn_more_4_1_1: `After successfully scheduling a pickup, you will receive a 📧 `,
    dialog_pickup_schedule_learn_more_4_1_2: `confirmation email`,
    dialog_pickup_schedule_learn_more_4_1_3: ` with a detailed overview of your pickup information and a confirmation number for your pickup appointment.`,
    dialog_pickup_schedule_learn_more_5: `What To Do If USPS Doesn't Show Up`,
    dialog_pickup_schedule_learn_more_5_1_1: `First, you need to understand that `,
    dialog_pickup_schedule_learn_more_5_1_2: `all pickups are scheduled through USPS`,
    dialog_pickup_schedule_learn_more_5_1_3: `, and if the mailman does not pick up your mail, you can make a second appointment or go directly to the 👉 `,
    dialog_pickup_schedule_learn_more_5_1_4: `post office`,
    dialog_pickup_schedule_learn_more_5_1_5: ` for drop-off.`,
    dialog_pickup_schedule_learn_more_6_1: `😉 If you still want to know more information about Package Pickup Service. Please access `,
    dialog_pickup_schedule_learn_more_6_2: `USPS`,
    dialog_pickup_schedule_learn_more_6_3: ` to get more details.`,
    dialog_pickup_schedule_learn_more_7: `OK`,
    // ----------------------------------------------------------------------
    dialog_notifications_1: `Notifications`,
    dialog_notifications_2_1: `All`,
    dialog_notifications_2_2: `Unread`,
    dialog_notifications_3: `View More`,
    dialog_notifications_4: `You have no messages yet`,
    // ----------------------------------------------------------------------
    dialog_item_1_1: `Add New Item`,
    dialog_item_1_2: `Edit Item`,
    dialog_item_2: `You can add multiple items to your customized stores.`,
    dialog_item_3: `Item Information`,
    dialog_item_3_1: `Select Customize Store`,
    dialog_item_3_1_1: `Add New Customize Store`,
    dialog_item_3_2: `Item Name`,
    dialog_item_3_3: `SKU`,
    dialog_item_3_4: `Quantity`,
    dialog_item_3_5: `Price`,
    dialog_item_3_6: `Thumbnail link`,
    dialog_item_4: `Dimension & Weight`,
    dialog_item_4_1: `Length`,
    dialog_item_4_2: `Width`,
    dialog_item_4_3: `Height`,
    dialog_item_4_4: `in`,
    dialog_item_4_5: `Weight`,
    dialog_item_4_6: `lb`,
    dialog_item_4_7: `oz`,
    dialog_item_5: `More Details (Optional)`,
    dialog_item_5_1: `Tariff Number`,
    dialog_item_5_2: `Select Origin Country`,
    dialog_item_5_3: `Style`,
    dialog_item_5_4: `Color`,
    dialog_item_5_5: `Size`,
    dialog_item_6: `Cancel`,
    dialog_item_7_1: `Add New Item`,
    dialog_item_7_2: `Save Change`,
    // ----------------------------------------------------------------------
    dialog_ups_accept_1_1: `You’re buying your first UPS label`,
    dialog_ups_accept_1_1_1: `Confirm your Ship-From address`,
    dialog_ups_accept_1_1_2: `*Please note this address will be your UPS account default address for return packages.`,
    dialog_ups_accept_2_1: `Congratulations! 🎉You are just one step away from purchasing your first UPS shipping label. To continue, please agree to and accept the`,
    dialog_ups_accept_2_2: `Terms and Conditions`,
    dialog_ups_accept_2_3: `UPS Prohibited Articles`,
    dialog_ups_accept_2_4: `and `,
    dialog_ups_accept_2_5: `UPS Technology Agreement`,
    dialog_ups_accept_3_1: `Accept & Buy Label`,
    dialog_ups_accept_3_2: `Cancel`,
    // ----------------------------------------------------------------------
    dialog_ups_saver_accept_1_1: `You’re buying your first UPS® Ground Saver label`,
    dialog_ups_saver_accept_2_1: `Congratulations! 🎉You are just one step away from purchasing your first UPS® Ground Saver shipping label. To continue, please agree to and accept the`,
    dialog_ups_saver_accept_2_2: `Terms and Conditions`,
    dialog_ups_saver_accept_2_3: `UPS Prohibited Articles`,
    dialog_ups_saver_accept_2_4: `and `,
    dialog_ups_saver_accept_2_5: `UPS Technology Agreement`,
    dialog_ups_saver_accept_3_1: `Accept & Buy Label`,
    dialog_ups_saver_accept_3_2: `Cancel`,
    // ----------------------------------------------------------------------
    response_service_level_desc_GUARANTEE_1: `Service Guarantee`,
    response_service_level_desc_GUARANTEE_2: `.`,
    response_service_level_desc_GUARANTEE_link: `https://www.ups.com/us/en/support/shipping-support/legal-terms-conditions/ups-service-guarantee.page`,
    response_service_level_desc_FIRST_CLASS_MAIL: `Cost-effective way to ship lightweight mails & packages weighing up to 15.99 oz`,
    response_service_level_desc_PRIORITY_MAIL: `Quick and economical way to mail packages under 70 lbs`,
    response_service_level_desc_PRIORITY_MAIL_EXPRESS: `Priority Mail Express® service provides next-day to 2–day delivery service by 6 PM with a `,
    response_service_level_desc_PRIORITY_MAIL_EXPRESS_extra_1: `money–back guarantee`,
    response_service_level_desc_PRIORITY_MAIL_EXPRESS_extra_2: `.`,
    response_service_level_desc_PRIORITY_MAIL_EXPRESS_link: `https://www.usps.com/ship/priority-mail-express.htm#:~:text=Next%2DDay%20to%202%2DDay%20Guarantee1%20%26%20Flat%20Rate%20Pricing&text=Priority%20Mail%20Express%C2%AE%20service,and%20PO%20Boxes%E2%84%A23.`,
    response_service_level_desc_MEDIA_MAIL: `Only for sending books, videotapes, DVDs, CDs, printed music and other sound recordings`,
    response_service_level_desc_LIBRARY_MAIL: `Only for libraries, museums and similar organizations to send items on loan to one another`,
    response_service_level_desc_PARCEL_SELECT_GROUND: `Ground delivery service at very low rates for large or heavy packages`,
    response_service_level_desc_FIRST_CLASS_INTERNATIONAL: `Includes letters, bills, postcards and small packages weighing 4lbs or less`,
    response_service_level_desc_PRIORITY_MAIL_INTERNATIONAL: `Send mail and packages weighing up to 70 lbs`,
    response_service_level_desc_PRIORITY_MAIL_EXPRESS_INTERNATIONAL: `Send international packages up to 70 lbs in 3–5 business days`,
    response_service_level_desc_GROUND: `A cost-effective, reliable ground delivery within 1 - 5 business days.`,
    response_service_level_desc_STANDARD: `Guaranteed economical ground delivery for less-urgent shipments`,
    response_service_level_desc_SAVER: `Guaranteed afternoon delivery times in 1-3 days for international shipments`,
    response_service_level_desc_WORLDWIDE_EXPRESS: `Guaranteed morning delivery times in 1-3 days for international shipments.`,
    response_service_level_desc_WORLDWIDE_EXPRESS_PLUS: `Guaranteed early-morning, day-definite delivery for international shipments`,
    response_service_level_desc_WORLDWIDE_EXPEDITED: `Guaranteed economical delivery for less urgent international shipments`,
    response_service_level_desc_NEXT_DAY_AIR: `Guaranteed next-business-day delivery from and to most addresses in the US50 and Puerto Rico`,
    response_service_level_desc_NEXT_DAY_AIR_SAVER: `Guaranteed next-business-day delivery from and to most addresses in the US48`,
    response_service_level_desc_NEXT_DAY_AIR_EARLY: `Guaranteed early-morning, next-business-day delivery`,
    response_service_level_desc_SECOND_DAY_AIR: `Guaranteed 2nd-business-day delivery from and to most addresses in the US50 and Puerto Rico`,
    response_service_level_desc_SECOND_DAY_AIR_AM: `Guaranteed morning 2nd-business-day delivery from most commercial addresses`,
    response_service_level_desc_THIRD_DAY_SELECT: `Guaranteed 3-working-day delivery`,
    response_service_level_desc_SMART_POST: `A cost-effective solution for shipping packages domestically in the U.S. that weigh under 70 pounds`,
    response_service_level_desc_SUREPOST_LESS_1LB: `An economy service for your non-urgent packages`,
    response_service_level_desc_SUREPOST_1LB_GREATER: `An economy service for your non-urgent packages`,
    response_service_level_desc_DHL_EXPRESS_WORLDWIDE: `Premium worldwide express service by air, only for documents`,
    response_service_level_desc_DHL_EXPRESS_WORLDWIDE_NONDOC: `Premium worldwide express service by air`,
    response_service_level_desc_DHL_EXPRESS_1200: `Delivery before 12 noon on the next possible delivery day. Money-back guarantee included.`,
    response_service_level_desc_DHL_EXPRESS_1200_NONDOC: `Delivery before 12 noon on the next possible delivery day. Money-back guarantee included.`,
    response_service_level_desc_DHL_EXPRESS_ENVELOPE: `With DHL Express Envelope, you can ensure documents that are not subject to customs processes.`,
    response_service_level_desc_DHL_MEDICAL_EXPRESS: `The ideal solution for time and temperature sensitive life science & healthcare shipments.`,
    response_service_level_desc_DHL_MEDICAL_EXPRESS_NODOC: `The ideal solution for time and temperature sensitive life science & healthcare shipments.`,
    response_service_level_desc_DHL_PARCEL_EXPEDITED_MAX: ``,
    response_service_level_desc_DHL_PACKET_INTERNATIONAL: `Access to your own pre-negotiated rates.`,
    response_service_level_desc_DHL_PARCEL_INTERNATIONAL_STANDARD: `Access to your own pre-negotiated rates.`,
    response_service_level_desc_DHL_PARCEL_INTERNATIONAL_DIRECT: `Access to your own pre-negotiated rates.`,
    response_service_level_desc_ONTRAC_SUNRISE: `OnTrac offers Sunrise, Sunrise Gold, Ground & Palletized Freight shipping services to meet your custom shipping needs.`,
    response_service_level_desc_ONTRAC_SUNRISE_GOLD: `OnTrac offers Sunrise, Sunrise Gold, Ground & Palletized Freight shipping services to meet your custom shipping needs.`,
    response_service_level_desc_ONTRAC_PALLETIZED_FREIGHT: `OnTrac offers Sunrise, Sunrise Gold, Ground & Palletized Freight shipping services to meet your custom shipping needs.`,
    response_service_level_desc_ONTRAC_GROUND: `OnTrac offers Sunrise, Sunrise Gold, Ground & Palletized Freight shipping services to meet your custom shipping needs.`,
    response_service_level_desc_ONTRAC_SAME_DAY: `OnTrac offers Sunrise, Sunrise Gold, Ground & Palletized Freight shipping services to meet your custom shipping needs.`,
    response_service_level_desc_FEDEX_INTERNATIONAL_CONNECT_PLUS: `Suitable for shipments weighing 70 lbs or less, and do not contain hazardous materials`,
    response_service_level_desc_USPS_GROUND_ADVANTAGE: `An affordable and reliable way to ship packages in 2–5 business days`,
    // ----------------------------------------------------------------------
    form_street1: `Street 1`,
    form_street2: `Street 2 (optional)`,
    form_city: `City`,
    form_zipcode: `Zipcode`,
    form_state: `State`,
    form_country: `Country`,
    // ----------------------------------------------------------------------
    input_schema_login_form_1_1: `Email must be a valid email address`,
    input_schema_login_form_1_2: `Email is required`,
    input_schema_login_form_2: `Password is required`,
    // ----------------------------------------------------------------------
    input_schema_register_form_1: `First name required`,
    input_schema_register_form_2: `Last name required`,
    input_schema_register_form_3_1: `Email must be a valid email address`,
    input_schema_register_form_3_2: `Email is required`,
    input_schema_register_form_4_1: `Password must at least 8 characters`,
    input_schema_register_form_4_2: `Password must under 20 characters`,
    input_schema_register_form_4_3: `Password must contain at least 1 Uppercase, 1 Lowercase, 1 Number or 1 Symbol Letter`,
    input_schema_register_form_4_4: `Password must not contain exact same letters before @ of email`,
    input_schema_register_form_4_5: `Password must not contain more than 3 consecutive or repeating numbers / letters`,
    input_schema_register_form_4_6: `Password is required`,
    input_schema_register_form_6: `Password must match`,
    input_schema_register_form_5: `You must agree to the ShipSaving Terms and Privacy Policy to create an account`,
    input_schema_register_form_7: `Plan required`,
    // ----------------------------------------------------------------------
    input_schema_reset_password_form_1_1: `Email must be a valid email address`,
    input_schema_reset_password_form_1_2: `Email is required`,
    input_schema_reset_password_form_2: `Email sent.`,
    // ----------------------------------------------------------------------
    input_schema_account_update_form_1: `First Name is required`,
    input_schema_account_update_form_1_1: `First Name must be under 20 characters`,
    input_schema_account_update_form_2: `Last Name is required`,
    input_schema_account_update_form_2_1: `Last Name must be under 20 characters`,
    // ----------------------------------------------------------------------
    input_schema_account_change_password_form_1: `Old Password is required`,
    input_schema_account_change_password_form_2_1: `New Password is required`,
    input_schema_account_change_password_form_2_2: `Password must at least 8 characters`,
    input_schema_account_change_password_form_2_3: `Password must under 20 characters`,
    input_schema_account_change_password_form_2_4: `Password must contain at least 1 Uppercase, 1 Lowercase, 1 Number or 1 Symbol Letter`,
    input_schema_account_change_password_form_2_5: `Password must not contain exact same letters before @ of email`,
    input_schema_account_change_password_form_2_6: `Password must not contain more than 3 consecutive or repeating numbers / letters`,
    input_schema_account_change_password_form_2_7: `Your new password cannot be the same as your current password`,
    input_schema_account_change_password_form_3: `Passwords must match`,
    // ----------------------------------------------------------------------
    input_schema_account_manage_company_1: `Company Name is required`,
    input_schema_account_manage_company_2: `Phone is required`,
    input_schema_account_manage_company_3: `Email is required`,
    input_schema_account_manage_company_4: `Street 1 is required`,
    input_schema_account_manage_company_5: `City is required`,
    input_schema_account_manage_company_6: `Zipcode is required`,
    input_schema_account_manage_company_7: `State is required`,
    input_schema_account_manage_company_8: `Country is required`,
    // ----------------------------------------------------------------------
    input_schema_bank_account_form_1: `Business Type is required`,
    input_schema_bank_account_form_2: `Account Number is required`,
    input_schema_bank_account_form_3: `Routing Number is required`,
    input_schema_bank_account_form_4: `Name is required`,
    input_schema_bank_account_form_5: `Street 1 is required`,
    input_schema_bank_account_form_6: `City is required`,
    input_schema_bank_account_form_7: `State is required`,
    input_schema_bank_account_form_8: `Zipcode is required`,
    input_schema_bank_account_form_9: `You must agree to the ShipSaving Terms and Privacy Policy to add a payment method`,
    // ----------------------------------------------------------------------
    input_schema_card_form_1: `Name is required`,
    input_schema_card_form_1_1: `Full name must not include any numbers or special character`,
    input_schema_card_form_2: `Card Number is required`,
    input_schema_card_form_2_1: `The card number cannot exceed 16 digits`,
    input_schema_card_form_2_2: `The card number can only contain digits`,
    input_schema_card_form_3: `Expiration Month is required`,
    input_schema_card_form_4: `Expiration Year is required`,
    input_schema_card_form_5: `CVV is required`,
    input_schema_card_form_6: `Street 1 is required`,
    input_schema_card_form_7: `City is required`,
    input_schema_card_form_8: `State is required`,
    input_schema_card_form_9: `Zipcode is required`,
    input_schema_card_form_10: `You must agree to the ShipSaving Terms and Privacy Policy to add a payment method`,
    // ----------------------------------------------------------------------
    input_schema_recharge_form_1: `Amount is required`,
    input_schema_recharge_form_1_1: `The minimum amount is $500`,
    input_schema_recharge_form_2: `Payment Method is required`,
    input_schema_recharge_form_3: `Name or company name is required`,
    input_schema_recharge_form_4: `Street 1 is required`,
    input_schema_recharge_form_5: `Screenshot is required`,
    // ----------------------------------------------------------------------
    input_schema_card_verify_form_1_1: `The amount must between 0.01 to 0.99`,
    input_schema_card_verify_form_1_2: `The Amount allow only 2 digits after decimal`,
    input_schema_card_verify_form_1_3: `The Amount is required`,
    input_schema_card_verify_form_1_4: `The two authorizations can not be the same`,
    // ----------------------------------------------------------------------
    input_schema_address_form_1: `First Name is required`,
    input_schema_address_form_2: `Last Name is required`,
    input_schema_address_form_3: `Phone is required`,
    input_schema_address_form_4: `Address is required`,
    input_schema_address_form_5: `City is required`,
    input_schema_address_form_6: `State is required`,
    input_schema_address_form_7: `Zipcode is required`,
    input_schema_address_form_8: `Country is required`,
    // ----------------------------------------------------------------------
    input_schema_package_form_1_1: `Nickname must under 20 characters`,
    input_schema_package_form_1_2: `Nickname is required`,
    input_schema_package_form_2_1: `The minimum length is 6"`,
    input_schema_package_form_2_2: `The max Length + Girth (Width * 2 + Height * 2) can not be over 165", but your box is `,
    input_schema_package_form_2_3: `Dimension length is required`,
    input_schema_package_form_3_1: `The minimum width is 3"`,
    input_schema_package_form_3_2: `The max Length + Girth (Width * 2 + Height * 2) can not be over 165", but your box is `,
    input_schema_package_form_3_3: `Dimension width is required`,
    input_schema_package_form_4_1: `The minimum height is 0.25"`,
    input_schema_package_form_4_2: `The max Length + Girth (Width * 2 + Height * 2) can not be over 165", but your box is `,
    input_schema_package_form_4_3: `Dimension height is required`,
    // ----------------------------------------------------------------------
    input_schema_bill_account_form_1: `Nickname is required`,
    input_schema_bill_account_form_2: `Account Number is required`,
    input_schema_bill_account_form_3: `Zip Code is required`,
    input_schema_bill_account_form_4: `Country is required`,
    // ----------------------------------------------------------------------
    input_schema_add_store_form_1_1: `Store nickname must be under 60 characters`,
    input_schema_add_store_form_1_2: `Nickname is required`,
    input_schema_add_store_form_2: `Ship From is required`,
    // ----------------------------------------------------------------------
    input_schema_add_carrier_form_1_1: `Nickname must under 20 characters`,
    input_schema_add_carrier_form_1_2: `Nickname is required`,
    input_schema_add_carrier_form_2: `Contact Name is required`,
    input_schema_add_carrier_form_3: `Title is required`,
    input_schema_add_carrier_form_4: `Company Name is required`,
    input_schema_add_carrier_form_5: `Company URL is required`,
    input_schema_add_carrier_form_6: `Company Email is required`,
    input_schema_add_carrier_form_7: `Company Phone is required`,
    input_schema_add_carrier_form_8: `Street 1 is required`,
    input_schema_add_carrier_form_9: `City is required`,
    input_schema_add_carrier_form_10: `State is required`,
    input_schema_add_carrier_form_11: `Zipcode is required`,
    input_schema_add_carrier_form_12: `Country is required`,
    input_schema_add_carrier_form_13: `Account Number is required`,
    input_schema_add_carrier_form_14: `Account Zipcode is required`,
    input_schema_add_carrier_form_15: `Account Country is required`,
    input_schema_add_carrier_form_16: `Invoice Date is required`,
    input_schema_add_carrier_form_17: `Invoice Number is required`,
    input_schema_add_carrier_form_18: `Control Id is required`,
    input_schema_add_carrier_form_19: `Invoice Amount is required`,
    // ----------------------------------------------------------------------
    input_schema_edit_carrier_form_1_1: `Nickname must under 20 characters`,
    input_schema_edit_carrier_form_1_2: `Nickname is required`,
    // ----------------------------------------------------------------------
    input_schema_store_form_1_2: `Name is required`,
    // ----------------------------------------------------------------------
    input_schema_add_edit_order_form_1: `Store is required`,
    input_schema_add_edit_order_form_2: `Name is required`,
    input_schema_add_edit_order_form_3: `Phone is required`,
    input_schema_add_edit_order_form_4: `Address is required`,
    input_schema_add_edit_order_form_5: `City is required`,
    input_schema_add_edit_order_form_6: `State is required`,
    input_schema_add_edit_order_form_7: `Zipcode is required`,
    input_schema_add_edit_order_form_8: `Country is required`,
    input_schema_add_edit_order_form_9: `Item is invalid, please fill both sku and quantity`,
    input_schema_add_edit_order_form_10: `Total Price must be greater than 0`,
    input_schema_add_edit_order_form_11: `Tax must be greater than 0`,
    input_schema_add_edit_order_form_12: `Shipping Fee must be greater than 0`,
    // ----------------------------------------------------------------------
    input_schema_ship_form_1: `Name is required`,
    input_schema_ship_form_1_1: `First Name is required`,
    input_schema_ship_form_1_2: `Last Name is required`,
    input_schema_ship_form_2: `Street 1 is required`,
    input_schema_ship_form_3: `City is required`,
    input_schema_ship_form_4: `State is required`,
    input_schema_ship_form_4_1: `State must under 5 characters`,
    input_schema_ship_form_5: `Zipcode is required`,
    input_schema_ship_form_6: `Country is required`,
    input_schema_ship_form_7_1: `Phone is required for international shipments`,
    input_schema_ship_form_7_2: `Phone is required when you save ship from address`,
    input_schema_ship_form_8_1_1: `The minimum weight is 0.01 lb`,
    input_schema_ship_form_8_1_1_1: `The maximum weight for letter is 0.21 lb`,
    input_schema_ship_form_8_1_2: `The maximum weight is 150 lb`,
    input_schema_ship_form_8_1_3: `Weight is required`,
    input_schema_ship_form_8_2_1: `The minimum weight is 0.16 oz`,
    input_schema_ship_form_8_2_2: `The maximum weight is 15.99 oz`,
    input_schema_ship_form_8_2_2_1: `The maximum weight for letter is 3.5 oz`,
    input_schema_ship_form_8_2_3: `Weight is required`,
    input_schema_ship_form_8_3_1: `The minimum weight is 0.01 kg`,
    input_schema_ship_form_8_3_2: `The maximum weight is 68.03 kg`,
    input_schema_ship_form_8_3_2_1: `The maximum weight for letter is 0.09 kg`,
    input_schema_ship_form_8_3_3: `Weight is required`,
    input_schema_ship_form_8_4_1: `The minimum weight is 4.54 g`,
    input_schema_ship_form_8_4_2: `The maximum weight is 999.99 g`,
    input_schema_ship_form_8_4_2_1: `The maximum weight for letter is 97.5 g`,
    input_schema_ship_form_8_4_3: `Weight is required`,
    input_schema_ship_form_8_5: `The maximum value is 5000`,
    input_schema_ship_form_8_domestic_LETTER: `The maximum weight for letter is 3.5 oz`,
    input_schema_ship_form_8_international_LETTER: `The maximum weight for letter is 3.5 oz`,
    input_schema_ship_form_8_domestic_LARGE_ENVELOPE_OR_FLAT: `The maximum weight is 70 lbs`,
    input_schema_ship_form_8_international_LARGE_ENVELOPE_OR_FLAT: `The maximum weight is 66 lbs`,
    input_schema_ship_form_8_domestic_THICK_ENVELOPE: `The maximum weight is 70 lbs`,
    input_schema_ship_form_8_international_THICK_ENVELOPE: `The maximum weight is 4 lbs`,
    input_schema_ship_form_8_domestic_FLAT_RATE_PADDED_ENVELOPE: `The maximum weight is 70 lbs`,
    input_schema_ship_form_8_international_FLAT_RATE_PADDED_ENVELOPE: `The maximum weight is 4 lbs`,
    input_schema_ship_form_8_domestic_FLAT_RATE_ENVELOPE: `The maximum weight is 70 lbs`,
    input_schema_ship_form_8_international_FLAT_RATE_ENVELOPE: `The maximum weight is 4 lbs`,
    input_schema_ship_form_8_domestic_LEGAL_FLAT_RATE_ENVELOPE: `The maximum weight is 70 lbs`,
    input_schema_ship_form_8_international_LEGAL_FLAT_RATE_ENVELOPE: `The maximum weight is 70 lbs`,
    input_schema_ship_form_8_domestic_SMALL_FLAT_RATE_BOX: `The maximum weight is 70 lbs`,
    input_schema_ship_form_8_international_SMALL_FLAT_RATE_BOX: `The maximum weight is 20 lbs`,
    input_schema_ship_form_8_domestic_MEDIUM_FLAT_RATE_BOX: `The maximum weight is 70 lbs`,
    input_schema_ship_form_8_international_MEDIUM_FLAT_RATE_BOX: `The maximum weight is 20 lbs`,
    input_schema_ship_form_8_domestic_LARGE_FLAT_RATE_BOX: `The maximum weight is 70 lbs`,
    input_schema_ship_form_8_international_LARGE_FLAT_RATE_BOX: `The maximum weight is 20 lbs`,
    input_schema_ship_form_8_domestic_LARGE_EXPRESS_BOX: `The maximum weight is 150 lbs`,
    input_schema_ship_form_8_international_LARGE_EXPRESS_BOX: `The maximum weight is 150 lbs`,
    input_schema_ship_form_8_domestic_MEDIUM_EXPRESS_BOX: `The maximum weight is 150 lbs`,
    input_schema_ship_form_8_international_MEDIUM_EXPRESS_BOX: `The maximum weight is 150 lbs`,
    input_schema_ship_form_8_domestic_SMALL_EXPRESS_BOX: `The maximum weight is 150 lbs`,
    input_schema_ship_form_8_international_SMALL_EXPRESS_BOX: `The maximum weight is 150 lbs`,
    input_schema_ship_form_8_domestic_UPS_PAK: `The maximum weight is 150 lbs`,
    input_schema_ship_form_8_international_UPS_PAK: `The maximum weight is 150 lbs`,
    input_schema_ship_form_8_domestic_UPS_TUBE: `The maximum weight is 150 lbs`,
    input_schema_ship_form_8_international_UPS_TUBE: `The maximum weight is 150 lbs`,
    input_schema_ship_form_8_international_UPS_10KG_BOX: `The maximum weight is 22 lbs`,
    input_schema_ship_form_8_international_UPS_25KG_BOX: `The maximum weight is 55 lbs`,
    input_schema_ship_form_8_domestic_UPS_LETTER: `The maximum weight is 150 lbs`,
    input_schema_ship_form_8_international_UPS_LETTER: `The maximum weight is 150 lbs`,
    input_schema_ship_form_9_1: `The minimum length is 6"`,
    input_schema_ship_form_9_2: `Dimension length is required`,
    input_schema_ship_form_9_3: `The max Length + Girth (Width * 2 + Height * 2) is 165", but your box is `,
    input_schema_ship_form_10_1: `The minimum width is 3"`,
    input_schema_ship_form_10_2: `Dimension width is required`,
    input_schema_ship_form_10_3: `The max Length + Girth (Width * 2 + Height * 2) is 165", but your box is `,
    input_schema_ship_form_11_1: `The minimum height is 0.25"`,
    input_schema_ship_form_11_2: `Dimension height is required`,
    input_schema_ship_form_11_3: `The max Length + Girth (Width * 2 + Height * 2) is 165", but your box is `,
    input_schema_ship_form_12_1: `Description is required`,
    input_schema_ship_form_12_2: `Country is required`,
    input_schema_ship_form_12_3_1: `The minimum quantity is 1`,
    input_schema_ship_form_12_3_2: `Quantity is required`,
    input_schema_ship_form_12_3_3: `Please enter an integer`,
    input_schema_ship_form_12_4_1: `Unit price must be greater than or equal to $1`,
    input_schema_ship_form_12_4_2: `Value is required`,
    input_schema_ship_form_13: `Street must under 35 characters. Please enter the remaining in Street 2`,
    input_schema_ship_form_14: `Street must under 35 characters`,
    input_schema_ship_form_15_1: `Custom Print #1 must under 20 characters`,
    input_schema_ship_form_15_2: `Custom Print #2 must under 20 characters`,
    // ----------------------------------------------------------------------
    input_schema_pickup_schedule_form_1: `Instruction must be under 75 characters`,
    // ----------------------------------------------------------------------
    input_schema_calculator_domestic_form_1: `Zipcode is required`,
    input_schema_calculator_domestic_form_2: `Zipcode is required`,
    input_schema_calculator_domestic_form_3_1_1: `The minimum weight is 0.01 lb`,
    input_schema_calculator_domestic_form_3_1_2: `The maximum weight is 150 lb`,
    input_schema_calculator_domestic_form_3_1_3: `Weight is required`,
    input_schema_calculator_domestic_form_3_2_1: `The minimum weight is 0.16 oz`,
    input_schema_calculator_domestic_form_3_2_2: `The maximum weight is 15.99 oz`,
    input_schema_calculator_domestic_form_3_2_3: `Weight is required`,
    input_schema_calculator_domestic_form_3_3_1: `The minimum weight is 0.01 kg`,
    input_schema_calculator_domestic_form_3_3_2: `The maximum weight is 68.03 kg`,
    input_schema_calculator_domestic_form_3_3_3: `Weight is required`,
    input_schema_calculator_domestic_form_3_4_1: `The minimum weight is 4.54 g`,
    input_schema_calculator_domestic_form_3_4_2: `The maximum weight is 999.99 g`,
    input_schema_calculator_domestic_form_3_4_3: `Weight is required`,
    input_schema_calculator_domestic_form_4_1: `The minimum length is 6"`,
    input_schema_calculator_domestic_form_4_2: `Dimension length is required`,
    input_schema_calculator_domestic_form_4_3: `The max Length + Girth (Width * 2 + Height * 2) is 165", but your box is `,
    input_schema_calculator_domestic_form_5_1: `The minimum width is 3"`,
    input_schema_calculator_domestic_form_5_2: `Dimension width is required`,
    input_schema_calculator_domestic_form_5_3: `The max Length + Girth (Width * 2 + Height * 2) is 165", but your box is `,
    input_schema_calculator_domestic_form_6_1: `The minimum height is 0.25"`,
    input_schema_calculator_domestic_form_6_2: `Dimension height is required`,
    input_schema_calculator_domestic_form_6_3: `The max Length + Girth (Width * 2 + Height * 2) is 165", but your box is `,
    // ----------------------------------------------------------------------
    input_schema_calculator_international_form_1: `Country is required`,
    input_schema_calculator_international_form_2: `Country is required`,
    input_schema_calculator_international_form_3: `City is required`,
    input_schema_calculator_international_form_4: `City is required`,
    input_schema_calculator_international_form_5: `Zipcode is required`,
    input_schema_calculator_international_form_6: `Zipcode is required`,
    input_schema_calculator_international_form_7_1_1: `The minimum weight is 0.01 lb`,
    input_schema_calculator_international_form_7_1_2: `The maximum weight is 150 lb`,
    input_schema_calculator_international_form_7_1_3: `Weight is required`,
    input_schema_calculator_international_form_7_2_1: `The minimum weight is 0.16 oz`,
    input_schema_calculator_international_form_7_2_2: `The maximum weight is 15.99 oz`,
    input_schema_calculator_international_form_7_2_3: `Weight is required`,
    input_schema_calculator_international_form_7_3_1: `The minimum weight is 0.01 kg`,
    input_schema_calculator_international_form_7_3_2: `The maximum weight is 68.03 kg`,
    input_schema_calculator_international_form_7_3_3: `Weight is required`,
    input_schema_calculator_international_form_7_4_1: `The minimum weight is 4.54 g`,
    input_schema_calculator_international_form_7_4_2: `The maximum weight is 999.99 g`,
    input_schema_calculator_international_form_7_4_3: `Weight is required`,
    input_schema_calculator_international_form_8_1: `The minimum length is 6"`,
    input_schema_calculator_international_form_8_2: `Dimension length is required`,
    input_schema_calculator_international_form_8_3: `The max Length + Girth (Width * 2 + Height * 2) is 165", but your box is `,
    input_schema_calculator_international_form_9_1: `The minimum width is 3"`,
    input_schema_calculator_international_form_9_2: `Dimension width is required`,
    input_schema_calculator_international_form_9_3: `The max Length + Girth (Width * 2 + Height * 2) is 165", but your box is `,
    input_schema_calculator_international_form_10_1: `The minimum height is 0.25"`,
    input_schema_calculator_international_form_10_2: `Dimension height is required`,
    input_schema_calculator_international_form_10_3: `The max Length + Girth (Width * 2 + Height * 2) is 165", but your box is `,
    // ----------------------------------------------------------------------
    input_schema_order_import_form_1: `Store is required`,
    input_schema_order_import_form_2: `File is required`,
    // ----------------------------------------------------------------------
    input_schema_order_export_form_1: `Start Date is required`,
    input_schema_order_export_form_2: `End Date is required`,
    input_schema_order_export_form_3: `Store is required`,
    input_schema_order_export_form_4: `Status is required`,
    input_schema_order_export_form_5: `There was an error downloading this file. Please try again later.`,
    // ----------------------------------------------------------------------
    input_schema_transaction_export_form_1: `Transaction Type is required`,
    input_schema_transaction_export_form_2: `Transaction Year is required`,
    // ----------------------------------------------------------------------
    input_schema_shipment_batch_label_download_form_1: `Store is required`,
    input_schema_shipment_batch_label_download_form_2: `Print size is required`,
    input_schema_shipment_batch_label_download_form_3: `Date is required`,
    input_schema_shipment_batch_label_download_form_4: `There was an error downloading this file. Please try again later.`,
    input_schema_shipment_batch_label_download_form_5: `Start Date is required`,
    input_schema_shipment_batch_label_download_form_6: `End Date is required`,
    // ----------------------------------------------------------------------
    input_schema_item_form_1: `Store is required`,
    input_schema_item_form_2_1: `Item Name max Length is 512`,
    input_schema_item_form_2_2: `Item Name is required`,
    input_schema_item_form_3_1: `SKU max Length is 128`,
    input_schema_item_form_3_2: `SKU is required`,
    input_schema_item_form_4: `Price no less than 0`,
    input_schema_item_form_5: `Thumbnails max Length is 191`,
    input_schema_item_form_6: `Length no less than`,
    input_schema_item_form_7: `Width no less than`,
    input_schema_item_form_8: `Height no less than`,
    input_schema_item_form_9_1: `lb no less than`,
    input_schema_item_form_9_2: `lb max Length is 150`,
    input_schema_item_form_10_1: `oz no less than`,
    input_schema_item_form_10_2: `oz max Length is 15`,
    // ----------------------------------------------------------------------
    com_shipsaving_auth_pwd_incorrect: `Incorrect email address or password, please try again.`,
    com_shipsaving_auth_pwd_incorrect_account: `The old password you entered is incorrect. Please try again.`,
    com_shipsaving_company_type: `This user is not belong to new client. Please contact your account admin.`,
    com_shipsaving_user_active_fail: `Your user account is fail to active, please contact us at support@shipsaving.com.`,
    com_shipsaving_user_no_exist: `This user does not exist. Please check that you have typed correctly.`,
    com_shipsaving_user_no_exist_1: `The email you used cannot be used to log in to this plan version. Please `,
    com_shipsaving_user_no_exist_2: `log in here`,
    com_shipsaving_user_no_exist_2_2: `https://s.shipsaving.com/login`,
    com_shipsaving_user_no_exist_3: `.`,
    com_shipsaving_email_registered: `This Email is already registered, please signin instead.`,
    com_shipsaving_mail_send: `An error occurred while sending mail, please verify your email settings.`,
    com_shipsaving_email_exist: `The email address entered already exists in the system.`,
    com_shipsaving_user_active_url_expired: `It looks like your URL has expired, please request a new one!`,
    com_shipsaving_user_reset_pwd_fail: `Reset password failed.`,
    com_shipsaving_user_api_token_fail: `Unable to get the API Token, must be admin user`,
    customer_company_no_exist: `company not exist`,
    com_shipsaving_transaction_invoive_fail: `Unable to get the invoice at this time, please try again later.`,
    com_shipsaving_shipments_not_found: `No shipment found, please try another search or contact us for more information.`,
    com_shipsaving_label_share_code_not_found: `The requested URL was not found on this server.`,
    com_shipsaving_shipments_overdue: `You cannot void this shipment because the time limit for void has been exceeded.`,
    com_shipsaving_shipments_voied: `This shipment has already been voided.`,
    com_shipsaving_shipment_invalid_shipment_id: `The shipment id is not exist, need to get rate again!`,
    com_shipsaving_shipment_invalid_rate_id: `This rate has expired, please click the Get Rate button again!`,
    com_shipsaving_shipment_qrcode_nosupport: `This carrier not support qrcode now!`,
    com_shipsaving_shipment_created: `This shipment has already been created.`,
    com_shipsaving_shipment_create_fail: `An error occurred while creating the shipment. Please try again later or contact us at support@shipsaving.com.`,
    com_shipsaving_shipment_create_fail_2: `The label has already been purchased for the order and cannot be purchased again`,
    com_shipsaving_shipment_create_lock: `Due to the payment failure when we charged you for the shipping adjustment fee, we temporarily disabled your \\"Buy a Label\\" feature. "`,
    com_shipsaving_shipment_charge_fail: `We were unable to process your payment. Please add a new payment method to your account.`,
    com_shipsaving_shipment_invalid_payment_method: `Your payment method is not valid. Please check your entries carefully and try again.`,
    com_shipsaving_shipments_preview_label_fail_: `Oops! There was a problem previewing this label.`,
    com_shipsaving_shipments_preview_qrcode_fail_: `Oops! There was a problem previewing this qrcode.`,
    com_shipsaving_shipments_create_fail1: `An error occurred while creating the shipment. The balance no enough`,
    com_shipsaving_shipment_rate_fail: `The company plan is free plan, can't get multiple package price at  one rate`,
    com_shipsaving_task_no_exist: `the task not exist`,
    com_shipsaving_task_download_file_error: `There was an error downloading the file.`,
    com_shipsaving_store_auth_fail: `This store authorization is already exist.`,
    com_shipsaving_store_no_support: `This Ecommerce platform is not supported in ShipSaving system.`,
    com_shipsaving_api_param_error: `Request parameter error.`,
    com_shipsaving_store_no_exist: `This store  is not exist.`,
    com_shipsaving_store_not_customized: `This store is not customized.`,
    com_shipsaving_store_platform_no_match: `Platform type is not matched with the modified store.`,
    com_shipsaving_carrier_account_no_support: `This Carrier is not supported in ShipSaving system.`,
    com_shipsaving_carrier_account_auth_no_exist: `This account authorization not exist.`,
    com_shipsaving_carrier_account_auth_duplicate: `Please note same carrier account can only be added once to your ShipSaving account. `,
    com_shipsaving_carrier_account_edit_fail: `This account can't modify`,
    com_shipsaving_carrier_account_no_exist: `This account is not exist.`,
    com_shipsaving_carrier_account_bill_account_no_support: `This account is not supported for add bill account.`,
    com_shipsaving_carrier_account_no_match: `Carrier account not match.`,
    com_shipsaving_carrier_account_auth_fail: `This bill account is not exist.`,
    com_shipsaving_payment_method_delete_fail: `Sorry, you cannot delete this credit card. Please keep at least one credit card on file to ensure you can pay properly when additional charges are applied to your shipment.`,
    com_shipsaving_payment_method_delete_fail1: `Sorry, you cannot delete this credit card. the card is plan fee payment method`,
    com_shipsaving_payment_method_delete_fail2: `You cannot delete this payment method as it uses for your payment by default`,
    com_shipsaving_warehouse_no_exist: `This warehouse data is not exist in the system.`,
    com_shipsaving_warehouse_delete_error: `You cannot delete this data because it is a default Ship From address.`,
    com_shipsaving_address_no_exist: `This address data is not exist in the system.`,
    com_shipsaving_address_delete_error: `You cannot delete this data because it is a default Ship To address.`,
    com_shipsaving_package_delete_error: `You cannot delete this data because it is a default package.`,
    com_shipsaving_package_no_exist: `Package is not exist in the system.`,
    com_shipsaving_payment_methods_no_exist: `payment methods not exist`,
    com_shipsaving_payment_methods_delete_fail: `payment methods is primary,can't delete`,
    com_shipsaving_payment_methods_no_verify: `payment methods not verify`,
    com_shipsaving_paymentmethods_creat: `fail to creat payment methods`,
    com_shipsaving_paymentmethods_creat_fail: `fail to creat payment methods,Must be a U.S. card`,
    com_shipsaving_paymentmethods_creat_fail_2: `fail to creat payment methods, no more 2 payment methods`,
    com_shipsaving_payment_methods_creat_bank_account_fail: `fail to creat bank account by boa`,
    com_shipsaving_paymentmethods_modify: `fail to modify payment methods`,
    com_shipsaving_payment_methods_verify_fail: `fail to payment methods verify`,
    com_shipsaving_payment_methods_charge_fail: `fail to charge`,
    com_shipsaving_paymentmethods_check_all: `You have exceeded a maximum of adding five (5) payment methods in total. Please contact customer service via support@shipsaving.com for further actions.`,
    com_shipsaving_paymentmethods_check_not_del: `You have exceeded a maximum of adding two (2) payment methods on either debit/credit card or bank account. Please contact customer service via support@shipsaving.com for further actions`,
    com_shipsaving_paymentmethods_check_exit: `The debit/credit card or bank account you just added has already been added by another company. If this was not you, please contact customer service via support@shipsaving.com immediately.`,
    com_shipsaving_paymentmethods_verify_fail: `verify error more than 3 times, verify failed`,
    com_shipsaving_payment_methods_add_fail: `Payment methods add fail more than 5 times, try again later please!`,
    com_shipsaving_payment_methods_add_service_stop: `Please be notified a service maintenance will be conducted on Friday 10/07/2022 until further notice. We sincerely apologize for any inconvenience.`,
    account_redeem_no_rebate: `Not any rebate balance`,
    payment_charge_no_exist: `charge not exist`,
    com_shipsaving_pickup_no_exist: `The pickup not exist`,
    com_shipsaving_pickup_canceled: `The pickup canceled!`,
    com_shipsaving_pickup_creat_fail: `Total package weight must be greater than 1lb`,
    com_shipsaving_webhook_unauthorized: `The request unauthorized`,
    com_shipsaving_order_no_exist: `The order not exist`,
    com_shipsaving_order_status_error: `The order status not awaiting`,
    com_shipsaving_order_no_warehouse: `The order not have warehouse`,
    com_shipsaving_combin_order_status_error: `one of orders status not awaiting`,
    com_shipsaving_order_delete_error1: `The order is not  customized store order,can't delete`,
    com_shipsaving_order_delete_error2: `The order have shipment data ,can't delete`,
    com_shipsaving_order_import_fail: `import orders fail`,
    order_import_order_template_version_error: `orders import_template version error`,
    order_import_order_template_download_fail: `import_order_template download fail`,
    com_shipsaving_item_no_exist: `This item is not exist in the system.`,
    com_shipsaving_item_store_no_support: `Item can only be added to your customized store`,
    com_shipsaving_item_store_no_support_1: `Item can't  be delete ,it not  customized store item`,
    com_shipsaving_item_sku_duplicate: `Item with the same sku has been added before`,
    com_shipsaving_shipping_rule_no_exist: `This shipping rule is not exist in the system.`,
    com_shipsaving_shipping_rule_no_items: `No item selected for this shipping rule`,
    com_shipsaving_shipping_rule_item_no_exist: `Item(s) not exist in this store`,
    com_shipsaving_shipping_rule_item_quantity_error: `Item(s) quantity must be greater than 0`,
    com_shipsaving_shipping_rule_item_combination_exist: `The permutation of item(s) has already been added before`,
    com_shipsaving_picking_list_order_exist: `order have picking list,check please`,
    com_shipsaving_picking_list_order_no_awaiting: `order status is not awaiting,check please`,
    com_shipsaving_picking_list_order_no_same_warehouse: `orders status is not belong same warehouse`,
    com_shipsaving_shipments_download_scan_form_fail_: `Oops! There was a problem download this scan_form.`,
    com_shipsaving_plan_change_fail_: `Current plan is choosed`,
    com_shipsaving_plan_change_fail_1_: `Your plan can only be changed once every 30 days.`,
    com_shipsaving_plan_payment_method_error_: `The primary & secondary payment method is same.`,
    com_shipsaving_plan_primary_payment_method_error_: `The primary payment method must be a card.`,
    com_shipsaving_plan_primary_payment_method_not_exist_: `The primary payment method is not exist in the system.`,
    com_shipsaving_plan_secondary_payment_method_error_: `The secondary payment method must be a card.`,
    com_shipsaving_plan_secondary_payment_method_not_exist_: `The secondary payment method is not exist in the system.`,
    order_task_request_fail: `order task opertion fail`,
    default_error_msg: `Something went wrong`,
    payment_paymentmethods_blacklist: `This billing address has been blocked due to previous abnormal behavior. Please contact our customer service team at support@shipsaving.com for further assistance.`,
    com_shipsaving_payment_methods_add_fail_blacklist: `We've encountered unexpected errors while processing your card. For the safety of your account, please contact our customer service team.`,
    com_shipsaving_shipment_rate_name_over_length: `The combined first and last name cannot be more than 35 characters.`,
    com_shipsaving_shipment_rate_name_all_spaces: `First name and last name cannot be all spaces. Please re-enter.`,
    com_shipsaving_default_error: `Sorry, an unknown error occurred. Please try again later or contact customer support.`,
    com_shipsaving_email_invalid: `Please enter a valid email address and try again.`,
    payment_payment_methods_verify_fail_1: `2 attempts remaining. `,
    payment_payment_methods_verify_fail_2: `Please log into your bank account to confirm the last two digits of the transaction amounts.`,
    payment_payment_methods_verify_second_fail_1: `Warning: Only 1 attempt left. `,
    payment_payment_methods_verify_second_fail_2: `Check for your recent transactions marked as 'SHIP SAVING' in your bank account.`,
    payment_paymentmethods_verify_fail: `You have used all your attempts. For your account's security, please contact our customer support.`,
    payment_paymentmethods_check_all: `You have reached the maximum times of adding payment methods. For assistance, please contact customer service.`,
};

export default en;
