import { createSlice } from '@reduxjs/toolkit';
// api
import {
    getNotificationList as getNotificationListAPI,
    getNotificationUnreadCount as getNotificationUnreadCountAPI,
    markNotificationAsRead as markNotificationAsReadAPI
} from "../../api/notification";

import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    notifications: [],
    notificationFilter: {
        status: 'all',
        perPage: 5,
        page: 0,
        total: 0,
        sort: 'desc',
    },
    totalUnRead: 0,
};

const slice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state, action) {
            state.isLoading = action.payload;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET & UPDATE NOTIFICATION LIST
        getNotificationsSuccess(state, action) {
            state.isLoading = false;
            state.notifications = action.payload;
        },

        // UPDATE NOTIFICATION LIST
        updateNotificationList(state, action) {
            state.isLoading = false;
            action.payload.forEach((_notification) => {
                state.notifications = [...state.notifications, _notification];
            });
        },

        // UPDATE NOTIFICATION FILTER
        updateNotificationsFilterSuccess(state, action) {
            state.notificationFilter = {
                ...state.notificationFilter,
                ...action.payload
            }
        },

        // GET TOTAL UNREAD MESSAGE NUMBER
        getNotificationUnreadCountSuccess(state, action) {
            state.totalUnRead = action.payload;
        },

        // UPDATE NOTIFICATION STATUS
        updateNotificationStatus(state, action) {
            const notificationId = action.payload;

            const modifiedNotifications = state.notifications.map((_notification) => {
                if (_notification.id === notificationId) {
                    _notification.is_readed = 1;
                }
                return _notification;
            });

            state.notifications = modifiedNotifications;
        },
    }
});


// Reducer
export default slice.reducer;

// Actions
export const { updateNotificationStatus } = slice.actions;

// ----------------------------------------------------------------------

export function getNotificationList(filter, update) {
    return async () => {
        try {
            const response = await getNotificationListAPI(filter);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.updateNotificationsFilterSuccess({
                    status: filter?.status || 'all',
                    total: data?.total || 0,
                    perPage: data?.per_page || 5,
                    page: data?.current_page || 0
                }));
                if (update) {
                    dispatch(slice.actions.updateNotificationList(data?.data || []));
                } else {
                    dispatch(slice.actions.getNotificationsSuccess(data?.data || []));
                }
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function getUnreadNotificationNum() {
    return async () => {
        try {
            const response = await getNotificationUnreadCountAPI();

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getNotificationUnreadCountSuccess(data?.unread_num || 0));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function markNotificationsAsRead(ids) {
    return async () => {
        try {
            const response = await markNotificationAsReadAPI(ids);

            const { status, data } = response;

            if (status === 200 && data) {
                const filter = {
                    status: 'all',
                    perPage: 5,
                    page: 0,
                }
                dispatch(getNotificationList(filter, false));
                dispatch(getUnreadNotificationNum());
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}