import { createSlice } from '@reduxjs/toolkit';
// api
import {
    getPlanInfo as getPlanInfoAPI,
    modifyPlan as modifyPlanAPI,
    modifyPlanPaymentMethod as modifyPlanPaymentMethodAPI,
    getPlanList as getPlanListAPI,
    getPlanChargeHistoryList as getPlanChargeHistoryListAPI,
} from "../../api/plan";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    currentPlan: {},
    planList: [],
    planChargeHistoryList: [],
    planChargeHistoryFilter: {
        sort: 'desc',
        page: 0,
        rowsPerPage: 5,
        total: 0,
    },
};

const slice = createSlice({
    name: 'plan',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET CURRENT PLAN
        getCurrentPlanSuccess(state, action) {
            state.isLoading = false;
            state.currentPlan = action.payload;
        },

        // GET PLAN
        getPlanSuccess(state, action) {
            state.isLoading = false;
            state.planList = action.payload;
        },

        // MODIFY PLAN PAYMENT METHOD
        modifyPlanPaymentMethodSuccess(state, action) {
            state.isLoading = false;
            state.currentPlan = {
                ...state.currentPlan,
                payment_method_id: action.payload,
            }
        },

        // GET PLAN CHARGE HISTORY
        getPlanChargeHistorySuccess(state, action) {
            state.isLoading = false;
            state.planChargeHistoryList = action.payload;
        },

        // UPDATE PLAN CHARGE HISTORY LIST FILTER
        updatePlanChargeHistoryFilterSuccess(state, action) {
            state.planChargeHistoryFilter = {
                ...state.planChargeHistoryFilter,
                ...action.payload,
            }
        },

        // RESET FILTER
        resetFilter(state) {
            state.planChargeHistoryFilter = {
                sort: 'desc',
                page: 0,
                rowsPerPage: 5,
                total: 0,
            }
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { resetFilter } = slice.actions;

// ----------------------------------------------------------------------

export function getCurrentPlan() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await getPlanInfoAPI();

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getCurrentPlanSuccess(data || {}));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

// ----------------------------------------------------------------------

export function modifyPlan(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await modifyPlanAPI(id);

            const { status, data } = response;

            if (status === 200 && data?.result === 'success') {
                if (id !== 1) dispatch(getCurrentPlan());
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    }
}

// ----------------------------------------------------------------------

export function modifyPlanPaymentMethod(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await modifyPlanPaymentMethodAPI(id);

            const { status, data } = response;

            if (status === 200 && data?.result === 'success') {
                dispatch(slice.actions.modifyPlanPaymentMethodSuccess(id));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    }
}

// ----------------------------------------------------------------------

export function getPlanList() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await getPlanListAPI();

            const { status, data } = response;

            if (status === 200) {
                const tempData = data.map(option => ({ label: option?.shipment_limit > -1 ? option?.shipment_limit : 0, fee: option?.monthly_fee, ...option }));

                dispatch(slice.actions.getPlanSuccess(tempData || []));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

// ----------------------------------------------------------------------

export function getPlanChargeHistoryList(filter) {
    return async (dispatch, getState) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await getPlanChargeHistoryListAPI(filter || getState().plan.planChargeHistoryFilter);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getPlanChargeHistorySuccess(data?.data || []));
                dispatch(slice.actions.updatePlanChargeHistoryFilterSuccess({
                    total: data?.total || 0,
                    rowsPerPage: data?.per_page || 5,
                }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

// ----------------------------------------------------------------------

export function updatePlanChargeHistoryListFilter(filter) {
    return () => {
        dispatch(slice.actions.updatePlanChargeHistoryFilterSuccess({
            sort: filter?.sort || 'desc',
            page: filter?.page || 0,
            rowsPerPage: filter?.rowsPerPage || 5,
            total: filter?.total || 0,
        }));
    }
}
