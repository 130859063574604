import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link } from '@mui/material';
// routes
import { PATH_EXTERNAL } from "../routes/paths";
//
import Image from "./Image";

// ----------------------------------------------------------------------

Logo.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, inDashboard, isCollapse, sx }) {
    const logo = (
        <Box sx={{ display: 'flex', width: 'auto', height: 40, pr: isCollapse ? 2 : 0, ...sx }}>
            {isCollapse ? (
                <Image disabledEffect visibleByDefault alt="logo" src="/logo/logo_single.svg" sx={{ height: 29, my: 'auto', mr: 'auto', '& img': { objectFit: 'contain' } }}/>
            ) : (
                <Image disabledEffect visibleByDefault alt="logo" src="/logo/logo_full.svg" sx={{ height: 29, maxWidth: 150, my: 'auto', mr: 'auto', '& img': { objectFit: 'contain' } }}/>
            )}
        </Box>
    )

    if (disabledLink) {
        return <>{logo}</>;
    }

    if (inDashboard) {
        return <RouterLink to="/">{logo}</RouterLink>;
    }

    return <Link href={PATH_EXTERNAL.mainSite} target="_blank">{logo}</Link>
}
