import PropTypes from "prop-types";
import React from "react";
// form
import { useFormContext } from "react-hook-form";
// @mui
import { Box, InputAdornment, Typography } from "@mui/material";
// hooks
import useLocales from "../../hooks/useLocales";
// components
import { RHFTextField } from "../hook-form";

// ----------------------------------------------------------------------

/**
 * @function FormPackageDimension
 * @param {boolean} [isEditable=true] 默认值为true
 */

FormPackageDimension.propTypes = {
    isEditable: PropTypes.bool,
    withX: PropTypes.bool,
    nameLength: PropTypes.string,
    nameWidth: PropTypes.string,
    nameHeight: PropTypes.string,
    size: PropTypes.string,
};

export default function FormPackageDimension({ isEditable = true, withX = false, nameLength = 'length', nameWidth = 'width', nameHeight = 'height', size = 'small' }) {
    const { translate } = useLocales();

    const { setValue } = useFormContext();

    const handleInputChange = (type, value) => {
        if (value !== '') setValue(type, Math.round(value * 100) / 100);
    };

    return (
        <Box display="grid" rowGap={3.5} columnGap={2.5} gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: withX ? '1fr max-content 1fr max-content 1fr' : 'repeat(3,1fr)' }}>
            <RHFTextField
                name={nameLength} label={`${translate('dialog_item_4_1')}`} type="number" size={size}
                onWheel={(e) => e.target.blur()}
                onBlur={(e) => handleInputChange(`${nameLength}`, e.target.value)}
                InputProps={{
                    endAdornment: <InputAdornment position="end">{`${translate(`dialog_item_4_4`)}`}</InputAdornment>,
                }}
                disabled={!isEditable}
            />

            {withX && (
                <Typography variant="h5" display={{ xs: 'none', sm: 'block' }} height="fit-content" m="auto">X</Typography>
            )}

            <RHFTextField
                name={nameWidth} label={`${translate('dialog_item_4_2')}`} type="number" size={size}
                onWheel={(e) => e.target.blur()}
                onBlur={(e) => handleInputChange(`${nameWidth}`, e.target.value)}
                InputProps={{
                    endAdornment: <InputAdornment position="end">{`${translate(`dialog_item_4_4`)}`}</InputAdornment>,
                }}
                disabled={!isEditable}
            />

            {withX && (
                <Typography variant="h5" display={{ xs: 'none', sm: 'block' }} height="fit-content" m="auto">X</Typography>
            )}

            <RHFTextField
                name={nameHeight} label={`${translate('dialog_item_4_3')}`} type="number" size={size}
                onWheel={(e) => e.target.blur()}
                onBlur={(e) => handleInputChange(`${nameHeight}`, e.target.value)}
                InputProps={{
                    endAdornment: <InputAdornment position="end">{`${translate(`dialog_item_4_4`)}`}</InputAdornment>,
                }}
                disabled={!isEditable}
            />
        </Box>
    );
}
