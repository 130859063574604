import PropTypes from "prop-types";
import React from "react";
// @mui
import {Stack, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";

// ----------------------------------------------------------------------

GradientLoadingButton.propTypes = {
    loading: PropTypes.bool,
    children: PropTypes.node,
    sx: PropTypes.object,
};

export default function GradientLoadingButton({loading, children, sx, ...other}) {
    return (
        <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            sx={ {
                width: '188px',
                height: '40px',
                p: '8px 20px',
                color: 'white',
                borderRadius: '8px',
                whiteSpace: 'nowrap',
                ...sx,
            } }
            loading={ loading }
            loadingIndicator={
                <Stack
                    sx={ {
                        p: '9.5px 20px', borderRadius: '8px',
                        whiteSpace: 'nowrap', width: '188px', height: '40px',
                        backgroundImage: `linear-gradient(to right bottom, rgba(255,255,255,0.2) 25%, 
                                        transparent 25%, transparent 50%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0.2) 75%,
                                        transparent 75%,transparent)`,
                        backgroundSize: '60px 60px',
                        animation: 'loading 1.5s linear infinite',
                        '@keyframes loading': {
                            '0%': {
                                backgroundPosition: '0 0',
                            },
                            '100%': {
                                backgroundPosition: '60px 0',
                            },
                        },
                        ...sx,
                    } }
                >
                    <Typography color="white">{ children }</Typography>
                </Stack>
            }
            { ...other }
        >
            { children }
        </LoadingButton>
    );
}
