import { createSlice } from '@reduxjs/toolkit';
// api
import {
    getUserAccountList as getUserAccountListAPI,
    addUserAccount as addUserAccountAPI,
    modifyUserAccount as modifyUserAccountAPI,
    activeUserAccount as activeUserAccountAPI,
    deleteUserAccount as deleteUserAccountAPI,
    getCompanyPlan as getCompanyPlanAPI,
} from "../../api/user";
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    userList: [],
    companyPlan: null,
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET USERS
        getUsersSuccess(state, action) {
            state.isLoading = false;
            state.userList = action.payload;
        },

        // ACTIVE / DE-ACTIVE USERS
        changeUserStatusSuccess(state, action) {
            const { id: carrierId, isActive } = action.payload;
            const modifiedUserList = state.userList.map((_user) => {
                if (_user.id === carrierId) {
                    _user.status = isActive;
                }

                return _user;
            });

            state.isLoading = false;
            state.userList = modifiedUserList;
        },

        // MODIFY USER
        modifyUserSuccess(state, action) {
            const { id: userId, first_name: firstName, last_name: lastName, email } = action.payload;

            const modifiedUserList = state.userList.map((_user) => {
                if (_user.id === userId) {
                    _user.name = firstName;
                    _user.last_name = lastName;
                    _user.email = email;
                }

                return _user;
            });

            state.isLoading = false;
            state.userList = modifiedUserList;
        },

        // DELETE ADDRESS
        deleteUserSuccess(state, action) {
            state.isLoading = false;
            state.userList = state.userList.filter((_user) => `${_user.id}` !== `${action.payload}`) || [];
        },

        // UPDATE COMPANY PLAN
        updateCompanyPlanSuccess(state, action) {
            state.companyPlan = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getUserList() {
    return async () => {
        try {
            const response = await getUserAccountListAPI();

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getUsersSuccess(data || []));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function addUser(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await addUserAccountAPI(detail);

            const { status, data } = response;

            if (status === 200 && data?.result === 'success') {
                dispatch(getUserList());
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function modifyUser(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await modifyUserAccountAPI(detail);

            const { status, data } = response;

            if (status === 200 && data?.result === 'success') {
                // dispatch(slice.actions.modifyUserSuccess(detail));
                dispatch(getUserList());
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function changeUserStatus(id, isActive) {
    return async () => {
        try {
            const response = await activeUserAccountAPI(id, isActive);

            const { status, data } = response;

            if (status === 200 && data?.result === 'success') {
                dispatch(slice.actions.changeUserStatusSuccess({ id, isActive }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function deleteUser(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await deleteUserAccountAPI(id);

            const { status, data } = response;

            if (status === 200 && data?.result === 'success') {
                dispatch(slice.actions.deleteUserSuccess(id));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function getCompanyPlan() {
    return async () => {
        try {
            const response = await getCompanyPlanAPI();

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.updateCompanyPlanSuccess(data ?? null));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}