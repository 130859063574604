import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CopyToClipboard } from 'react-copy-to-clipboard';
// @mui
import { Tooltip } from "@mui/material";
// hooks
import useLocales from "../hooks/useLocales";

// ----------------------------------------------------------------------

CopyClipboard.propTypes = {
    copyValue: PropTypes.string,
    imageStyle: PropTypes.object,
};

export default function CopyClipboard({ copyValue, imageStyle }) {
    const { translate } = useLocales();

    const [state, setState] = useState({
        value: '',
        copied: false,
    });

    const onCopy = () => {
        setState({ ...state, copied: true });

        setTimeout(() => setState({ ...state, copied: false }), 1500);
    };

    useEffect(() => {
        if (copyValue) {
            setState({ ...state, value: copyValue });
        }
    }, [copyValue]);

    return (
        <CopyToClipboard text={state.value} onCopy={onCopy}>
            <Tooltip title={`${translate('copied')}`}
                     open={state.copied}
                     placement="top"
                     PopperProps={{
                         disablePortal: true,
                         sx: {
                             textTransform: 'capitalize',
                             '& .MuiTooltip-tooltipPlacementTop': {
                                 mb: '6px !important',
                                 lineHeight: 1.4,
                                 bgcolor: '#313D5B',
                                 borderRadius: '25px'
                             }
                         }
                     }}
                     disableFocusListener
                     disableHoverListener
                     disableTouchListener
            >
                <img width="24px"
                     height="24px"
                     src={'/assets/icon/system/ic_copy.svg'}
                     alt="copy"
                     style={{ cursor: 'pointer', ...imageStyle }}
                />
            </Tooltip>
        </CopyToClipboard>
    )
}
