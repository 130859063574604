import { createSlice } from '@reduxjs/toolkit';
import { format } from 'date-fns';
// api
import {
    addScanForm as addScanFormAPI,
    addUnPurchased as addUnPurchasedAPI,
    buyLabel as buyLabelAPI,
    cancelPickup as cancelPickupAPI,
    createPickup as createPickupAPI,
    deleteUnPurchased as deleteUnPurchasedAPI,
    getPickupList as getPickupListAPI,
    getPurchased as getPurchasedAPI,
    getPurchasedList as getPurchasedListAPI,
    getRatesList as getRatesListAPI,
    getScanFormList as getScanFormListAPI,
    getUnPurchased as getUnPurchasedAPI,
    getUnPurchasedList as getUnPurchasedListAPI,
    getUnPurchasedNumber as getUnPurchasedNumberAPI,
    modifyUnPurchased as modifyUnPurchasedAPI,
    voidLabel as voidLabelAPI,
} from "../../api/shipment";
import { validateAddress as validateAddressAPI } from "../../api/address";
import { addWarehouse as addWarehouseAPI } from "../../api/warehouse";
import { getOrder as getOrderAPI } from "../../api/order";
// utils
import { getWeightByUnit } from "../../utils/getRequestData";
import { getCountryCode } from "../../utils/getCode";
import { fShipment } from "../../utils/formatData";
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    showTutorial: false,
    quickRate: {
        rate_request: null,
    },
    shipment: {
        id: null,
        company_id: null,
        order_id: null,
        rate_request: null,
        rate_result: null,
    },
    shipmentCreate: {
        nextStep: false,
        selectedRate: null,
        buyLabelResult: [],
        predefinedPackages: [],
        selectedShipFrom: null,
        weightArr: [],
        international: false,
    },
    shipmentList: [],
    shipmentFilter: {
        labelType: 'all',
        storeId: 'all',
        status: 'all',
        carrierAccountId: 'all',
        keyword: '',
        sort: 'desc',
        page: 0,
        rowsPerPage: 5,
        total: 0,
    },
    draftNumber: 0,
    draftList: [],
    draftsFilter: {
        carrierAccountId: 'all',
        keyword: '',
        sort: 'desc',
        page: 0,
        rowsPerPage: 5,
        total: 0,
    },
    pickupList: [],
    pickupFilter: {
        status: 'all',
        keyword: '',
        sort: 'desc',
        page: 0,
        rowsPerPage: 5,
        total: 0,
    },
    scanFormList: [],
    scanFormFilter: {
        keyword: '',
        sort: 'desc',
        page: 0,
        rowsPerPage: 5,
        total: 0,
    },
};

const slice = createSlice( {
    name: 'shipment',
    initialState,
    reducers: {
        // START LOADING
        startLoading( state, action ) {
            state.isLoading = action.payload;
        },

        // HAS ERROR
        hasError( state, action ) {
            state.isLoading = false;
            state.error = action.payload;
        },

        clearError( state ) {
            state.error = null;
        },

        // UPDATE SHIPMENT-CREATE SUCCESS
        updateShipmentCreateSuccess( state, action ) {
            state.shipmentCreate = {
                ...state.shipmentCreate,
                ...action.payload,
            };
            // state.isLoading = false;
        },

        // RESET SHIPMENT CREATE
        resetShipmentCreate( state ) {
            state.shipmentCreate = {
                nextStep: false,
                selectedRate: null,
                buyLabelResult: [],
                predefinedPackages: [],
                selectedShipFrom: null,
                weightArr: [],
                international: false,
            };
        },

        // GET SHIPMENT
        getShipmentSuccess( state, action ) {
            state.isLoading = false;
            state.shipment = action.payload;
        },

        // UPDATE SHIPMENT
        updateShipmentSuccess( state, action ) {
            state.shipment = {
                ...state.shipment,
                ...action.payload,
            };
        },

        // RESET SHIPMENT
        resetShipment( state ) {
            state.shipment = {
                id: null,
                company_id: null,
                order_id: null,
                rate_request: null,
                rate_result: null,
            };
        },

        // GET SHIPMENT(PURCHASED) SHIPMENTS
        getShipmentsSuccess( state, action ) {
            state.isLoading = false;
            state.shipmentList = action.payload;

            if (!state.showTutorial && action.payload.length > 0) {
                state.showTutorial = true;
            }
        },

        // UPDATE SHIPMENT(PURCHASED) LIST FILTER
        updateShipmentListFilterSuccess( state, action ) {
            state.shipmentFilter = {
                ...state.shipmentFilter,
                ...action.payload,
            };
        },

        // GET DRAFT(UN-PURCHASED) SHIPMENTS NUMBER
        getDraftNumberSuccess( state, action ) {
            state.isLoading = false;
            state.draftNumber = action.payload;
        },

        // GET DRAFT(UN-PURCHASED) SHIPMENTS
        getDraftsSuccess( state, action ) {
            state.isLoading = false;
            state.draftList = action.payload;
        },

        // UPDATE DRAFT(UN-PURCHASED) SHIPMENT LIST FILTER
        updateDraftListFilterSuccess( state, action ) {
            state.draftsFilter = {
                ...state.draftsFilter,
                ...action.payload,
            };
        },

        // GET PICKUP SUCCESS
        getPickupsSuccess( state, action ) {
            state.isLoading = false;
            state.pickupList = action.payload;
        },

        // UPDATE PICKUP LIST FILTER
        updatePickupListFilterSuccess( state, action ) {
            state.pickupFilter = {
                ...state.pickupFilter,
                ...action.payload,
            };
        },

        // GET SCAN FORM LIST
        getScanFormSuccess( state, action ) {
            state.isLoading = false;
            state.scanFormList = action.payload;
        },

        // UPDATE SCAN FORM LIST FILTER
        updateScanFormListFilterSuccess( state, action ) {
            state.scanFormFilter = {
                ...state.scanFormFilter,
                ...action.payload,
            };
        },

        // CANCEL PICKUP
        cancelPickupSuccess( state, action ) {
            state.isLoading = false;
            state.pickupList = state.pickupList.map( ( _pickup ) => {
                if (_pickup.id === action.payload) {
                    _pickup.pickup_status = 'canceled';
                }

                return _pickup;
            } );
        },

        // UPDATE QUICKRATE
        updateQuickRateSuccess( state, action ) {
            state.quickRate = {
                ...state.quickRate,
                ...action.payload,
            };
        },

        // RESET QUICKRATE
        resetQuickRate( state ) {
            state.quickRate = {
                rate_request: null,
            };
        },
    },
} );

// Reducer
export default slice.reducer;

// Actions
export const {
    clearError,
    updateShipmentCreateSuccess,
    resetShipmentCreate,
    updateShipmentSuccess,
    resetShipment,
    updateQuickRateSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getShipmentInfo( id ) {
    return async () => {
        try {
            const response = await getUnPurchasedAPI( id );
            const {status, data} = response;

            if (status === 200 && data) {
                dispatch( slice.actions.getShipmentSuccess( data ?? null ) );
            }
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
        }
    };
}

export function getPurchasedShipmentInfo( id, isReturn ) {
    return async () => {
        try {
            const response = await getPurchasedAPI( id );
            const {status, data} = response;

            if (status === 200 && data) {
                if (isReturn) {
                    updateStandardizeShipment( data );
                } else {
                    duplicatePurchasedShipment( data );
                }
            }
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
        }
    };
}

export function getOrderShipmentInfo( orderId ) {
    return async () => {
        try {
            const response = await getOrderAPI( orderId );

            const {status, data} = response;

            if (status === 200) {
                updateStandardizeShipment( data?.shipments ?? [] );
            }
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
        }
    };
}

export function getRates( values, selectedShipFrom, selectedPackage, requireCustomForms ) {
    return async () => {
        dispatch( slice.actions.startLoading( true ) );
        try {
            const requestShipment = getUpdateShipment( {...values}, selectedShipFrom, selectedPackage, requireCustomForms );

            dispatch( slice.actions.updateShipmentCreateSuccess( {
                predefinedPackages: values.parcels,
                selectedShipFrom: values.shipFrom,
            } ) );

            const weightArr = [];

            values.parcels.forEach( ( p ) => {
                const weightObj = {
                    value: p.weight,
                    unit: p.weightUnit,
                };
                weightArr.push( weightObj );
            } );

            dispatch( slice.actions.updateShipmentCreateSuccess( {
                weightArr,
            } ) );

            dispatch( slice.actions.updateShipmentSuccess( {
                rate_request: requestShipment,
            } ) );

            const response = await getRatesListAPI( requestShipment );

            const {status, data} = response;

            if (status === 200) {
                const resultObj = data ? standardizeRatesList( data ) : null;
                dispatch( slice.actions.updateShipmentSuccess( {
                    rate_result: resultObj,
                } ) );

                if (data?.rates.length) {
                    dispatch( slice.actions.updateShipmentCreateSuccess( {
                        selectedRate: resultObj?.rates[0] ?? null,
                        nextStep: true,
                    } ) );
                }
            }

            dispatch( slice.actions.startLoading( false ) );

            if (values.saveShipFrom) {
                let residential = false;

                const response = await validateAddressAPI( {
                    street: values?.fromStreet1 || '',
                    street2: values?.fromStreet2 || '',
                    city: values?.fromCity || '',
                    state: values?.fromState || '',
                    country: getCountryCode( values.fromCountry ) || '',
                    zip: values?.fromZipcode || '',
                } );

                const {status, data} = response;

                if (status === 200) {
                    residential = Boolean( data?.residential );
                }

                await addWarehouseAPI( {
                    name: values.fromCompany || '',
                    first_name: values.fromFirstName || '',
                    last_name: values.fromLastName || '',
                    email: values.fromEmail || '',
                    phone: values.fromPhone || '',
                    street: values.fromStreet1 || '',
                    street2: values.fromStreet2 || '',
                    city: values.fromCity || '',
                    state: values.fromState || '',
                    zipcode: values.fromZipcode || '',
                    country: getCountryCode( values.fromCountry ) || '',
                    is_default: false,
                    residential,
                } );
            }
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
        }
    };
}

export function updateCheckRates( shipment, newData, optionType ) {
    return async () => {
        dispatch( slice.actions.startLoading( true ) );
        try {
            const request = {};
            Object.keys( shipment ).forEach( key => {
                if (key === 'options') {
                    const obj = {};
                    const {options} = shipment;
                    Object.keys( options ).forEach( o => {
                        if (o === 'ship_date' && optionType === 'date') {
                            obj[o] = fShipment( new Date() );
                            // obj[o] = `${format(newData, 'yyyy-MM-dd')}T20:00:00-07:00`;
                        } else if (o === 'require_hazmat' && optionType === 'hazmat') {
                            obj[o] = newData;
                        } else {
                            obj[o] = options[o];
                        }
                    } );
                    request[key] = obj;
                } else {
                    request[key] = shipment[key];
                }
            } );

            const response = await getRatesListAPI( request );

            const {status, data} = response;

            if (status === 200) {
                const resultObj = data ? standardizeRatesList( data ) : null;

                if (data?.rates.length) {
                    dispatch( slice.actions.updateShipmentSuccess( {
                        rate_result: resultObj,
                        rate_request: request,
                    } ) );

                    dispatch( slice.actions.updateShipmentCreateSuccess( {
                        selectedRate: resultObj?.rates[0] ?? null,
                        nextStep: true,
                    } ) );
                }
            }
            dispatch( slice.actions.startLoading( false ) );
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
        }
    };
}

export function buyLabel( request ) {
    return async () => {
        dispatch( slice.actions.startLoading( true ) );
        try {
            const response = await buyLabelAPI( request );

            const {status, data} = response;

            if (status === 200) {
                dispatch( slice.actions.updateShipmentCreateSuccess( {
                    buyLabelResult: data ?? [],
                } ) );
                dispatch( slice.actions.startLoading( false ) );
                return true;
            }
            dispatch( slice.actions.startLoading( false ) );
            return false;
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
            return false;
        }
    };
}

export function addUnPurchasedShipment( shipment ) {
    return async () => {
        dispatch( slice.actions.startLoading( true ) );
        try {
            const request = getUnPurchasedShipmentRequest( shipment );

            const response = await addUnPurchasedAPI( request );
            const {status} = response;

            if (status === 200) {
                dispatch( getShipmentList() );
            }
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
        }
    };
}

export function modifyUnPurchasedShipment( shipment ) {
    return async () => {
        dispatch( slice.actions.startLoading( true ) );
        try {
            const request = getUnPurchasedShipmentRequest( shipment );

            const response = await modifyUnPurchasedAPI( request );
            const {status} = response;

            if (status === 200) {
                dispatch( getShipmentList() );
            }
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
        }
    };
}

export function voidLabel( id ) {
    return async () => {
        dispatch( slice.actions.startLoading( true ) );
        try {
            const response = await voidLabelAPI( id );

            const {status, data} = response;

            if (status === 200 && data?.result === 'success') {
                await dispatch( getShipmentList() );
            }
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
        }
    };
}

export function deleteDraft( id ) {
    return async () => {
        try {
            const response = await deleteUnPurchasedAPI( id );
            const {status, data} = response;

            if (status === 200 && data?.result === 'success') {
                await dispatch( getDraftList() );
            }
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
        }
    };
}

// ----------------------------------------------------------------------

function getUpdateShipment( shipment, selectedShipFrom, selectedPackages, requireCustomForms ) {
    let fromAddress;
    if (selectedShipFrom !== null && selectedShipFrom !== undefined) {
        fromAddress = {
            city: selectedShipFrom.city,
            company: selectedShipFrom.company_name,
            country: selectedShipFrom.country,
            email: selectedShipFrom.email,
            first_name: selectedShipFrom.first_name,
            last_name: selectedShipFrom.last_name,
            phone: selectedShipFrom.phone,
            state: selectedShipFrom.state,
            street1: selectedShipFrom.street,
            street2: selectedShipFrom.street2,
            zip: selectedShipFrom.zip,
        };
    } else {
        fromAddress = {
            city: shipment?.fromCity || '',
            company: shipment?.fromCompany || '',
            country: getCountryCode( shipment?.fromCountry ) || '',
            email: shipment?.fromEmail || '',
            first_name: shipment?.fromFirstName || '',
            last_name: shipment?.fromLastName || '',
            phone: shipment?.fromPhone || '',
            state: shipment?.fromState || '',
            street1: shipment?.fromStreet1 || '',
            street2: shipment?.fromStreet2 || '',
            zip: shipment?.fromZipcode || '',
        };
    }
    const requestParcels = [];

    shipment.parcels.forEach( ( _p, index ) => {
        const printCustoms = [];

        if (_p.customPrint1 !== '') {
            printCustoms.push( _p.customPrint1 );
        }

        if (_p.customPrint2 !== '') {
            printCustoms.push( _p.customPrint2 );
        }

        const weightValue = getWeightByUnit( _p.weight, _p.weightUnit );

        let parcel;
        if (selectedPackages[index].pid !== 1) {
            const selectedP = selectedPackages[index];

            let type = 'PACKAGE';
            let packageId = null;
            if (selectedP.type !== 'PACKAGE') {
                type = selectedP.code;
                if (!selectedP.isSubheader && selectedP.carrier !== 'USER') {
                    packageId = selectedP.id;
                }
            }
            parcel = {
                height: selectedP.height || 0,
                length: selectedP.length || 0,
                width: selectedP.width || 0,
                predefined_package: type || 'PACKAGE',
                predefined_package_carrier: selectedP.carrier || 'USER',
                predefined_package_id: packageId,
                signature: shipment?.signature || 'NO_SIGNATURE',
                require_additional_handling: shipment?.irregularPackage || false,
                insurance: shipment?.insuranceProvider !== 'NONE' ? parseFloat( _p.insurance !== "" ? _p.insurance : 0 ) : 0,
                weight: weightValue,
                print_customs: printCustoms,
            };
        } else {
            parcel = {
                height: _p.height || 0,
                length: _p.length || 0,
                width: _p.width || 0,
                predefined_package: 'PACKAGE',
                predefined_package_carrier: 'USER',
                predefined_package_id: null,
                signature: shipment?.signature || 'NO_SIGNATURE',
                require_additional_handling: shipment?.irregularPackage || false,
                insurance: shipment?.insuranceProvider !== 'NONE' ? parseFloat( _p.insurance !== "" ? _p.insurance : 0 ) : 0,
                weight: weightValue,
                print_customs: printCustoms,
            };
        }

        if (shipment.parcels.length === 1 && shipment?.insuranceProvider !== 'NONE') {
            parcel.insurance = parseFloat( shipment.insurance );
        }

        requestParcels.push( parcel );
    } );

    const result = {
        from_address: fromAddress,
        to_address: {
            city: shipment?.toCity || '',
            company: shipment?.toCompany || '',
            country: getCountryCode( shipment?.toCountry ) || '',
            email: shipment?.toEmail || '',
            first_name: shipment?.toFirstName || '',
            last_name: shipment?.toLastName || '',
            phone: shipment?.toPhone || '',
            state: shipment?.toState || '',
            street1: shipment?.toStreet1 || '',
            street2: shipment?.toStreet2 || '',
            zip: shipment?.toZipcode || '',
            residential: shipment?.toResidential || false,
        },
        parcels: requestParcels,
        options: {
            require_saturday_delivery: shipment?.saturdayDelivery || false,
            require_carbon_neutral: shipment?.hazardousMaterials || false,
            ship_date: fShipment( new Date() ),
            // ship_date: `${format(new Date(), 'yyyy-MM-dd')}T20:00:00-07:00`,
            insurance_provider: shipment?.insuranceProvider !== 'NONE' ? shipment?.insuranceProvider : null,
            is_return_label: shipment?.returnStatus,
            return_service_code: (shipment?.returnServiceCode ?? null) === "NONE" ? null : (shipment.returnServiceCode || null),
            return_label_delivery_email: shipment?.returnLabelDeliveryEmail ?? null,
            require_direct_delivery: shipment?.requireDirectDelivery,
            rma_number: shipment?.returnStatus ? format( new Date(), 'YmdHis' ) : null,
            require_hazmat: shipment?.hazardousMaterial || false,
        },
        customs_info: {
            contents_type: shipment?.contentsType || 'MERCHANDISE',
            non_delivery_option: shipment?.nonDeliveryOption || 'RETURN',
            customs_items: requireCustomForms ? shipment?.items.map( _item => ({
                ..._item,
                origin_country: getCountryCode( _item?.origin_country ) || '',
            }) ) : [],
        },
        is_quick_rate: false,
    };

    // if (requireCustomForms) {
    //     result.customs_info.contents_type = shipment?.contentsType || 'MERCHANDISE';
    //     result.customs_info.customs_items = (shipment?.items ?? []).map(_item => ({
    //         ..._item,
    //         origin_country: getCountryCode(_item?.origin_country) || '',
    //     }));
    //
    //     // result.customs_info = {
    //     //     contents_type: shipment?.contentsType || 'MERCHANDISE',
    //     //     non_delivery_option: shipment?.nonDeliveryOption || 'RETURN',
    //     //     customs_items: shipment?.items.map(_item => ({
    //     //         ..._item,
    //     //         origin_country: getCountryCode(_item?.origin_country) || '',
    //     //     })),
    //     // }
    // }
    
    if (shipment.eelPfcType === 'EXEMPTION_CODE') {
        result.customs_info.eel_pfc_type = shipment.eelPfcType;
        result.customs_info.eel_pfc_code = shipment.exemption;
    } else if (shipment.eelPfcType === 'ITN') {
        result.customs_info.eel_pfc_type = shipment.eelPfcType;
        result.customs_info.eel_pfc_code = shipment.itn;
    }

    if (shipment.taxIdType !== '') {
        result.customs_info.tax_ids = [
            {
                tax_id_type: shipment.taxIdType,
                tax_id_number: shipment.taxIdValue,
                country_code: 'US',
            },
        ];
    }

    return result;
}

function getUnPurchasedShipmentRequest( shipment ) {
    return {
        shipments_rate_id: shipment.id,
        order_id: shipment.order_id,
        request: shipment.rate_request,
        response: shipment.rate_result,
    };
}

export function updateStandardizeShipment( data ) {
    let fromAddress;
    let toAddress;
    const parcels = [];

    data?.forEach( ( _shipment, index ) => {
        if (index === 0) {
            fromAddress = {
                city: _shipment?.from_city || '',
                company: _shipment?.from_company || '',
                country: _shipment?.from_country || '',
                email: '',
                name: _shipment?.from_name || '',
                first_name: splitName( _shipment?.from_name ).firstName,
                last_name: splitName( _shipment?.from_name ).lastName,
                phone: _shipment?.from_phone || '',
                state: _shipment?.from_state || '',
                street1: _shipment?.from_street || '',
                street2: _shipment?.from_street2 || '',
                zip: _shipment?.from_zip || '',
            };
            toAddress = {
                city: _shipment?.to_city || '',
                company: _shipment?.to_company || '',
                country: _shipment?.to_country || '',
                email: '',
                name: _shipment?.to_name || '',
                first_name: splitName( _shipment?.to_name ).firstName,
                last_name: splitName( _shipment?.to_name ).lastName,
                phone: _shipment?.to_phone || '',
                state: _shipment?.to_state || '',
                street1: _shipment?.to_street || '',
                street2: _shipment?.to_street2 || '',
                zip: _shipment?.to_zip || '',
            };
        }
        if (data?.length === 1) {
            parcels.push( {
                length: _shipment?.length || '',
                width: _shipment?.width || '',
                height: _shipment?.height || '',
                weight: _shipment?.weight || '',
                insurance: 0,
                print_customs: [],
                predefined_package: _shipment?.predefined_package || '',
                predefined_package_carrier: _shipment?.provider === 'user' ? 'USER' : _shipment?.carrier,
            } );
        }
    } );

    dispatch( slice.actions.updateShipmentSuccess( {
        rate_request: data?.length ? {
            from_address: toAddress ?? null,
            to_address: fromAddress ?? null,
            parcels,
        } : null,
    } ) );
}

export function duplicatePurchasedShipment( data ) {
    let fromAddress;
    let toAddress;
    const parcels = [];

    data?.forEach( ( _shipment, index ) => {
        if (index === 0) {
            fromAddress = {
                city: _shipment?.from_city || '',
                company: _shipment?.from_company || '',
                country: _shipment?.from_country || '',
                email: '',
                name: _shipment?.from_name || '',
                first_name: splitName( _shipment?.from_name ).firstName,
                last_name: splitName( _shipment?.from_name ).lastName,
                phone: _shipment?.from_phone || '',
                state: _shipment?.from_state || '',
                street1: _shipment?.from_street || '',
                street2: _shipment?.from_street2 || '',
                zip: _shipment?.from_zip || '',
            };
            toAddress = {
                city: _shipment?.to_city || '',
                company: _shipment?.to_company || '',
                country: _shipment?.to_country || '',
                email: '',
                name: _shipment?.to_name || '',
                first_name: splitName( _shipment?.to_name ).firstName,
                last_name: splitName( _shipment?.to_name ).lastName,
                phone: _shipment?.to_phone || '',
                state: _shipment?.to_state || '',
                street1: _shipment?.to_street || '',
                street2: _shipment?.to_street2 || '',
                zip: _shipment?.to_zip || '',
            };
        }

        parcels.push( {
            length: _shipment?.length || '',
            width: _shipment?.width || '',
            height: _shipment?.height || '',
            weight: _shipment?.weight || '',
            insurance: 0,
            print_customs: [],
            predefined_package: _shipment?.predefined_package || '',
            predefined_package_carrier: _shipment?.provider === 'user' ? 'USER' : _shipment?.carrier,
        } );
    } );

    dispatch( slice.actions.updateShipmentSuccess( {
        rate_request: data?.length ? {
            from_address: fromAddress ?? null,
            to_address: toAddress ?? null,
            parcels,
        } : null,
    } ) );
}

export function standardizeRatesList( data ) {
    const ratesArr = [];

    data.rates.forEach( ( rate, index ) => {
        if (rate?.is_cheapest) {
            ratesArr.push( {
                ...rate,
                displaySubtitle: true,
                subtitle: 'shipments_create_8_1',
                bgColor: '#00AB55',
                imgPath: '/assets/icon/system/ic_cheapest',
            } );
        }
    } );

    data.rates.filter( rate => (rate.is_popular && !rate?.is_cheapest) ).forEach( ( rate, index ) => {
        ratesArr.push( {
            ...rate,
            displaySubtitle: index === 0,
            subtitle: 'shipments_create_8_3',
            bgColor: '#2C6CD2',
            imgPath: '/assets/icon/system/ic_popular',
        } );

    } );

    data.rates.filter( rate => (!rate.is_popular && !rate.is_cheapest) ).forEach( ( rate, index ) => {
        ratesArr.push( {
            ...rate,
            displaySubtitle: index === 0,
            subtitle: 'shipments_create_8_2',
            bgColor: '#8D1BFF',
            imgPath: '/assets/icon/system/ic_more',
        } );
    } );


    const result = {};
    Object.keys( data ).forEach( o => {
        if (o === 'rates') {
            result[o] = ratesArr;
        } else {
            result[o] = data[o];
        }
    } );

    return result;
}

// ----------------------------------------------------------------------

export function getShipmentList( filter ) {
    return async ( dispatch, getState ) => {
        dispatch( slice.actions.startLoading( true ) );
        try {
            const response = await getPurchasedListAPI( filter || getState().shipment.shipmentFilter );

            const {status, data} = response;

            if (status === 200) {
                dispatch( slice.actions.getShipmentsSuccess( data?.data || [] ) );
                dispatch( slice.actions.updateShipmentListFilterSuccess( {
                    total: data?.total || 0,
                    rowsPerPage: data?.per_page || 5,
                } ) );
            }
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
        }
    };
}

// ----------------------------------------------------------------------

export function updateShipmentListFilter( filter ) {
    return () => {
        dispatch( slice.actions.updateShipmentListFilterSuccess( {
            labelType: filter?.labelType || 'all',
            storeId: filter?.storeId || 'all',
            status: filter?.status || 'all',
            carrierAccountId: filter?.carrierAccountId || 'all',
            keyword: filter?.keyword || '',
            sort: filter?.sort || 'desc',
            page: filter?.page || 0,
            rowsPerPage: filter?.rowsPerPage || 5,
            total: filter?.total || 0,
        } ) );
    };
}

// ----------------------------------------------------------------------

export function getDraftList( filter ) {
    return async ( dispatch, getState ) => {
        dispatch( slice.actions.startLoading( true ) );
        try {
            const response = await getUnPurchasedListAPI( filter || getState().shipment.draftsFilter );

            const {status, data} = response;

            if (status === 200) {
                dispatch( slice.actions.getDraftsSuccess( data?.data || [] ) );
                dispatch( slice.actions.updateDraftListFilterSuccess( {
                    total: data?.total || 0,
                    rowsPerPage: data?.per_page || 5,
                } ) );
            }
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
        }
    };
}

// ----------------------------------------------------------------------

export function getDraftNumber() {
    return async () => {
        dispatch( slice.actions.startLoading( true ) );
        try {
            const response = await getUnPurchasedNumberAPI();

            const {status, data} = response;

            if (status === 200) {
                dispatch( slice.actions.getDraftNumberSuccess( data?.count_number ?? 0 ) );
            }
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
        }
    };
}

// ----------------------------------------------------------------------

export function updateDraftListFilter( filter ) {
    return () => {
        dispatch( slice.actions.updateDraftListFilterSuccess( {
            carrierAccountId: filter?.carrierAccountId || 'all',
            keyword: filter?.keyword || '',
            sort: filter?.sort || 'desc',
            page: filter?.page || 0,
            rowsPerPage: filter?.rowsPerPage || 5,
            total: filter?.total || 0,
        } ) );
    };
}

// ----------------------------------------------------------------------

export function getPickupList( filter ) {
    return async ( dispatch, getState ) => {
        dispatch( slice.actions.startLoading( true ) );
        try {
            const response = await getPickupListAPI( filter || getState().shipment.pickupFilter );

            const {status, data} = response;

            if (status === 200) {
                dispatch( slice.actions.getPickupsSuccess( data?.data || [] ) );
                dispatch( slice.actions.updatePickupListFilterSuccess( {
                    total: data?.total || 0,
                    rowsPerPage: data?.per_page || 5,
                } ) );
            }
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
        }
    };
}

// ----------------------------------------------------------------------

export function addPickup( request ) {
    return async () => {
        dispatch( slice.actions.startLoading( true ) );
        try {
            const response = await createPickupAPI( request );

            const {status, data} = response;

            if (status === 200 && data) {
                const {confirmation_number: confirmationNumber, pickup_date: pickupDate} = data;

                dispatch( getPickupList( {} ) );

                return {
                    confirmation: confirmationNumber || '',
                    pickupDate: pickupDate || '',
                };
            }
            return false;
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function updatePickupListFilter( filter ) {
    return () => {
        dispatch( slice.actions.updatePickupListFilterSuccess( {
            status: filter?.status || 'all',
            keyword: filter?.keyword || '',
            sort: filter?.sort || 'desc',
            page: filter?.page || 0,
            rowsPerPage: filter?.rowsPerPage || 5,
            total: filter?.total || 0,
        } ) );
    };
}

// ----------------------------------------------------------------------

export function getScanFormList( filter ) {
    return async ( dispatch, getState ) => {
        dispatch( slice.actions.startLoading( true ) );
        try {
            const response = await getScanFormListAPI( filter || getState().shipment.scanFormFilter );

            const {status, data} = response;

            if (status === 200) {
                dispatch( slice.actions.getScanFormSuccess( data?.data || [] ) );
                dispatch( slice.actions.updateScanFormListFilterSuccess( {
                    total: data?.total || 0,
                    rowsPerPage: data?.per_page || 5,
                } ) );
            }
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
        }
    };
}

// ----------------------------------------------------------------------

export function addScanForm( request ) {
    return async () => {
        dispatch( slice.actions.startLoading( true ) );
        try {
            const response = await addScanFormAPI( request );

            const {status, data} = response;

            if (status === 200 && data) {
                dispatch( getScanFormList( {} ) );
                return true;
            }
            return false;
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function updateScanFormFilter( filter ) {
    return () => {
        dispatch( slice.actions.updateScanFormListFilterSuccess( {
            keyword: filter?.keyword || '',
            sort: filter?.sort || 'desc',
            page: filter?.page || 0,
            rowsPerPage: filter?.rowsPerPage || 5,
            total: filter?.total || 0,
        } ) );
    };
}

// ----------------------------------------------------------------------

export function resetFilter() {
    return async () => {
        const defaultFilter = {
            labelType: 'all',
            storeId: 'all',
            status: 'all',
            carrierAccountId: 'all',
            keyword: '',
            sort: 'desc',
            page: 0,
            rowsPerPage: 5,
            total: 0,
        };

        dispatch( slice.actions.updateShipmentListFilterSuccess( {...defaultFilter} ) );

        return defaultFilter;
    };
}

// ----------------------------------------------------------------------

export function resetDraftFilter() {
    return async () => {
        const defaultFilter = {
            carrierAccountId: 'all',
            keyword: '',
            sort: 'desc',
            page: 0,
            rowsPerPage: 5,
            total: 0,
        };

        dispatch( slice.actions.updateDraftListFilterSuccess( {...defaultFilter} ) );

        return defaultFilter;
    };
}

// ----------------------------------------------------------------------

export function resetPickupFilter() {
    return async () => {
        const defaultFilter = {
            status: 'all',
            keyword: '',
            sort: 'desc',
            page: 0,
            rowsPerPage: 5,
            total: 0,
        };

        dispatch( slice.actions.updatePickupListFilterSuccess( {...defaultFilter} ) );

        return defaultFilter;
    };
}

// ----------------------------------------------------------------------

export function resetScanFormFilter() {
    return async () => {
        const defaultFilter = {
            keyword: '',
            sort: 'desc',
            page: 0,
            rowsPerPage: 5,
            total: 0,
        };

        dispatch( slice.actions.updateScanFormListFilterSuccess( {...defaultFilter} ) );

        return defaultFilter;
    };
}

// ----------------------------------------------------------------------

export function cancelPickup( id ) {
    return async () => {
        dispatch( slice.actions.startLoading( true ) );
        try {
            const response = await cancelPickupAPI( id );

            const {status, data} = response;

            if (status === 200 && data?.result === 'success') {
                dispatch( slice.actions.cancelPickupSuccess( id ) );
                return true;
            }
            return false;
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function getQuickRates( detail ) {
    return async () => {
        dispatch( slice.actions.startLoading( true ) );
        try {
            const result = await getRatesListAPI( detail );

            const {status, data} = result;

            if (status === 200) {
                dispatch( slice.actions.startLoading( false ) );
                return standardizeRatesList( data || null );
            }
            dispatch( slice.actions.startLoading( false ) );
            return false;
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function splitName( str ) {
    try {
        const strArr = str.split( " " );
        if (strArr.length > 1) {
            return {
                firstName: str.substring( 0, (str.length - strArr[strArr.length - 1].length - 1) ),
                lastName: strArr.pop(),
            };
        }
        return {firstName: str, lastName: ''};
    } catch (error) {
        console.log( error );
        return {firstName: '', lastName: ''};
    }
}

// ----------------------------------------------------------------------

export function getPredefineFromQuickRates() {
    return async ( dispatch, getState ) => {
        try {
            const _shipment = getState().shipment;

            if (!_shipment.quickRate.rate_request) return;

            const tempRateRequest = {
                ..._shipment.shipment.rate_request,
                ..._shipment.quickRate.rate_request,
            };

            dispatch( slice.actions.updateShipmentSuccess( {
                rate_request: {
                    ...tempRateRequest,
                },
            } ) );
            dispatch( slice.actions.resetQuickRate() );
        } catch (error) {
            dispatch( slice.actions.hasError( error ) );
        }
    };
}
