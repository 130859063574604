import { createSlice } from '@reduxjs/toolkit';
// api
import {
    getShippingRulesList as getShippingRulesListAPI,
    addShippingRule as addShippingRuleAPI,
    modifyShippingRule as modifyShippingRuleAPI,
    deleteShippingRule as deleteShippingRuleAPI,
} from "../../api/shippingRule";
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    shippingRuleList: [],
    shippingRuleFilter: {
        sort: 'desc',
        page: 0,
        rowsPerPage: 5,
        total: 0,
    },
    isOpenModal: false,
};

const slice = createSlice({
    name: 'address',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET SHIPPING RULE LIST SUCCESS
        getShippingRulesSuccess(state, action) {
            state.isLoading = false;
            state.shippingRuleList = action.payload;
        },

        // UPDATE SHIPPING RULE LIST FILTER
        updateShippingRuleFilterSuccess(state, action) {
            state.shippingRuleFilter = {
                ...state.shippingRuleFilter,
                ...action.payload,
            };
        },

        // DELETE ADDRESS
        deleteShippingRuleSuccess(state, action) {
            state.isLoading = false;
            state.shippingRuleList = state.shippingRuleList.filter((_shippingRule) => `${_shippingRule.id}` !== `${action.payload}`) || [];
        },

        // OPEN NEW EDIT MODAL
        openModal(state) {
            state.isOpenModal = true;
        },

        // CLOSE NEW EDIT MODAL
        closeModal(state) {
            state.isOpenModal = false;
        },

        // RESET STATES
        resetShippingRules(state) {
            state.isOpenModal = false;
        },
    },
});

export default slice.reducer;

export const { openModal, closeModal, resetShippingRules } = slice.actions;

// ----------------------------------------------------------------------

export function getShippingRuleList(filter) {
    return async () => {
        try {
            const response = await getShippingRulesListAPI(filter);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getShippingRulesSuccess(data?.data ?? []));
                dispatch(slice.actions.updateShippingRuleFilterSuccess({
                    total: data?.total || 0,
                    rowsPerPage: data?.per_page || 5,
                    page: data?.current_page || 0,
                }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateShippingRuleListFilter(filter) {
    return () => {
        dispatch(slice.actions.updateShippingRuleFilterSuccess({
            sort: filter?.sort || 'desc',
            page: filter?.page || 0,
            rowsPerPage: filter?.rowsPerPage || 5,
            total: filter?.total || 0,
        }));
    };
}

// ----------------------------------------------------------------------

export function addShippingRule(detail) {
    return async (dispatch, getState) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await addShippingRuleAPI(detail);

            const { status, data } = response;

            if (status === 200 && data?.result === 'success') {
                dispatch(getShippingRuleList(getState().shippingRule.shippingRuleFilter));
                dispatch(slice.actions.closeModal());
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function modifyShippingRule(detail) {
    return async (dispatch, getState) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await modifyShippingRuleAPI(detail);

            const { status, data } = response;

            if (status === 200 && data?.result === 'success') {
                dispatch(getShippingRuleList(getState().shippingRule.shippingRuleFilter));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function deleteShippingRule(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await deleteShippingRuleAPI(id);

            const { status, data } = response;

            if (status === 200 && data?.result === 'success') {
                dispatch(slice.actions.deleteShippingRuleSuccess(id));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}
