import PropTypes from 'prop-types';
import React from "react";
// @mui
import { Box, Stack, Typography } from '@mui/material';
import { styled } from "@mui/material/styles";
// components
import Image from "../Image";

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ style }) => {
    return {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: '10px',
        ...style,
    };
});

// ----------------------------------------------------------------------

ImageBox.propTypes = {
    title: PropTypes.string.isRequired,
    src: PropTypes.string,
    quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sku: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    size: PropTypes.number,
    style: PropTypes.object,
    onClick: PropTypes.func,
    imageProps: PropTypes.object,
    customizeArea: PropTypes.element,
    product: PropTypes.bool,
    bordered: PropTypes.bool,
    disabledBase: PropTypes.bool,
};

export default function ImageBox({ title, src, quantity, sku, content, size = 40, style, onClick, imageProps, customizeArea, product, bordered, disabledBase, sx }) {
    return (
        <RootStyle style={{ ...style, cursor: onClick ? 'pointer' : 'unset' }} onClick={onClick}>
            <Image
                src={src}
                alt={`image-${title}`}
                onError={(event) => {
                    event.target.src = '/assets/icon/system/ic_default_image.svg';
                }}
                sx={{
                    width: size, height: size, borderRadius: '4px', overflow: 'hidden',
                    ...(bordered && { border: '1px solid #292929' }),
                    '& img': { objectFit: 'contain' },
                    ...sx,
                }}
                visibleByDefault
                disabledEffect
                disabledBase={disabledBase}
                {...imageProps}
            />

            {product && quantity && (
                <Typography variant="body1" sx={{ minWidth: '20px' }} noWrap>
                    {`x ${quantity}`}
                </Typography>
            )}

            <Stack direction="row" flex={1} spacing={1} flexShrink={0} alignSelf="auto">
                <Stack flex={1} height={1} spacing={0.25} alignSelf={product ? 'flex-start' : 'auto'}>
                    <Typography variant="body1" sx={{ textOverflow: "ellipsis", display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical', overflow: "hidden" }}>
                        {title}
                    </Typography>

                    {product && sku && (
                        <Typography variant="body1" color="text.disabled" noWrap>
                            {`SKU: ${sku}`}
                        </Typography>
                    )}

                    {content && (
                        <Typography variant="body1" color="text.disabled" noWrap>
                            {content}
                        </Typography>
                    )}
                </Stack>

                {customizeArea}
            </Stack>
        </RootStyle>
    );
}
