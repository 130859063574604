export const PERMISSIONS_ROUTE = {
    '/shipments': 'shipments',
    '/shipments/create': 'shipments.buy_label',
    '/scan-form': 'shipments.scan_form',
    '/shipments/pickup/schedule': 'shipments.pick_up',
    '/orders': 'orders',
    '/items': 'items',
    '/reports': 'reports',
    // '/partner-tool': 'usps',
    '/partner-tool': 'carrier_referral',
    '/settings/transactions': 'account.billing.transaction',
    '/settings/billings': 'account.billing',
    '/settings/integration': 'integration',
    '/settings/configuration': 'configuration',
};
