// utils
import axios from '../utils/axios';

// ----------------------------------------------------------------------

const getBalance = () => axios.get('/billing/balance/info');

const getBalanceIncreaseTimes = () => axios.get('/billing/balance/increase_times');

const balancePayoff = ({method, amount}) => axios.post('/billing/balance/payoff', {
    payment_method_id: method || '',
    amount: amount || 0,
});

const rechargeBalance = ({method, amount, billing}) => axios.post('/billing/balance/increase', {
    payment_method_id: method || '',
    amount: amount || 0,
    billing,
});

const rechargeBalanceByThirdParty = ({method, amount, billing}) => axios.post('/yuansfer/checkout', {
    type: method || '',
    amount: amount || 0,
    billing,
});

const rechargeBalanceByZelleImageUpload = (file) => {
    const formData = new FormData();
    formData.append('file', file);

    return axios.post('/billing/offline/image_upload', formData);
};

const rechargeBalanceByZelle = (detail) => axios.post('/billing/offline/add', {...detail});

// ----------------------------------

const getPaymentMethodList = () => axios.post('/billing/payment_methods/list', {
    per_page: 25,
    current_page: 0,
});

const getPaymentMethod = (id) => axios.get('/billing/payment_methods/info', {
    params: {
        id,
    },
});

const addPaymentMethod = (data) => axios.post('/billing/payment_methods/add', {
    ...data,
});

const addPaymentForPlanMethod = (data) => axios.post('/billing/payment_methods/add_for_plan', {
    ...data,
});

const modifyPaymentMethod = (data) => axios.post('/billing/payment_methods/modify', {
    ...data,
});

const verifyPaymentMethod = (data) => axios.post('/billing/payment_methods/verify', {
    ...data,
});

const deletePaymentMethod = (id) => axios.delete('/billing/payment_methods/delete', {
    data: {
        ids: [id],
    },
});

const makeDefaultPaymentMethod = (id) => axios.post('/billing/payment_methods/make_default', null, {
    params: {
        id,
    },
});

// ----------------------------------

const getTransactionList = (filter) => axios.post('/billing/transaction/list', {
    transaction_type: filter?.transactionType === 'all' ? null : (filter?.transactionType || null),
    search_year: filter?.searchYear === 'all' ? null : (filter?.searchYear || null),
    // eslint-disable-next-line no-nested-ternary
    tracking_code: filter?.keyword === 'all' ? null : (filter?.keyword ? filter.keyword.trim() : null),
    per_page: filter?.rowsPerPage || 5,
    current_page: filter?.page || 0,
    order_by: [
        {
            name: "created_at",
            sorting: filter?.sort || "desc",
        },
    ],
});

const getTransaction = (id) => axios.get('/billing/transaction/info', {
    params: {
        id,
    },
});

const getInvoice = (detail) => axios.post('/billing/transaction/get_invoice', {
        transaction_id: detail.transactionId,
        billing: detail.billing,
    }, {responseType: 'blob'},
);

const exportTransactionList = (filter) => axios.post('/billing/transaction/export', {
    transaction_type: filter?.transactionType === 'all' ? null : (filter?.transactionType || null),
    search_year: filter?.searchYear === 'all' ? null : (filter?.searchYear || null),
    // eslint-disable-next-line no-nested-ternary
    keyword: filter?.keyword === 'all' ? null : (filter?.keyword ? filter.keyword.trim() : null),
});

// ----------------------------------

const getZelleList = (filter) => axios.post('/billing/offline/list', {
    offline_type: "ZELLE",
    offline_status: filter?.offlineStatus === 'all' ? null : (filter?.offlineStatus || null),
    per_page: filter?.rowsPerPage || 5,
    current_page: filter?.page || 0,
    order_by: [
        {
            name: "created_at",
            sorting: filter?.sort || "desc",
        },
    ],
});

// ----------------------------------

const getRebate = () => axios.get('/rebate/info');

const getRebateList = (filter) => axios.post('/rebate/list', {
    start_time: filter?.startTime === 'all' ? null : (filter?.startTime || null),
    end_time: filter?.endTime === 'all' ? null : (filter?.endTime || null),
    // eslint-disable-next-line no-nested-ternary
    tracking_code: filter?.keyword === 'all' ? null : (filter?.keyword ? filter.keyword.trim() : null),
    per_page: filter?.rowsPerPage || 5,
    current_page: filter?.page || 0,
    order_by: [
        {
            name: "created_at",
            sorting: filter?.sort || "desc",
        },
    ],
});

const redeemRebate = () => axios.get('/rebate/redeem');

// ----------------------------------

const getExportTaskStatus = (id) => axios.get('/task/get_status', {
    params: {
        task_id: id,
    },
});

const getExportFile = (name) => axios.get('/task/download_export_file', {
    responseType: 'blob',
    params: {
        file_name: name,
    },
});

// ----------------------------------------------------------------------

export {
    getBalance,
    getBalanceIncreaseTimes,
    balancePayoff,
    rechargeBalance,
    rechargeBalanceByThirdParty,
    rechargeBalanceByZelle,
    rechargeBalanceByZelleImageUpload,
    // ----------------------------------
    getPaymentMethodList,
    getPaymentMethod,
    addPaymentMethod,
    addPaymentForPlanMethod,
    modifyPaymentMethod,
    deletePaymentMethod,
    verifyPaymentMethod,
    makeDefaultPaymentMethod,
    // ----------------------------------
    getTransactionList,
    getTransaction,
    getInvoice,
    exportTransactionList,
    getZelleList,
    // ----------------------------------
    getRebate,
    getRebateList,
    redeemRebate,
    // ----------------------------------
    getExportTaskStatus,
    getExportFile,
};
