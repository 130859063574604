import PropTypes from 'prop-types';
import React from "react";
// @mui
import { Stack, Typography } from '@mui/material';
// hooks
import useLocales from "../../../../hooks/useLocales";
// components
import Image from "../../../../components/Image";

// ----------------------------------------------------------------------

OrderSelectService.propTypes = {
    currentDetail: PropTypes.array,
}

export default function OrderSelectService({ currentDetail }) {
    const { translate } = useLocales();

    return (
        <Stack spacing={2.5}>
            <Typography variant="h3">{`${translate('orders_6_5_9')}`}</Typography>

            <Stack spacing={2}>
                {currentDetail.map((detail, index) => {
                    return (
                        <Stack key={`service-${index}`} direction="row" px={2} py={2.75} sx={{ border: '1px solid #D4D4D4', borderRadius: '8px' }}>
                            <Stack direction="row" spacing={1.25} flex={1}>
                                <Image src={`/assets/icon/carriers/ic_${detail.carrier.toLowerCase()}.svg`}
                                       alt="usps"
                                       sx={{ mt: '2px', width: '40px', height: '40px', borderRadius: '8px', '& img': { objectFit: 'contain' } }}
                                       disabledEffect
                                />

                                <Stack flex={1}>
                                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                                        <Stack flex={1}>
                                            <Typography variant="body1" textTransform="capitalize">
                                                {detail.service_level_nick_name ?? detail.service_level_name}
                                                {detail?.is_saturday_delivery && `${translate('quickrate_10_3')}`}
                                            </Typography>
                                        </Stack>

                                        <Stack flex={0}>
                                            <Typography variant="h5" noWrap>
                                                {`$${detail.your_rate || '-'}`}
                                            </Typography>
                                        </Stack>
                                    </Stack>

                                    <Typography variant="body1" sx={{ color: (theme) => `${theme.palette.grey[500]}`, pr: 3 }}>
                                        {detail?.delivery_days ? `${translate('shipment_viewDetails_9_2_1')}${detail?.delivery_days}${translate('shipment_viewDetails_9_2_2')}` : `${translate('shipment_viewDetails_9_3')}`}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    )
                })}
            </Stack>
        </Stack>
    )
}
