import PropTypes from 'prop-types';
import React, { useState } from "react";
// @mui
import { Box, IconButton, Stack, Typography, Modal } from "@mui/material";
// hooks
import useLocales from "../../../../hooks/useLocales";
// components
import Iconify from "../../../../components/Iconify";
import ImageBox from "../../../../components/customized/ImageBox";
import MoreActions from "../../../../components/customized/MoreActions";

// ----------------------------------------------------------------------

OrderTableCellItems.propTypes = {
    detail: PropTypes.object,
    isShowCombine: PropTypes.bool,
    onShowCombine: PropTypes.func,
};

export default function OrderTableCellItems( {detail, isShowCombine, onShowCombine} ) {
    const {translate} = useLocales();

    const {child_orders: childOrders, combined_order: combinedOrder, order_items: orderItems} = detail;
    const {item, title, quantity, sku} = orderItems[0] || {};
    const {thumbnail} = item || {};

    const [showMultiItemsActions, setShowMultiItemsActions] = useState( null );

    const handleOpenMenu = ( event ) => {
        if (event?.currentTarget)
            setShowMultiItemsActions( event.currentTarget );
    };

    const handleCloseMenu = () => setShowMultiItemsActions( null );

    const [open, setOpen] = React.useState( false );
    const [openCombinedItems, setOpenCombinedItems] = React.useState( false );
    const handleOpenImage = () => setOpen( true );
    const handleClose = () => setOpen( false );

    const [itemID, setItemID] = React.useState( 0 );

    const handleOpenCombinedImage = ( id ) => {
        setOpenCombinedItems( true );
        setItemID( id );
    };
    const handleCombinedImageClose = () => {
        setOpenCombinedItems( false );
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: '#fff',
        border: '1px solid #ddd',
        borderRadius: '10px',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Box>
            {/* 无Item */}
            {(!childOrders || !childOrders.length) && (!orderItems || !orderItems.length) && (
                <Typography variant="body1" noWrap>N/A</Typography>
            )}

            {/* 1个Order，单种Item */}
            {(!childOrders || !childOrders.length) && orderItems && orderItems.length === 1 && (
                <Stack direction="row">
                    <Box>
                        <ImageBox onClick={thumbnail ? handleOpenImage : null} title="" src={thumbnail || ''} sx={thumbnail && {'& img': {cursor: 'zoom-in'}}} product bordered disabledBase/>
                        <Modal open={open} onClose={handleClose} disableAutoFocus>
                            <Box sx={style}>
                                <img src={thumbnail || ''} alt={title}/>
                            </Box>
                        </Modal>
                    </Box>

                    <Stack direction="row" flex={1} justifyContent="space-between" spacing={1}>
                        <Stack flex={1} spacing={0.25}>
                            <Typography variant="body1" sx={{overflow: "hidden", textOverflow: "ellipsis", display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical'}}>
                                {title}
                            </Typography>

                            <Typography variant="body1" sx={{color: 'text.secondary'}} noWrap>
                                {`SKU: ${sku}`}
                            </Typography>
                        </Stack>

                        <Typography variant="body1" sx={{minWidth: '20px'}} noWrap>
                            {`x ${quantity}`}
                        </Typography>
                    </Stack>
                </Stack>
            )}

            {/* 1个Order，多种Item */}
            {(!childOrders || !childOrders.length) && orderItems && orderItems.length > 1 && (
                <ImageBox
                    title={`${translate( 'orders_6_4_1_1' )}${orderItems.length}${translate( 'orders_6_4_1_2' )}`}
                    content={`${translate( 'orders_6_4_2' )}`}
                    src="/assets/icon/orders/ic_items_group.svg"
                    imageProps={{
                        alt: "items group",
                        sx: {
                            width: '40px', height: '40px', bgcolor: '#F7F7F7', borderRadius: '4px', border: '1px solid #292929',
                            '& img': {margin: 'auto', objectFit: 'contain'},
                        },
                    }}
                    customizeArea={
                        <MoreActions
                            open={showMultiItemsActions}
                            onOpen={handleOpenMenu}
                            onClose={handleCloseMenu}
                            display={
                                <IconButton sx={{mt: '-12px', width: '20px', height: '20px', minWidth: 'unset', padding: 0}}>
                                    <Iconify icon="eva:arrow-ios-downward-fill" sx={{width: 16, height: 16, color: '#292929'}}/>
                                </IconButton>
                            }
                            actions={
                                <Stack width="24em" spacing={2.5} maxHeight="268px" p={1.5} overflow="scroll" sx={{msOverflowStyle: 'none', scrollbarWidth: 'none', '&::-webkit-scrollbar:horizontal': {display: 'none'}}}>
                                    {orderItems.map( ( _item, index ) => {
                                        const {id: _orderID, item: _orderItem, title: _orderTitle, quantity: _orderQuantity, sku: _orderSKU} = _item || {};
                                        const {thumbnail: _orderItemThumbnail} = _orderItem || {};

                                        return (
                                            <Stack key={`item-${_orderID}-${index}`} direction="row">
                                                <Box>
                                                    <ImageBox
                                                        onClick={_orderItemThumbnail ? () => handleOpenCombinedImage( _orderID ) : null} title="" src={_orderItemThumbnail || ''}
                                                        sx={thumbnail && {'& img': {cursor: 'zoom-in'}}} product bordered disabledBase
                                                    />
                                                    <Modal open={openCombinedItems && (itemID === _orderID)} onClose={handleCombinedImageClose} disableAutoFocus>
                                                        <Box sx={style}>
                                                            <img src={_orderItemThumbnail || ''} alt={_orderTitle}/>
                                                        </Box>
                                                    </Modal>
                                                </Box>

                                                <Stack direction="row" flex={1} justifyContent="space-between" spacing={1}>
                                                    <Stack spacing={0.25}>
                                                        <Typography variant="body1" sx={{overflow: "hidden", textOverflow: "ellipsis", display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical'}}>
                                                            {_orderTitle}
                                                        </Typography>

                                                        <Typography variant="body1" sx={{color: 'text.secondary'}} noWrap>
                                                            {`SKU: ${_orderSKU}`}
                                                        </Typography>
                                                    </Stack>

                                                    <Typography variant="body1" sx={{minWidth: '20px'}} noWrap>
                                                        {`x ${_orderQuantity}`}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        );
                                    } )}
                                </Stack>
                            }
                            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                            transformOrigin={{vertical: 'top', horizontal: 'right'}}
                        />
                    }
                    bordered
                />
            )}

            {/* Combined Order */}
            {combinedOrder !== 1 && childOrders && childOrders.length > 0 && (
                <ImageBox
                    title={`${translate( 'orders_6_4_3_1' )}${childOrders.length}${translate( 'orders_6_4_3_2' )}`}
                    content={`${translate( 'orders_6_4_2' )}`}
                    src="/assets/icon/orders/ic_items_combine.svg"
                    imageProps={{
                        alt: "items combine",
                        sx: {
                            width: '40px', height: '40px', bgcolor: '#F7F7F7', borderRadius: '4px', border: '1px solid #292929',
                            '& img': {margin: 'auto', objectFit: 'contain'},
                        },
                    }}
                    customizeArea={
                        <IconButton sx={{mt: '-12px', width: '20px', height: '20px', minWidth: 'unset', padding: 0}}>
                            <Iconify icon={isShowCombine ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'} sx={{width: 16, height: 16, color: '#292929'}}/>
                        </IconButton>
                    }
                    onClick={() => onShowCombine( !isShowCombine )}
                    bordered
                />
            )}
        </Box>
    );
}
