import { useState } from 'react';
// @mui
import { MenuItem, Stack } from '@mui/material';
// hooks
import useLocales from '../../../hooks/useLocales';
// components
import Image from '../../../components/Image';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import Iconify from "../../../components/Iconify";

// ----------------------------------------------------------------------

export default function LanguagePopover({ hasArrow }) {
    const { allLangs, currentLang, onChangeLang } = useLocales();

    const [open, setOpen] = useState(null);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleChangeLang = (newLang) => {
        onChangeLang(newLang);
        handleClose();
    };

    return (
        <>
            <Stack direction='row' alignItems='center' onClick={hasArrow ? handleOpen : null}>
                <IconButtonAnimate
                    onClick={hasArrow ? null : handleOpen}
                    sx={{
                        width: 40,
                        height: 40,
                        p: 0,
                        ...(open && { bgcolor: 'action.selected' }),
                    }}
                >
                    <Image disabledEffect src='/assets/icon/navbar/ic_language.svg' alt='language' sx={{ width: '24px', height: '24px', '& img': { objectFit: 'contain' } }}/>
                    {/* <Image disabledEffect src={currentLang.icon} alt={currentLang.label} sx={{ width: '28px', height: '20px', '& img': { objectFit: 'contain' } }}/> */}
                </IconButtonAnimate>

                {hasArrow ? (
                    <Iconify
                        icon='eva:arrow-ios-downward-fill'
                        sx={{ width: 16, height: 16 }}
                    />
                ) : null}
            </Stack>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    mt: 1.5,
                    ml: 0.75,
                    width: 180,
                    '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
                }}
            >
                <Stack spacing={0.75}>
                    {allLangs.map((option) => (
                        <MenuItem
                            key={option.value}
                            selected={option.value === currentLang.value}
                            onClick={() => handleChangeLang(option.value)}
                        >
                            <Image disabledEffect alt={option.label} src={option.icon} sx={{ width: 28, height: 20, mr: 2 }}/>

                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>
            </MenuPopover>
        </>
    );
}
