import { GlobalStyles } from '@mui/material';

// ----------------------------------------------------------------------

export default function AutocompleteStyle() {
    return (
        <GlobalStyles
            styles={{
                '.pac-container': {
                    zIndex: 9999,
                    ':after': {
                        display: 'none'
                    }
                },
                '.pac-item': {
                    padding: '8px 4px'
                },
                '.hdpi .pac-icon': {
                    backgroundImage: "url('https://shipsaving.s3.us-west-2.amazonaws.com/resources/public/assets/icon/system/ic_address.svg')",
                },
                '.pac-icon': {
                    marginTop: 0,
                    width: '30px',
                    height: '30px',
                    backgroundPosition: 'center',
                    backgroundImage: "url('https://shipsaving.s3.us-west-2.amazonaws.com/resources/public/assets/icon/system/ic_address.svg')",
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: 0.5
                }
            }}
        />
    );
}
