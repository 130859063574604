import React from "react";
import PropTypes from 'prop-types';
// @mui
import { Box, Button, Dialog, Stack, Typography } from '@mui/material';
// hooks
import useLocales from "../../hooks/useLocales";
// components
import Image from "../../components/Image";

// ----------------------------------------------------------------------

LockedAccountNotification.propTypes = {
    onClose: PropTypes.func,
    onClickButton: PropTypes.func,
    open: PropTypes.bool,
};

export default function LockedAccountNotification({ open, onClose, onClickButton }) {
    const { translate } = useLocales();

    return (
        <Dialog fullWidth open={open} onClose={onClose}>
            <Box sx={{ py: "40px", px: "60px" }}>
                <Image src='/assets/image/system/locked_account.svg' alt='locked account' sx={{ height: "180px", mb: "30px", '& img': { mx: 'auto', objectFit: 'contain' }, '&.MuiBox-root': { mx: 'auto' } }}/>

                <Stack mb={3.75} spacing={1.25} textAlign='center'>
                    <Typography variant="h2" sx={{ letterSpacing: 0 }}>{`${translate('dialog_auth_locked_account_1')}`}</Typography>
                    <Typography variant="body1" textAlign='center'>{`${translate('dialog_auth_locked_account_2')}`}</Typography>
                </Stack>

                <Button variant="contained" sx={{ p: '8px 20px', whiteSpace: 'nowrap', textTransform: 'inherit' }} onClick={onClickButton} fullWidth>
                    {`${translate('dialog_auth_locked_account_3')}`}
                </Button>
            </Box>
        </Dialog>
    );
}
