import PropTypes from "prop-types";
import React from "react";
// @mui
import {styled} from "@mui/material/styles";
import {Alert, Grow} from "@mui/material";
//
import {HEADER} from "../../config";

// ----------------------------------------------------------------------

const GrowStyle = styled(Grow, {})(({theme}) => ({
    position: 'absolute',
    top: HEADER.MOBILE_HEIGHT,
    left: 0,
    right: 0,
    borderRadius: 0,
    zIndex: theme.zIndex.appBar + 1,
    [theme.breakpoints.up('lg')]: {
        top: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    },
}));

// ----------------------------------------------------------------------

HeaderAlert.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.node,
};

export default function HeaderAlert({active, children}) {
    return (
        <GrowStyle in={ active }>
            <Alert variant="filled" severity="error" sx={ {opacity: '0.9 !important', alignItems: 'center', '& .MuiAlert-message': {width: 1}} }>
                { children }
            </Alert>
        </GrowStyle>
    )
}
