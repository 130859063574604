import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import userReducer from './slices/user';
import orderReducer from './slices/order';
import shipmentReducer from './slices/shipment';
import calendarReducer from './slices/calendar';
import paymentReducer from './slices/payment';
import carrierReducer from './slices/carrier';
import addressReducer from './slices/address';
import packageReducer from './slices/package';
import storeReducer from './slices/store';
import tourReducer from './slices/tour';
import notificationReducer from './slices/notification';
import warehouseReducer from './slices/warehouse';
import automationReducer from './slices/automation';
import rebateReducer from './slices/rebate';
import otherReducer from './slices/other';
import itemReducer from './slices/item';
import shippingRuleReducer from './slices/shippingRule';
import reportReducer from './slices/report';
import partnerReducer from './slices/partner';
import planReducer from './slices/plan';
import permissionReducer from './slices/permission';

// ----------------------------------------------------------------------

const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    // whitelist: [],
};

const tourPersistConfig = {
    key: 'tour',
    storage,
    keyPrefix: 'redux-',
};

const userPersistConfig = {
    key: 'user',
    storage,
    keyPrefix: 'redux-',
};

const orderPersistConfig = {
    key: 'order',
    storage,
    keyPrefix: 'redux-',
};

const shipmentPersistConfig = {
    key: 'shipment',
    storage,
    keyPrefix: 'redux-',
};

const paymentPersistConfig = {
    key: 'payment',
    storage,
    keyPrefix: 'redux-',
};

const rebatePersistConfig = {
    key: 'rebate',
    storage,
    keyPrefix: 'redux-',
};

const carrierPersistConfig = {
    key: 'carrier',
    storage,
    keyPrefix: 'redux-',
};

const addressPersistConfig = {
    key: 'address',
    storage,
    keyPrefix: 'redux-',
};

const packagePersistConfig = {
    key: 'package',
    storage,
    keyPrefix: 'redux-',
};

const storePersistConfig = {
    key: 'store',
    storage,
    keyPrefix: 'redux-',
};

const notificationPersistConfig = {
    key: 'notification',
    storage,
    keyPrefix: 'redux-',
};

const warehousePersistConfig = {
    key: 'warehouse',
    storage,
    keyPrefix: 'redux-',
};

const automationPersistConfig = {
    key: 'automation',
    storage,
    keyPrefix: 'redux-',
};

const otherPersistConfig = {
    key: 'other',
    storage,
    keyPrefix: 'redux-',
};

const itemPersistConfig = {
    key: 'item',
    storage,
    keyPrefix: 'redux-',
};

const shippingRulePersistConfig = {
    key: 'shippingRule',
    storage,
    keyPrefix: 'redux-',
};

const reportPersistConfig = {
    key: 'report',
    storage,
    keyPrefix: 'redux-',
};

const partnerPersistConfig = {
    key: 'partner',
    storage,
    keyPrefix: 'redux-',
};

const planPersistConfig = {
    key: 'plan',
    storage,
    keyPrefix: 'redux-',
};

const permissionPersistConfig = {
    key: 'permission',
    storage,
    keyPrefix: 'redux-',
};

const appReducer = combineReducers({
    calendar: calendarReducer,
    // tour: persistReducer(tourPersistConfig, tourReducer),
    // user: persistReducer(userPersistConfig, userReducer),
    // order: persistReducer(orderPersistConfig, orderReducer),
    // shipment: persistReducer(shipmentPersistConfig, shipmentReducer),
    // payment: persistReducer(paymentPersistConfig, paymentReducer),
    // rebate: persistReducer(rebatePersistConfig, rebateReducer),
    // carrier: persistReducer(carrierPersistConfig, carrierReducer),
    // address: persistReducer(addressPersistConfig, addressReducer),
    // package: persistReducer(packagePersistConfig, packageReducer),
    // store: persistReducer(storePersistConfig, storeReducer),
    // notification: persistReducer(notificationPersistConfig, notificationReducer),
    // warehouse: persistReducer(warehousePersistConfig, warehouseReducer),
    // automation: persistReducer(automationPersistConfig, automationReducer),
    // other: persistReducer(otherPersistConfig, otherReducer),
    // item: persistReducer(itemPersistConfig, itemReducer),
    // shippingRule: persistReducer(shippingRulePersistConfig, shippingRuleReducer),
    // report: persistReducer(reportPersistConfig, reportReducer),
    // partner: persistReducer(partnerPersistConfig, partnerReducer),
    // plan: persistReducer(planPersistConfig, planReducer),
    // permission: persistReducer(permissionPersistConfig, permissionReducer),
    // ==================
    tour: tourReducer,
    user: userReducer,
    order: orderReducer,
    shipment: shipmentReducer,
    payment: paymentReducer,
    rebate: rebateReducer,
    carrier: carrierReducer,
    address: addressReducer,
    package: packageReducer,
    store: storeReducer,
    notification: notificationReducer,
    warehouse: warehouseReducer,
    automation: automationReducer,
    other: otherReducer,
    item: itemReducer,
    shippingRule: shippingRuleReducer,
    report: reportReducer,
    partner: partnerReducer,
    plan: planReducer,
    permission: permissionReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        // for all keys defined in your persistConfig(s)
        storage.removeItem('persist:root');

        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

export { rootPersistConfig, rootReducer };
