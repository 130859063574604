import PropTypes from 'prop-types';
import React from "react";
// @mui
import { Box, Typography, Stack } from '@mui/material';
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import Image from "../Image";

// ----------------------------------------------------------------------

DialogHeader.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    src: PropTypes.string,
};

export default function DialogHeader({ title, content, src }) {
    const isDesktop = useResponsive('up', 'sm');

    return (
        <Box position='relative' width={1} height='120px' px={3.75} pt={3.75} pb={2.5} alignItems='center' justifyContent='center' bgcolor='#2C6CD2'
             sx={{ borderTopLeftRadius: '16px', borderTopRightRadius: '16px', ...(!isDesktop && {px:1.875}) }}
        >
            <Stack spacing={0.625} maxWidth='300px'>
                <Typography variant="h2" color='white'>{title}</Typography>
                <Typography variant="body1" color='white'>{content}</Typography>
            </Stack>

            {isDesktop && src && (
                <Stack position='absolute' top='20px' right='42px' zIndex={1}>
                    <Image src={src} alt="" sx={{ width: '145px', height: '130px', '& img': { objectFit: 'contain' } }}/>
                </Stack>
            )}
        </Box>
    );
}
