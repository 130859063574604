// utils
import axios from '../utils/axios';

// ----------------------------------------------------------------------

const getStoreList = () => axios.get('/stores/list');

const getOptionStoreList = () => axios.get('/options/stores');

const getStore = (id) => axios.get('/stores/info', {
    params: {
        id
    }
});

const addStore = (data) => axios.post('/stores/add', { ...data });

const modifyStore = (data) => axios.post('/stores/modify', { ...data });

const deleteStore = (id) => axios.delete('/stores/delete', {
    params: {
        id
    }
});

const getStoreRedirectURL = (platform) => axios.post('/stores/auth/pre_url', {
    platform
});

const getStoreAuthURL = (data) => axios.post('/stores/auth/url', { ...data });

const authStoreToken = (data) => axios.post('/stores/auth/token', { ...data });

const authStoreConfirm = (data) => axios.post('/stores/auth/confirm', { ...data });

const getConnectStoreList = () => axios.get('/config/channel');

//---------------------------------------------------------------------------
// store auth & add
const getStoreAuthVerify = (data) => axios.post('/stores/add', { ...data });


// --------------------------------------------------------------------------

export {
    getStoreList,
    getOptionStoreList,
    getStore,
    addStore,
    modifyStore,
    deleteStore,
    getStoreRedirectURL,
    getStoreAuthURL,
    authStoreToken,
    authStoreConfirm,
    getConnectStoreList,
    getStoreAuthVerify,
}
