import { useEffect, useState, createContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// routes
import { PATH_AUTH } from "../routes/paths";
// hooks
import useAuth from "../hooks/useAuth";

// ----------------------------------------------------------------------

const RouterContext = createContext(null);

function RouterProvider({ children }) {
    const location = useLocation()

    const navigate = useNavigate();

    const { isAuthenticated, verifyToken, logout } = useAuth();

    const [route, setRoute] = useState({
        to: location.pathname,
        from: location.pathname
    });

    // useEffect(() => {
    //     setRoute((prev) => ({ to: location.pathname, from: prev.to }))
    // }, [location]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        if (isAuthenticated && location.pathname !== "/") {
            const result = await verifyToken();

            if (result === true) {
                setRoute((prev) => ({ to: location.pathname, from: prev.to }))
            } else if (result?.code === 'com.shipsaving.auth.token.expired' || result?.code === 'com.shipsaving.auth.token.invalid' || result?.code === 'com.shipsaving.company.inactivated') {
                logout().then(() => navigate(PATH_AUTH.login, { replace: true }));
            } else {
                setRoute((prev) => ({ to: PATH_AUTH.login, from: prev.to }))
            }
        } else {
            setRoute((prev) => ({ to: location.pathname, from: prev.to }))
        }

        // setRoute((prev) => ({ to: location.pathname, from: prev.to }))
    }, [isAuthenticated, location]);

    return (
        <RouterContext.Provider value={route}>
            {children}
        </RouterContext.Provider>
    )
}

export { RouterProvider, RouterContext };
