import React, { useEffect } from "react";
import TagManager from "react-gtm-module";
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { AutocompleteStyle } from './components/autocomplete-address';

// ----------------------------------------------------------------------

function initGTM() {
    if (window.gtmDidInit) return;

    window.gtmDidInit = true;

    TagManager.initialize({ gtmId: 'GTM-PZDRM7CZ' });
}

function initGTMOnEvent(event) {
    initGTM();
    event.currentTarget.removeEventListener(event.type, initGTMOnEvent);
}

// ----------------------------------------------------------------------

export default function App() {
    useEffect(() => {
        setTimeout(() => initGTM(), 5000);

        window.addEventListener("mousemove", initGTMOnEvent);
        window.addEventListener("keydown", initGTMOnEvent);
        window.addEventListener("scroll", initGTMOnEvent);
        window.addEventListener("touchstart", initGTMOnEvent);

        // 在head标签插入meta标签，解决在生产环境链接失效问题
        const metaTag = document.getElementsByTagName('meta');

        let isHasTag = true;

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < metaTag.length; i++) {   // 避免重复插入meta标签

            const { httpEquiv } = metaTag[i];

            if (httpEquiv === 'Cache-Control') {
                isHasTag = false;
            }
        }

        if (isHasTag) {
            const headItem = document.head;

            const oMeta = document.createElement('meta');

            oMeta.setAttribute('http-equiv', 'Cache-Control');
            oMeta.content = 'no-cache, no-stone, must-revalidate';

            headItem.appendChild(oMeta)
        }

        return () => {
            window.removeEventListener("mousemove", initGTMOnEvent);
            window.addEventListener("keydown", initGTMOnEvent);
            window.removeEventListener("scroll", initGTMOnEvent);
            window.removeEventListener("touchstart", initGTMOnEvent);
        }
    }, []);

    return (
        <MotionLazyContainer>
            <ThemeProvider>
                <ThemeSettings>
                    <NotistackProvider>
                        <ProgressBarStyle/>
                        <ChartStyle/>
                        <AutocompleteStyle/>
                        <ScrollToTop/>
                        <Router/>
                    </NotistackProvider>
                </ThemeSettings>
            </ThemeProvider>
        </MotionLazyContainer>
    );
}
