import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import VerificationInput from "react-verification-input";
import { useNavigate } from "react-router-dom";
// @mui
import { Box, Dialog, Stack, Typography, Button, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
// api
import { resendEmail as resendEmailAPI } from "../../../../api/auth";
// routes
import { PATH_AUTH, PATH_DASHBOARD } from "../../../../routes/paths";
// hooks
import useLocales from "../../../../hooks/useLocales";
// components
import Image from "../../../../components/Image";
import useAuth from "../../../../hooks/useAuth";

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
    container: {
        width: "fit-content",
        height: "60px",
        gap: "30px"
    },
    character: {
        width: "60px",
        height: "60px",
        fontSize: "24px",
        borderColor: "#D8DDE1",
        borderRadius: "8px",
        backgroundColor: "white",
        lineHeight: "60px",
        overflow: "hidden"
    },
    characterInactive: {
        backgroundColor: "white",
    },
}));

VerificationCodeDialog.propTypes = {
    email: PropTypes.string,
    open: PropTypes.bool,
    onComplete: PropTypes.func,
    onResendEmail: PropTypes.func,
    onClose: PropTypes.func,
};

export default function VerificationCodeDialog({ email, open, onComplete, onResendEmail, onClose }) {
    const { translate } = useLocales();

    const classes = useStyles();

    const navigate = useNavigate();

    const { activeUser, checkPermission } = useAuth();

    const [errorResponse, setErrorResponse] = useState(null);

    const handleActiveUser = (code) => {
        activeUser(email, code).then(async (response) => {
            if (response) {
                const { permissionList } = response;

                if (permissionList && await checkPermission("carrier_referral", permissionList)) {
                    navigate(PATH_DASHBOARD.partner.root, { replace: true });
                } else {
                    navigate(PATH_DASHBOARD.root)
                }

                onClose();
            }
        }).catch((error) => setErrorResponse(error));
    }

    const handleOnComplete = (code) => {
        onComplete(code);
        onClose();
    }

    const handleResendEmail = () => {
        if (onComplete) {
            onResendEmail();
        } else {
            resendEmailAPI(email).catch((error) => setErrorResponse(error));
        }
    }

    useEffect(() => {
        if (errorResponse) {
            setTimeout(() => {
                setErrorResponse(null)
            }, 5000);
        }
    }, [errorResponse])

    return (
        <Dialog fullWidth open={open} onClose={() => null}
                PaperProps={{ style: { maxHeight: "unset", overflow: 'auto' } }}
                sx={{ '& .MuiDialog-container': { alignItems: 'flex-start' }, }}
        >
            <Image src="/assets/image/auth/verification.svg" alt="verification" sx={{ width: '100%', height: "auto", mx: 'auto' }}/>

            <Box sx={{ py: 5, px: 5 }}>
                <Stack alignItems="center">
                    <Typography variant="h1" mb={1.25} textAlign="center">{`${translate('dialog_auth_verify_code_0')}`}</Typography>

                    <Typography variant="body1" mb={2.5} textAlign="center">{`${translate(onComplete ? 'dialog_auth_verify_code_1_2' : 'dialog_auth_verify_code_1_1')}`}</Typography>

                    <Stack mb={1.25}>
                        <VerificationInput
                            length={4} placeholder="" autoFocus
                            inputProps={{
                                style: {
                                    height: 60,
                                    lineHeight: "60px"
                                }
                            }}
                            classNames={{
                                container: classes.container,
                                character: classes.character,
                                characterInactive: classes.characterInactive,
                                // characterSelected: "character--selected",
                                // characterFilled: "character--filled",
                            }}
                            onComplete={(code) => onComplete ? handleOnComplete(code) : handleActiveUser(code)}
                        />
                    </Stack>

                    <Typography variant="body1" color="error.main" mb={1} textAlign="center">
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {`${translate(errorResponse?.code.replaceAll(".", "_").replaceAll(" ", "_"))}` === errorResponse?.code.replaceAll(".", "_").replaceAll(" ", "_") ?
                            (errorResponse?.message ? errorResponse.message : `${translate('default_error_msg')}`) :
                            `${translate(errorResponse?.code.replaceAll(".", "_").replaceAll(" ", "_"))}`}
                    </Typography>

                    <Typography variant="body1" mb={0} textAlign="center">
                        {`${translate('dialog_auth_verify_code_2')}`}
                        <Link component={Button} variant="contained" color="#2C6CD2" sx={{ bgcolor: 'transparent !important', p: 0 }} underline="always" disableRipple onClick={handleResendEmail}>
                            {`${translate('dialog_auth_verify_code_3')}`}
                        </Link>
                    </Typography>
                </Stack>
            </Box>
        </Dialog>
    );
}
