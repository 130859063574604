import { createSlice } from '@reduxjs/toolkit';
// api
import {
    getRebate as getRebateAPI,
    // ----------------------------------
    getRebateList as getRebateListAPI,
} from "../../api/billing";
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    rebate: '0',
    rebateList: [],
    filter: {
        startTime: null,
        endTime: null,
        keyword: '',
        sort: 'desc',
        page: 0,
        rowsPerPage: 5,
        total: 0
    },
};

const slice = createSlice({
    name: 'rebate',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET REBATE
        getRebateSuccess(state, action) {
            state.isLoading = false;
            state.rebate = action.payload;
        },

        // GET REBATE METHODS
        getRebatesSuccess(state, action) {
            state.isLoading = false;
            state.rebateList = action.payload;
        },

        // UPDATE REBATE LIST FILTER
        updateRebateListFilterSuccess(state, action) {
            state.filter = {
                ...state.filter,
                ...action.payload
            }
        },

        // RESET FILTER
        resetFilter(state) {
            state.filter = {
                startTime: null,
                endTime: null,
                keyword: '',
                sort: 'desc',
                page: 0,
                rowsPerPage: 5,
                total: 0
            }
        },
    }
});

// Reducer
export default slice.reducer;

// Actions
export const { resetFilter } = slice.actions;

// ----------------------------------------------------------------------

export function getRebate() {
    return async () => {
        try {
            const response = await getRebateAPI();

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getRebateSuccess(data?.rebate ?? '0'));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

// ----------------------------------------------------------------------

export function getRebateList(filter) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await getRebateListAPI(filter);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getRebatesSuccess(data?.data || []));
                dispatch(slice.actions.updateRebateListFilterSuccess({
                    total: data?.total || 0,
                    rowsPerPage: data?.per_page || 5,
                }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

// ----------------------------------------------------------------------

export function updateRebateListFilter(filter) {
    return () => {
        dispatch(slice.actions.updateRebateListFilterSuccess({
            startTime: filter?.startTime || null,
            endTime: filter?.endTime || null,
            keyword: filter?.keyword || '',
            sort: filter?.sort || 'desc',
            page: filter?.page || 0,
            rowsPerPage: filter?.rowsPerPage || 5,
            total: filter?.total || 0,
        }));
    }
}
