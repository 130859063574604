// ----------------------------------------------------------------------

export function getWeightByUnit(weight, unit) {
    let result = '';

    switch (unit) {
        case 'lb':
            result = weight;
            break;
        case 'oz':
            result = Math.round(weight * 6.25 * 100) / 10000;
            break;
        case 'g':
            result = Math.round(weight * 0.220462 * 100) / 10000;
            break;
        case 'kg':
            result = Math.round(weight * 220.462 * 100) / 10000;
            break;
        default:
            result = weight;
            break;
    }

    return result;
}

export function getParcel(form, data) {
    let parcel;

    if (data) {
        let type = 'PACKAGE';

        if (data.type !== 'PACKAGE') type = data?.code;

        parcel = {
            pid: data?.pid ?? '',
            length: data?.length || 0,
            width: data?.width || 0,
            height: data?.height || 0,
            code: type || 'PACKAGE',
            carrier: data?.carrier || 'USER',
        };
    } else {
        parcel = {
            length: form.length || 0,
            width: form.width || 0,
            height: form.height || 0,
            code: 'PACKAGE',
            carrier: 'USER',
        };
    }

    return parcel;
}
