// utils
import axios from '../utils/axios';

// ----------------------------------------------------------------------

const getCarrierAccountList = () => axios.get('/carrier_accounts/list');

const activeCarrierAccount = (id, isActive) => axios.post('/carrier_accounts/active', null, {
    params: {
        id,
        is_active: isActive
    }
});

const addCarrierAccount = (detail) => axios.post('/carrier_accounts/add', { ...detail });

const modifyCarrierAccount = (detail) => axios.post('/carrier_accounts/modify', { ...detail });

const deleteCarrierAccount = (id) => axios.delete('/carrier_accounts/delete', {
    params: {
        id
    }
});

const getConnectCarrierList = () => axios.get('/config/carrier');

// ----------------------------------

const getCarrierBillingAccountList = (id) => axios.get('/bill_accounts/list', {
    params: {
        carrier_account_id: id
    }
});

const getCarrierBillingAccount = (id) => axios.get('/bill_accounts/info', {
    params: {
        id
    }
});

const addCarrierBillingAccount = (detail) => axios.post('/bill_accounts/add', { ...detail });

const modifyCarrierBillingAccount = (detail) => axios.post('/bill_accounts/modify', { ...detail });

const deleteCarrierBillingAccount = (id) => axios.delete('/bill_accounts/delete', {
    params: {
        id
    }
});
// ----------------------------------

const getUPSLicense = (detail) => axios.post('/carrier_accounts/ups/license', { ...detail });

const getFedexLicense = (detail) => axios.post('/carrier_accounts/fedex/registration', { ...detail });

const getUPSAgreement = () => axios.post('/carrier_accounts/ups/agreement');

// ----------------------------------------------------------------------

const getServiceLevels = (carrierCode) => axios.get('/options/carrier/services', {
    params: {
        carrier_code: carrierCode
    }
});

const getServiceLevelsSSAccount = (providerId) => axios.get('/options/provider/services', {
    params: {
        provider_id: providerId
    }
});

// ----------------------------------------------------------------------

const getUPSDAPAcceptInfo = () => axios.get('/carrier_accounts/ups_dap/agreement/info');

const setUPSDAPAccept = (detail) => axios.post('/carrier_accounts/ups_dap/agreement/set', { ...detail });

const getUPSSaverAcceptInfo = () => axios.get('/carrier_accounts/ups_saver/agreement/info');

const setUPSSaverAccept = () => axios.get('/carrier_accounts/ups_saver/agreement/set');

export {
    getCarrierAccountList,
    activeCarrierAccount,
    addCarrierAccount,
    modifyCarrierAccount,
    deleteCarrierAccount,
    getConnectCarrierList,
    // ----------------------------------
    getCarrierBillingAccountList,
    getCarrierBillingAccount,
    addCarrierBillingAccount,
    modifyCarrierBillingAccount,
    deleteCarrierBillingAccount,
    // ----------------------------------
    getUPSLicense,
    getUPSAgreement,
    getFedexLicense,
    // ----------------------------------
    getServiceLevels,
    getServiceLevelsSSAccount,
    // ----------------------------------
    getUPSDAPAcceptInfo,
    setUPSDAPAccept,
    // ----------------------------------
    getUPSSaverAcceptInfo,
    setUPSSaverAccept
}
