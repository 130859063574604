import {createSlice} from '@reduxjs/toolkit';
// api
import {voidLabel as voidLabelAPI} from "../../api/shipment";
import {
    addOrder as addOrderAPI,
    addOrderCombine as addOrderCombineAPI,
    addPickingList as addPickingListAPI,
    deleteOrder as deleteOrderAPI,
    deletePickingList as deletePickingListAPI,
    deletePickingListOrder as deletePickingListOrderAPI,
    getOrder as getOrderAPI,
    getOrderAwaitingNumberA as getOrderAwaitingNumberAAPI,
    getOrderAwaitingNumberB as getOrderAwaitingNumberBAPI,
    getOrderCombineList as getOrderCombineListAPI,
    getOrderList as getOrderListAPI,
    getPickingList as getPickingListAPI,
    modifyBatchOrderHold as modifyBatchOrderHoldAPI,
    modifyBatchOrderStatus as modifyBatchOrderStatusAPI,
    modifyOrder as modifyOrderAPI,
    modifyPickingListOrder as modifyPickingListOrderAPI,
} from "../../api/order";
// utils
import {getAppointedDate} from "../../utils/getFilterDate";
//
import {dispatch} from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    awaitingNumber: 0,
    order: null,
    orderList: [],
    sidebarFilter: {
        orderNumber: '',
        itemName: '',
        itemSKU: '',
        itemQuantity: '',
        warehouseID: 'all',
        shippingArea: 'all',
        isCombined: null,
        isHold: null,
        trackingCode: '',
        clientName: '',
        clientCity: '',
        clientZipcode: '',
    },
    filter: {
        storeId: 'all',
        carrierAccountId: 'all',
        dateSelection: 'all',
        startTime: '',
        endTime: '',
        status: '',
        keyword: '',
        sort: 'desc',
        page: 0,
        rowsPerPage: 25,
        total: 0,
    },
    orderAwaitingList: [],
    awaitingFilter: {
        storeId: 'all',
        carrierAccountId: 'all',
        dateSelection: 'all',
        startTime: '',
        endTime: '',
        status: 'awaiting',
        keyword: '',
        sort: 'desc',
        page: 0,
        rowsPerPage: 25,
        total: 0,
    },
    orderShippedList: [],
    shippedFilter: {
        storeId: 'all',
        carrierAccountId: 'all',
        dateSelection: 'all',
        startTime: '',
        endTime: '',
        status: 'shipped',
        keyword: '',
        sort: 'desc',
        page: 0,
        rowsPerPage: 25,
        total: 0,
    },
    orderCancelledList: [],
    cancelledFilter: {
        storeId: 'all',
        carrierAccountId: 'all',
        dateSelection: 'all',
        startTime: '',
        endTime: '',
        status: 'canceled',
        keyword: '',
        sort: 'desc',
        page: 0,
        rowsPerPage: 25,
        total: 0,
    },
    combineList: [],
    pickingList: [],
};

const slice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET ORDER
        getOrderSuccess(state, action) {
            state.isLoading = false;
            state.order = action.payload;
        },

        // ADD ORDER
        addOrderSuccess(state) {
            state.isLoading = false;
        },

        // MODIFY ORDER
        modifyOrderSuccess(state) {
            state.isLoading = false;
        },

        // DELETE ORDER
        deleteOrderSuccess(state) {
            state.isLoading = false;
        },

        // UPDATE ORDER SIDEBAR FILTER
        updateOrderSidebarFilterSuccess(state, action) {
            state.sidebarFilter = {
                ...state.sidebarFilter,
                ...action.payload,
            };
        },

        // MODIFY AWAITING ORDER LIST
        modifyAwaitingOrderSuccess(state, action) {
            // const obj = action.payload;
            const modifiedOrderList = state.orderAwaitingList.map((_order) => {
                if (action.payload?.id === _order.id) {
                    _order = action.payload;
                }

                return _order;
            });

            state.isLoading = false;
            state.orderAwaitingList = modifiedOrderList;
        },

        // MODIFY ALL ORDER LIST
        modifyAllOrderSuccess(state, action) {
            // const obj = action.payload;
            const modifiedAllOrderList = state.orderList.map((_order) => {
                if (action.payload?.id === _order.id) {
                    _order = action.payload;
                }

                return _order;
            });

            state.isLoading = false;
            state.orderList = modifiedAllOrderList;
        },

        // GET ORDER LIST
        getOrdersSuccess(state, action) {
            state.isLoading = false;
            state.orderList = action.payload;
        },

        // UPDATE ORDER LIST FILTER
        updateOrderListFilterSuccess(state, action) {
            state.filter = {
                ...state.filter,
                ...action.payload,
            };
        },

        // GET ORDER AWAITING LIST
        getOrdersAwaitingSuccess(state, action) {
            state.isLoading = false;
            state.orderAwaitingList = action.payload;
        },

        // UPDATE ORDER AWAITING LIST FILTER
        updateOrderAwaitingListFilterSuccess(state, action) {
            state.awaitingFilter = {
                ...state.awaitingFilter,
                ...action.payload,
            };
        },

        // GET ORDER SHIPPED LIST
        getOrdersShippedSuccess(state, action) {
            state.isLoading = false;
            state.orderShippedList = action.payload;
        },

        // UPDATE ORDER SHIPPED LIST FILTER
        updateOrderShippedListFilterSuccess(state, action) {
            state.shippedFilter = {
                ...state.shippedFilter,
                ...action.payload,
            };
        },

        // GET ORDER CANCELED LIST
        getOrdersCancelledSuccess(state, action) {
            state.isLoading = false;
            state.orderCancelledList = action.payload;
        },

        // UPDATE ORDER CANCELED LIST FILTER
        updateOrderCancelledListFilterSuccess(state, action) {
            state.cancelledFilter = {
                ...state.cancelledFilter,
                ...action.payload,
            };
        },

        // GET ORDER COMBINE LIST
        getOrdersCombineSuccess(state, action) {
            state.isLoading = false;
            state.combineList = action.payload;
        },

        // ADD ORDER COMBINE SUCCESS
        addOrdersCombineSuccess(state, action) {
            state.isLoading = false;
            state.combineList.splice(action.payload.numOfList, 1);
        },

        // GET ORDER PICKING LIST
        getOrdersPickingSuccess(state, action) {
            state.isLoading = false;
            state.pickingList = action.payload;
        },

        // GET ORDER HOLD FROM ORDER LIST
        modifyOrdersHoldSuccess(state, action) {
            state.isLoading = false;
            state.orderList = state.orderList.map((_order) => {
                if (_order.id === action.payload.order_ids[0]) {
                    _order.hold = action.payload.is_hold;
                }

                return _order;
            });
        },

        // GET ORDER HOLD FROM ORDER AWAITING LIST
        modifyOrdersAwaitingHoldSuccess(state, action) {
            state.isLoading = false;

            const modifiedOrderAwaitingList = state.orderAwaitingList.map((_order) => {
                if (_order.id === action.payload.order_ids[0]) {
                    _order.hold = action.payload.is_hold;
                }

                return _order;
            });

            state.orderAwaitingList = modifiedOrderAwaitingList;
        },

        // GET ORDER HOLD FROM ORDER SHIPPED LIST
        modifyOrdersShippedHoldSuccess(state, action) {
            state.isLoading = false;

            const modifiedOrderShippedList = state.orderShippedList.map((_order) => {
                if (_order.id === action.payload.order_ids[0]) {
                    _order.hold = action.payload.is_hold;
                }

                return _order;
            });

            state.orderShippedList = modifiedOrderShippedList;
        },

        // GET ORDER HOLD FROM ORDER CANCELED LIST
        modifyOrdersCancelledHoldSuccess(state, action) {
            state.isLoading = false;

            const modifiedOrderCancelledList = state.orderCancelledList.map((_order) => {
                if (_order.id === action.payload.order_ids[0]) {
                    _order.hold = action.payload.is_hold;
                }

                return _order;
            });

            state.orderCancelledList = modifiedOrderCancelledList;
        },

        getAwaitingNumberSuccess(state, action) {
            state.isLoading = false;
            state.awaitingNumber = action.payload;
        },

        actionSuccess(state) {
            state.isLoading = false;
        },

        // RESET ORDER
        resetOrder(state) {
            state.order = null;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {resetOrder, getOrderSuccess, modifyAwaitingOrderSuccess, modifyAllOrderSuccess} = slice.actions;

// ----------------------------------------------------------------------

export function getOrderInfo(id) {
    return async () => {
        try {
            const response = await getOrderAPI(id);

            const {status, data} = response;

            if (status === 200) {
                dispatch(slice.actions.getOrderSuccess(data ?? null));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function addOrder(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const result = await addOrderAPI(detail);

            const {status, data} = result;

            if (status === 200 && data?.result === 'success') {
                dispatch(slice.actions.addOrderSuccess());
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function modifyOrder(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const result = await modifyOrderAPI(detail);

            const {status, data} = result;

            if (status === 200 && data?.result === 'success') {
                dispatch(slice.actions.modifyOrderSuccess());
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function deleteOrder(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const result = await deleteOrderAPI(id);

            const {status, data} = result;

            if (status === 200 && data?.result === 'success') {
                dispatch(slice.actions.deleteOrderSuccess());
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function modifyBatchOrderStatus(page, detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const result = await modifyBatchOrderStatusAPI({
                ...detail,
            });

            const {status, data} = result;

            if (status === 200 && data?.result === 'success') {
                dispatch(slice.actions.actionSuccess());
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function modifyBatchOrderHold(page, detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const result = await modifyBatchOrderHoldAPI({
                ...detail,
            });

            const {status, data} = result;

            if (status === 200 && data?.result === 'success') {
                if (page === 'all') {
                    dispatch(slice.actions.modifyOrdersHoldSuccess(detail));
                } else if (page === 'awaiting') {
                    dispatch(slice.actions.modifyOrdersAwaitingHoldSuccess(detail));
                } else if (page === 'shipped') {
                    dispatch(slice.actions.modifyOrdersShippedHoldSuccess(detail));
                } else if (page === 'canceled') {
                    dispatch(slice.actions.modifyOrdersCancelledHoldSuccess(detail));
                }
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function getAwaitingNumber() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const result = await getOrderAwaitingNumberAAPI();
            // const result = await getOrderAwaitingNumberBAPI();

            const {status, data} = result;

            if (status === 200) {
                dispatch(slice.actions.getAwaitingNumberSuccess(data?.awaitint_number || 0));
                // dispatch(slice.actions.getAwaitingNumberSuccess(data?.awaiting || 0));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getOrderList(filter) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            let tempFilter;

            if (filter.dateSelection) {
                const tempTime = getAppointedDate(filter.dateSelection);

                tempFilter = {...filter, ...tempTime};
            } else {
                tempFilter = {...filter, startTime: '', endTime: ''};
            }

            const response = await getOrderListAPI(tempFilter);

            const {status, data} = response;

            if (status === 200) {
                dispatch(slice.actions.getOrdersSuccess(data?.data || []));
                dispatch(slice.actions.updateOrderListFilterSuccess({
                    total: data?.total || 0,
                    rowsPerPage: data?.per_page || 5,
                }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateOrderListFilter(filter) {
    return () => {
        dispatch(slice.actions.updateOrderListFilterSuccess({
            storeId: filter?.storeId || 'all',
            carrierAccountId: filter?.carrierAccountId || 'all',
            dateSelection: filter?.dateSelection || 'all',
            startTime: filter?.startTime || '',
            endTime: filter?.endTime || '',
            status: filter?.status || 'all',
            keyword: filter?.keyword || '',
            sort: filter?.sort || 'desc',
            page: filter?.page || 0,
            rowsPerPage: filter?.rowsPerPage || 5,
            total: filter?.total || 0,
        }));
    };
}

// ----------------------------------------------------------------------

export function resetFilter() {
    return async () => {
        const defaultFilter = {
            storeId: 'all',
            carrierAccountId: 'all',
            dateSelection: 'all',
            startTime: '',
            endTime: '',
            status: 'all',
            keyword: '',
            sort: 'desc',
            page: 0,
            rowsPerPage: 25,
            total: 0,
        };

        dispatch(slice.actions.updateOrderListFilterSuccess({...defaultFilter}));

        return defaultFilter;
    };
}

// ----------------------------------------------------------------------

export function getOrderAwaitingList(filter) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            let tempFilter;

            if (!filter.startTime && !filter.endTime) {
                const tempTime = getAppointedDate(filter.dateSelection);

                tempFilter = {...filter, ...tempTime};
            } else {
                tempFilter = {...filter};
            }

            const response = await getOrderListAPI(tempFilter);

            const {status, data} = response;

            if (status === 200) {
                dispatch(slice.actions.getOrdersAwaitingSuccess(data?.data || []));
                dispatch(slice.actions.updateOrderAwaitingListFilterSuccess({
                    total: data?.total || 0,
                    rowsPerPage: data?.per_page || 5,
                }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateOrderAwaitingListFilter(filter) {
    return () => {
        dispatch(slice.actions.updateOrderAwaitingListFilterSuccess({
            storeId: filter?.storeId || 'all',
            carrierAccountId: filter?.carrierAccountId || 'all',
            dateSelection: filter?.dateSelection || 'all',
            startTime: filter?.startTime || '',
            endTime: filter?.endTime || '',
            status: 'awaiting',
            keyword: filter?.keyword || '',
            sort: filter?.sort || 'desc',
            page: filter?.page || 0,
            rowsPerPage: filter?.rowsPerPage || 5,
            total: filter?.total || 0,
        }));
    };
}

// ----------------------------------------------------------------------

export function resetAwaitingFilter() {
    return async () => {
        const defaultFilter = {
            storeId: 'all',
            carrierAccountId: 'all',
            dateSelection: 'all',
            startTime: '',
            endTime: '',
            status: 'awaiting',
            keyword: '',
            sort: 'desc',
            page: 0,
            rowsPerPage: 25,
            total: 0,
        };

        dispatch(slice.actions.updateOrderAwaitingListFilterSuccess({...defaultFilter}));

        return defaultFilter;
    };
}

// ----------------------------------------------------------------------

export function getOrderShippedList(filter) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            let tempFilter;

            if (!filter.startTime && !filter.endTime) {
                const tempTime = getAppointedDate(filter.dateSelection);

                tempFilter = {...filter, ...tempTime};
            } else {
                tempFilter = {...filter};
            }

            const response = await getOrderListAPI(tempFilter);

            const {status, data} = response;

            if (status === 200) {
                dispatch(slice.actions.getOrdersShippedSuccess(data?.data || []));
                dispatch(slice.actions.updateOrderShippedListFilterSuccess({
                    total: data?.total || 0,
                    rowsPerPage: data?.per_page || 5,
                }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateOrderShippedListFilter(filter) {
    return () => {
        dispatch(slice.actions.updateOrderShippedListFilterSuccess({
            storeId: filter?.storeId || 'all',
            carrierAccountId: filter?.carrierAccountId || 'all',
            dateSelection: filter?.dateSelection || 'all',
            startTime: filter?.startTime || '',
            endTime: filter?.endTime || '',
            status: 'shipped',
            keyword: filter?.keyword || '',
            sort: filter?.sort || 'desc',
            page: filter?.page || 0,
            rowsPerPage: filter?.rowsPerPage || 5,
            total: filter?.total || 0,
        }));
    };
}

// ----------------------------------------------------------------------

export function resetShippedFilter() {
    return async () => {
        const defaultFilter = {
            storeId: 'all',
            carrierAccountId: 'all',
            dateSelection: 'all',
            startTime: '',
            endTime: '',
            status: 'shipped',
            keyword: '',
            sort: 'desc',
            page: 0,
            rowsPerPage: 25,
            total: 0,
        };

        dispatch(slice.actions.updateOrderShippedListFilterSuccess({...defaultFilter}));

        return defaultFilter;
    };
}

// ----------------------------------------------------------------------

export function getOrderCancelledList(filter) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            let tempFilter;

            if (!filter.startTime && !filter.endTime) {
                const tempTime = getAppointedDate(filter.dateSelection);

                tempFilter = {...filter, ...tempTime};
            } else {
                tempFilter = {...filter};
            }

            const response = await getOrderListAPI(tempFilter);

            const {status, data} = response;

            if (status === 200) {
                dispatch(slice.actions.getOrdersCancelledSuccess(data?.data || []));
                dispatch(slice.actions.updateOrderCancelledListFilterSuccess({
                    total: data?.total || 0,
                    rowsPerPage: data?.per_page || 5,
                }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateOrderCancelledListFilter(filter) {
    return () => {
        dispatch(slice.actions.updateOrderCancelledListFilterSuccess({
            storeId: filter?.storeId || 'all',
            carrierAccountId: filter?.carrierAccountId || 'all',
            dateSelection: filter?.dateSelection || 'all',
            startTime: filter?.startTime || '',
            endTime: filter?.endTime || '',
            status: 'canceled',
            keyword: filter?.keyword || '',
            sort: filter?.sort || 'desc',
            page: filter?.page || 0,
            rowsPerPage: filter?.rowsPerPage || 5,
            total: filter?.total || 0,
        }));
    };
}

// ----------------------------------------------------------------------

export function resetCancelledFilter() {
    return async () => {
        const defaultFilter = {
            storeId: 'all',
            carrierAccountId: 'all',
            dateSelection: 'all',
            startTime: '',
            endTime: '',
            status: 'canceled',
            keyword: '',
            sort: 'desc',
            page: 0,
            rowsPerPage: 25,
            total: 0,
        };

        dispatch(slice.actions.updateOrderCancelledListFilterSuccess({...defaultFilter}));

        return defaultFilter;
    };
}

// ----------------------------------------------------------------------

export function updateOrderSidebarFilter(filter) {
    return () => {
        dispatch(slice.actions.updateOrderSidebarFilterSuccess({
            orderNumber: filter?.orderNumber || '',
            itemName: filter?.itemName || '',
            itemSKU: filter?.itemSKU || '',
            itemQuantity: filter?.itemQuantity || '',
            warehouseID: filter?.warehouseID || 'all',
            shippingArea: filter?.shippingArea || 'all',
            isCombined: filter?.isCombined ? true : null,
            isHold: filter?.isHold ? true : null,
            trackingCode: filter?.trackingCode || '',
            clientName: filter?.clientName || '',
            clientCity: filter?.clientCity || '',
            clientZipcode: filter?.clientZipcode || '',
        }));
    };
}

// ----------------------------------------------------------------------

export function resetSidebarFilter() {
    return async () => {
        const defaultFilter = {
            orderNumber: '',
            itemName: '',
            itemSKU: '',
            itemQuantity: '',
            warehouseID: 'all',
            shippingArea: 'all',
            isCombined: null,
            isHold: null,
            trackingCode: '',
            clientName: '',
            clientCity: '',
            clientZipcode: '',
        };

        dispatch(slice.actions.updateOrderSidebarFilterSuccess({...defaultFilter}));

        return defaultFilter;
    };
}

// ----------------------------------------------------------------------

export function getCombineList() {
    return async () => {
        // dispatch(slice.actions.startLoading());
        try {
            const response = await getOrderCombineListAPI();

            const {status, data} = response;

            if (status === 200) {
                dispatch(slice.actions.getOrdersCombineSuccess(data || []));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function addOrderCombine(list, numOfList) {
    return async () => {
        // dispatch(slice.actions.startLoading());
        try {
            const result = await addOrderCombineAPI(list);

            const {status, data} = result;

            if (status === 200) {
                dispatch(slice.actions.addOrdersCombineSuccess(numOfList));

                return data?.master_order_number || '';
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function getPickingList() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await getPickingListAPI();

            const {status, data} = response;

            if (status === 200) {
                dispatch(slice.actions.getOrdersPickingSuccess(data || []));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function createPicking(list) {
    return async () => {
        try {
            const response = await addPickingListAPI(list);

            const {status, data} = response;

            if (status === 200 && data?.result === 'success') {
                dispatch(getPickingList());

                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function modifyPicking(detail) {
    return async () => {
        try {
            const response = await modifyPickingListOrderAPI(detail);

            const {status, data} = response;

            if (status === 200 && data?.result === 'success') {
                dispatch(getPickingList());
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function deletePicking(id) {
    return async () => {
        try {
            const response = await deletePickingListAPI(id);

            const {status, data} = response;

            if (status === 200 && data?.result === 'success') {
                dispatch(getPickingList());

                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function deletePickingOrder(detail) {
    return async () => {
        try {
            const response = await deletePickingListOrderAPI(detail);

            const {status, data} = response;

            return status === 200 && data?.result === 'success';
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function voidLabel(id) {
    return async () => {
        dispatch(slice.actions.startLoading(true));
        try {
            const response = await voidLabelAPI(id);

            const {status, data} = response;

            if (status === 200 && data?.result === 'success') {
                dispatch(slice.actions.actionSuccess());
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}
