import PropTypes from 'prop-types';
import React from "react";
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// constants
import { PERMISSIONS_ROUTE } from "../constants/permissions";
// routes
import { PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
    children: PropTypes.node,
};

export default function GuestGuard({ children }) {
    const { pathname } = useLocation();
    const { isAuthenticated, checkPermission } = useAuth();

    const permission = PERMISSIONS_ROUTE[pathname];

    if (isAuthenticated && permission && !checkPermission(permission)) {
        // if (checkPermission('usps'))
        if (checkPermission('carrier_referral'))
            return <Navigate to={`${PATH_DASHBOARD.partner.root}`}/>;

        return <Navigate to={`${PATH_DASHBOARD.root}`}/>;
    }

    // if (isAuthenticated) {
    //     return <Navigate to={PATH_DASHBOARD.root}/>;
    // }

    return <>{children}</>;
}
