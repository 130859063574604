import React, { useEffect, useState } from 'react';
// @mui
import { Box, List, Button, Divider, Typography } from '@mui/material';
// redux
import { batch, useDispatch, useSelector } from "../../../redux/store";
import { getNotificationList, getUnreadNotificationNum, markNotificationsAsRead } from "../../../redux/slices/notification";
// hooks
import useLocales from "../../../hooks/useLocales";
// components
import Scrollbar from '../../../components/Scrollbar';
import Image from "../../../components/Image";
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
// sections
import { NotificationDialog, NotificationItem } from "../../../sections/@dashboard/notification";

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
    const { translate } = useLocales();

    const dispatch = useDispatch();

    const { notifications, totalUnRead, notificationFilter } = useSelector(({ notification }) => notification);

    const [openNotification, setOpenNotification] = useState(null);

    const [notificationDialog, setNotificationDialog] = useState(null);

    const handleOpenNotificationPopover = (event) => {
        const filter = {
            status: 'all',
            perPage: 5,
            page: 0,
        }

        dispatch(getNotificationList(filter, false));
        setOpenNotification(event.currentTarget);
    };

    const handleCloseNotificationPopover = () => setOpenNotification(null);

    const handleMarkAllAsRead = () => {
        dispatch(markNotificationsAsRead([]));
    };

    const handleOpenNotificationDialog = () => {
        setOpenNotification(null);

        setNotificationDialog(
            <NotificationDialog
                open={Boolean(true)}
                onClose={() => setNotificationDialog(null)}
            />
        );
    };

    useEffect(() => batch(() => {
        dispatch(getNotificationList());
        dispatch(getUnreadNotificationNum());
    }), [dispatch]);

    return (
        <>
            <IconButtonAnimate color={openNotification ? 'primary' : 'default'} onClick={handleOpenNotificationPopover} sx={{ width: 40, height: 40, position: 'relative' }}>
                <Image src='/assets/icon/navbar/ic_bell.svg' alt='bell' sx={{ width: "24px", height: "24px", '& img': { objectFit: 'contain' } }}/>
                {totalUnRead > 0 &&
                    <Box
                        sx={{
                            position: 'absolute', top: 4, right: 4, bgcolor: '#FF4842', letterSpacing: '-1px',
                            color: '#ffffff', fontSize: '11px', fontWeight: 700, borderRadius: 4, py: 0.25,
                            ...(totalUnRead < 10 ? { pl: 0.8, pr: 0.8 } : { pl: 0.375, pr: 0.5 })
                        }}
                    >
                        {totalUnRead}
                    </Box>
                }
            </IconButtonAnimate>

            <MenuPopover
                open={Boolean(openNotification)}
                anchorEl={openNotification}
                onClose={handleCloseNotificationPopover}
                sx={{ width: 360, height: 'auto', p: 0, mt: 1.5, ml: 0.75, pb: 0.5 }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5, minHeight: '71px' }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle1">{`${translate('notifications_1')}`}</Typography>
                    </Box>

                     {(() => {
                        let count = 0;
                        notifications.forEach((_notification, index) => {
                            if (index < 3 && !_notification.is_readed) {
                                count += 1;
                            }
                        });

                        if (count > 0) {
                            return (
                                <Button
                                    sx={{ color: '#2C6CD2', mr: '-8px' }}
                                    onClick={handleMarkAllAsRead}
                                    disabled={count <= 0}
                                >
                                    {`${translate('notifications_2')}`}
                                </Button>
                            )
                        }

                        return null;
                     })()}
                </Box>

                <Divider sx={{ borderStyle: 'solid', mb: '-1px' }}/>

                {notifications?.length > 0 ?
                    <Scrollbar sx={{ height: { xs: 'auto' } }}>
                        <List disablePadding>
                            {notifications.map((notification, index) => (
                                index < 3 && <NotificationItem key={`popover-${notification.id}`} notification={notification} isDialog={false}/>
                            ))}
                        </List>
                        {notificationFilter?.total > 0 &&
                            <>
                                <Divider sx={{ borderStyle: 'solid' }}/>

                                <Box sx={{ p: 1 }}>
                                    <Button fullWidth disableRipple sx={{ color: '#292929' }} onClick={handleOpenNotificationDialog}>
                                        {`${translate('notifications_3')}`}
                                    </Button>
                                </Box>
                            </>
                        }
                    </Scrollbar>
                    :
                    <Box justifyContent="center" alignItems="center" sx={{ py: 6.25, px: 2, textAlign: 'center' }}>
                        <Image src='/assets/icon/navbar/ic_message.svg'
                               alt='message'
                               sx={{ width: "32px", height: '24px', mx: 'auto', mb: 1.75 }}
                        />
                        <Typography variant="body1" sx={{ color: '#9D9D9D', mb: 0.5 }}>{`${translate('notifications_4')}`}</Typography>
                    </Box>
                }
            </MenuPopover>

            {notificationDialog}
        </>
    );
}
