import { createSlice } from '@reduxjs/toolkit';
// api
import {
    getReportOrders as getReportOrdersAPI,
    getReportShipmentsCost as getReportShipmentsCostAPI,
    getReportShipmentsRefund as getReportShipmentsRefundAPI,
    // getReportShipmentsMonthCost as getReportShipmentsMonthCostAPI,
    getReportShipmentsService as getReportShipmentsServiceAPI
} from "../../api/report";
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    shippedOrderNumber: 0,
    shippedOrderLabelFee: 0,
    canceledOrderNumber: 0,
    holdOrderNumber: 0,
    awaitingOrderNumber: 0,
    shipmentCost: null,
    shipmentRefund: null,
    service: null,
    // cost: null
};

const slice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET REPORT ORDERS
        getReportOrdersSuccess(state, action) {
            state.isLoading = false;

            state.shippedOrderNumber = action.payload?.shipped_order_number || 0;
            state.shippedOrderLabelFee = action.payload?.shipped_order_label_fee || 0;
            state.canceledOrderNumber = action.payload?.canceled_order_number || 0;
            state.holdOrderNumber = action.payload?.hold_order_number || 0;
            state.awaitingOrderNumber = action.payload?.awaiting_order_number || 0;
        },

        getReportShipmentsCostSuccess(state, action) {
            state.isLoading = false;
            state.shipmentCost = action.payload?.shipment_cost;
        },

        getReportShipmentsRefundSuccess(state, action) {
            state.isLoading = false;
            state.shipmentRefund = action.payload?.shipment_refund;
        },

        // getReportShipmentsMonthCostSuccess(state, action) {
        //     state.isLoading = false;
        //     state.cost = action.payload;
        // },

        getReportShipmentsServiceSuccess(state, action) {
            state.isLoading = false;
            state.service = action.payload;
        },


    }
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getReportOrders(date) {
    return async () => {
        try {
            const response = await getReportOrdersAPI(date);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getReportOrdersSuccess(data || null));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

// ----------------------------------------------------------------------

export function getReportShipmentsCost(date) {
    return async () => {
        try {
            const response = await getReportShipmentsCostAPI(date);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getReportShipmentsCostSuccess(data || null));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

// ----------------------------------------------------------------------

export function getReportShipmentsRefund(date) {
    return async () => {
        try {
            const response = await getReportShipmentsRefundAPI(date);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getReportShipmentsRefundSuccess(data || null));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

// ----------------------------------------------------------------------

// export function getReportShipmentsMonthCost(date) {
//     return async () => {
//         try {
//             const response = await getReportShipmentsMonthCostAPI(date);
//
//             const { status, data } = response;
//
//             if (status === 200) {
//                 dispatch(slice.actions.getReportShipmentsMonthCostSuccess(data || null));
//             }
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     }
// }

// ----------------------------------------------------------------------

export function getReportShipmentsService(date) {
    return async () => {
        try {
            const response = await getReportShipmentsServiceAPI(date);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getReportShipmentsServiceSuccess(data || null));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

// ----------------------------------------------------------------------
