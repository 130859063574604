import PropTypes from "prop-types";
import React from "react";
// form
import {useFormContext, Controller} from "react-hook-form";
// @mui
import {Autocomplete, Box, InputAdornment, TextField} from "@mui/material";
// constants
import {COUNTRIES} from "../../../../../constants/country";
// hooks
import useLocales from "../../../../../hooks/useLocales";
// components
import Image from "../../../../../components/Image";

// ----------------------------------------------------------------------

/**
 * @function FormCountry
 * @description if has default, country {String} - e.g "US" or "CA"
 * @param {string} [name=country] 默认值为country
 * @param {string} [label]
 *
 */

FormCountry.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
};

export default function FormCountry({name = 'country', label = 'form_country', ...other}) {
    const {translate} = useLocales();

    const {control} = useFormContext();

    return (
        <Controller
            name={ name }
            control={ control }
            render={ ({field, fieldState: {error}}) => {
                const code = "value" in other ? other.value : field?.value || '';
                const selected = COUNTRIES.find(_item => (_item.label === code || _item.code === code));

                return (
                    <Autocomplete
                        autoHighlight
                        value={ selected?.label ?? code }
                        onChange={ (event, newValue) => field.onChange(newValue) }
                        options={ COUNTRIES.map(_item => _item.label) }
                        renderOption={ (props, option) => {
                            const selectedOption = COUNTRIES.find(_item => (_item.label === option || _item.code === option));

                            return (
                                <Box component="li"  { ...props }>
                                    <Image
                                        src={ `https://flagcdn.com/w20/${ selectedOption.code.toLowerCase() }.png` }
                                        srcSet={ `https://flagcdn.com/w40/${ selectedOption.code.toLowerCase() }.png 2x` }
                                        alt="flag"
                                        sx={ {lineHeight: 'inherit', width: '24px', height: '16px', mr: 2, flexShrink: 0} }
                                        disabledBase
                                    />
                                    { `${ selectedOption.label } (${ selectedOption.code })` }
                                </Box>
                            );
                        } }
                        renderInput={ (params) => {
                            const tempParams = {...params};

                            tempParams.InputProps.startAdornment = selected ? (
                                <InputAdornment position="start" sx={ {ml: 1} }>
                                    <Image
                                        src={ `https://flagcdn.com/w20/${ selected.code.toLowerCase() }.png` }
                                        srcSet={ `https://flagcdn.com/w40/${ selected.code.toLowerCase() }.png 2x` }
                                        alt="flag"
                                        sx={ {lineHeight: 'inherit', width: '24px', height: '16px', flexShrink: 0} }
                                        disabledBase
                                    />
                                </InputAdornment>
                            ) : null;

                            return (
                                <TextField
                                    { ...tempParams }
                                    label={ `${ translate(label) }` }
                                    onChange={ (event) => field.onChange(event.target.value) }
                                    error={ !!error }
                                    helperText={ error?.message }
                                    inputProps={ {
                                        ...tempParams?.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    } }
                                />
                            );
                        } }
                        size="small"
                        { ...other }
                    />
                );
            } }
        />
    );
}
