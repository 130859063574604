import PropTypes from 'prop-types';
import React, { useMemo, useState } from "react";
import * as Yup from 'yup';
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, Typography, Button, ListSubheader, MenuItem } from '@mui/material';
import { LoadingButton } from "@mui/lab";
// api
import { getOrderPreRatesList as getOrderPreRatesListAPI, getOrderList as getOrderListAPI } from "../../../../api/order";
// constants
import { UNIT_OPTIONS } from "../../../../constants/options";
// redux
import { useDispatch, useSelector } from "../../../../redux/store";
import { modifyAwaitingOrderSuccess, modifyAllOrderSuccess } from "../../../../redux/slices/order";
// hooks
import useLocales from "../../../../hooks/useLocales";
// components
import Image from "../../../../components/Image";
import { FormProvider, RHFSelect, RHFTextField } from "../../../../components/hook-form";
import DefaultLabel from "../../../../components/customized/DefaultLabel";
// utils
import { getWeightByUnit, getParcel } from "../../../../utils/getRequestData";


// ----------------------------------------------------------------------

OrderSelectPackage.propTypes = {
    orderId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    currentDetail: PropTypes.object,
    onClose: PropTypes.func,
    onRefresh: PropTypes.func,
    page: PropTypes.string,
    isInternational: PropTypes.bool,
};

export default function OrderSelectPackage({ orderId, currentDetail, onClose, onRefresh, page, isInternational }) {
    const { translate } = useLocales();

    const dispatch = useDispatch();

    const { allPackageList } = useSelector((state) => state.package);
    const { awaitingFilter, filter } = useSelector(({ order }) => order);

    const [isLoading, setIsLoading] = useState(false);

    const PackageSchema = Yup.object().shape({
        length: Yup.number().transform(value => value || 0).when('packageType', {
            is: 1,
            then: Yup.number().transform(value => value || 0).min(6, `${translate('input_schema_calculator_domestic_form_4_1')}`).required(`${translate('input_schema_calculator_domestic_form_4_2')}`),
        }).test("check perimeter", (value, item) => {
            const perimeter = (value + item.parent.width + item.parent.height) * 2 - Math.max(value, item.parent.width, item.parent.height);
            return (item.parent.packageType !== 1 || (item.parent.packageType === 1 && perimeter <= 165)) ? true :
                item.createError({
                    message: `${translate('input_schema_calculator_domestic_form_4_3')}${perimeter}"`,
                });
        }),
        width: Yup.number().transform(value => value || 0).when('packageType', {
            is: 1,
            then: Yup.number().transform(value => value || 0).min(3, `${translate('input_schema_calculator_domestic_form_5_1')}`).required(`${translate('input_schema_calculator_domestic_form_5_2')}`),
        }).test("check perimeter", (value, item) => {
            const perimeter = (item.parent.length + value + item.parent.height) * 2 - Math.max(item.parent.length, value, item.parent.height);
            return (item.parent.packageType !== 1 || (item.parent.packageType === 1 && perimeter <= 165)) ? true :
                item.createError({
                    message: `${translate('input_schema_calculator_domestic_form_5_3')}${perimeter}"`,
                });
        }),
        height: Yup.number().transform(value => value || 0).when('packageType', {
            is: 1,
            then: Yup.number().transform(value => value || 0).min(0.25, `${translate('input_schema_calculator_domestic_form_6_1')}`).required(`${translate('input_schema_calculator_domestic_form_6_2')}`),
        }).test("check perimeter", (value, item) => {
            const perimeter = (item.parent.length + item.parent.width + value) * 2 - Math.max(item.parent.length, item.parent.width, value);
            return (item.parent.packageType !== 1 || (item.parent.packageType === 1 && perimeter <= 165)) ? true :
                item.createError({
                    message: `${translate('input_schema_calculator_domestic_form_6_3')}${perimeter}"`,
                });
        }),
        weight: Yup.number().transform(value => value || 0)
            .when('weightUnit', {
                is: 'lb',
                then: Yup.number().when('packageType', (type) => {
                    const _code = allPackageList.find(p => p.pid === type)?.code;

                    if (isInternational) {
                        // USPS
                        if (_code === 'LETTER')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(0.21, `${translate('input_schema_ship_form_8_international_LETTER')}`);
                        if (_code === 'LARGE_ENVELOPE_OR_FLAT')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(66, `${translate('input_schema_ship_form_8_international_LARGE_ENVELOPE_OR_FLAT')}`);
                        if (_code === 'THICK_ENVELOPE')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(4, `${translate('input_schema_ship_form_8_international_THICK_ENVELOPE')}`);
                        if (_code === 'FLAT_RATE_PADDED_ENVELOPE')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(4, `${translate('input_schema_ship_form_8_international_FLAT_RATE_PADDED_ENVELOPE')}`);
                        if (_code === 'FLAT_RATE_ENVELOPE')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(4, `${translate('input_schema_ship_form_8_international_FLAT_RATE_ENVELOPE')}`);
                        if (_code === 'LEGAL_FLAT_RATE_ENVELOPE')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(70, `${translate('input_schema_ship_form_8_international_LEGAL_FLAT_RATE_ENVELOPE')}`);
                        if (_code === 'SMALL_FLAT_RATE_BOX')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(20, `${translate('input_schema_ship_form_8_international_SMALL_FLAT_RATE_BOX')}`);
                        if (_code === 'MEDIUM_FLAT_RATE_BOX')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(20, `${translate('input_schema_ship_form_8_international_MEDIUM_FLAT_RATE_BOX')}`);
                        if (_code === 'LARGE_FLAT_RATE_BOX')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(20, `${translate('input_schema_ship_form_8_international_LARGE_FLAT_RATE_BOX')}`);
                        // UPS
                        if (_code === 'LARGE_EXPRESS_BOX')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(150, `${translate('input_schema_ship_form_8_international_LARGE_EXPRESS_BOX')}`);
                        if (_code === 'MEDIUM_EXPRESS_BOX')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(150, `${translate('input_schema_ship_form_8_international_MEDIUM_EXPRESS_BOX')}`);
                        if (_code === 'SMALL_EXPRESS_BOX')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(150, `${translate('input_schema_ship_form_8_international_SMALL_EXPRESS_BOX')}`);
                        if (_code === 'UPS_PAK')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(150, `${translate('input_schema_ship_form_8_international_UPS_PAK')}`);
                        if (_code === 'UPS_TUBE')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(150, `${translate('input_schema_ship_form_8_international_UPS_TUBE')}`);
                        if (_code === 'UPS_10KG_BOX')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(22, `${translate('input_schema_ship_form_8_international_UPS_10KG_BOX')}`);
                        if (_code === 'UPS_25KG_BOX')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(55, `${translate('input_schema_ship_form_8_international_UPS_25KG_BOX')}`);
                        if (_code === 'UPS_LETTER')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(150, `${translate('input_schema_ship_form_8_international_UPS_LETTER')}`);
                    } else {
                        // USPS
                        if (_code === 'LETTER')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(0.21, `${translate('input_schema_ship_form_8_domestic_LETTER')}`);
                        if (_code === 'LARGE_ENVELOPE_OR_FLAT')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(70, `${translate('input_schema_ship_form_8_domestic_LARGE_ENVELOPE_OR_FLAT')}`);
                        if (_code === 'THICK_ENVELOPE')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(70, `${translate('input_schema_ship_form_8_domestic_THICK_ENVELOPE')}`);
                        if (_code === 'FLAT_RATE_PADDED_ENVELOPE')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(70, `${translate('input_schema_ship_form_8_domestic_FLAT_RATE_PADDED_ENVELOPE')}`);
                        if (_code === 'FLAT_RATE_ENVELOPE')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(70, `${translate('input_schema_ship_form_8_domestic_FLAT_RATE_ENVELOPE')}`);
                        if (_code === 'LEGAL_FLAT_RATE_ENVELOPE')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(70, `${translate('input_schema_ship_form_8_domestic_LEGAL_FLAT_RATE_ENVELOPE')}`);
                        if (_code === 'SMALL_FLAT_RATE_BOX')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(70, `${translate('input_schema_ship_form_8_domestic_SMALL_FLAT_RATE_BOX')}`);
                        if (_code === 'MEDIUM_FLAT_RATE_BOX')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(70, `${translate('input_schema_ship_form_8_domestic_MEDIUM_FLAT_RATE_BOX')}`);
                        if (_code === 'LARGE_FLAT_RATE_BOX')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(70, `${translate('input_schema_ship_form_8_domestic_LARGE_FLAT_RATE_BOX')}`);
                        // UPS
                        if (_code === 'LARGE_EXPRESS_BOX')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(150, `${translate('input_schema_ship_form_8_domestic_LARGE_EXPRESS_BOX')}`);
                        if (_code === 'MEDIUM_EXPRESS_BOX')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(150, `${translate('input_schema_ship_form_8_domestic_MEDIUM_EXPRESS_BOX')}`);
                        if (_code === 'SMALL_EXPRESS_BOX')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(150, `${translate('input_schema_ship_form_8_domestic_SMALL_EXPRESS_BOX')}`);
                        if (_code === 'UPS_PAK')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(150, `${translate('input_schema_ship_form_8_domestic_UPS_PAK')}`);
                        if (_code === 'UPS_TUBE')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(150, `${translate('input_schema_ship_form_8_domestic_UPS_TUBE')}`);
                        if (_code === 'UPS_LETTER')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).max(150, `${translate('input_schema_ship_form_8_domestic_UPS_LETTER')}`);
                    }

                    return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_1_1')}`).required(`${translate('input_schema_ship_form_8_1_3')}`);
                }),
            })
            .when('weightUnit', {
                is: 'oz',
                then: Yup.number().when('packageType', (type) => {
                    const _code = allPackageList.find(p => p.pid === type)?.code;

                    if (isInternational) {
                        // USPS
                        if (_code === 'LETTER')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(3.43, `${translate('input_schema_ship_form_8_international_LETTER')}`);
                        if (_code === 'LARGE_ENVELOPE_OR_FLAT')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(1056, `${translate('input_schema_ship_form_8_international_LARGE_ENVELOPE_OR_FLAT')}`);
                        if (_code === 'THICK_ENVELOPE')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(64, `${translate('input_schema_ship_form_8_international_THICK_ENVELOPE')}`);
                        if (_code === 'FLAT_RATE_PADDED_ENVELOPE')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(64, `${translate('input_schema_ship_form_8_international_FLAT_RATE_PADDED_ENVELOPE')}`);
                        if (_code === 'FLAT_RATE_ENVELOPE')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(64, `${translate('input_schema_ship_form_8_international_FLAT_RATE_ENVELOPE')}`);
                        if (_code === 'LEGAL_FLAT_RATE_ENVELOPE')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(1120, `${translate('input_schema_ship_form_8_international_LEGAL_FLAT_RATE_ENVELOPE')}`);
                        if (_code === 'SMALL_FLAT_RATE_BOX')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(352, `${translate('input_schema_ship_form_8_international_SMALL_FLAT_RATE_BOX')}`);
                        if (_code === 'MEDIUM_FLAT_RATE_BOX')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(352, `${translate('input_schema_ship_form_8_international_MEDIUM_FLAT_RATE_BOX')}`);
                        if (_code === 'LARGE_FLAT_RATE_BOX')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(352, `${translate('input_schema_ship_form_8_international_LARGE_FLAT_RATE_BOX')}`);
                        // UPS
                        if (_code === 'LARGE_EXPRESS_BOX')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(2400, `${translate('input_schema_ship_form_8_international_LARGE_EXPRESS_BOX')}`);
                        if (_code === 'MEDIUM_EXPRESS_BOX')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(2400, `${translate('input_schema_ship_form_8_international_MEDIUM_EXPRESS_BOX')}`);
                        if (_code === 'SMALL_EXPRESS_BOX')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(2400, `${translate('input_schema_ship_form_8_international_SMALL_EXPRESS_BOX')}`);
                        if (_code === 'UPS_PAK')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(2400, `${translate('input_schema_ship_form_8_international_UPS_PAK')}`);
                        if (_code === 'UPS_TUBE')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(2400, `${translate('input_schema_ship_form_8_international_UPS_TUBE')}`);
                        if (_code === 'UPS_10KG_BOX')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(352, `${translate('input_schema_ship_form_8_international_UPS_10KG_BOX')}`);
                        if (_code === 'UPS_25KG_BOX')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(880, `${translate('input_schema_ship_form_8_international_UPS_25KG_BOX')}`);
                        if (_code === 'UPS_LETTER')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(2400, `${translate('input_schema_ship_form_8_international_UPS_LETTER')}`);
                    } else {
                        // USPS
                        if (_code === 'LETTER')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(3.43, `${translate('input_schema_ship_form_8_domestic_LETTER')}`);
                        if (_code === 'LARGE_ENVELOPE_OR_FLAT')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(1120, `${translate('input_schema_ship_form_8_domestic_LARGE_ENVELOPE_OR_FLAT')}`);
                        if (_code === 'THICK_ENVELOPE')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(1120, `${translate('input_schema_ship_form_8_domestic_THICK_ENVELOPE')}`);
                        if (_code === 'FLAT_RATE_PADDED_ENVELOPE')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(1120, `${translate('input_schema_ship_form_8_domestic_FLAT_RATE_PADDED_ENVELOPE')}`);
                        if (_code === 'FLAT_RATE_ENVELOPE')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(1120, `${translate('input_schema_ship_form_8_domestic_FLAT_RATE_ENVELOPE')}`);
                        if (_code === 'LEGAL_FLAT_RATE_ENVELOPE')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(1120, `${translate('input_schema_ship_form_8_domestic_LEGAL_FLAT_RATE_ENVELOPE')}`);
                        if (_code === 'SMALL_FLAT_RATE_BOX')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(1120, `${translate('input_schema_ship_form_8_domestic_SMALL_FLAT_RATE_BOX')}`);
                        if (_code === 'MEDIUM_FLAT_RATE_BOX')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(1120, `${translate('input_schema_ship_form_8_domestic_MEDIUM_FLAT_RATE_BOX')}`);
                        if (_code === 'LARGE_FLAT_RATE_BOX')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(1120, `${translate('input_schema_ship_form_8_domestic_LARGE_FLAT_RATE_BOX')}`);
                        // UPS
                        if (_code === 'LARGE_EXPRESS_BOX')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(2400, `${translate('input_schema_ship_form_8_domestic_LARGE_EXPRESS_BOX')}`);
                        if (_code === 'MEDIUM_EXPRESS_BOX')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(2400, `${translate('input_schema_ship_form_8_domestic_MEDIUM_EXPRESS_BOX')}`);
                        if (_code === 'SMALL_EXPRESS_BOX')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(2400, `${translate('input_schema_ship_form_8_domestic_SMALL_EXPRESS_BOX')}`);
                        if (_code === 'UPS_PAK')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(2400, `${translate('input_schema_ship_form_8_domestic_UPS_PAK')}`);
                        if (_code === 'UPS_TUBE')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(2400, `${translate('input_schema_ship_form_8_domestic_UPS_TUBE')}`);
                        if (_code === 'UPS_LETTER')
                            return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).max(2400, `${translate('input_schema_ship_form_8_domestic_UPS_LETTER')}`);
                    }

                    return Yup.number().min(0.16, `${translate('input_schema_ship_form_8_2_1')}`).required(`${translate('input_schema_ship_form_8_2_3')}`);
                }),
            })
            .when('weightUnit', {
                is: 'kg',
                then: Yup.number().when('packageType', (type) => {
                    const _code = allPackageList.find(p => p.pid === type)?.code;

                    if (isInternational) {
                        // USPS
                        if (_code === 'LETTER')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(0.09, `${translate('input_schema_ship_form_8_international_LETTER')}`);
                        if (_code === 'LARGE_ENVELOPE_OR_FLAT')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(29.94, `${translate('input_schema_ship_form_8_international_LARGE_ENVELOPE_OR_FLAT')}`);
                        if (_code === 'THICK_ENVELOPE')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(1.81, `${translate('input_schema_ship_form_8_international_THICK_ENVELOPE')}`);
                        if (_code === 'FLAT_RATE_PADDED_ENVELOPE')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(1.81, `${translate('input_schema_ship_form_8_international_FLAT_RATE_PADDED_ENVELOPE')}`);
                        if (_code === 'FLAT_RATE_ENVELOPE')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(1.81, `${translate('input_schema_ship_form_8_international_FLAT_RATE_ENVELOPE')}`);
                        if (_code === 'LEGAL_FLAT_RATE_ENVELOPE')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(31.75, `${translate('input_schema_ship_form_8_international_LEGAL_FLAT_RATE_ENVELOPE')}`);
                        if (_code === 'SMALL_FLAT_RATE_BOX')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(9.07, `${translate('input_schema_ship_form_8_international_SMALL_FLAT_RATE_BOX')}`);
                        if (_code === 'MEDIUM_FLAT_RATE_BOX')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(9.07, `${translate('input_schema_ship_form_8_international_MEDIUM_FLAT_RATE_BOX')}`);
                        if (_code === 'LARGE_FLAT_RATE_BOX')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(9.07, `${translate('input_schema_ship_form_8_international_LARGE_FLAT_RATE_BOX')}`);
                        // UPS
                        if (_code === 'LARGE_EXPRESS_BOX')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(68.04, `${translate('input_schema_ship_form_8_international_LARGE_EXPRESS_BOX')}`);
                        if (_code === 'MEDIUM_EXPRESS_BOX')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(68.04, `${translate('input_schema_ship_form_8_international_MEDIUM_EXPRESS_BOX')}`);
                        if (_code === 'SMALL_EXPRESS_BOX')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(68.04, `${translate('input_schema_ship_form_8_international_SMALL_EXPRESS_BOX')}`);
                        if (_code === 'UPS_PAK')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(68.04, `${translate('input_schema_ship_form_8_international_UPS_PAK')}`);
                        if (_code === 'UPS_TUBE')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(68.04, `${translate('input_schema_ship_form_8_international_UPS_TUBE')}`);
                        if (_code === 'UPS_10KG_BOX')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(9.98, `${translate('input_schema_ship_form_8_international_UPS_10KG_BOX')}`);
                        if (_code === 'UPS_25KG_BOX')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(24.95, `${translate('input_schema_ship_form_8_international_UPS_25KG_BOX')}`);
                        if (_code === 'UPS_LETTER')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(68.04, `${translate('input_schema_ship_form_8_international_UPS_LETTER')}`);
                    } else {
                        // USPS
                        if (_code === 'LETTER')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(0.09, `${translate('input_schema_ship_form_8_domestic_LETTER')}`);
                        if (_code === 'LARGE_ENVELOPE_OR_FLAT')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(31.75, `${translate('input_schema_ship_form_8_domestic_LARGE_ENVELOPE_OR_FLAT')}`);
                        if (_code === 'THICK_ENVELOPE')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(31.75, `${translate('input_schema_ship_form_8_domestic_THICK_ENVELOPE')}`);
                        if (_code === 'FLAT_RATE_PADDED_ENVELOPE')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(31.75, `${translate('input_schema_ship_form_8_domestic_FLAT_RATE_PADDED_ENVELOPE')}`);
                        if (_code === 'FLAT_RATE_ENVELOPE')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(31.75, `${translate('input_schema_ship_form_8_domestic_FLAT_RATE_ENVELOPE')}`);
                        if (_code === 'LEGAL_FLAT_RATE_ENVELOPE')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(31.75, `${translate('input_schema_ship_form_8_domestic_LEGAL_FLAT_RATE_ENVELOPE')}`);
                        if (_code === 'SMALL_FLAT_RATE_BOX')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(31.75, `${translate('input_schema_ship_form_8_domestic_SMALL_FLAT_RATE_BOX')}`);
                        if (_code === 'MEDIUM_FLAT_RATE_BOX')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(31.75, `${translate('input_schema_ship_form_8_domestic_MEDIUM_FLAT_RATE_BOX')}`);
                        if (_code === 'LARGE_FLAT_RATE_BOX')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(31.75, `${translate('input_schema_ship_form_8_domestic_LARGE_FLAT_RATE_BOX')}`);
                        // UPS
                        if (_code === 'LARGE_EXPRESS_BOX')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(68.04, `${translate('input_schema_ship_form_8_domestic_LARGE_EXPRESS_BOX')}`);
                        if (_code === 'MEDIUM_EXPRESS_BOX')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(68.04, `${translate('input_schema_ship_form_8_domestic_MEDIUM_EXPRESS_BOX')}`);
                        if (_code === 'SMALL_EXPRESS_BOX')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(68.04, `${translate('input_schema_ship_form_8_domestic_SMALL_EXPRESS_BOX')}`);
                        if (_code === 'UPS_PAK')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(68.04, `${translate('input_schema_ship_form_8_domestic_UPS_PAK')}`);
                        if (_code === 'UPS_TUBE')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(68.04, `${translate('input_schema_ship_form_8_domestic_UPS_TUBE')}`);
                        if (_code === 'UPS_LETTER')
                            return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).max(68.04, `${translate('input_schema_ship_form_8_domestic_UPS_LETTER')}`);
                    }

                    return Yup.number().min(0.01, `${translate('input_schema_ship_form_8_3_1')}`).required(`${translate('input_schema_ship_form_8_3_3')}`);
                }),
            })
            .when('weightUnit', {
                is: 'g',
                then: Yup.number().when('packageType', (type) => {
                    const _code = allPackageList.find(p => p.pid === type)?.code;

                    if (isInternational) {
                        // USPS
                        if (_code === 'LETTER')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(97.5, `${translate('input_schema_ship_form_8_international_LETTER')}`);
                        if (_code === 'LARGE_ENVELOPE_OR_FLAT')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(29937.1, `${translate('input_schema_ship_form_8_international_LARGE_ENVELOPE_OR_FLAT')}`);
                        if (_code === 'THICK_ENVELOPE')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(1814.37, `${translate('input_schema_ship_form_8_international_THICK_ENVELOPE')}`);
                        if (_code === 'FLAT_RATE_PADDED_ENVELOPE')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(1814.37, `${translate('input_schema_ship_form_8_international_FLAT_RATE_PADDED_ENVELOPE')}`);
                        if (_code === 'FLAT_RATE_ENVELOPE')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(1814.37, `${translate('input_schema_ship_form_8_international_FLAT_RATE_ENVELOPE')}`);
                        if (_code === 'LEGAL_FLAT_RATE_ENVELOPE')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(31751.5, `${translate('input_schema_ship_form_8_international_LEGAL_FLAT_RATE_ENVELOPE')}`);
                        if (_code === 'SMALL_FLAT_RATE_BOX')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(9071.85, `${translate('input_schema_ship_form_8_international_SMALL_FLAT_RATE_BOX')}`);
                        if (_code === 'MEDIUM_FLAT_RATE_BOX')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(9071.85, `${translate('input_schema_ship_form_8_international_MEDIUM_FLAT_RATE_BOX')}`);
                        if (_code === 'LARGE_FLAT_RATE_BOX')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(9071.85, `${translate('input_schema_ship_form_8_international_LARGE_FLAT_RATE_BOX')}`);
                        // UPS
                        if (_code === 'LARGE_EXPRESS_BOX')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(68038.9, `${translate('input_schema_ship_form_8_international_LARGE_EXPRESS_BOX')}`);
                        if (_code === 'MEDIUM_EXPRESS_BOX')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(68038.9, `${translate('input_schema_ship_form_8_international_MEDIUM_EXPRESS_BOX')}`);
                        if (_code === 'SMALL_EXPRESS_BOX')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(68038.9, `${translate('input_schema_ship_form_8_international_SMALL_EXPRESS_BOX')}`);
                        if (_code === 'UPS_PAK')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(68038.9, `${translate('input_schema_ship_form_8_international_UPS_PAK')}`);
                        if (_code === 'UPS_TUBE')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(68038.9, `${translate('input_schema_ship_form_8_international_UPS_TUBE')}`);
                        if (_code === 'UPS_10KG_BOX')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(9979.03, `${translate('input_schema_ship_form_8_international_UPS_10KG_BOX')}`);
                        if (_code === 'UPS_25KG_BOX')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(24947.6, `${translate('input_schema_ship_form_8_international_UPS_25KG_BOX')}`);
                        if (_code === 'UPS_LETTER')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(68038.9, `${translate('input_schema_ship_form_8_international_UPS_LETTER')}`);
                    } else {
                        // USPS
                        if (_code === 'LETTER')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(97.5, `${translate('input_schema_ship_form_8_domestic_LETTER')}`);
                        if (_code === 'LARGE_ENVELOPE_OR_FLAT')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(31751.5, `${translate('input_schema_ship_form_8_domestic_LARGE_ENVELOPE_OR_FLAT')}`);
                        if (_code === 'THICK_ENVELOPE')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(31751.5, `${translate('input_schema_ship_form_8_domestic_THICK_ENVELOPE')}`);
                        if (_code === 'FLAT_RATE_PADDED_ENVELOPE')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(31751.5, `${translate('input_schema_ship_form_8_domestic_FLAT_RATE_PADDED_ENVELOPE')}`);
                        if (_code === 'FLAT_RATE_ENVELOPE')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(31751.5, `${translate('input_schema_ship_form_8_domestic_FLAT_RATE_ENVELOPE')}`);
                        if (_code === 'LEGAL_FLAT_RATE_ENVELOPE')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(31751.5, `${translate('input_schema_ship_form_8_domestic_LEGAL_FLAT_RATE_ENVELOPE')}`);
                        if (_code === 'SMALL_FLAT_RATE_BOX')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(31751.5, `${translate('input_schema_ship_form_8_domestic_SMALL_FLAT_RATE_BOX')}`);
                        if (_code === 'MEDIUM_FLAT_RATE_BOX')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(31751.5, `${translate('input_schema_ship_form_8_domestic_MEDIUM_FLAT_RATE_BOX')}`);
                        if (_code === 'LARGE_FLAT_RATE_BOX')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(31751.5, `${translate('input_schema_ship_form_8_domestic_LARGE_FLAT_RATE_BOX')}`);
                        // UPS
                        if (_code === 'LARGE_EXPRESS_BOX')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(68038.9, `${translate('input_schema_ship_form_8_domestic_LARGE_EXPRESS_BOX')}`);
                        if (_code === 'MEDIUM_EXPRESS_BOX')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(68038.9, `${translate('input_schema_ship_form_8_domestic_MEDIUM_EXPRESS_BOX')}`);
                        if (_code === 'SMALL_EXPRESS_BOX')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(68038.9, `${translate('input_schema_ship_form_8_domestic_SMALL_EXPRESS_BOX')}`);
                        if (_code === 'UPS_PAK')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(68038.9, `${translate('input_schema_ship_form_8_domestic_UPS_PAK')}`);
                        if (_code === 'UPS_TUBE')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(68038.9, `${translate('input_schema_ship_form_8_domestic_UPS_TUBE')}`);
                        if (_code === 'UPS_LETTER')
                            return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).max(68038.9, `${translate('input_schema_ship_form_8_domestic_UPS_LETTER')}`);
                    }

                    return Yup.number().min(4.54, `${translate('input_schema_ship_form_8_4_1')}`).required(`${translate('input_schema_ship_form_8_4_3')}`);
                }),
            }),
    });

    const defaultValues = useMemo(() => {
        const data = {};

        data.packageType = 1;

        if (!currentDetail?.pid) {
            const predefinedPackage = allPackageList.find((_p) => _p.type === "my_package" && _p.is_default);

            if (!currentDetail && predefinedPackage) {
                data.packageType = predefinedPackage.pid;
            }
        } else {
            data.packageType = currentDetail.pid;
        }

        data.length = currentDetail?.length || '';
        data.width = currentDetail?.width || '';
        data.height = currentDetail?.height || '';
        data.weight = currentDetail?.weight || '';
        data.weightUnit = 'lb';

        return data;
    }, [currentDetail, allPackageList]);

    const methods = useForm({
        resolver: yupResolver(PackageSchema),
        defaultValues,
        mode: "onChange",
    });

    const {
        reset,
        watch,
        setValue,
        handleSubmit,
    } = methods;

    const values = watch();

    const handleInputChange = (type, value) => {
        if (value !== '') {
            value = Math.round(value * 100) / 100;
            setValue(type, Math.round(value * 100) / 100);
        }
    };

    const handlePackageRequest = () => {
        let selectedPackage = null;

        if (values.packageType !== 1) {
            selectedPackage = allPackageList.find((p) => p.pid === values.packageType);
        }

        const weightValue = getWeightByUnit(values.weight, values.weightUnit);
        const parcel = getParcel(values, selectedPackage);

        return {
            order_id: orderId,
            packages_info: [
                {
                    height: parcel?.height || 0,
                    length: parcel?.length || 0,
                    predefined_package_id: parcel?.pid ?? '',
                    predefined_package: parcel?.code || 'PACKAGE',
                    predefined_package_carrier: parcel?.carrier || 'USER',
                    weight: weightValue || 0,
                    width: parcel?.width || 0,
                },
            ],
        };
    };

    const checkOrderInfo = async () => {
        try {
            const result = page === "awaiting" ? await getOrderListAPI(awaitingFilter) : await getOrderListAPI(filter);
            const { status, data } = result;

            if (status === 200) {
                const dataArray = data.data;
                const orderInfo = dataArray.filter((dataItem) => dataItem.id === orderId);
                dispatch(page === "awaiting" ? modifyAwaitingOrderSuccess(orderInfo?.length ? orderInfo[0] : null) : modifyAllOrderSuccess(orderInfo?.length ? orderInfo[0] : null));
            }

        } catch (error) {
            console.error(error);

            setIsLoading(false);
        }
    };

    const handleCheckRates = async () => {
        try {
            setIsLoading(true);

            const result = await getOrderPreRatesListAPI(handlePackageRequest());

            const { status } = result;

            if (status === 200) {
                onClose();
                // onRefresh();
                checkOrderInfo();
            }

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    const onSubmit = async () => {
        try {
            await new Promise((resolve) => setTimeout(resolve, 500));
            reset();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2.5}>
                <Stack spacing={2}>
                    <Typography variant="h3">{`${translate('orders_6_4_4')}`}</Typography>

                    <RHFSelect
                        name="packageType"
                        inputProps={{ sx: { py: 1.25, pl: 1 } }}
                        SelectProps={{
                            native: false,
                            MenuProps: {
                                sx: {
                                    width: '100%',
                                    maxWidth: '360px',
                                    maxHeight: '320px',
                                    px: '16px',
                                },
                            },
                        }}
                        onChange={(event) => setValue("packageType", event.target.value)}
                    >
                        {allPackageList && allPackageList.map((_item, index) => {
                                if (_item.isSubheader) {
                                    return (
                                        <ListSubheader key={`subheader-${index}`}>{`${translate(`option_subheader_${_item?.name.replaceAll(' ', '_')}`)}`}</ListSubheader>
                                    );
                                }

                                return (
                                    <MenuItem key={`item-${index}`} value={_item.pid}>
                                        <Stack direction="row" alignItems="center" spacing={2} width={1}>
                                            <Image
                                                src={`/assets/image/packages/${_item.code.toLowerCase() === 'package' ? 'custom_package.svg' : `${_item.code.toLowerCase()}.png`}`} alt=""
                                                sx={{ flexShrink: 0, width: '80px', height: '80px', '& img': { objectFit: 'contain' } }}
                                                onError={(event) => {
                                                    event.target.src = '/assets/image/packages/custom_package.svg';
                                                }}
                                                visibleByDefault disabledEffect
                                            />

                                            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} width={1}>
                                                <Stack spacing={0.5} overflow="hidden">
                                                    <Typography variant="body1" overflow="hidden" sx={{ lineHeight: '1rem', whiteSpace: 'normal', textOverflow: 'ellipsis' }}>
                                                        {_item?.type === 'custom_package' ? `${translate(`option_${_item.type}_name`)}` : _item?.nick_name ?? _item?.name}
                                                    </Typography>
                                                    <Typography variant="body1" color="text.secondary" overflow="hidden" sx={{ lineHeight: '1rem', whiteSpace: 'normal', textOverflow: 'ellipsis' }}>
                                                        {_item?.type === 'custom_package' ? `${translate(`option_${_item.type}_desc`)}` : _item?.description}
                                                    </Typography>
                                                </Stack>

                                                {_item.is_default && (
                                                    <DefaultLabel sx={{ width: 'fit-content', ':first-of-type': { marginRight: 2 } }}/>
                                                )}
                                            </Stack>
                                        </Stack>
                                    </MenuItem>
                                );
                            },
                        )}
                    </RHFSelect>

                    {values.packageType === 1 &&
                        <>
                            <Typography variant="body1">{`${translate('orders_6_4_5')}`}</Typography>

                            <Stack width={1} direction="row" spacing={2}>
                                <RHFTextField
                                    name="length"
                                    label={`${translate('quickrate_5_1_1')}`}
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    onBlur={(e) => handleInputChange('length', e.target.value)}
                                    size="small"
                                />
                                <RHFTextField
                                    name="width"
                                    label={`${translate('quickrate_5_1_2')}`}
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    onBlur={(e) => handleInputChange('width', e.target.value)}
                                    size="small"
                                />
                                <RHFTextField
                                    name="height"
                                    label={`${translate('quickrate_5_1_3')}`}
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    onBlur={(e) => handleInputChange('height', e.target.value)}
                                    size="small"
                                />
                            </Stack>
                        </>
                    }

                    <Typography variant="body1">{`${translate('orders_6_4_6')}`}</Typography>

                    <Stack flexDirection="row">
                        <RHFTextField
                            name="weight"
                            type="number"
                            label={`${translate('quickrate_5_2')}`}
                            onWheel={(e) => e.target.blur()}
                            onBlur={(e) => handleInputChange('weight', e.target.value)}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                    borderRight: 'none',
                                },
                            }}
                            size="small"
                        />
                        <RHFSelect
                            name="weightUnit"
                            SelectProps={{ native: false }}
                            sx={{
                                width: 80,
                                '& .MuiOutlinedInput-root': {
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                },
                            }}
                            size="small"
                        >
                            {UNIT_OPTIONS.map((option) => (
                                <MenuItem
                                    key={option.key}
                                    value={option.key}
                                    sx={{
                                        mx: 1,
                                        my: 0.5,
                                        borderRadius: 0.75,
                                        typography: 'body2',
                                    }}
                                >
                                    {option.name}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                    </Stack>
                </Stack>

                <Stack direction="row" justifyContent="flex-end" spacing={3.75}>
                    <Button variant="text" sx={{ width: 'fit-content', height: 'fit-content', p: 0, color: 'rgba(41, 41, 41, 0.4)', '&:hover': { bgcolor: 'transparent' } }} onClick={onClose} disableRipple>
                        {`${translate('orders_6_4_7')}`}
                    </Button>

                    <LoadingButton
                        variant="text" sx={{ width: 'fit-content', height: 'fit-content', p: 0, color: '#394559', '&:hover': { bgcolor: 'transparent' } }}
                        loading={isLoading}
                        onClick={handleSubmit(handleCheckRates)}
                        disableRipple
                    >
                        {currentDetail ? `${translate('orders_6_4_9')}` : `${translate('orders_6_4_8')}`}
                    </LoadingButton>
                </Stack>
            </Stack>
        </FormProvider>
    );
}
