import PropTypes from 'prop-types';
import React from "react";
// @mui
import { Box, IconButton } from '@mui/material';
//
import MenuPopover from '../MenuPopover';
import Iconify from "../Iconify";

// ----------------------------------------------------------------------

MoreActions.propTypes = {
    actions: PropTypes.node,
    open: PropTypes.object,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    display: PropTypes.node,
    sxButton: PropTypes.object,
    sxMenuPopover: PropTypes.object,
    propsIconButton: PropTypes.object,
    disabled: PropTypes.bool,
};

export default function MoreActions( {actions, open, onClose, onOpen, display, disabled, sxButton, sxMenuPopover, propsIconButton, marginThreshold, ...props} ) {
    return (
        <>
            <Box>
                {display ? (
                    <Box sx={{cursor: 'pointer', color: '#2C6CD2', ...sxButton}} onClick={disabled ? null : onOpen}>
                        {display}
                    </Box>
                ) : (
                    <IconButton onClick={onOpen} disabled={disabled} {...propsIconButton}>
                        <Iconify icon={'eva:more-vertical-fill'} width={20} height={20}/>
                    </IconButton>
                )}
            </Box>

            <MenuPopover
                open={Boolean( open )}
                anchorEl={open}
                onClose={onClose}
                anchorOrigin={{vertical: 'top', horizontal: 'left'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                arrow="right-top"
                marginThreshold={marginThreshold ?? 16}
                sx={{
                    width: 'max-content',
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
                    '& .MuiMenuItem-root': {
                        pl: 2,
                        pr: 2,
                        typography: 'body2',
                        borderRadius: 0.75,
                        '& svg': {mr: 2, width: 20, height: 20},
                    },
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    msOverflowStyle: 'none',  /* IE and Edge */
                    scrollbarWidth: 'none',/* Firefox */
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    ...sxMenuPopover,
                }}
                {...props}
            >
                {actions}
            </MenuPopover>
        </>
    );
}
