import * as React from "react";
import PropTypes from "prop-types";
// @mui
import {Avatar, Box, ListItemAvatar, ListItemButton, ListItemText, Typography} from "@mui/material";
// hooks
import useLocales from "../../../hooks/useLocales";
// redux
import { useDispatch } from "../../../redux/store";
import { updateNotificationStatus, getUnreadNotificationNum } from "../../../redux/slices/notification";
// api
import { markNotificationAsRead as markNotificationAsReadAPI } from "../../../api/notification";
// utils
import { fDateNotificationTime } from "../../../utils/formatTime";

// ----------------------------------------------------------------------

const IMAGE_BASE_URL = 'https://shipsaving.s3.us-west-2.amazonaws.com/resources/public';

NotificationItem.propTypes = {
    notification: PropTypes.object,
    isDialog: PropTypes.bool,
};

export default function NotificationItem({ notification, isDialog }) {
    const { translate, currentLang } = useLocales();

    const dispatch = useDispatch();

    const handleMarkAsRead = async () => {
        if (!notification.is_readed) {
            try {
                const id = notification.id;

                const response = await markNotificationAsReadAPI([id]);

                const { status, data } = response;

                if (status === 200 && data) {
                    dispatch(updateNotificationStatus(id));
                    dispatch(getUnreadNotificationNum());
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    return (
        <ListItemButton
            alignItems="flex-start"
            sx={{
                py: `${isDialog ? '24px' : '12px'}`,
                px: 2.5,
                mt: '1px',
                ...(!notification?.is_readed ? {
                    bgcolor: '#EDEDED',
                    '&.MuiListItemButton-root:hover': {
                        bgcolor: '#EDEDED',
                    },
                    '& .dot':{
                        height: '10px',
                        width: '10px',
                        backgroundColor: '#00AB55',
                        borderRadius: '50%',
                        display:'inline-block',
                    }
                } : {
                    '&.MuiListItemButton-root:hover': {
                        bgcolor: 'transparent !important',
                        cursor: 'unset',
                    },
                    '& .MuiListItemButton-root.Mui-forcusVisible': {
                        bgcolor: 'transparent !important',
                    },
                }),
                '&.MuiListItemButton-root:not(:last-child)::after': {
                    content: '""',
                    height: '1px',
                    width: 'calc(100% - 40px)',
                    position: 'absolute',
                    bottom: 0,
                    right: '20px',
                    bgcolor: '#E6E6E6',
                },
            }}
            onClick={handleMarkAsRead}
        >
            <ListItemAvatar sx={{ mt: 0 }}>
                <Avatar
                    alt="notification"
                    src={`${IMAGE_BASE_URL}/assets/icon/navbar/ic_${notification?.content_type?.toLowerCase()}.svg`}
                />
            </ListItemAvatar>
            <ListItemText
                primary={
                    <Box sx={{ display:'flex', justifyContent:'space-between' }}>
                        <Box>
                            <Typography
                                variant="h4"
                                sx={{ mb: 0.5, '& a': { color: '#292929' } }}
                                dangerouslySetInnerHTML={{
                                    __html: currentLang.value === 'cn' ? notification?.title_zh : notification?.title_en,
                                }}
                            />
                            <Typography
                                variant="body1"
                                sx={{ '& a': { color: '#292929' } }}
                                dangerouslySetInnerHTML={{
                                    __html: currentLang.value === 'cn' ? notification?.content_zh : notification?.content_en,
                                }}
                            />
                        </Box>
                        <Box>
                            <Typography component="span" className={"dot"} />
                        </Box>
                    </Box>
                }
                secondary={
                    <Typography width={1} display="flex" direction="row" justifyContent="space-between" alignItems="center" sx={{ pt: 1.25 }}>
                        <Typography
                            component="span" variant="caption"
                            sx={{ textTransform: 'capitalize', color: '#9D9D9D' }}
                        >
                            {(() => {
                                switch (notification?.content_type) {
                                    case "system":
                                        return `${translate('notifications_5_1')}`;
                                    case "carrier":
                                        return `${translate('notifications_5_2')}`;
                                    default:
                                        return `${notification?.content_type}${translate('notifications_5_3')}`;
                                }
                            })()}
                        </Typography>
                        <Typography
                            component="span"
                            variant="caption"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                color: '#9D9D9D',
                            }}
                        >
                            {fDateNotificationTime(notification?.start_at)}
                        </Typography>
                    </Typography>
                }
            />
        </ListItemButton>
    );
}
