// utils
import axios from '../utils/axios';

// ----------------------------------------------------------------------

const getAddressList = (filter) => axios.post('/address/list', {
    // eslint-disable-next-line no-nested-ternary
    residential: filter?.residential === 'residential' ? true : filter?.residential === 'commercial' ? false : null,
    // eslint-disable-next-line no-nested-ternary
    keyword: filter?.keyword === 'all' ? null : (filter?.keyword ? filter.keyword.trim() : null),
    per_page: filter?.rowsPerPage || 5,
    current_page: filter?.page || 0,
    order_by: [
        {
            name: "created_at",
            sorting: filter?.sort || "desc"
        }
    ]
});

const getAddress = (id) => axios.get('/address/info', {
    params: {
        id
    }
});

const addAddress = (data) => axios.post('/address/add', { ...data });

const modifyAddress = (data) => axios.post('/address/modify', { ...data });

const deleteAddress = (id) => axios.delete('/address/delete', {
    params: {
        id
    }
});

const validateAddress = (data) => axios.post('/address/validate', { ...data });

const extractAddress = (address) => axios.post('/address/extract', {
    address
});

// ----------------------------------------------------------------------

export {
    getAddressList,
    getAddress,
    addAddress,
    modifyAddress,
    deleteAddress,
    validateAddress,
    extractAddress
}
