import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { isBefore } from 'date-fns';
// @mui
import { Button, Typography } from '@mui/material';
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// hooks
import useResponsive from "../../../hooks/useResponsive";
import useAuth from "../../../hooks/useAuth";
// components
import Image from '../../../components/Image';
// utils
import { fDateTrail } from "../../../utils/formatTime";

// ----------------------------------------------------------------------

export default function TrailPopover() {
    const upMd = useResponsive('up', 'md');

    const { company } = useAuth();
    const { current_plan: cPlan, trial_end_date: trialEndDate, trial_end_time: trialEndTime } = company || {};
    const { id: cID } = cPlan || {};

    const [display, setDisplay] = useState(null);

    // ----------------------------------

    useEffect(() => {
        if (trialEndTime) {
            const time1 = new Date(`${trialEndTime}`);
            const time2 = new Date();

            setDisplay(isBefore(time2, time1));
        }
    }, [trialEndTime]);

    return (
        <>
            {upMd && cID > 1 && display && (
                <Button
                    component={RouterLink}
                    to={`${PATH_DASHBOARD.settings.account}/#plan`}
                    startIcon={
                        <Image disabledEffect src="/assets/icon/navbar/ic_trial.svg" alt="trial" sx={{ width: '24px', height: '24px', '& img': { objectFit: 'contain' } }}/>
                    }
                    sx={{
                        px: 1.5,
                    }}
                >
                    <Typography variant="body1">
                        Your trial ends in&nbsp;
                        <Typography component="span" variant="body2">{fDateTrail(`${trialEndDate}`)}</Typography>
                        &nbsp;(PST/PDT)
                    </Typography>
                </Button>
            )}
        </>
    );
}
