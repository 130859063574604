import React from "react";
import PropTypes from 'prop-types';
import { m, AnimatePresence } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, List, IconButton, LinearProgress, ListItemIcon, ListItemText, ListItem, Stack, Typography } from '@mui/material';
// utils
import { fData } from '../../utils/formatNumber';
import getFileData from '../../utils/getFileData';
//
import Image from '../Image';
import Iconify from '../Iconify';
import { varFade } from '../animate';

// ----------------------------------------------------------------------

SingleFilePreview.propTypes = {
    file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onRemove: PropTypes.func,
    onDownload: PropTypes.func,
    showPreview: PropTypes.bool,
    showProgress: PropTypes.bool,
    fileType: PropTypes.string,
    progress: PropTypes.number,
};

export default function SingleFilePreview({ showPreview = false, showProgress = false, file, onRemove, onDownload, fileType = 'image', progress = 0 }) {
    const { name, size, preview } = getFileData(file, 0);

    return (
        <List disablePadding sx={{ mt: 2.5 }}>
            <AnimatePresence>
                {showPreview ? (
                    <ListItem
                        component={m.div}{...varFade().inRight}
                        sx={{
                            p: 0,
                            m: 0.5,
                            width: 80,
                            height: 80,
                            borderRadius: 1.25,
                            overflow: 'hidden',
                            position: 'relative',
                            display: 'inline-flex',
                            border: (theme) => `solid 1px ${theme.palette.grey[800]}`,
                        }}
                    >
                        <Image alt="preview" src={preview} ratio="1/1"/>

                        {onRemove && (
                            <IconButton
                                size="small"
                                onClick={() => onRemove(file)}
                                sx={{
                                    top: 6,
                                    p: '2px',
                                    right: 6,
                                    position: 'absolute',
                                    color: 'common.white',
                                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                                    '&:hover': {
                                        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                                    },
                                }}
                            >
                                <Iconify icon={'eva:close-fill'}/>
                            </IconButton>
                        )}
                    </ListItem>
                ) : (
                    <ListItem
                        component={m.div}{...varFade().inRight}
                        sx={{
                            mt: 0,
                            px: 1.25,
                            py: 1,
                            borderRadius: 0.75,
                            border: (theme) => `solid 1px ${theme.palette.grey[800]}`,
                        }}
                    >
                        {showProgress && (
                            <Box sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }} zIndex={0}>
                                <LinearProgress
                                    variant="determinate" value={progress}
                                    sx={{
                                        height: 1,
                                        bgcolor: 'transparent',
                                        '& .MuiLinearProgress-bar': { bgcolor: '#E6F0FF' },
                                    }}
                                />
                            </Box>
                        )}

                        <ListItemIcon sx={{ mr: 1.25 }}>
                            <Image
                                src={`/assets/icon/system/ic_upload_${fileType}.svg`}
                                alt="upload_image"
                                sx={{ width: '48px', height: '48px', '& img': { borderRadius: '8px', objectFit: 'contain' } }}
                                visibleByDefault
                            />
                        </ListItemIcon>

                        <ListItemText
                            primary={
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                    <Stack display="inline-block" sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                        {typeof file === 'string' ? file : name}
                                    </Stack>

                                    {showProgress && (
                                        <Stack flex={0}>{`${progress}%`}</Stack>
                                    )}
                                </Stack>
                            }
                            // secondary={typeof file === 'string' ? '' : fData(size || 0)}
                            primaryTypographyProps={{ variant: 'body1', mr: '10px' }}
                            // secondaryTypographyProps={{ variant: 'caption' }}
                            sx={{ zIndex: 1 }}
                        />

                        {onRemove && (
                            <IconButton
                                edge="end" size="small" onClick={() => onRemove(file)}
                                sx={{
                                    p: '2px',
                                    color: 'common.white',
                                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                                    '&:hover': {
                                        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                                    },
                                }}
                            >
                                <Iconify icon={'eva:close-fill'}/>
                            </IconButton>
                        )}
                    </ListItem>
                )}
            </AnimatePresence>
        </List>
    );
}
