// utils
import axios from '../utils/axios';

// ----------------------------------------------------------------------

const getNotificationList = (filter) => axios.post('/notification/list', {
    filter_read: filter?.status || 'all',
    per_page: filter?.perPage || 5,
    current_page: filter?.page || 0,
    order_by: [
        {
            name: "created_at",
            sorting: filter?.sort || "desc"
        }
    ]
});

const getNotificationUnreadCount = () => axios.get('/notification/unread_count');

const markNotificationAsRead = (ids) => axios.post('/notification/mark_read', {
    notification_ids: ids || []
});

// ----------------------------------------------------------------------

export {
    getNotificationList,
    getNotificationUnreadCount,
    markNotificationAsRead
}
