import { getExportFile as getExportFileAPI, getExportTaskStatus as getExportTaskStatusAPI } from "../api/billing";

// ----------------------------------------------------------------------

export async function handleTask(id, onProgress, onSuccess, onFailed, count = 0) {
    try {
        const response = await getExportTaskStatusAPI(id);

        const { status, data } = response;
        const { status: taskStatus, percentage, result } = data || {};
        const { result_file: resultFile, file_name: name, errors } = result || {};

        if (status === 200) {
            if (taskStatus === 'COMPLETED') {
                onSuccess(resultFile || name || null);
            } else if (taskStatus === 'FAILED') {
                onFailed(errors || []);
            } else {
                const result = onProgress(percentage ?? 0);

                if (count < 51 && result) setTimeout(() => handleTask(id, onProgress, onSuccess, onFailed, count + 1).then(null), 2000);
            }
        } else {
            onFailed();
        }
    } catch (error) {
        onFailed();
    }
}

export async function handleDownload(name) {
    if (!name) return;

    try {
        const response = await getExportFileAPI(name);

        const { status, data } = response;

        if (status === 200 && data) {
            downloadFile(data, name);

            return data;
        }
        return null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export function getFile(response, name, type = 'application/pdf') {
    try {
        const { status, data: stream, headers } = response;

        let blob;
        let url;
        let downloadFileName = name;

        if (status === 200 && stream) {
            blob = new Blob([stream], { type });
            url = window.URL.createObjectURL(blob);

            if (headers['content-disposition'])
                downloadFileName = headers['content-disposition'].split(';')[2].split('filename=')[1].replaceAll('"', '');
        }

        return {
            file: blob,
            url,
            name: downloadFileName
        }
    } catch (error) {
        console.log(error);
    }
}

export function downloadFile(blob, name) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // 兼容ie浏览器
        window.navigator.msSaveOrOpenBlob(blob, name);
    } else {
        const url = window.URL.createObjectURL(blob);
        const downloadElement = document.createElement('a');
        downloadElement.style.display = 'none';
        downloadElement.href = url;
        downloadElement.download = name;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(url);
    }
}

export function openFile(stream, type = 'application/pdf') {
    const blob = new Blob([stream], { type });
    const url = window.URL.createObjectURL(blob);

    window.open(url);
}
