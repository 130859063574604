// utils
import axios from '../utils/axios';

// ----------------------------------------------------------------------

const getWarehouseList = (filter) => axios.post('/warehouse/list', {
    // eslint-disable-next-line no-nested-ternary
    residential: filter?.residential === 'residential' ? true : filter?.residential === 'commercial' ? false : null,
    // eslint-disable-next-line no-nested-ternary
    keyword: filter?.keyword === 'all' ? null : (filter?.keyword ? filter.keyword.trim() : null),
    order_by: [
        {
            name: "created_at",
            sorting: filter?.sort || "desc"
        }
    ]
});

const getWarehouse = (id) => axios.get('/warehouse/info', {
    params: {
        id
    }
});

const addWarehouse = (data) => axios.post('/warehouse/add', { ...data });

const modifyWarehouse = (data) => axios.post('/warehouse/modify', { ...data });

const deleteWarehouse = (id) => axios.delete('/warehouse/delete', {
    params: {
        id
    }
});

// ----------------------------------------------------------------------

export {
    getWarehouseList,
    getWarehouse,
    addWarehouse,
    modifyWarehouse,
    deleteWarehouse
}
