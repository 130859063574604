// ----------------------------------------------------------------------

// export function fBank(cc) {
//     const amex = /^3[47][0-9]{13}$/;
//     const visa = /^4[0-9]{12}(?:[0-9]{3})?$/;
//     const cup1 = /^62[0-9]{14}[0-9]*$/;
//     const cup2 = /^81[0-9]{14}[0-9]*$/;
//
//     const mastercard = /^5[1-5][0-9]{14}$/;
//     const mastercard2 = /^2[2-7][0-9]{14}$/;
//
//     const disco1 = /^6011[0-9]{12}[0-9]*$/;
//     const disco2 = /^62[24568][0-9]{13}[0-9]*$/;
//     const disco3 = /^6[45][0-9]{14}[0-9]*$/;
//
//     const diners = /^3[0689][0-9]{12}[0-9]*$/;
//     const jcb = /^35[0-9]{14}[0-9]*$/;
//
//
//     if (visa.test(cc)) {
//         return 'VISA';
//     }
//     if (amex.test(cc)) {
//         return 'AMEX';
//     }
//     if (mastercard.test(cc) || mastercard2.test(cc)) {
//         return 'MASTERCARD';
//     }
//     if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
//         return 'DISCOVER';
//     }
//     if (diners.test(cc)) {
//         return 'DINERS';
//     }
//     if (jcb.test(cc)) {
//         return 'JCB';
//     }
//     if (cup1.test(cc) || cup2.test(cc)) {
//         return 'CHINA_UNION_PAY';
//     }
//     return undefined;
// }

export function fBank(number) {
    // visa
    let re = /^4/;
    if (number.match(re) != null)
        return "Visa";

    // Mastercard
    // Updated for Mastercard 2017 BINs expansion
    if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number))
        return "Mastercard";

    // AMEX
    re = /^3[47]/;
    if (number.match(re) != null)
        return "AMEX";

    // Discover
    re = /^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/;
    if (number.match(re) != null)
        return "Discover";

    // Diners
    re = /^36/;
    if (number.match(re) != null)
        return "Diners";

    // Diners - Carte Blanche
    re = /^30[0-5]/;
    if (number.match(re) != null)
        return "Diners - Carte Blanche";

    // JCB
    re = /^35(2[89]|[3-8][0-9])/;
    if (number.match(re) != null)
        return "JCB";

    // Visa Electron
    re = /^(4026|417500|4508|4844|491(3|7))/;
    if (number.match(re) != null)
        return "Visa Electron";

    return "";
}

export function fExpiration(event) {
    const code = event.keyCode;
    const allowedKeys = [8];
    if (allowedKeys.indexOf(code) !== -1) return;

    event.target.value = event.target.value.replace(
        /^([1-9]\/|[2-9])$/g, '0$1/' // 3 > 03/
    ).replace(
        /^(0[1-9]|1[0-2])$/g, '$1/' // 11 > 11/
    ).replace(
        /^([0-1])([3-9])$/g, '0$1/$2' // 13 > 01/3
    ).replace(
        /^(0?[1-9]|1[0-2])([0-9]{4})$/g, '$1/$2' // 14141 > 01/4141
    ).replace(
        /^([0]+)\/|[0]+$/g, '0' // 0/ > 0 and 00 > 0
    ).replace(
        /[^\d/]|^[/]*$/g, '' // To allow only digits and `/`
    ).replace(
        /\/\//g, '/' // Prevent entering more than 1 `/`
    );
}

export function fCVV(event) {
    if (!event.target.value) return;

    event.target.value = event.target.value.toString().slice(0, 4)
}
