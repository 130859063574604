import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
// form
import { useFormContext } from 'react-hook-form';
// @mui
import { Box, InputAdornment, MenuItem, Stack, Typography } from "@mui/material";
// hooks
import useLocales from "../../hooks/useLocales";
// components
import { RHFSelect, RHFTextField } from "../hook-form";
import Image from "../Image";
// utils
import { fBank, fCVV } from "../../utils/formatCard";

// ----------------------------------------------------------------------

const MONTH_OPTIONS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

// ----------------------------------------------------------------------

/**
 * @function FormCreditCardInfo
 * @param {boolean} [isEdit=false] 默认值为false
 */

FormCreditCardInfo.propTypes = {
    isEdit: PropTypes.bool,
};

export default function FormCreditCardInfo({ isEdit }) {
    const { translate } = useLocales();

    const { watch } = useFormContext();

    const watched = watch();

    const [bankLogoURL, setBankLogoURL] = useState('');

    const [yearOptions, setYearOptions] = useState([]);

    useEffect(() => setYearOptions(Array.from({ length: 15 }, (_, i) => new Date().getFullYear() + i)), []);

    useEffect(() => {
        const bank = fBank(watched.number);

        if (bank) {
            const imageURL = `/assets/icon/billing/ic_${bank.toLowerCase()}.svg`;

            setBankLogoURL(imageURL);
        } else {
            setBankLogoURL('');
        }
    }, [watched.number]);

    return (
        <>
            <Stack spacing={1.25}>
                <RHFTextField
                    size="small"
                    name="number"
                    label={`${translate('dialog_add_card_1_3')}`}
                    onPaste={e => e.preventDefault()}
                    InputProps={{
                        endAdornment: (
                            bankLogoURL ? (
                                <InputAdornment position="end">
                                    <Image
                                        src={bankLogoURL}
                                        alt=""
                                        loading="lazy"
                                        sx={{ width: '32px', height: '22px', '& img': { objectFit: 'contain' } }}
                                    />
                                </InputAdornment>
                            ) : null
                        ),
                    }}
                    fullWidth
                    disabled={isEdit}
                />

                <Stack direction="row" alignItems="center" spacing={1.25}>
                    <Typography variant="body1" color="error">{`${translate('dialog_add_card_1_3_1')}`}</Typography>

                    <Stack direction="row" spacing={1}>
                        <Image
                            src="/assets/icon/billing/ic_mastercard.svg"
                            alt="mastercard"
                            loading="lazy"
                            sx={{ width: '24px', height: '16px', '& img': { objectFit: 'contain' } }}
                        />
                        <Image
                            src="/assets/icon/billing/ic_visa.svg"
                            alt="visa"
                            loading="lazy"
                            sx={{ width: '24px', height: '16px', '& img': { objectFit: 'contain' } }}
                        />
                        <Image
                            src="/assets/icon/billing/ic_amex.svg"
                            alt="amex"
                            loading="lazy"
                            sx={{ width: '24px', height: '16px', '& img': { objectFit: 'contain' } }}
                        />
                        <Image
                            src="/assets/icon/billing/ic_discover.svg"
                            alt="discover"
                            loading="lazy"
                            sx={{ width: '24px', height: '16px', '& img': { objectFit: 'contain' } }}
                        />
                    </Stack>
                </Stack>
            </Stack>

            <Box
                display="grid"
                rowGap={3.75}
                columnGap={2.5}
                gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}
            >
                <Box
                    display="grid"
                    rowGap={3.75}
                    columnGap={2.5}
                    gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}
                >
                    <RHFSelect
                        size="small"
                        name="expMonth"
                        label={`${translate('dialog_add_card_1_4')}`}
                        SelectProps={{
                            native: false,
                            MenuProps: {
                                sx: {
                                    maxHeight: '380px',
                                },
                            },
                        }}
                    >
                        {MONTH_OPTIONS.map((item, index) => (
                            <MenuItem key={`item-month-${item}-${index}`} value={item}>
                                <Typography variant="body1" minHeight="21px">{item}</Typography>
                            </MenuItem>
                        ))}
                    </RHFSelect>

                    <RHFSelect
                        size="small"
                        name="expYear"
                        label={`${translate('dialog_add_card_1_5')}`}
                        SelectProps={{
                            native: false,
                            MenuProps: {
                                sx: {
                                    maxHeight: '380px',
                                },
                            },
                        }}
                    >
                        {yearOptions.map((item, index) => (
                            <MenuItem
                                key={`item-year-${index}`}
                                value={item}
                            >
                                <Typography
                                    variant="body1"
                                    minHeight="21px"
                                >{item}</Typography>
                            </MenuItem>
                        ))}
                    </RHFSelect>
                </Box>

                <RHFTextField
                    size="small"
                    name="cvc"
                    label={`${translate('dialog_add_card_1_6')}`}
                    type="number"
                    onInput={fCVV}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Image
                                    src="/assets/icon/billing/ic_cvv.svg"
                                    alt="cvv"
                                    sx={{ width: '32px', height: '22px', '& img': { objectFit: 'contain' } }}
                                />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
        </>
    )
}
