import {createSlice} from '@reduxjs/toolkit';
// api
import {
    getStoreList as getStoreListAPI,
    getOptionStoreList as getOptionStoreListAPI,
    modifyStore as modifyStoreAPI,
    deleteStore as deleteStoreAPI,
    getConnectStoreList as getConnectStoreListAPI,
    addStore as addStoreAPI,
    getStoreAuthVerify as getStoreAuthVerifyAPI,
} from "../../api/store";
//
import {dispatch} from '../store';

// ----------------------------------------------------------------------

const initialState = {
    storeLocation: null,
    isLoading: false,
    error: null,
    optionStoreList: [],
    storeList: [],
    authSuccess: null,
    connectStoreList: null,
    isOpenAddModal: false,
    isOpenConnectModal: false,
};

const slice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // SET LOCATION
        setRequestedLocationSuccess(state, action) {
            state.storeLocation = action.payload;
        },

        // RESET LOCATION
        resetRequestedLocation(state) {
            state.storeLocation = null;
        },

        // GET OPTION STORES
        getOptionStoresSuccess(state, action) {
            state.isLoading = false;
            state.optionStoreList = action.payload;
        },

        // GET STORES
        getStoresSuccess(state, action) {
            state.isLoading = false;
            state.storeList = action.payload;
        },

        // ADD STORE
        addStoreSuccess(state) {
            state.isLoading = false;
            state.isOpenAddModal = false;
            state.isOpenConnectModal = false;
        },

        // MODIFY STORE
        modifyStoreSuccess(state) {
            state.isLoading = false;
            state.isOpenAddModal = false;
        },

        // DELETE STORE
        deleteStoreSuccess(state) {
            state.isLoading = false;
        },

        // UPDATE AUTH SUCCESS
        updateAuthSuccess(state, action) {
            state.authSuccess = action.payload;
        },

        // GET CONNECT STORE LIST SUCCESS
        getConnectStoresSuccess(state, action) {
            state.connectStoreList = action.payload;
        },

        // OPEN ADD MODAL
        openAddModal(state) {
            state.isOpenAddModal = true;
        },

        closeAddModal(state) {
            state.isOpenAddModal = false;
        },

        // OPEN CONNECT MODAL
        openConnectModal(state) {
            state.isOpenConnectModal = true;
        },

        closeConnectModal(state) {
            state.isOpenConnectModal = false;
        },

        // RESET STORE
        resetStore(state) {
            state.isLoading = false;
            state.isOpenAddModal = false;
            state.isOpenConnectModal = false;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {updateAuthSuccess, openAddModal, closeAddModal, resetStore, openConnectModal, closeConnectModal} = slice.actions;

// ----------------------------------------------------------------------

export function setStoreLocation(url) {
    return () => dispatch(slice.actions.setRequestedLocationSuccess(url));
}

// ----------------------------------------------------------------------

export function resetStoreLocation() {
    return () => dispatch(slice.actions.resetRequestedLocation());
}

// ----------------------------------------------------------------------

export function getOptionStoreList() {
    return async (dispatch, getState) => {
        try {
            const response = await getOptionStoreListAPI();

            const {status, data} = response;

            if (status === 200) {
                dispatch(slice.actions.getOptionStoresSuccess(data ?? []));
                return data.length || 0;
            }
            return 0;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            const {optionStoreList} = getState().store;
            return optionStoreList.length || 0;
        }
    };
}

// ----------------------------------------------------------------------

export function getStoreList() {
    return async () => {
        try {
            const response = await getStoreListAPI();

            const {status, data} = response;

            if (status === 200) {
                dispatch(slice.actions.getStoresSuccess(data ?? []));
                return data
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function addStore(store) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await addStoreAPI(store);

            const {status, data} = response;

            if (status === 200 && data?.result === 'success') {
                dispatch(slice.actions.addStoreSuccess(store));
                dispatch(slice.actions.updateAuthSuccess({success: true}));
                dispatch(getStoreList());
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function modifyStore(store) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await modifyStoreAPI(store);

            const {status, data} = response;

            if (status === 200 && data?.result === 'success') {
                dispatch(slice.actions.modifyStoreSuccess(store));
                dispatch(getStoreList());
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function deleteStore(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await deleteStoreAPI(id);
            const {status, data} = response;

            if (status === 200 && data?.result === 'success') {
                dispatch(getStoreList());
                dispatch(slice.actions.deleteStoreSuccess(id));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getConnectStores() {
    return async () => {
        try {
            const response = await getConnectStoreListAPI();
            const {status, data} = response;

            if (status === 200 && data) {
                dispatch(slice.actions.getConnectStoresSuccess(data ?? null));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function addStoreNew(request) {
    return async () => {
        try {
            const response = await getStoreAuthVerifyAPI(request);
            const {status, data} = response;

            if (status === 200 && data) {
                dispatch(getStoreList());
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


