// utils
import axios from '../utils/axios';

// ----------------------------------------------------------------------

const getUser = () => axios.get('/user/profile/get');

const avatarUpload = (file) => {
    const formData = new FormData();
    formData.append('file', file);

    return axios.post('/user/profile/avatar/upload', formData);
};

const modifyUser = (user) => axios.post('/user/profile/modify', { ...user });

const changePassword = (oldPassword, newPassword) => axios.post('/user/change_password', {
    old_password: oldPassword,
    new_password: newPassword,
});

const getCompany = () => axios.get('/company/info');

const getCompanyPlan = () => axios.get('/company/plan/info');

const modifyCompany = (company) => axios.post('/company/modify', { ...company });

const getCompanyReferralLink = () => axios.get('/company/get_referral_link');

// ----------------------------------

const getTour = () => axios.post('/user/tour/get');

const modifyTour = (type) => axios.post('/user/tour/modify', null, {
    params: {
        type,
    },
});

// ----------------------------------

const getUserAccountList = () => axios.get('/user/list');

const getUserAccountInfo = (id) => axios.get('/user/info', {
    params: {
        id,
    },
});

const addUserAccount = (user) => axios.post('/user/add', { ...user });

const modifyUserAccount = (user) => axios.post('/user/modify', { ...user });

const activeUserAccount = (id, isActive) => axios.post('/user/activate', {
    id,
    status: isActive,
});

const deleteUserAccount = (id) => axios.delete('/user/delete', {
    params: {
        id,
    },
});

const resendActiveEmail = (id) => axios.get('/user/resend_mail', {
    params: {
        id,
    },
});

// ----------------------------------------------------------------------

export {
    getUser,
    avatarUpload,
    modifyUser,
    changePassword,
    getCompany,
    getCompanyPlan,
    modifyCompany,
    getCompanyReferralLink,
    // ----------------------------------
    getTour,
    modifyTour,
    // ----------------------------------
    getUserAccountList,
    getUserAccountInfo,
    addUserAccount,
    modifyUserAccount,
    activeUserAccount,
    deleteUserAccount,
    resendActiveEmail,
};
