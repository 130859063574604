import * as Yup from 'yup';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Box, Stack, Typography, Button, Link, MenuItem, FormHelperText } from '@mui/material';
import { LoadingButton } from "@mui/lab";
// api
import { ordersImportTemplate as ordersImportTemplateAPI, ordersImport as ordersImportAPI } from "../../../../api/order";
// hooks
import useLocales from "../../../../hooks/useLocales";
// redux
import { useSelector } from "../../../../redux/store";
// components
import Image from "../../../../components/Image";
import { FormProvider, RHFUploadSingleFile, RHFSelect } from "../../../../components/hook-form";
import FileList from "../../../../components/customized/FileList";
// utils
import { handleTask, downloadFile, getFile } from "../../../../utils/handleTask";

// ----------------------------------------------------------------------

OrderImport.propTypes = {
    onClose: PropTypes.func,
    onMore: PropTypes.func,
    onSuccess: PropTypes.func,
};

export default function OrderImport({ onClose, onMore, onSuccess }) {
    const { translate } = useLocales();

    const { enqueueSnackbar } = useSnackbar();

    const { optionStoreList } = useSelector(({ store }) => store);

    const [importable, setImportable] = useState(true);
    const [storeOptions, setStoreOptions] = useState([]);

    const ImportSchema = Yup.object().shape({
        storeId: Yup.string().required(`${translate('input_schema_order_import_form_1')}`),
        file: Yup.object().nullable().required(`${translate('input_schema_order_import_form_2')}`),
    });

    const defaultValues = {
        storeId: '',
        file: null,
    };

    const methods = useForm({
        resolver: yupResolver(ImportSchema),
        defaultValues,
    });

    const {
        setValue,
        getValues,
        watch,
        handleSubmit,
        setError,
        clearErrors,
        formState: { errors, isSubmitting },
    } = methods;

    const watched = watch();

    const handleClearErrors = () => clearErrors('afterSubmit');

    const handleOnDrop = useCallback((acceptedFiles) => {
        handleClearErrors();

        const file = acceptedFiles[0];

        if (file) {
            setValue(
                'file',
                {
                    status: '',
                    percentage: 0,
                    file: Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    }),
                },
            );
        }
    }, [setValue]);

    const handleOnRemove = () => {
        setImportable(true);

        setValue('file', null);

        handleClearErrors();
    };

    const handleDownloadOnProgress = (percentage) => {
        const checkTarget = getValues(`file`);

        if (!checkTarget) return false;

        setValue(`file.percentage`, Math.round(percentage));
        return true;
    };

    const handleDownloadOnSuccess = () => {
        setImportable(true);

        setValue(`file.status`, 'succeed');
        setValue(`file.percentage`, 100);

        onSuccess();
    };

    const handleDownloadOnFailed = (errors) => {
        setImportable(true);

        setValue(`file.status`, 'failed');
        setValue(`file.percentage`, 100);

        if (errors && errors.length > 0) {
            setError('afterSubmit', {
                message: (
                    <FormHelperText error sx={{ display: 'flex', px: 0.5 }}>
                        Error&nbsp;!&nbsp;&nbsp;
                        <Link
                            component={Button} underline="always" sx={{ height: 'fit-content', p: 0, bgcolor: 'transparent !important', color: 'error.main', fontSize: '13px', fontWeight: '400', lineHeight: '1.125rem' }}
                            onClick={() => onMore(errors)}
                        >
                            Read more...
                        </Link>
                    </FormHelperText>
                ),
            });

            enqueueSnackbar('There was an error uploading this file. Please try again later.', { variant: 'warning' });
        }
    };

    const handleDownloadTemplate = async () => {
        ordersImportTemplateAPI().then((response) => {
            const result = getFile(response, "import_order_template.xlsx");

            if (result) downloadFile(result.file, result.name);
        });
    };

    const onSubmit = async (values) => {
        try {
            handleClearErrors();

            setImportable(false);

            setValue(`file.status`, '');
            setValue(`file.percentage`, 0);

            const result = await ordersImportAPI(values.storeId, values.file.file);

            const { status, data } = result;

            if (status === 200) {
                await handleTask(data?.id, handleDownloadOnProgress, handleDownloadOnSuccess, handleDownloadOnFailed);
            } else {
                handleDownloadOnFailed();
            }
        } catch (error) {
            console.error(error);
            handleDownloadOnFailed();
        }
    };

    // ----------------------------------

    useEffect(() => {
        const tempOptions = [];

        (optionStoreList || []).forEach(_store => _store.platform === "customized" && tempOptions.push({ value: _store.id, label: _store.name }));

        setStoreOptions(tempOptions);
    }, [optionStoreList]);

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3.75}>
                <Stack spacing={2.5}>
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                        <Typography variant="h3">
                            {`${translate('orders_18_1')}`}
                        </Typography>

                        <Link
                            component={Button} variant="h4" underline="always" sx={{ height: 'fit-content', p: 0, bgcolor: 'transparent !important', color: '#2C6CD2', fontWeight: '400' }}
                            onClick={handleDownloadTemplate}
                            disableRipple
                        >
                            {`${translate('orders_18_2')}`}
                        </Link>
                    </Stack>

                    <RHFSelect name="storeId" label={`${translate('orders_2_1')}`} size="small" SelectProps={{ native: false }}>
                        {storeOptions.map((option) => (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                                sx={{
                                    mx: 1,
                                    my: 0.5,
                                    borderRadius: 0.75,
                                    typography: 'body2',
                                    // textTransform: 'capitalize',
                                }}
                            >
                                {`${translate(option.label)}`}
                            </MenuItem>
                        ))}
                    </RHFSelect>


                </Stack>
                <Stack>
                    <RHFUploadSingleFile
                        name="file"
                        accept=".xls, .xlsx, .xlsm, .xlsb"
                        maxSize={3145728}
                        onDrop={handleOnDrop}
                        purpose="order_form"
                        fileType="xls"
                        showFile={false}
                        disabled={!importable}
                    />

                    {watched.file && (
                        <FileList detail={watched.file} onRemove={handleOnRemove}/>
                    )}

                    {!!errors.afterSubmit && <Box>{errors.afterSubmit.message}</Box>}
                </Stack>

                <Stack direction="row" justifyContent="flex-end" spacing={3.75}>
                    <Button variant="text" sx={{ width: 'fit-content', height: 'fit-content', p: 0, color: 'rgba(41, 41, 41, 0.4)', '&:hover': { bgcolor: 'transparent' } }} onClick={onClose} disableRipple>
                        {`${translate('orders_18_7')}`}
                    </Button>

                    <LoadingButton type="submit" variant="text" sx={{ width: 'fit-content', height: 'fit-content', p: 0, color: '#394559', '&:hover': { bgcolor: 'transparent' } }} loading={isSubmitting} disableRipple>
                        {`${translate('orders_18_8')}`}
                    </LoadingButton>
                </Stack>
            </Stack>
        </FormProvider>
    );
}
