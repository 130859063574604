import PropTypes from 'prop-types';
import React, { useImperativeHandle, useRef, useEffect, useMemo, useState, forwardRef } from "react";
// @mui
import { Alert, Box, Dialog, Stack, Tab, Tabs, Typography } from '@mui/material';
// form
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// hooks
import useLocales from "../../hooks/useLocales";
import useTabs from "../../hooks/useTabs";
// redux
import { useDispatch, useSelector } from "../../redux/store";
// components
import Scrollbar from '../../components/Scrollbar';
import { FormProvider, RHFTextField } from "../../components/hook-form";
import { FormBillingInfo, FormCreditCardInfo } from "../../components/form";
import GradientLoadingButton from '../../components/customized/GradientLoadingButton';
// utils
import { fAbsoluteNumber } from '../../utils/formatNumber';
import { modifyMethod, payoffBalance } from "../../redux/slices/payment";

// ----------------------------------------------------------------------

const CardPayForm = forwardRef(({ detail, balance, onClose }, ref) => {
    const { translate } = useLocales();

    const dispatch = useDispatch();

    const CardSchema = Yup.object().shape({
        name: Yup.string().matches(/^[A-Za-z ]*$/, `${translate('input_schema_card_form_1_1')}`).required(`${translate('input_schema_card_form_1')}`),
        number: Yup.string().required(`${translate('input_schema_card_form_2')}`),
        expMonth: Yup.string().required(`${translate('input_schema_card_form_3')}`),
        expYear: Yup.string().required(`${translate('input_schema_card_form_4')}`),
        cvc: Yup.string().required(`${translate('input_schema_card_form_5')}`),
        street1: Yup.string().required(`${translate('input_schema_card_form_6')}`),
        city: Yup.string().required(`${translate('input_schema_card_form_7')}`),
        state: Yup.string().nullable().required(`${translate('input_schema_card_form_8')}`),
        zipcode: Yup.string().required(`${translate('input_schema_card_form_9')}`),
    });

    const getDefaultValues = (detail) => {
        const { billing, credentials } = detail || {};
        const { name, street1, street2, city, state, zipcode } = billing || {};
        const { last4, expMpnth, expYear } = credentials || {};

        return {
            name: name || '',
            number: last4 ? `**** **** **** ${last4}` : '',
            expMonth: expMpnth || '',
            expYear: expYear || '',
            cvc: '',
            street1: street1 || '',
            street2: street2 || '',
            city: city || '',
            state: state || '',
            zipcode: zipcode || '',
            country: 'US',
        };
    };

    const defaultValues = useMemo(() => getDefaultValues(detail), [detail]);

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(CardSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { errors },
    } = methods;

    const onSubmit = async (values) => {
        try {
            const methodDetail = {
                type: "CARD",
                plateform: "BOA",
                card: {
                    number: detail?.credentials?.last4 || '',
                    cvc: values.cvc,
                    expMonth: values.expMonth,
                    expYear: values.expYear,
                },
                billing: {
                    name: values.name || '',
                    email: '',
                    phone: '',
                    company: '',
                    street1: values.street1 || '',
                    street2: values.street2 || '',
                    city: values.city || '',
                    state: values.state || '',
                    zipcode: values.zipcode || '',
                    country: "US",
                },
                is_default: values.isDefault || false,
            };

            const result = await dispatch(payoffBalance({ id: detail.id, ...methodDetail }, balance));

            onClose(result);
        } catch (error) {
            console.error(error);
        }
    };

    useImperativeHandle(ref, () => handleSubmit(onSubmit));

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            const anchorName = Object.keys(errors)[0];
            if (anchorName) {
                const anchorEArr = document.getElementsByName(anchorName);
                if (anchorEArr.length > 0) {
                    anchorEArr[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }
        }
    }, [errors]);

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3.75} mb={3.75} pt={3}>
                <Stack spacing={3.75}>
                    <RHFTextField
                        size="small"
                        name="name"
                        label={`${translate('dialog_add_card_1_2')}`}
                    />

                    <FormCreditCardInfo isEdit={Boolean(true)}/>
                </Stack>

                <Stack spacing={2.5}>
                    <Typography variant="body2">{`${translate('dialog_add_card_1_7')}`}</Typography>

                    <FormBillingInfo displayCountry disableCountry/>
                </Stack>

                {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
            </Stack>
        </FormProvider>
    );
});

CardPayForm.propTypes = {
    detail: PropTypes.object,
    balance: PropTypes.number,
    onClose: PropTypes.func,
};

// ----------------------------------------------------------------------

PayNowDialog.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
};

export default function PayNowDialog({ open, onClose, onSuccess }) {
    const { translate } = useLocales();

    const { isLoading, methodList, balance } = useSelector((state) => state.payment);

    const { currentTab, onChangeTab } = useTabs("");

    const tab1Ref = useRef(null);
    const tab2Ref = useRef(null);

    const [init, setInit] = useState(false);
    const [tabs, setTabs] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);

    const handleChangeTab = (event, newValue) => {
        onChangeTab(event, newValue);

        const targetIndex = tabs.findIndex((t) => `${t.id}` === newValue);
        setActiveIndex(targetIndex);
    }

    useEffect(() => {
        if (methodList.length > 0) {
            const list = methodList.filter(m => m.status === 'VERIFIED');

            setTabs(list);
        }
    }, [methodList, setTabs]);

    useEffect(() => {
        if (tabs.length > 0 && !init) {
            setInit(true);
        }

        if (tabs.length > 0 && !currentTab && !init) {
            setTimeout(() => {
                onChangeTab(null, `${tabs[0].id}`)
            }, 0);
        }
    }, [tabs, init, currentTab]);

    return (
        <Dialog fullWidth open={open} onClose={onClose} sx={{ '& .MuiPaper-root': { borderRadius: 1 } }}>
            <Stack sx={{ height: 'calc( 100% - 120px )', overflow: "auto" }}>
                <Box sx={{ px: 3.75, pt: 3.75, pb: 0 }}>
                    {init && (
                        <>
                            <Tabs
                                allowScrollButtonsMobile
                                variant="scrollable"
                                scrollButtons="auto"
                                value={currentTab}
                                TabIndicatorProps={{ color: 'primary' }}
                                sx={{ minHeight: '34px', borderBottom: '1px solid #E6E6E6' }}
                                onChange={handleChangeTab}

                            >
                                {tabs.map((tab) => (
                                    <Tab
                                        key={tab.id}
                                        label={`${tab?.credentials?.brand}${' ending in '}${tab?.credentials?.last4} `}
                                        value={`${tab.id}`}
                                        sx={{
                                            minHeight: '34px',
                                            textTransform: 'capitalize',
                                        }}
                                        disableRipple
                                    />
                                ))}
                            </Tabs>

                            {tabs.map((tab, index) => {
                                const isMatched = `${tab.id}` === `${currentTab}`;

                                return (
                                    <Box key={tab.id} sx={{ display: isMatched ? "block" : "none" }}>
                                        {index === 0 ? (
                                            <CardPayForm ref={tab1Ref} detail={tab} balance={Math.abs(balance)} onClose={onSuccess}/>
                                        ) : (
                                            <CardPayForm ref={tab2Ref} detail={tab} balance={Math.abs(balance)} onClose={onSuccess}/>
                                        )}
                                    </Box>
                                );
                            })}
                        </>
                    )}
                </Box>
            </Stack>

            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", px: 3.75, py: 1.875, boxShadow: "4px 0 10px 0 #0000001A" }}>
                <Typography variant="h3">{`${translate('Payment Total: ')} ${fAbsoluteNumber(balance)}`}</Typography>

                {activeIndex === 0 ? (
                    <GradientLoadingButton type="button" loading={isLoading} sx={{ width: "100%", maxWidth: '118px' }} onClick={tab1Ref.current}>
                        {`${translate('Pay Now')}`}
                    </GradientLoadingButton>
                ) : (
                    <GradientLoadingButton type="button" loading={isLoading} sx={{ width: "100%", maxWidth: '118px' }} onClick={tab2Ref.current}>
                        {`${translate('Pay Now')}`}
                    </GradientLoadingButton>
                )}
            </Box>
        </Dialog>
    );
}
