// utils
import axios from '../utils/axios';

// ----------------------------------------------------------------------

const getCompanyAutomation = () => axios.get('/company/automation/info');

const modifyCompanyAutomation = (data) => axios.post('/company/automation/modify', { ...data });

// ----------------------------------------------------------------------

export { getCompanyAutomation, modifyCompanyAutomation };
