import * as React from "react";
import PropTypes from "prop-types";
// @mui
import { Box, Button, Dialog, Divider, List, Stack, Typography } from "@mui/material";
// hooks
import useLocales from "../../../hooks/useLocales";
// redux
import { useDispatch, useSelector } from "../../../redux/store";
import { getNotificationList, markNotificationsAsRead } from "../../../redux/slices/notification";
// components
import Scrollbar from "../../../components/Scrollbar";
import Image from "../../../components/Image";
//
import NotificationItem from "./NotificationItem";

// ----------------------------------------------------------------------

NotificationDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
}

export default function NotificationDialog({ open, onClose }) {
    const { translate } = useLocales();

    const dispatch = useDispatch();

    const { notifications, totalUnRead, notificationFilter } = useSelector(({ notification }) => notification);

    const handleFilterChanged = (status) => {
        if (notificationFilter?.status !== status) {
            const filter = {
                status,
                perPage: notificationFilter?.perPage || 5,
                page: 0,
            }
            dispatch(getNotificationList(filter, false));
        }
    }

    const handleUpdateNotifications = () => {
        const filter = {
            status: notificationFilter?.status || 'all',
            perPage: notificationFilter?.perPage || 5,
            page: notificationFilter?.page + 1 || 0,
        }
        dispatch(getNotificationList(filter, true));
    }

    const handleMarkAllAsRead = () => {
        dispatch(markNotificationsAsRead([]));
    };

    return (
        <Dialog fullWidth open={open} onClose={onClose} PaperProps={{ style: { borderRadius: 8 } }}>
            <Stack width={1}
                   direction="row"
                   justifyContent="space-between"
                   alignItems="center"
                   sx={{ px: 2.5, pt: 5, pb: 3.75, backgroundColor: '#2C6CD2' }}
            >
                <Typography variant="h2" color={'#fff'}>{`${translate('dialog_notifications_1')}`}</Typography>

                <Stack direction="row" spacing={0.5}>
                    {(() => {
                        let count = 0;
                        notifications.forEach((_notification, index) => {
                            if (index < 3 && !_notification.is_readed) {
                                count += 1;
                            }
                        });

                        if (count > 0) {
                            return (
                                <Button
                                    sx={{ color: '#fff', mr: '-8px' }}
                                    onClick={handleMarkAllAsRead}
                                    disabled={count <= 0}
                                >
                                    {`${translate('notifications_2')}`}
                                </Button>
                            )
                        }

                        return null;
                    })()}
                </Stack>
            </Stack>
            <Scrollbar>
                <Stack>
                    {notifications?.length > 0 ?
                        <>
                            <List disablePadding>
                                {notifications.map((notification) => (
                                    <NotificationItem key={`dialog-${notification.id}`} notification={notification} isDialog/>
                                ))}
                            </List>
                            {notificationFilter?.total > (notificationFilter?.perPage * (notificationFilter?.page + 1)) &&
                                <>
                                    <Divider sx={{ borderStyle: 'solid' }}/>

                                    <Box sx={{ p: 1 }}>
                                        <Button fullWidth disableRipple sx={{ color: '#292929' }} onClick={handleUpdateNotifications}>
                                            {`${translate('dialog_notifications_3')}`}
                                        </Button>
                                    </Box>
                                </>
                            }
                        </>
                        :
                        <Box justifyContent="center" alignItems="center" sx={{ py: 6.25, px: 2, textAlign: 'center' }}>
                            <Image src='/assets/icon/navbar/ic_message.svg' alt='message' sx={{ width: "32px", height: '24px', mx: 'auto', mb: 1.75 }}/>
                            <Typography variant="body1" sx={{ color: '#9D9D9D', mb: 0.5 }}>{`${translate('dialog_notifications_4')}`}</Typography>
                        </Box>
                    }
                </Stack>
            </Scrollbar>
        </Dialog>
    )
}
