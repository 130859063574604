import { format } from "date-fns";

// ----------------------------------------------------------------------

export function fCarrier(data) {
    if (!data) return '';

    return data.replaceAll('_', ' ');
}

export function fService(data) {
    if (!data) return '';

    return data.replaceAll('_', ' ');
}

export function fDeliveryService(carrier, service) {
    if (!carrier && !service) return '-';

    const c = fCarrier(carrier);
    const s = fCapitalize(fService(service));

    return [c, s].join(' ').trim();
}

// export function fCapitalize(data) {
//     if (!data) return '';
//
//     const words = data.toLowerCase().split(" ");
//
//     // eslint-disable-next-line no-plusplus
//     for (let i = 0; i < words.length; i++) {
//         words[i] = words[i][0].toUpperCase() + words[i].substring(1);
//     }
//
//     return words.join(" ");
// }

export function fCapitalize(data) {
    if (!data) return '';

    const words = data.toLowerCase();

    return words.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
}

export function fShipment(data) {
    if (!data) return '';

    const DATE = format(data, 'yyyy-MM-dd');
    const TIME = format(data, 'HH:mm');
    const TIMEZONE = format(data, 'xxx');

    return `${DATE}T${TIME}:00${TIMEZONE}`
}
