// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_AUTH = '';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    register: path(ROOTS_AUTH, '/signup'),
    registerPartner: path(ROOTS_AUTH, '/signup-partner'),
    resetPassword: path(ROOTS_AUTH, '/forgot_password'),
    newPassword: path(ROOTS_AUTH, '/reset_password'),
    verified: path(ROOTS_AUTH, '/verified_email'),
    loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
    registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
    verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
    paymentComplete: '/payment-complete',
    comingSoon: '/coming-soon',
    maintenance: '/maintenance',
    pricing: '/pricing',
    payment: '/payment',
    about: '/about-us',
    contact: '/contact-us',
    faqs: '/faqs',
    page403: '/403',
    page404: '/404',
    page500: '/500',
    components: '/components',
};

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    shipments: {
        root: '/shipments',
        create: '/shipments/create',
        shipmentsView: (id) => `/shipments/${id}`,
        pickup: '/shipments/pickup/schedule',
        scanForm: '/shipments/scan-form',
    },
    orders: {
        root: '/orders',
        ordersView: (id) => `/orders/${id}`,
    },
    items: {
        root: '/items',
    },
    reports: {
        root: '/reports',
    },
    partner: {
        root: '/partner-tool',
        partnerView: (id) => `/partner-tool/${id}`,
    },
    settings: {
        root: '/settings',
        account: '/settings/account',
        // zelle: '/settings/zellehistory',
        transactions: '/settings/transactions',
        // transactionsView: (id) => `/settings/transactions/${id}`,
        // billings: '/settings/billings',
        // rebates: '/settings/rebates',
        integration: '/settings/integration',
        configuration: '/settings/configuration',
    },
    calculator: '/quickrate',
    // ----------------------------------------------------------------------
    permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';

export const PATH_UPS_AGREEMENT = '/ups-agreement';

export const PATH_LABEL_SHARE = (code) => `/label/${code}`;

// ----------------------------------------------------------------------

export const PATH_EXTERNAL = {
    mainSite: 'https://www.shipsaving.com',
    tutorials: 'https://www.youtube.com/watch?v=ifA_BJwWLjs&list=PLu8E_4Z-kU0hL0Ibx9oZwNZcAPXcoFYwJ',
    shipsavingFeatures: 'https://www.shipsaving.com/en/features',
    shipsavingTerms: 'https://www.shipsaving.com/en/terms',
    shipsavingPartners: 'https://www.shipsaving.com/en/partners',
    upsGuide: 'https://www.canva.com/design/DAEe23d4zL0/dX-urCesPJjNKd2qrh6lgQ/view?utm_content=DAEe23d4zL0&utm_campaign=designshare&utm_medium=link&utm_source=sharebutton',
    mps: 'https://www.shipsaving.com/blog/what-is-multi-piece-shipment',
    dhlPickup: 'https://mydhl.express.dhl/us/en/schedule-pickup.html#/schedule-pickup#label-reference',
    ontracPickup: 'https://www.ontrac.com/schedulepickup.asp',
    uspsScanForm: 'https://www.shipsaving.com/blog/5-reasons-why-you-must-use-usps-scan-form',
    fedexPickup: 'https://www.fedex.com/en-us/shipping/schedule-manage-pickups.html',
    upsPickup: 'https://wwwapps.ups.com/pickup/schedule?loc=en_US',
    shipsavingBlogShippingGuideGeneralDangerousAndProhibitedItems: 'https://www.shipsaving.com/blog/shipping-guide-general-dangerous-and-prohibited-items',
    upsFindLocations: 'https://www.ups.com/dropoff?loc=en_us',
};

export const PATH_FILES = {
    redemptionRequestForm: 'https://shipsaving.s3.us-west-2.amazonaws.com/resources/public/files/WITHDRAWAL+REQUEST+FORM+-+SHIPSAVING.pdf',
    redemptionRequestFormStandard: 'https://shipsaving.s3.us-west-2.amazonaws.com/resources/public/files/REDEMPTION+REQUEST+FORM+STANDARD+-+SHIPSAVING.pdf',
    importOrderTemplate: 'https://shipsaving.s3.us-west-2.amazonaws.com/resources/public/files/import_order_template.xlsx',
};
