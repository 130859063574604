import {endOfDay, endOfMonth, format} from 'date-fns';

// ----------------------------------------------------------------------

export function getTodayDate() {
    // const start = new Date();
    const start = new Date(new Date().toLocaleDateString());
    start.setHours(0, 0, 0, 0);
    const startResult = format(start, 'yyyy-MM-dd HH:mm:SSxxx').replace(' ', 'T');

    // const end = new Date();
    const end = new Date(new Date().toLocaleDateString());
    end.setDate(end.getDate() + 1);
    end.setHours(0, 0, 0, 0);
    const endResult = format(end, 'yyyy-MM-dd HH:mm:SSxxx').replace(' ', 'T');

    return {
        startTime: startResult,
        endTime: endResult,
    };
}

export function getLast24hDate() {
    // const today = Math.round(new Date().getTime());
    const today = Math.round(new Date(new Date().toLocaleDateString()).getTime());

    const last24h = today - (24 * 3600 * 1000);
    const startResult = format(new Date(last24h).setSeconds(0, 590), 'yyyy-MM-dd HH:mm:SSxxx').replace(' ', 'T');

    return {
        startTime: startResult,
        endTime: null,
    };
}

export function getPreviousDate(day) {
    // const today = Math.round(new Date().getTime());
    const today = Math.round(new Date(new Date().toLocaleDateString()).getTime());

    const previousDay = today - (day * 24 * 3600 * 1000);
    const startResult = format(new Date(previousDay).setSeconds(0, 590), 'yyyy-MM-dd HH:mm:SSxxx').replace(' ', 'T');

    const end = new Date().setSeconds(0, 590);
    const endResult = format(end, 'yyyy-MM-dd HH:mm:SSxxx').replace(' ', 'T');

    return {
        startTime: startResult,
        endTime: endResult,
    };
}

export function getPreviousDateWithoutEnd(day) {
    // const today = Math.round(new Date().getTime());
    const today = Math.round(new Date(new Date().toLocaleDateString()).getTime());

    const previousDay = today - (day * 24 * 3600 * 1000);
    const startResult = format(new Date(previousDay).setHours(0, 0, 0, 0), 'yyyy-MM-dd HH:mm:SSxxx').replace(' ', 'T');

    return {
        startTime: startResult,
        endTime: null,
    };
}

export function getPreviousDateToYesterdayShort(day) {
    const today = Math.round(new Date(new Date().toLocaleDateString()).getTime());

    const previousDay = today - (day * 24 * 3600 * 1000);
    const startResult = format(new Date(previousDay).setHours(0, 0, 0, 0), 'yyyy-MM-dd HH:mm:SSxxx').replace(' ', 'T');

    const yesterday = new Date(today - (24 * 3600 * 1000)).setHours(0, 0, 0, 0);
    const endResult = format(yesterday, 'yyyy-MM-dd HH:mm:SSxxx').replace(' ', 'T');

    return {
        startTime: startResult,
        endTime: endResult,
    };
}

export function getPreviousDateToYesterday(day) {
    // const today = Math.round(new Date().getTime());
    const today = Math.round(new Date(new Date().toLocaleDateString()).getTime());

    const previousDay = today - (day * 24 * 3600 * 1000);
    const startResult = format(new Date(previousDay).setHours(0, 0, 0, 0), 'yyyy-MM-dd HH:mm:SSxxx').replace(' ', 'T');

    // const yesterday = today - (24 * 3600 * 1000);
    const yesterday = new Date().setHours(0, 0, 0, 0);
    // const endResult = format(new Date(yesterday).setHours(23, 59, 0, 590), 'yyyy-MM-dd HH:mm:SSxxx').replace(' ', 'T');
    const endResult = format(yesterday, 'yyyy-MM-dd HH:mm:SSxxx').replace(' ', 'T');

    return {
        startTime: startResult,
        endTime: endResult,
    };
}

export function getPreviousMonthDate(date) {
    // const year = new Date().getFullYear();
    const year = new Date(new Date().toLocaleDateString()).getFullYear();
    // const month = new Date().getMonth() - date;
    const month = new Date(new Date().toLocaleDateString()).getMonth() - date;
    // const monthNext = new Date().getMonth() - (date - 1);

    const previousYear = new Date(year, month, 1);
    const startResult = format(new Date(previousYear), 'yyyy-MM-dd HH:mm:SSxxx').replace(' ', 'T');

    const end = new Date(endOfMonth(new Date(year, month, 1))).setSeconds(0, 590);
    const endResult = format(end, 'yyyy-MM-dd HH:mm:SSxxx').replace(' ', 'T');

    return {
        startTime: startResult,
        endTime: endResult,
    };
}

export function getPreviousMonthDateToYesterday(date) {
    // const year = new Date().getFullYear();
    const year = new Date(new Date().toLocaleDateString()).getFullYear();
    // const month = new Date().getMonth() - date;
    const month = new Date(new Date().toLocaleDateString()).getMonth() - date;
    // const monthNext = new Date().getMonth() - (date - 1);

    const previousYear = new Date(year, month, 1);
    const startResult = format(new Date(previousYear), 'yyyy-MM-dd HH:mm:SSxxx').replace(' ', 'T');

    const end = new Date().setHours(0, 0, 0, 0);
    const endResult = format(end, 'yyyy-MM-dd HH:mm:SSxxx').replace(' ', 'T');

    return {
        startTime: startResult,
        endTime: endResult,
    };
}

export function getPreviousYearDate(date) {
    // const year = new Date().getFullYear() - (date - 1);
    const year = new Date(new Date().toLocaleDateString()).getFullYear() - (date - 1);

    const previousYear = new Date(year, 0, 1);
    const startResult = format(new Date(previousYear), 'yyyy-MM-dd HH:mm:SSxxx').replace(' ', 'T');

    // const end = new Date(year, 11, 31);
    // end.setHours(23, 59, 59, 999);
    // const end = new Date(year + 1, 0, 1);
    const end = new Date(year, 11, 31);
    const endResult = format(end, 'yyyy-MM-dd HH:mm:SSxxx').replace(' ', 'T');

    return {
        startTime: startResult,
        endTime: endResult,
    };
}

export function getPreviousYearDateToYesterday(date) {
    // const year = new Date().getFullYear() - (date - 1);
    const year = new Date(new Date().toLocaleDateString()).getFullYear() - (date - 1);

    const previousYear = new Date(year, 0, 1);
    const startResult = format(new Date(previousYear), 'yyyy-MM-dd HH:mm:SSxxx').replace(' ', 'T');

    const end = new Date().setHours(0, 0, 0, 0);
    const endResult = format(end, 'yyyy-MM-dd HH:mm:SSxxx').replace(' ', 'T');

    return {
        startTime: startResult,
        endTime: endResult,
    };
}

export function getCustomDate(date) {
    const customDate = new Date(date);

    return format(customDate, 'yyyy-MM-dd HH:mm:SSxxx').replace(' ', 'T');
}

export function getAppointedDate(dateSelection, date) {
    let tempTime = {startTime: '', endTime: ''};

    switch (dateSelection) {
        case 'all':
            tempTime = {
                startTime: null,
                endTime: null,
            };
            break;
        case 'today':
            tempTime = getTodayDate();
            break;
        case 'last24h':
            tempTime = getLast24hDate();
            break;
        case 'last7d':
            tempTime = getPreviousDateWithoutEnd(7);
            break;
        case 'last30d':
            tempTime = getPreviousDateWithoutEnd(30);
            break;
        case 'last45d':
            tempTime = getPreviousDateWithoutEnd(45);
            break;
        case 'last1m':
            tempTime = getPreviousMonthDate(1);
            break;
        case 'this1m':
            tempTime = getPreviousMonthDate(0);
            break;
        case 'this1y':
            tempTime = getPreviousYearDate(1);
            break;
        case 'this2y':
            tempTime = getPreviousYearDate(2);
            break;
        case 'this3y':
            tempTime = getPreviousYearDate(3);
            break;
        case 'last7dToN':
            tempTime = getPreviousDate(7);
            break;
        case 'last30dToN':
            tempTime = getPreviousDate(30);
            break;
        case 'last45dToN':
            tempTime = getPreviousDate(45);
            break;
        case 'last7dToYS':
            tempTime = getPreviousDateToYesterdayShort(7);
            break;
        case 'last30dToYS':
            tempTime = getPreviousDateToYesterdayShort(30);
            break;
        case 'last7dToY':
            tempTime = getPreviousDateToYesterday(7);
            break;
        case 'last30dToY':
            tempTime = getPreviousDateToYesterday(30);
            break;
        case 'this1mToY':
            tempTime = getPreviousMonthDateToYesterday(0);
            break;
        case 'this1yToY':
            tempTime = getPreviousYearDateToYesterday(1);
            break;
        case 'this2yToY':
            tempTime = getPreviousYearDateToYesterday(2);
            break;
        case 'this3yToY':
            tempTime = getPreviousYearDateToYesterday(3);
            break;
        case 'custom':
            tempTime = {
                startTime: getCustomDate(date.startDate),
                endTime: getCustomDate(endOfDay(new Date(date.endDate))),
            };
            break;
        default:
            break;
    }

    return tempTime;
}
