// @mui
import { enUS, zhCN } from '@mui/material/locale';
// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------

// export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';
export const HOST_API = 'https://app-service.shipsaving.us';
// export const HOST_API = 'http://192.168.1.70:8080';

export const FIREBASE_API = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.root; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

// export const HEADER = {
//     MOBILE_HEIGHT: 64,
//     MAIN_DESKTOP_HEIGHT: 88,
//     DASHBOARD_DESKTOP_HEIGHT: 92,
//     DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
// };

export const HEADER = {
    MOBILE_HEIGHT: 60,
    MAIN_DESKTOP_HEIGHT: 88,
    DASHBOARD_DESKTOP_HEIGHT: 60,
    DASHBOARD_DESKTOP_OFFSET_HEIGHT: 60,
};

export const NAVBAR = {
    BASE_WIDTH: 260,
    BASE_DRAWER_WIDTH: 280,
    DASHBOARD_WIDTH: 240,
    DASHBOARD_COLLAPSE_WIDTH: 88,
    //
    DASHBOARD_ITEM_ROOT_HEIGHT: 48,
    DASHBOARD_ITEM_SUB_HEIGHT: 40,
    DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
    NAVBAR_ITEM: 22,
    NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
    themeMode: 'light',
    themeDirection: 'ltr',
    themeContrast: 'default',
    themeLayout: 'horizontal',
    themeColorPresets: 'default',
    // themeColorPresets: 'blue',
    themeStretch: false,
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
    {
        label: 'English(USA)',
        value: 'en',
        systemValue: enUS,
        icon: '/assets/icon/language/ic_language_us.svg',
    }, {
        label: '简体中文',
        value: 'cn',
        systemValue: zhCN,
        icon: '/assets/icon/language/ic_language_cn.svg',
    },
    // {
    //     label: 'English',
    //     value: 'en',
    //     systemValue: enUS,
    //     icon: '/assets/icons/flags/ic_flag_en.svg',
    // },
    // {
    //     label: 'French',
    //     value: 'fr',
    //     systemValue: frFR,
    //     icon: '/assets/icons/flags/ic_flag_fr.svg',
    // },
    // {
    //     label: 'Vietnamese',
    //     value: 'vn',
    //     systemValue: viVN,
    //     icon: '/assets/icons/flags/ic_flag_vn.svg',
    // },
    // {
    //     label: 'Chinese',
    //     value: 'cn',
    //     systemValue: zhCN,
    //     icon: '/assets/icons/flags/ic_flag_cn.svg',
    // },
    // {
    //     label: 'Arabic (Sudan)',
    //     value: 'ar',
    //     systemValue: arSD,
    //     icon: '/assets/icons/flags/ic_flag_sa.svg',
    // },
];

const langBrowserZH = (navigator?.languages ?? []).findIndex(value => value.includes("zh"));
const langBrowserEN = (navigator?.languages ?? []).findIndex(value => value.includes("en"));
// eslint-disable-next-line no-nested-ternary
const langBrowser = (langBrowserZH > -1 && langBrowserEN > -1) ? (langBrowserZH < langBrowserEN ? allLangs[1] : allLangs[0]) : (langBrowserZH < 0 ? allLangs[0] : langBrowserEN < 0 ? allLangs[1] : allLangs[0]);

export const defaultLang = langBrowser || allLangs[0]; // English
