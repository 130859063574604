import PropTypes from "prop-types";
import React from "react";
// @mui
import { Box, Stack } from "@mui/material";
// hooks
import useLocales from "../../hooks/useLocales";
// components
import { RHFTextField } from "../hook-form";
import { FormState, FormCountry } from "./index";

// ----------------------------------------------------------------------

/**
 * @function FormBillingInfo
 * @description if has default, country {String} - e.g "US" or "CA"
 * @description if has default, state {String} - e.g "CA" or "California"
 * @param {boolean} [displayCountry=false] 默认值为false
 * @param {boolean} [disableCountry=false] 默认值为false
 */

FormBillingInfo.propTypes = {
    stateName: PropTypes.string,
    countryName: PropTypes.string,
    displayCountry: PropTypes.bool,
    disableCountry: PropTypes.bool,
};

export default function FormBillingInfo({ stateName = "state", countryName = 'country', displayCountry = false, disableCountry = false }) {
    const { translate } = useLocales();

    return (
        <Stack spacing={3.75}>
            <RHFTextField name="street1" label={`${translate('form_street1')}`} size="small"/>

            <RHFTextField name="street2" label={`${translate(`form_street2`)}`} size="small"/>

            <Box display="grid" rowGap={3.75} columnGap={2.5} gridTemplateColumns={{ xs: '1fr', sm: displayCountry ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)' }}>
                <RHFTextField name="city" label={`${translate('form_city')}`} size="small"/>

                <FormState name={stateName} countryName={countryName} label={`${translate('form_state')}`} size="small"/>

                <RHFTextField name="zipcode" label={`${translate('form_zipcode')}`} size="small"/>

                {displayCountry && (
                    <FormCountry name={countryName} disabled={disableCountry}/>
                )}
            </Box>
        </Stack>
    );
}
