import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import AuthLayout from '../layouts/auth';
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import ErrorContainer from "../components/ErrorContainer";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')}/>}>
            <Component {...props} />
        </Suspense>
    );
};

export default function Router() {
    return useRoutes([
        {
            path: '/',
            element: (
                <GuestGuard>
                    <AuthLayout/>
                </GuestGuard>
            ),
            children: [
                { path: 'login', element: <Login/> },
                { path: 'signup', element: <Register/> },
                { path: 'signup-partner', element: <RegisterPartner/> },
                { path: 'verified_email', element: <Verified/> },
                // { path: 'forgot_password', element: <ResetPassword/> },
                // { path: 'reset_password', element: <NewPassword/> },
                // { path: 'verified_email', element: <Verified/> },
                // { path: 'login-unprotected', element: <Login/> },
                // { path: 'register-unprotected', element: <Register/> },
                // { path: 'verify', element: <VerifyCode/> },
            ],
        },
        { path: '/forgot_password', element: <ResetPassword/> },
        { path: '/reset_password', element: <NewPassword/> },

        // Dashboard Routes
        {
            path: '/',
            element: (
                <AuthGuard>
                    <ErrorContainer>
                        <DashboardLayout/>
                    </ErrorContainer>
                </AuthGuard>
            ),
            children: [
                { element: <Navigate to={PATH_AFTER_LOGIN} replace/>, index: true },
                { path: 'dashboard', element: <Dashboard/> },
                {
                    path: 'shipments',
                    children: [
                        { element: <Shipments/>, index: true },
                        { path: 'create', element: <ShipmentsCreate/> },
                        { path: ':id', element: <ShipmentsDetail/> },
                        { path: 'pickup/schedule', element: <ShipmentsPickupSchedule/> },
                        { path: 'scan-form', element: <ShipmentsScanForm/> },
                    ],
                },
                {
                    path: 'orders',
                    children: [
                        { element: <Orders/>, index: true },
                        { path: ':id', element: <OrdersDetail/> },
                    ],
                },
                {
                    path: 'items',
                    children: [
                        { element: <Items/>, index: true },
                    ],
                },
                { path: 'reports', element: <Reports/> },
                {
                    path: 'partner-tool',
                    children: [
                        { element: <PartnerTool/>, index: true },
                        { path: ':id', element: <PartnerToolDetail/> },
                    ],
                },
                { path: 'quickrate', element: <Calculator/> },
                {
                    path: 'settings',
                    children: [
                        { path: 'account', element: <SettingAccount/> },
                        // {path: 'zellehistory', element: <SettingZelleHistoryList/>},
                        { path: 'transactions', element: <SettingTransactionList/> },
                        // { path: 'billings', element: <SettingBillingList/> },
                        { path: 'integration', element: <SettingIntegration/> },
                        { path: 'configuration', element: <SettingConfiguration/> },
                    ],
                },
                { path: 'stores/:platform', element: <StoresAuth/> },
            ],
        },
        { path: '/payment-complete', element: <PaymentComplete/> },
        { path: '/ups-agreement', element: <UPSAgreement/> },
        { path: '/label/:code', element: <LabelShare/> },

        // Template Routes
        // {
        //     path: '/dashboard',
        //     element: (
        //         <AuthGuard>
        //             <DashboardLayout/>
        //         </AuthGuard>
        //     ),
        //     children: [
        //         { element: <Dashboard/>, index: true },
        //         { path: 'permission-denied', element: <PermissionDenied/> },
        //     ],
        // },

        // Routes
        {
            path: '*',
            element: <LogoOnlyLayout/>,
            children: [
                { path: 'coming-soon', element: <ComingSoon/> },
                { path: 'maintenance', element: <Maintenance/> },
                { path: '500', element: <Page500/> },
                { path: '404', element: <Page404/> },
                { path: '403', element: <Page403/> },
                { path: '*', element: <Navigate to="/404" replace/> },
            ],
        },
        { path: '*', element: <Navigate to="/404" replace/> },
    ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const RegisterPartner = Loadable(lazy(() => import('../pages/auth/RegisterPartner')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const Verified = Loadable(lazy(() => import('../pages/auth/Verified')));
// const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD
const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')));
const Shipments = Loadable(lazy(() => import('../pages/dashboard/Shipments')));
const Orders = Loadable(lazy(() => import('../pages/dashboard/Orders')));
const Items = Loadable(lazy(() => import('../pages/dashboard/Items')));
const Reports = Loadable(lazy(() => import('../pages/dashboard/Reports')));
const PartnerTool = Loadable(lazy(() => import('../pages/dashboard/PartnerTool')));
const PartnerToolDetail = Loadable(lazy(() => import('../pages/dashboard/PartnerToolDetail')));

// SHIPMENTS
const ShipmentsCreate = Loadable(lazy(() => import('../pages/dashboard/ShipmentsCreate')));
const ShipmentsDetail = Loadable(lazy(() => import('../pages/dashboard/ShipmentsDetail')));
const ShipmentsPickupSchedule = Loadable(lazy(() => import('../pages/dashboard/ShipmentsPickupSchedule')));
const ShipmentsScanForm = Loadable(lazy(() => import('../pages/dashboard/ShipmentsScanForm')));

// ORDERS
const OrdersDetail = Loadable(lazy(() => import('../pages/dashboard/OrdersDetail')));

// SETTING
const SettingAccount = Loadable(lazy(() => import('../pages/dashboard/SettingAccount')));
const SettingTransactionList = Loadable(lazy(() => import('../pages/dashboard/SettingTransactionList')));
const SettingZelleHistoryList = Loadable(lazy(() => import('../pages/dashboard/SettingZelleHistoryList')));
const SettingBillingList = Loadable(lazy(() => import('../pages/dashboard/SettingBillingList')));
const SettingIntegration = Loadable(lazy(() => import('../pages/dashboard/SettingIntegration')));
const SettingConfiguration = Loadable(lazy(() => import('../pages/dashboard/SettingConfiguration')));

// CALCULATOR
const Calculator = Loadable(lazy(() => import('../pages/dashboard/Calculator')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
const PaymentComplete = Loadable(lazy(() => import('../pages/PaymentComplete')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));

// Label Share
const LabelShare = Loadable(lazy(() => import('../pages/LabelShare')));

// Stores OAuth
const StoresAuth = Loadable(lazy(() => import('../pages/dashboard/StoresAuth')));

// Other
const UPSAgreement = Loadable(lazy(() => import('../pages/UPSAgreement')));
