// ----------------------------------------------------------------------

import { Box, Stack, Typography } from "@mui/material";
import React from "react";

const breakpoints = {
    values: {
        xs: 0,
        sm: 576,
        md: 768,
        // lg: 992,
        lg: 960,
        xl: 1200,
        xxl: 2160,
    }
};

export default breakpoints;


//     <Stack display='flex' flexDirection='column' alignItems='center' width='36px'>
//         <Box sx={{ width: '12px', height: '12px', borderRadius: '50%', bgcolor: 'success.main', }}/>
//         <Stack width='2px' height={1} sx={{ border: '1px dashed #141B22' }}/>
//     </Stack>
//     <Stack mt={-0.5} pb={1}>
//         <Typography variant="subtitle2" mb={0.5}>Label Purchased</Typography>
//         <Typography variant="caption" component='p' color='text.secondary'>
//             06/12/2022 at 14:255
//         </Typography>
//     </Stack>
//
//
//     <Stack display='flex' flexDirection='row' alignItems='center' height='36px'>
//         <Box sx={{ width: '12px', height: '12px', borderRadius: '50%', bgcolor: 'success.main', }}/>
//         <Stack width={1} height='2px' sx={{ border: '1px dashed #141B22' }}/>
//     </Stack>
//     <Stack pr={1}>
//         <Typography variant="subtitle2" mb={0.5}>Label Purchased</Typography>
//         <Typography variant="caption" component='p' color='text.secondary'>
//             06/12/2022 at 14:255
//         </Typography>
//     </Stack>
