import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Button } from '@mui/material';
//
import BlockContent from './BlockContent';
import RejectionFiles from './RejectionFiles';
import MultiFilePreview from './MultiFilePreview';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
    outline: 'none',
    padding: theme.spacing(2.5, 1),
    borderRadius: '8px',
    backgroundColor: '#FAFAFA',
    border: `1px dashed #292929`,
    '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

// ----------------------------------------------------------------------

UploadMultiFile.propTypes = {
    files: PropTypes.array.isRequired,
    error: PropTypes.bool,
    showPreview: PropTypes.bool,
    showFiles: PropTypes.bool,
    onUpload: PropTypes.func,
    onRemove: PropTypes.func,
    onRemoveAll: PropTypes.func,
    helperText: PropTypes.node,
    sx: PropTypes.object,
};

export default function UploadMultiFile({
                                            error,
                                            showPreview = false,
                                            showFiles = true,
                                            files,
                                            onUpload,
                                            onRemove,
                                            onRemoveAll,
                                            helperText,
                                            sx,
                                            ...other
                                        }) {
    const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
        ...other,
    });

    return (
        <Box sx={{ width: '100%', ...sx }}>
            <DropZoneStyle
                {...getRootProps()}
                sx={{
                    ...(isDragActive && { opacity: 0.72 }),
                    ...((isDragReject || error) && {
                        color: 'error.main',
                        borderColor: 'error.light',
                        bgcolor: 'error.lighter',
                    }),
                }}
            >
                <input {...getInputProps()} />

                <BlockContent/>
            </DropZoneStyle>

            {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections}/>}

            {showFiles && (
                <MultiFilePreview files={files} showPreview={showPreview} onRemove={onRemove}/>
            )}

            {files.length > 0 && (
                <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
                    {onRemoveAll && (
                        <Button color="inherit" size="small" onClick={onRemoveAll}>
                            Remove all
                        </Button>
                    )}
                    {onUpload && (
                        <Button size="small" variant="contained" onClick={onUpload}>
                            Upload files
                        </Button>
                    )}
                </Stack>
            )}

            {helperText && helperText}
        </Box>
    );
}
