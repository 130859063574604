import PropTypes from "prop-types";
import React from "react";
import { useFormContext } from "react-hook-form";
// @mui
import { Box, Stack } from "@mui/material";
// hooks
import useLocales from "../../hooks/useLocales";
// components
// import { AutocompleteAddress } from "../autocomplete-address";
import AutocompleteAddress from "../AutocompleteAddress";
import { RHFTextField } from "../hook-form";
import { FormState, FormCountry } from "./index";

// ----------------------------------------------------------------------

/**
 * @function FormAddress
 * @description if has default, country {String} - e.g "US" or "CA"
 * @description if has default, state {String} - e.g "CA" or "California"
 */


FormAddress.propTypes = {
    stateName: PropTypes.string,
    countryName: PropTypes.string,
};

export default function FormAddress({ stateName = "state", countryName = 'country' }) {
    const { translate } = useLocales();

    const { watch } = useFormContext();

    const watched = watch();

    return (
        <Stack spacing={3.75}>
            {/* <AutocompleteAddress label={`${translate('form_street1')}`} size="small"/> */}

            <AutocompleteAddress
                name="street1"
                label={`${translate('form_street1')}`}
                values={watched.street1}
                city="city"
                state={stateName}
                zip="zipcode"
                country={countryName}
                size="small"
            />

            <RHFTextField name="street2" label={`${translate(`form_street2`)}`} size="small"/>

            <Box display="grid" rowGap={3.75} columnGap={2.5} gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}>
                <RHFTextField name="city" label={`${translate('form_city')}`} size="small"/>

                <FormState name={stateName} countryName={countryName} label={`${translate('form_state')}`} size="small"/>

                <RHFTextField name="zipcode" label={`${translate('form_zipcode')}`} size="small"/>

                <FormCountry name={countryName}/>
            </Box>
        </Stack>
    );
}
